import React, { useState, useEffect } from "react";
import "bootstrap-daterangepicker/daterangepicker.css";
import { customStyles, useStyles } from "./../../Utilities/CSSUtilities";
 import girl from "../images/girl.png";
 import male from "../images/male.jpg";
 import other from "../images/other.jpg";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import google1 from './../../Images/google1.png';
import twitter1 from './../../Images/twitter1.png';
import fb from './../../Images/fb.png';
import laptop from './../../Images/laptop.jpg';
import { Link,useParams, useNavigate } from "react-router-dom";
import {
Card,
CardBody,
CardSubtitle,
CardText,
Col,
Row,
Button,
} from "reactstrap";
import axios from "axios";
import base_url from "../../api/bootapi";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Select from 'react-select';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { 
  setStarted,
  selectStarted,
  setStopped,
  selectStopped,
  logout,
  selectEmployee,
  setSessionIdData,
  selectSessionId
 } from "../../components/features/empSlice";
import { useToasts } from 'react-toast-notifications';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import $ from 'jquery'; 


const UserProfile = (props) => {
  const classes = useStyles();
  const params = useParams();
  const jwtToken = "JWT " + localStorage.getItem("JWTToken");    
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [backdropOpen, setBackdropOpen] = useState(false);
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});
  const formData = new FormData();
  const [inputtype, setinputtype] = useState("password");
  const dispatch = useDispatch();
  const employeeData = useSelector(selectEmployee);
  const startedData = useSelector(selectStarted);
  const stoppedData = useSelector(selectStopped);
  const sessionIdData = useSelector(selectSessionId);
  const { addToast } = useToasts();


  useEffect(() => {
    loadUsers(params.user_id);
    loadCompany();
    getPermissions();
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
  },[]);


    const [newvalue, setValue] = React.useState('1');
    const [companyData, setCompanyData] = useState({});
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

  const getPermissions = () => {
    getRoles();
    userByRoleidAll();
    getManagerData();
  };


  const inputEvent = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setUserData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  }; 

  const goback = () => {
    navigate('/profile');
  };  

  const onSubmit = (data2) => {  
    console.log("entering");
    let primary_image = data2.image.item(0);
    if(primary_image){
    formData.append("image", primary_image, primary_image.name);
    }

    let first_name = data2.first_name;
    let last_name = data2.last_name;
    let email = data2.email;
    let phone = data2.phone;
    let date_of_birth = data2.date_of_birth;
    let gender = data2.gender;
    let role_name = selectedRoleName;
    let role_id = Number(selectedRoleId);
    let total_leaves = Number(data2.total_leaves);
    let used_leaves = Number(data2.used_leaves);
    let status = JSON.parse(data2.status);
    let designation = data2.designation;
    let department = data2.department;
    let company_id = employeeData.company_id;
    let company_code = employeeData.company_code;
    let reporting_manager_name = selectedReportingManagerName;
    let reporting_manager_id = Number(selectedReportingManagerId);
    let manager = projecManagerData;

    let data = {
      first_name,
      last_name,
      email,
      phone,
      date_of_birth,
      gender,
      role_name,
      total_leaves,
      used_leaves,
      role_id,
      status,
      designation,
      department,
      company_id ,
      company_code,
      reporting_manager_id,
      reporting_manager_name,
      manager,
    };
      console.log(data);
      formData.append("data", JSON.stringify(data));
      postUser(formData);
  };

  const loadUsers = () => {
    setBackdropOpen(true);
    axios
      .get(`${base_url.api1}/myappapi/users/getuser?company_code=` + employeeData.company_code +`&user_id=` + params.user_id, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        let userDetails = response.data.Users;
        let projectManagerData= response.data.Users.project_managers;
        let ManagerData_obj = [];
        for (let i = 0; i < projectManagerData.length; i++) {
          let data = {
            value: projectManagerData[i].manager_id,
            label: projectManagerData[i].manager_name,
          };
          ManagerData_obj.push(data);
        } 
        if (ManagerData_obj.length == 0) {
          setProjecManagerState(true);
        } else {
          setProjecManagerState(false);
        }
        setSelectedProjectManager1(ManagerData_obj);
        setSelectedProjectManager(ManagerData_obj);
        setUserData({
          first_name: userDetails.first_name,
          last_name: userDetails.last_name,
          email: userDetails.email,
          phone: userDetails.phone,
          date_of_birth: userDetails.date_of_birth,
          gender: userDetails.gender,
          role_id: userDetails.role_id,
          role_name: userDetails.role_name,
          total_leaves: userDetails.total_leaves,
          used_leaves: userDetails.used_leaves,
          status: userDetails.status,
          reporting_manager_name: userDetails.reporting_manager_name,
          reporting_manager_id:userDetails.reporting_manager_id,
          designation: userDetails.designation,
          department: userDetails.department,
          company_id : userDetails.company_id,
          company_code : userDetails.company_code
        });
        setSelectedRoleId(userDetails.role_id);
        setSelectedRoleName(userDetails.role_name);
        setgetSelected(userDetails.reporting_manager_id);
        setBackdropOpen(false);
      })
      .catch((error) => {
        console.log(error);
        setBackdropOpen(false);
      });
  };

  const postUser = (data) => {
    setBackdropOpen(true);
    axios 
      .put(`${base_url.api1}/myappapi/users/update?company_code=` + employeeData.company_code + `&user_id=` + params.user_id ,data, {
        headers: {
          Authorization: jwtToken,  
        },
      })
      .then(
        (response) => {
          addToast(response.data.message, {
            appearance: "success",
            autoDismiss: true,
          });
          setBackdropOpen(false);
          navigate(`/employee-list`);
        },
        (error) => {
          addToast(error.response.data.message, {
            appearance: "error",
            autoDismiss: true,
          });
          console.log(JSON.stringify(error.response.data.message));
          setBackdropOpen(false);
        }
      );
  };

  const [selectedRoleId, setSelectedRoleId] = useState(); 
  const [availableroles, setAvailableroles] = useState([]);
  const [selectedRoleName, setSelectedRoleName] = useState();
  const getRoles = () => {
    setBackdropOpen(true);
    axios
      .get(`${base_url.api1}/myappapi/role/list?company_code=` + employeeData.company_code, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        console.log(response.data.Role);
        setAvailableroles(response.data.Role);
        setBackdropOpen(false);
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response.data.message));
        console.log(JSON.stringify(error));
        setBackdropOpen(false);
      });
  };
  const lookup = availableroles;


  const handleChangeRole = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    console.log(optionElementId);
    const optionElementValue = optionElement.getAttribute("value");
    console.log(optionElementValue); 

    setSelectedRoleId(optionElementId);
    setSelectedRoleName(optionElementValue);
  };

  const [availableRoleById, setAvailableRoleById] = useState([]);
  const userByRoleidAll = () => {
    setBackdropOpen(true);
    axios
      .get(`${base_url.api1}/myappapi/all_users_by_role?role_id=` + 1 + `,` + 3 + `&company_code=` + employeeData.company_code , {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        console.log(response.data.Users);
        setAvailableRoleById(response.data.Users);
        setBackdropOpen(false);
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
        setBackdropOpen(false);
      });
  };

  const look = availableRoleById;
  // console.log(look);

  const [getselected, setgetSelected] = useState(-1); 


  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const [baseimage, setbaseimage] = useState("");
  const [logofilesize, setlogofilesize] = useState(false);
  const uploadLogo = async (data) => {
    const file = data.target.files[0];
    formData.append("image", file, file.name);
    const base64 = await convertBase64(file);
    setbaseimage(base64);
  };
  const chklogosize = (e) => {
    var chk = false;
    const numberfiles = e.target.files;
    for (let i = 0; i < numberfiles.length; i++) {
      if (numberfiles[i].size / 1048576 > 10) {
        chk = true;
      }
    }
    setlogofilesize(chk);
  };

  let userImage;
  if(employeeData.photo) {
    userImage = base_url.file1 + employeeData.photo.split('/var/www/crm.odisoft.in/')[1];
    console.log(userImage);
  }
  const [availableManager, setAvailableManager] = useState();
  const [managerData, setManager] = useState();
  const getManagerData = () => {
    axios
      .get(`${base_url.api1}/myappapi/all_users_by_role?role_id=1,3&company_code=` + employeeData.company_code , {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        // console.log(response.data.Users);
         setManager(response.data.Users);
        let manager_data = response.data.Users;
        const manager_object=[];
        for (let i = 0; i < manager_data.length; i++) {
          let data = {
            value: manager_data[i].user_id,
            label: manager_data[i].first_name+" "+manager_data[i].last_name,
          };
          manager_object.push(data);
        }
        setAvailableManager(manager_object);
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
      });
  };

  const [selectedReportingManagerId, setSelectedReportingManagerId] = useState();
  const [selectedReportingManagerName, setSelectedReportingManagerName] = useState();
  const handleChangeReportingManager = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    console.log(optionElementId);
    const optionElementValue = optionElement.getAttribute("value");
    console.log(optionElementValue); 

    setSelectedReportingManagerId(optionElementId);
    setSelectedReportingManagerName(optionElementValue);
  };

  const [projecManagerState, setProjecManagerState] = useState(false);
  const [projecManagerData, setSelectedProjectManager] = useState();
  const [projecManagerData1, setSelectedProjectManager1] = useState(true);
  const onSelectProjectManager = (selectedList, selectedItem) => {
    console.log("Entering",selectedList);
    let mng_obj=[];
    for (let i = 0; i < selectedList.length; i++) {
      let data = {
        manager_id: selectedList[i].value,
        manager_name: selectedList[i].label,
      }
      mng_obj.push(data);
    }
    if (mng_obj.length == 0) {
      setProjecManagerState(true);
      setSelectedProjectManager1(null);
      setSelectedProjectManager(null);
    } else {
      setProjecManagerState(false);
      setSelectedProjectManager(mng_obj);
      setSelectedProjectManager1(selectedList);
    }
  };

  const loadCompany = () => {
    setBackdropOpen(true);
      axios
        .get(`${base_url.api1}/myappapi/company?company_code=` + employeeData.company_code , {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then((response) => {
           setBackdropOpen(false);
          setCompanyData(response.data.Company);
        })
        .catch((error) => {
          console.log(error);
          setBackdropOpen(false);
        });
    };
    <script>
    {$(document).ready(function(){
      
      $('input[type=password]').keyup(function() {
        var pswd = $(this).val();
        
        //validate the length
        if ( pswd.length < 8 ) {
          $('#length').removeClass('valid').addClass('invalid');
        } else {
          $('#length').removeClass('invalid').addClass('valid');
        }
        
        //validate letter
        if ( pswd.match(/[A-z]/) ) {
          $('#letter').removeClass('invalid').addClass('valid');
        } else {
          $('#letter').removeClass('valid').addClass('invalid');
        }
    
        //validate capital letter
        if ( pswd.match(/[A-Z]/) ) {
          $('#capital').removeClass('invalid').addClass('valid');
        } else {
          $('#capital').removeClass('valid').addClass('invalid');
        }
    
        //validate number
        if ( pswd.match(/\d/) ) {
          $('#number').removeClass('invalid').addClass('valid');
        } else {
          $('#number').removeClass('valid').addClass('invalid');
        }
        
        //validate space
        if ( pswd.match(/[^a-zA-Z0-9\-\/]/) ) {
          $('#space').removeClass('invalid').addClass('valid');
        } else {
          $('#space').removeClass('valid').addClass('invalid');
        }
        
      }).focus(function() {
        $('#pswd_info').show();
      }).blur(function() {
        $('#pswd_info').hide();
      });
      
    })}
    </script>

  return (
    <>
      <div className="profile_container">
      <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={newvalue}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                  <Tab label="User Profile" value="1" />
                  <Tab label="Password" value="2" />
                  <Tab label="Company Profile" value="3" />
                </TabList>
              </Box>
              <TabPanel value="1">
              <div className="profile_header">
          <p>User Profile</p>
        </div>

        <div style={{position:"relative"}}>
        <div className="profile_main">
          <div className="profile_main_left">
            <h1>Edit Profile</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
            <div style={{display:"flex",flexDirection:"column",gap:"20px",marginTop:"40px"}}>
            <h4>Personal details </h4>
            <div className="profile_grid_three">


              <div>
              <h3>Employee Code</h3>
                <h3 className="profilefield1">
                {userData.emp_code}
                </h3>        
              </div> 

              <div>
                <h3>
                  First Name
                </h3>
                <input
                  className="profilefield"
                  type="text"
                  {...register("first_name", { required: true })} 
                  id="first_name"
                  value={userData.first_name}
                  onChange={inputEvent}  
                  />
              </div>

              <div>
                <h3>
                  Last Name
                </h3>
                <input
                  className="profilefield"
                  type="text"
                  {...register("last_name", { required: false})} 
                  id="last_name"
                  value={userData.last_name}
                  onChange={inputEvent}
                />
              </div>
            </div>

            <div className="profile_grid_two">

              <div>
                <h3>
                  Gender
                </h3>
                    <select
                      className="profilefield"
                      {...register('gender',{ required: false })}
                      value={userData.gender}
                      onChange={inputEvent}
                    >
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>    
              </div> 

              <div>
                <h3>
                  Date Of Birth
                </h3>
                <input
                  className="profilefield"
                  type="date"
                  {...register('date_of_birth',{ required: false})}
                  id="date_of_birth"
                  value={userData.date_of_birth}
                  onChange={inputEvent}  
                />               
              </div>
            </div>


            <h4>Contact details </h4>
            <div className="profile_grid_two">       
             <div>
               <h3>
                 Mobile
               </h3>
               <input
                 className="profilefield"
                 type="number"
                 {...register("phone", { required: false })} 
                 id="phone"
                 value={userData.phone}
                 onChange={inputEvent}  
               />      
             </div>
             <div>
                <h3>
                  Email Address
                </h3>
                <input
                  className="profilefield"
                  type="email"
                  {...register("email", { required: true })} 
                  id="email"
                  placeholder="Email"
                  value={userData.email}
                  onChange={inputEvent}  
                />      
              </div> 
           </div>

             <h4> Professional details</h4>
            <div className="profile_grid_three">

              <div>
              <level>department</level>
              <div className="profilefield_my">
                <h3>
                {userData.department}
                </h3>            
              </div>
              </div>

              <div>
              <level>Designation</level>
              <div className="profilefield_my">
                <h3>    
                {userData.designation}
                </h3>            
              </div>
              </div>
              
              <div>
              <level>Role</level>
              <div className="profilefield_my">
                <h3>
                {selectedRoleName}
                </h3>
              </div>
              </div>              
            </div>
            <div className="profile_grid_three">
              <div>
              <level>Reporting Manager</level>
              <div className="profilefield_my">
                <h3>
                {userData.reporting_manager_name}
                </h3>
              </div>
              </div> 

              <div>
              <level>Total Leaves</level>
              <div className="profilefield_my">
                <h3>
                {userData.total_leaves}
                </h3> 
              </div>        
              </div> 

              <div>
              <level>Used Leaves</level>
              <div className="profilefield_my">
                <h3>
                {userData.used_leaves}
                </h3> 
              </div>        
              </div> 
            </div>

            <div>
              <hr />            
            </div>

            </div>
            {/* <div
                  style={{
                    float: "right",
                    display: "flex",
                    padding: "5px",
                  }}
                >
                  <Button
                    color="primary"
                    type="submit"
                    style={{
                      marginTop: "20px",
                      marginLeft: "5px",
                      float: "right",
                    }}
                  >
                    Submit
                  </Button>
                </div> */}
            </form>

          </div>
          <div className="profile_main_right">
                <div className="right_header"></div>
                <div className="overlay_profile">
                    <div style={{display:"flex",justifyContent:"center"}}>
                    <div className="circle_profile">
                      {/* {userData.gender == "Male" ? <img src={girl} alt="abc" />:<img src={userImage} alt="abc" />} */}
                      
                  {(userData.photo)?
                    <img src={userImage}/>:
                  (userData.gender === "Male")?
                    <img src={male}/>:
                  (userData.gender === "Female")?
                    <img src={girl}/>:
                  (userData.gender === "Other")?
                    <img src={other} />:null}
        
                </div>
                </div>
               

                <div style={{textAlign:"center"}}>
                    <p className="profile_name">{userData.first_name} {" "}{userData.last_name}</p>

                    <p className="profile_id">{userData.username}</p>

                    <p className="profile_desc">"jasbdcvjb ajvsdbvh bvdskvj bdjf b bdjsfbv jabfds vjbk"</p>
                </div>
                <hr/>
                <div style={{gap:"20px",marginTop:"40px",textAlign:"center"}}>
                <img src={google1} width="43" height="43" />&nbsp;&nbsp;&nbsp;&nbsp;
                <img src={fb} width="40" height="40" />&nbsp;&nbsp;&nbsp;&nbsp;
                <img src={twitter1} width="40" height="40" />&nbsp;&nbsp;&nbsp;&nbsp;
                <div>
                </div>
                       
                </div>
                </div>

          </div>
        </div>
        </div>
              </TabPanel>
              <TabPanel value="2">
<div class="container">
	<div class="col">

			<div class="search-box">
				<div class="caption">
					<h3>Reset Password </h3>
				</div>
				<form action="" class="loginForm">
					<div class="input-group">
          <div class="col-md-3 text-center">
						<input type={inputtype} id="name" class="form-control" placeholder="Current Password" style={{ height: "75px", width: "250px" }}/>
            {inputtype === "password" ? (
                <i className="fa fa-eye-slash fonticonpositioning" onClick={() => setinputtype("text")} aria-hidden="true"></i>
              ) : (
                <i className="fa fa-eye fonticonpositioning" onClick={() => setinputtype("password")} aria-hidden="true"></i>
           )}
						{/* <input type="password" id="paw" class="form-control" placeholder="New Password" style={{ height: "75px", width: "250px" }}/> */}
						{/* <input type="submit" id="submit" class="form-control" value="Change Password" style={{ height: "75px", width: "250px" }}/> */}
					</div>
          <div class="col-md-1"></div>
          <div class="col-md-3">
          <input type={inputtype} id="paw" class="form-control" placeholder="New Password" style={{ height: "75px", width: "250px" }}/>
          {inputtype === "password" ? (
                <i className="fa fa-eye-slash fonticonpositioning" onClick={() => setinputtype("text")} aria-hidden="true"></i>
              ) : (
                <i className="fa fa-eye fonticonpositioning" onClick={() => setinputtype("password")} aria-hidden="true"></i>
           )}
          </div>
          <div class="col-md-1"></div>
          <div class="col-md-3">
          <input type="submit" id="submit" class="form-control" value="Change Password" style={{ height: "75px", width: "250px" }}/>
          </div>
          <div class="col-md-1"></div>
          </div>
          <div class="col-md-3">
                <div class="aro-pswd_info">
                  <div id="pswd_info">
                    <h4>Password must be requirements</h4>
                    <ul>
                      <li id="letter" class="invalid">At least <strong>one letter</strong></li>
                      <li id="capital" class="invalid">At least <strong>one capital letter</strong></li>
                      <li id="number" class="invalid">At least <strong>one number</strong></li>
                      <li id="length" class="invalid">Be at least <strong>8 characters</strong></li>
                      <li id="space" class="invalid">be<strong> use [~,!,@,#,$,%,^,&,*,-,=,.,;,']</strong></li>
                    </ul>
                  </div>
                </div>
              </div>
				</form>
			</div>

	</div>
</div>


              </TabPanel>
              <TabPanel value="3">
<div class="container emp-profile">
            <form method="post">
                <div class="row">
                    <div class="col-md-4">
                        <div class="profile-img">
                        <img src={laptop} width="200" height="200" />
                            <div class="file btn btn-lg btn-primary">
                                Change Photo
                                <input type="file" name="file"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="profile-head">
                                    <h5>
                                        OdiSoft Tachnology Pvt Limited
                                    </h5>
                                    <h6>
                                    More than 15+ years we provide business consulting
                                    </h6>
                                    <p class="proile-rating">RATINGS : <span>8/10</span></p>
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">About</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Timeline</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <input type="submit" class="profile-edit-btn" name="btnAddMore" onClick={goback} value="Edit Profile"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="profile-work">
                            <p>Company Product</p>
                            <a href="">GPS Tracker</a><br/>
                            <a href="">GPS fleet/OBD</a><br/>
                            <a href="">GPS fleet/OBD</a><br/>
                            <a href="">Hardware Design</a>
                            <p>SKILLS</p>
                            <a href="">Web Designer</a><br/>
                            <a href="">Web Developer</a><br/>
                            <a href="">WordPress</a><br/>
                            <a href="">React, Angular</a><br/>
                            <a href="">PHP, .Net, Python, NodeJs</a><br/>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="tab-content profile-tab" id="myTabContent">
                            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label>Company Name</label>
                                            </div>
                                            <div class="col-md-6">
                                            <p>{companyData.company_name}</p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label>Company Code</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p>{companyData.company_code}</p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label>Email</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p>info@odisoft.in</p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label>Contact Us</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p>+91-9619273866</p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label>Full Day Work Duration</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p>{(companyData.full_day_work_duration)/3600}{" "}Hours</p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label>Half Day Work Duration</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p>{(companyData.half_day_work_duration)/3600}{" "}Hours</p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label>Half Days</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p>{companyData.half_days}</p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label>Week Off</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p>{companyData.week_off}</p>
                                            </div>
                                        </div>
                            </div>
                            <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label>Experience</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p>Expert</p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label>Hourly Rate</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p>10$/hr</p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label>Total Projects</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p>230</p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label>English Level</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p>Expert</p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label>Availability</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p>6 months</p>
                                            </div>
                                        </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <label>Your Bio</label><br/>
                                        <p>Your detail description</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>           
        </div>
              </TabPanel>
            </TabContext>
          </Box>
      </div>
    </>
  );
};

export default UserProfile;

