import React, { useState, useEffect } from 'react';
import {
	Routes,
	Route,
	Link,
	NavLink,
	Redirect,
	HashRouter,
	useNavigate,
} from 'react-router-dom';
import './components/css/LoginForm.css';
import 'react-datepicker/dist/react-datepicker.css';
import './App.css';
import axios from 'axios';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useForm } from 'react-hook-form';
import MainDashboard from './components/MainDashboard/MainDashboard';
import NewDash from './components/DashBoard/NewDash';
import HolidayList from './components/Settings/Holidaylist/HolidayList';
import AppConfig from './components/Settings/AppConfig/AppConfig';
import Audit from './components/Settings/Audit/Audit';
import ResignationList from './components/Resignation/ResignationList';
import LeaveApplication from './components/LeaveApplication/LeaveApplication';
import ApprovedLeaveApplication from './components/LeaveApplication/ApprovedLeaveApplication';
import PendingLeaveApplication from './components/LeaveApplication/PendingLeaveApplication';
import DeclinedLeaveApplication from './components/LeaveApplication/DeclinedLeaveApplication';
import Performance from './components/Performance/Performance';
import AllEmployees from './components/AllEmployees/AllEmployees';
import CreateEmployee from './components/AllEmployees/CreateEmployee';
import EditEmployees from './components/AllEmployees/EditEmployees';
import ViewEmployee from './components/AllEmployees/ViewEmployee';
import ManageRole from './components/Settings/ManageRole/ManageRole';
import EmployeeAttendance from './components/AllEmployees/EmployeeAttendance';
import AttendanceList from './components/Attendance/AttendanceList';
import base_url from './api/bootapi';
import { useDispatch, useSelector } from 'react-redux';
import {
	login,
	company,
	selectEmployee,
	selectCompany,
	setEmpIpAddress,
	setStarted,
	setStopped,
	setSessionIdData,
	setOnBreak,
} from './components/features/empSlice';
import Task from './components/TaskModule/Task';
import Project from './components/TaskModule/Project';
import CreateTask from './components/TaskModule/CreateTask';
import EditTask from './components/TaskModule/EditTask';
import Backdrop from '@mui/material/Backdrop';
import Swal from 'sweetalert2';
import CircularProgress from '@mui/material/CircularProgress';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import TypeofHoliday from './components/Settings/Holidaylist/TypeofHoliday';
import TypeofLeave from './components/Settings/LeaveType/TypeofLeave';
import SignUp from './components/SignUp/SignUp';
import TaskStatus from './components/Settings/TaskStatus/TaskStatus';
import TaskPriority from './components/Settings/TaskPriority/TaskPriority';
import MyTasks from './components/TaskModule/MyTasks';
import AbsentList from './components/Attendance/AbsentList';
import OverTimeList from './components/Attendance/OverTimeList';
import ShiftIncompleteList from './components/Attendance/ShiftIncompleteList';
import BreakExceedList from './components/Attendance/BreakExceedList';
import ViewTask from './components/AllEmployees/ViewTask';
import AddMyTask from './components/TaskModule/AddMyTask';
import Profile from './components/Profile/profile';
import EditCompnay from './components/Profile/EditCompnay';
import UserProfile from './components/UserProfile/UserProfile';
import EditUserProfile from './components/UserProfile/EditUserProfile';
import Team from './components/Team/Team';
import Modal from 'react-modal';
import { Col, Row, Button } from 'reactstrap';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import Department from './components/Settings/Department/Department';
import ViewTeam from './components/Team/ViewTeam';
import StatusMail from './components/StatusMail/StatusMail';
import Permissions from './components/Settings/ManageRole/Permissions';
import ErrorPage from './components/ErrorPage';
import CallAuditapi from './components/services/auditservice';
import UserProf from './components/UserProfile/UserProf';
import Chat from './components/Chat/Chat';
import AllTasks from './components/TaskModule/AllTasks';
import socketIO from 'socket.io-client';
import Designation from './components/Settings/Designation/Designation';

const ENDPOINT = 'http:// localhost:4500/';
const socket = socketIO(ENDPOINT, { transports: ['websocket'] });

const customStyles2 = {
	content: {
		top: '40%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		minWidth: '400px',
		maxWidth: '900px',
		maxHeight: '600px',
		transform: 'translate(-50%, -50%)',
	},
};

function LogIn(props) {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();
	const [inputtype, setinputtype] = useState('password');
	let navigate = useNavigate();
	const dispatch = useDispatch();
	const [backdropOpen, setBackdropOpen] = useState(false);
	const { addToast } = useToasts();

	const onSubmit = (data) => {
		setBackdropOpen(true);
		let email = data.email.trim();
		let password = data.password.trim();

		let authenticateData = {
			email,
			password,
		};
		fetch(base_url['api1'] + '/myappapi/users/login', {
			method: 'POST',
			headers: { 'Content-type': 'application/json' },
			body: JSON.stringify(authenticateData),
		})
			.then((r) => r.json())
			.then((result) => {
				console.log(result);
				if (result.status_code === 200) {
					axios
						.get(
							`${base_url.api1}/myappapi/company?company_code=` +
								result.Admin.company_code,
							{
								headers: {
									Authorization: 'JWT ' + result.token,
								},
							}
						)
						.then(
							(response) => {
								console.log(response.data.Company.bcc_mail);
								if (response.status === 200) {
									dispatch(
										company({
											bcc_mail: response.data.Company.bcc_mail,
											break_duration: response.data.Company.break_duration,
											company_code: response.data.Company.company_code,
											company_email: response.data.Company.company_email,
											company_id: response.data.Company.company_id,
											company_name: response.data.Company.company_name,
											company_phone: response.data.Company.company_phone,
											company_size: response.data.Company.company_size,
											created_date: response.data.Company.created_date,
											full_day_work_duration:
												response.data.Company.full_day_work_duration,
											half_day_work_duration:
												response.data.Company.half_day_work_duration,
											half_days: response.data.Company.half_days,
											logo: response.data.Company.logo,
											status: response.data.Company.status,
											user_id: response.data.Company.user_id,
											verified: response.data.Company.verified,
											week_off: response.data.Company.week_off,
											updated_date: response.data.Company.updated_date,
											used_leaves: response.data.Company.used_leaves,
											total_leaves: response.data.Company.total_leaves,
										})
									);
								}
							},
							(error) => {
								setBackdropOpen(false);
								console.log(JSON.stringify(error.response.data));
							}
						);
					addToast(result.message, {
						appearance: 'success',
						autoDismiss: true,
					});
					dispatch(
						login({
							token: result.token,
							emp_email: result.Admin.email,
							emp_id: result.Admin.user_id,
							emp_code: result.Admin.emp_code,
							username: result.Admin.username,
							role_permissions: result.Admin.resource,
							photo: result.Admin.photo,
							emp_role_id: result.Admin.role_id,
							emp_role_name: result.Admin.role_name,
							emp_designation: result.Admin.department,
							company_code: result.Admin.company_code,
							company_id: result.Admin.company_id,
							emp_name: result.Admin.first_name + ' ' + result.Admin.last_name,
							rule_id: result.Admin.rule_id,
							rule_name: result.Admin.rule_name,
							reporting_manager_id: result.Admin.reporting_manager_id,
							reporting_manager_name: result.Admin.reporting_manager_name,
							used_leaves: result.Admin.used_leaves,
							total_leaves: result.Admin.total_leaves,
						})
					);
					let log =
						result.Admin.first_name +
						' ' +
						result.Admin.last_name +
						' Logged In.';
					CallAuditapi(log);
					dispatch(setStarted(0));
					dispatch(setStopped(0));
					dispatch(setSessionIdData(0));
					localStorage.setItem('JWTToken', result.token);
					localStorage.setItem('Company_Code', result.Admin.company_code);
					localStorage.setItem('Company_Id', result.Admin.company_id);
					localStorage.setItem('EmpId', result.Admin.user_id);
					localStorage.setItem(
						'EmpName',
						result.Admin.first_name + ' ' + result.Admin.last_name
					);
					setBackdropOpen(false);
					navigate(`/`);
				} else if (result.status_code === 400) {
					setBackdropOpen(false);
					addToast(result.message, {
						appearance: 'error',
						autoDismiss: true,
					});
					console.log('Login Error');
				} else {
					setBackdropOpen(false);
					addToast(result.message, {
						appearance: 'error',
						autoDismiss: true,
					});
					console.log('Wrong Id & pass');
				}
			});

		axios.get('https://geolocation-db.com/json/').then((res) => {
			let ipaddress = res.data.IPv4;
			dispatch(setEmpIpAddress(res.data.IPv4));
			localStorage.setItem('EmpIpAddress', res.data.IPv4);
		});
	};

	const [modalIsOpen, setIsOpen] = React.useState(false);
	const openForgotPassModal = (elem) => {
		setIsOpen(true);
	};

	const closeModal = () => {
		setIsOpen(false);
	};

	const [forgotUser, setForgotUser] = useState(false);
	const forgotPassword = (e) => {
		// console.log(e.target[0].value);
		axios
			.get(`${base_url.api1}/myappapi/users/forgotpassword?email=`, {
				params: {
					email: e.target[0].value.trim(),
				},
			})
			.then(
				(response) => {
					setIsOpen(false);
					Swal.fire({
						position: 'center',
						icon: 'success',
						title: 'Password Reset Link Send to Your Email',
						showConfirmButton: false,
						timer: 2000,
					});
				},
				(error) => {
					setForgotUser(true);
				}
			);
	};

	return (
		<>
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={backdropOpen}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<div className="loginform">
				<div className="form-inner">
					<h2 className="headclass">
						<img src="./logo1.png" width={240} />
					</h2>
					<h2 style={{ textAlign: 'center' }}>Log In</h2>
					<form
						id="myForm"
						action=""
						method="post"
						onSubmit={handleSubmit(onSubmit)}
					>
						<div className="form-group">
							<input
								className="form-control underline-input forminput"
								type="text"
								placeholder="UserName"
								id="email"
								{...register('email', { required: true })}
							/>
							{errors.email && (
								<p className="errormessage">Username is Required</p>
							)}
						</div>
						<div className="form-group" stype={{ position: 'relative' }}>
							<input
								className="form-control underline-input forminput"
								type={inputtype}
								placeholder="Password"
								id="password"
								{...register('password', { required: true })}
							/>
							{errors.password && (
								<p className="errormessage">Password is Required</p>
							)}
							{inputtype === 'password' ? (
								<i
									className="fa fa-eye-slash fonticonpositioning"
									style={{
										cursor: 'pointer',
										position: 'absolute',
										right: '7px',
										top: '50%',
										fontSize: '1.4em',
										color: '#94b2c6',
									}}
									onClick={() => setinputtype('text')}
									aria-hidden="true"
								></i>
							) : (
								<i
									className="fa fa-eye fonticonpositioning"
									style={{
										cursor: 'pointer',
										position: 'absolute',
										right: '7px',
										top: '50%',
										fontSize: '1.4em',
										color: '#94b2c6',
									}}
									onClick={() => setinputtype('password')}
									aria-hidden="true"
								></i>
							)}
						</div>
						<div className="form-group text-center mt-20">
							<button
								className="btn btn-full btn-dutch b-0 br-5 login_access"
								type="submit"
								style={{ padding: '0px' }}
							>
								Login
							</button>
						</div>
					</form>
					{/* <div className="form-group text-left">
            <label className="checkbox checkbox-custom-alt checkbox-custom-sm inline-block">
              <input type="checkbox" name="Remember" />
              <i></i> Remember me
            </label>
          </div>   */}
					<h5 className="forgotclass">
						<nav>
							<p style={{ cursor: 'pointer' }} onClick={openForgotPassModal}>
								Forgot Password?
							</p>
							<Modal
								isOpen={modalIsOpen}
								onRequestClose={closeModal}
								style={customStyles2}
								ariaHideApp={false}
								contentLabel="Example Modal"
							>
								<form
									onSubmit={(e) => {
										e.preventDefault();
										forgotPassword(e);
									}}
								>
									<div>
										<h3 style={{ color: 'black' }}>Reset Password</h3>
										<div className="form-group creatadmin">
											<Row>
												<Col>
													<label>Email:</label>
													<input
														type="text"
														className="form-control underline-input"
														required
													/>
													{forgotUser && (
														<p className="errormessage">User Does Not Exist</p>
													)}
												</Col>
											</Row>
										</div>
										<div className="form-group creatadmin">
											<Row style={{ float: 'right' }}>
												<Button
													style={{
														marginTop: '20px',
														marginRight: '10px',
													}}
													type="submit"
													color="primary"
												>
													Submit
												</Button>
												<Button
													style={{
														marginTop: '20px',
														marginRight: '10px',
													}}
													color="secondary"
													onClick={closeModal}
												>
													Cancel
												</Button>
											</Row>
										</div>
									</div>
								</form>
							</Modal>
						</nav>
					</h5>
					<p className="forgotclass">
						Don't have an account? <a href="/Sign-up">Signup</a>
					</p>
				</div>
			</div>
		</>
	);
}

function App() {
	useEffect(() => {
		getPermissions();
	}, []);

	const getPermissions = () => {
		VerifyUser();
	};

	const employee = useSelector(selectEmployee);
	const company = useSelector(selectCompany);
	// console.log(window.location.href);

	const VerifyUser = () => {
		const url_string = window.location.href;
		let url = new URL(url_string);
		let email = url.searchParams.get('email');
		let token = url.searchParams.get('token');
		let hash = url.searchParams.get('hash');
		let company_code = url.searchParams.get('company_code');
		let key = url.searchParams.get('key');
		// console.log(email);
		// console.log(token);
		// console.log(hash);
		// console.log(company_code);
		// console.log(key);

		let data = {
			hash,
			email,
			key,
			company_code,
			token,
		};

		// console.log(data);
		if (
			email != null &&
			token != null &&
			hash != null &&
			company_code != null &&
			key != null
		) {
			axios
				.post(`${base_url.api1}/myappapi/verification?email=` + email, data, {
					headers: {
						Authorization: 'JWT ' + token,
					},
				})
				.then((response) => {
					console.log(response);
					Swal.fire({
						position: 'center',
						icon: 'success',
						title: 'Employee Verified Successfully',
						showConfirmButton: false,
						timer: 1500,
					});
				})
				.catch((error) => {
					console.log(JSON.stringify(error));
					Swal.fire({
						position: 'center',
						icon: 'error',
						title: 'Employee Not Verified',
						showConfirmButton: false,
						timer: 1500,
					});
				});
		}
	};
	return (
		<ToastProvider>
			<div>
				{!employee ? (
					<Routes>
						<Route exact path="/" element={<LogIn />} />
						<Route
							exact
							path="/reset-password/:token/:email"
							element={<ForgotPassword />}
						/>
						<Route exact path="/Sign-up" element={<SignUp />} />
					</Routes>
				) : (
					<Routes>
						<Route
							exact
							path="/"
							element={
								<NewDash sidebarvalue={'dashboard'} Comp={MainDashboard} />
							}
						/>
						<Route
							exact
							path="/holiday-list"
							element={<NewDash sidebarvalue={'Holiday'} Comp={HolidayList} />}
						/>
						<Route
							exact
							path="/holiday-type"
							element={
								<NewDash sidebarvalue={'TypeofHoliday'} Comp={TypeofHoliday} />
							}
						/>
						<Route
							exact
							path="/employee-list/"
							element={
								<NewDash sidebarvalue={'AllEmployees'} Comp={AllEmployees} />
							}
						/>
						<Route
							exact
							path="/create-employee"
							element={
								<NewDash
									sidebarvalue={'CreateEmployee'}
									Comp={CreateEmployee}
								/>
							}
						/>
						<Route
							exact
							path="/attendance-list/"
							element={
								<NewDash
									sidebarvalue={'AttendanceList'}
									Comp={AttendanceList}
								/>
							}
						/>
						<Route
							exact
							path="/edit-employee/:user_id"
							element={
								<NewDash sidebarvalue={'editEmployee'} Comp={EditEmployees} />
							}
						/>
						<Route
							exact
							path="/attendance/:user_id"
							element={
								<NewDash
									sidebarvalue={'Attendance'}
									Comp={EmployeeAttendance}
								/>
							}
						/>
						<Route
							exact
							path="/appconfig"
							element={<NewDash sidebarvalue={'appconfig'} Comp={AppConfig} />}
						/>
						<Route
							exact
							path="/designation"
							element={
								<NewDash sidebarvalue={'Designation'} Comp={Designation} />
							}
						/>
						<Route
							exact
							path="/resignation"
							element={
								<NewDash sidebarvalue={'resignation'} Comp={ResignationList} />
							}
						/>
						<Route
							exact
							path="/leave-application"
							element={
								<NewDash
									sidebarvalue={'leaveApplication'}
									Comp={LeaveApplication}
								/>
							}
						/>
						<Route
							exact
							path="/approved-leave-application"
							element={
								<NewDash
									sidebarvalue={'approvedleaveApplication'}
									Comp={ApprovedLeaveApplication}
								/>
							}
						/>
						<Route
							exact
							path="/pending-leave-application"
							element={
								<NewDash
									sidebarvalue={'pendingleaveApplication'}
									Comp={PendingLeaveApplication}
								/>
							}
						/>
						<Route
							exact
							path="/declined-leave-application"
							element={
								<NewDash
									sidebarvalue={'declinedleaveApplication'}
									Comp={DeclinedLeaveApplication}
								/>
							}
						/>
						<Route
							exact
							path="/leave-type"
							element={
								<NewDash sidebarvalue={'TypeofLeave'} Comp={TypeofLeave} />
							}
						/>
						{/* <Route exact path="/performance" element={ <NewDash sidebarvalue={"Performance"} Comp={Performance} /> } /> */}
						<Route
							exact
							path="/audit-log"
							element={<NewDash sidebarvalue={'Audit'} Comp={Audit} />}
						/>
						<Route
							exact
							path="/roles"
							element={<NewDash sidebarvalue={'Roles'} Comp={ManageRole} />}
						/>
						<Route
							exact
							path="/view-tasks/:project_id/:project_name"
							element={<NewDash sidebarvalue={'Task'} Comp={Task} />}
						/>
						<Route
							exact
							path="/all-tasks"
							element={<NewDash sidebarvalue={'AllTasks'} Comp={AllTasks} />}
						/>
						<Route
							exact
							path="/add-task/:project_id/:project_name"
							element={<NewDash sidebarvalue={'Task'} Comp={CreateTask} />}
						/>
						<Route
							exact
							path="/edit-task/:project_id/:project_name/:task_id"
							element={<NewDash sidebarvalue={'Task'} Comp={EditTask} />}
						/>
						<Route
							exact
							path="/task-status"
							element={
								<NewDash sidebarvalue={'TaskStatus'} Comp={TaskStatus} />
							}
						/>
						<Route
							exact
							path="/task-priority"
							element={<NewDash sidebarvalue={'Task'} Comp={TaskPriority} />}
						/>
						<Route
							exact
							path="/projects"
							element={<NewDash sidebarvalue={'Project'} Comp={Project} />}
						/>
						<Route
							exact
							path="/my-tasks"
							element={<NewDash sidebarvalue={'MyTasks'} Comp={MyTasks} />}
						/>
						<Route
							exact
							path="/absent-list"
							element={
								<NewDash sidebarvalue={'AbsentList'} Comp={AbsentList} />
							}
						/>
						<Route
							exact
							path="/overtime-list"
							element={
								<NewDash sidebarvalue={'PresentList'} Comp={OverTimeList} />
							}
						/>
						<Route
							exact
							path="/shiftincompleted-list"
							element={
								<NewDash
									sidebarvalue={'ShiftIncompleteList'}
									Comp={ShiftIncompleteList}
								/>
							}
						/>
						<Route
							exact
							path="/breakexceed-list"
							element={
								<NewDash
									sidebarvalue={'BreakExceedList'}
									Comp={BreakExceedList}
								/>
							}
						/>
						<Route
							exact
							path="/view-employee-task/:user_id"
							element={<NewDash sidebarvalue={'ViewTask'} Comp={ViewTask} />}
						/>
						<Route
							exact
							path="/performance/:user_id"
							element={
								<NewDash sidebarvalue={'Performance'} Comp={Performance} />
							}
						/>
						<Route
							exact
							path="/add-mytask/:user_id"
							element={<NewDash sidebarvalue={'AddMyTask'} Comp={AddMyTask} />}
						/>
						<Route
							exact
							path="/profile"
							element={<NewDash sidebarvalue={'Profile'} Comp={Profile} />}
						/>
						<Route
							exact
							path="/edit-companyprofile"
							element={
								<NewDash sidebarvalue={'EditCompnay'} Comp={EditCompnay} />
							}
						/>
						<Route
							exact
							path="/userprofile/:user_id"
							element={
								<NewDash sidebarvalue={'UserProfile'} Comp={UserProfile} />
							}
						/>
						<Route
							exact
							path="/user-prof/:user_id"
							element={<NewDash sidebarvalue={'UserProf'} Comp={UserProf} />}
						/>
						<Route
							exact
							path="/edituserprofile"
							element={
								<NewDash
									sidebarvalue={'EditUserProfile'}
									Comp={EditUserProfile}
								/>
							}
						/>
						<Route
							exact
							path="/team"
							element={<NewDash sidebarvalue={'Team'} Comp={Team} />}
						/>
						<Route
							exact
							path="/view-team/:team_id"
							element={<NewDash sidebarvalue={'ViewTeam'} Comp={ViewTeam} />}
						/>
						<Route
							exact
							path="/department"
							element={
								<NewDash sidebarvalue={'Department'} Comp={Department} />
							}
						/>
						<Route
							exact
							path="/status-mail"
							element={
								<NewDash sidebarvalue={'StatusMail'} Comp={StatusMail} />
							}
						/>
						<Route
							exact
							path="/chat"
							element={<NewDash sidebarvalue={'Chat'} Comp={Chat} />}
						/>
						<Route
							exact
							path="/role-permissions/:role_id/:role_name"
							element={
								<NewDash sidebarvalue={'Permissions'} Comp={Permissions} />
							}
						/>
						<Route
							exact
							path="/errorPage"
							element={<NewDash sidebarvalue={'dashboard'} Comp={ErrorPage} />}
						/>
					</Routes>
				)}
			</div>
		</ToastProvider>
	);
}

export default App;
