export default {
    show1: false,
    show2: false,
    show3: false,
    show4: false,
    show5: false,
    show6: false,
    show7: false,
    show8: false,
    show9: false,
    show10: false,
    show11: false,
    show12: false,
    show13: false,
    show14: false,
    show15: false,
    show16: false,
    show17: false,
    show18: false,
    show19: false,
    show20: false,
    show21: false,
    show22: false,
    show23: false,
    show24: false,
    show25: false,
    show26: false,
    show27: false,
    show28: false,
    show29: false,
    show30: false,
    show31: false,
    show32: false,
    show33: false,
    show34: false,
    show35: false,
    show36: false,
    show37: false,
    show38: false,
    show39: false,
    show40: false,
    show41: false,
    show42: false,
    show43: false,
    show44: false,
    show45: false,
    show46: false,
    show47: false,
    show48: false,
    show49: false,
    show50: false,
    show51: false,
    show52: false,
    show53: false,
    show54: false,
    show55: false,
    show56: false,
    show57: false,
    show58: false,
    show59: false,
    show60: false,
    show61: false,
    show62: false,
    show63: false,
    show64: false,
    show65: false,
    show66: false,
    show67: false,
    show68: false,
    show69: false,
    show70: false,
    show71: false,
    show72: false,
    show73: false,
    show74: false,
    show75: false,
    show76: false,
    show77: false,
    show78: false,
    show79: false,
    show80: false,
    show81: false,
    show82: false,
    show83: false,
    show84: false,
    show85: false,
    show86: false,
};
  