import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  Col,
  Row,
  Button,
} from "reactstrap";
import axios from "axios";
import base_url from "../../api/bootapi";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from "@mui/material/CircularProgress";
import { useStyles } from "./../../Utilities/CSSUtilities";
import PasswordStrengthBar from "react-password-strength-bar";
import { useDispatch, useSelector } from "react-redux";
import {
  setStarted,
  selectStarted,
  setStopped,
  selectStopped,
  logout,
  selectEmployee,
  setSessionIdData,
  selectSessionId
} from "../../components/features/empSlice";
import { useToasts } from 'react-toast-notifications';
import Select from "react-select";
import CallAuditapi from "../services/auditservice";

const EditTask = (props) => {
  const params = useParams();
  const jwtToken = "JWT " + localStorage.getItem("JWTToken");
  const [taskData, setTaskData] = useState({});
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm();
  const [backdropOpen, setBackdropOpen] = useState(false);
  const navigate = useNavigate();
  const classes = useStyles();
  const formData = new FormData();
  // const [taskDetails, settaskDetails] = useState();


  const dispatch = useDispatch();
  const employeeData = useSelector(selectEmployee);
  const { addToast } = useToasts();


  useEffect(() => {
    getPermissions();
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
  }, []);



  const getPermissions = () => {
    const resource_obj = [];
    for (let i = 0; i < employeeData.role_permissions.length; i++) {
      resource_obj.push(employeeData.role_permissions[i].resource_id);
    }
    var available_resource = resource_obj.includes(14);

    if (available_resource) {
      loadTask(params.task_id);
      userByidAll();
      getAllTaskStatusList();
    } else {
      navigate('/errorPage');
    }
  };


  const inputEvent = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    console.log(name);
    console.log(value);
    // setTaskData( { ...taskData,[e.target.name]:e.target.value});
    setTaskData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };



  const goback = () => {
    navigate('/view-tasks/' + params.project_id + '/' + params.project_name);
  };

  const onSubmit = (data2) => {
    // console.log(data2);
    // if (userState == undefined) {
    //   setUserState(true);
    //   return;
    // }
    // if (data2.status == "") {
    //   setSelectedTaskStatus(false);
    //   return
    // }
    let task_description = data2.task_description.trim();
    let task_title = data2.task_title;
    let task_start_time = data2.task_start_time ? (data2.task_start_time) : null;
    let task_end_time = data2.task_end_time ? (data2.task_end_time) : null;
    // let status = Number(selectedStatusName);
    let status = Number(data2.status);
    let priority = data2.priority;
    let assignee_id = employeeData.emp_id;
    let assignee_name = employeeData.emp_name;
    let company_code = employeeData.company_code;
    let emp_id = Number(userData.value);
    let emp_name = userData.label;
    let project_id = params.project_id;
    let added_by = employeeData.emp_name.trim();
    let task_type = data2.task_type;
    let task_log = employeeData.emp_name + " assigned task " + task_title + " to " + emp_name;


    let data = {
      emp_id,
      task_title,
      status,
      emp_name,
      assignee_id,
      assignee_name,
      task_log,
      project_id,
      task_start_time,
      task_end_time,
      task_description,
      priority,
      added_by,
      task_type,
    };
    console.log(data);
    formData.append("data", JSON.stringify(data));
    postTask(formData);
  };


  const loadTask = () => {
    setBackdropOpen(true);
    axios
      .get(`${base_url.api1}/office_app/assign_task?company_code=` + employeeData.company_code + `&id=` + params.task_id, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        let task_Data = response.data.Task;
        console.log(task_Data);
        setTaskData({
          task_title: task_Data.task_title,
          task_status_name: task_Data.task_status_name,
          status: task_Data.status,
          assignee_id: task_Data.assignee_id,
          assignee_name: task_Data.assignee_name,
          project_id: params.project_id,
          task_start_time: task_Data.task_start_time,
          task_end_time: task_Data.task_end_time,
          task_description: task_Data.task_description,
          priority: task_Data.priority,
          task_type: task_Data.task_type,
        });
        setSelectedUser({
          value: task_Data.emp_id,
          label: task_Data.emp_name,
        });
        let user_Data = {
          value: task_Data.emp_id,
          label: task_Data.emp_name,
        }
        let Task_Data = {
          task_Data,
          user_Data,
        }
        reset(Task_Data);
        setBackdropOpen(false);
      })
      .catch((error) => {
        console.log(error);
        setBackdropOpen(false);
      });
  };

  const postTask = (data) => {
    setBackdropOpen(true);
    axios
      .put(`${base_url.api1}/office_app/assign_task?company_code=` + employeeData.company_code + `&id=` + params.task_id, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          console.log(data);
          let log = employeeData.emp_name + " edited in the task.";
          CallAuditapi(log);
          setBackdropOpen(false);
          navigate(`/view-tasks/` + params.project_id + '/' + params.project_name);
        },
        (error) => {
          setBackdropOpen(false);
        }
      );
  };
  const [availableroles, setAvailableroles] = useState([]);

  const lookup = availableroles;

  const [selected, setSelected] = useState(-1);
  const value = selected !== -1 && lookup[selected];

  const [selectedrole, setSelectedrole] = useState();
  const [defaultRole, setdefaultRole] = useState();

  const handleChangeRole = (event) => {
    console.log(event);
    setdefaultRole(event.target.value);
    console.log(event.target.value);
    const index = event.target.selectedIndex;
    console.log(index);
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    console.log(optionElementId);
    const optionElementValue = optionElement.getAttribute("value");
    console.log(optionElementValue);

    setSelectedrole(optionElementId);
    setdefaultRole(optionElementValue);
  };




  const [availableUserById, setAvailableUserById] = useState([]);
  const [userId, setUserId] = useState([]);
  const userByidAll = () => {
    setBackdropOpen(true);
    axios
      .get(`${base_url.api1}/myappapi/all_users_by_role?company_code=` + employeeData.company_code + `&status=True`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        setUserId(response.data.user_id);
        let emp_data = response.data.Users;
        // const emp_obj = [{
        //   value: null,
        //   label: "-Select Employee-",
        // }];
        const emp_obj = [];
        for (let i = 0; i < emp_data.length; i++) {
          let data = {
            value: emp_data[i].user_id,
            label: emp_data[i].first_name + " " + emp_data[i].last_name,
          };
          emp_obj.push(data);
        }
        setAvailableUserById(emp_obj);
        // setAvailableUserById(response.data.Users);
        setBackdropOpen(false);
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
        setBackdropOpen(false);
      });
  };
  const [selectedUserId, setSelectedUserId] = useState();
  const [selectedUserName, setSelectedUserName] = useState();
  const handleChangeProject = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    console.log(optionElementId);
    const optionElementValue = optionElement.getAttribute("value");
    console.log(optionElementValue);


    setSelectedUserId(optionElementId);
    setSelectedUserName(optionElementValue);
  };

  const [userState, setUserState] = useState();
  const [userData, setSelectedUser] = useState();
  const onSelectUser = (selectedList, selectedItem) => {
    console.log("Entering", selectedList);
    setSelectedUser(selectedList);
    console.log(selectedList);
    setUserState(false);
  };

  const [taskstatus, setTaskStatus] = useState([]);
  const getAllTaskStatusList = (value1) => {
    setBackdropOpen(true);
    axios
      .get(`${base_url.api1}/office_app/Task_status/all?company_code=` + employeeData.company_code, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setTaskStatus(response.data.Status);
          setBackdropOpen(false);
        },
        (error) => {
          console.log(JSON.stringify(error.response.data));
          setBackdropOpen(false);
          setTaskStatus();
        }
      );
  };
  const looksup = taskstatus;
  const [selectedStatusId, setSelectedStatusId] = useState();
  const [selectedTaskStatus, setSelectedTaskStatus] = useState();
  const values = selectedStatusId !== 0 && looksup[selectedStatusId];
  const [selectedStatusName, setSelectedStatusName] = useState();
  const [defaultStatus, setdefaultStatus] = useState();
  const handleChangeStatus = (event) => {
    setdefaultStatus(event.target.value);
    console.log(event.target.value);
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    const optionElementValue = optionElement.getAttribute("value");

    setSelectedStatusId(optionElementId);
    setSelectedStatusName(optionElementValue);
    setdefaultStatus(optionElementValue);
  };


  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Card className="commoncomponent_cardcss">
        <CardBody>
          <CardSubtitle className="font-weight-bold"></CardSubtitle>
          <div>
            <Row>
              <Col md={6}>
                <br></br>
                <CardText className={classes.headtext}
                  style={{ marginTop: "-10px" }}>
                  <strong>Edit Task</strong>
                </CardText>
              </Col>
              <Col md={6}>
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  style={{ float: "right" }}
                  onClick={goback}
                >
                  &#x2630; Tasks
                </Button>
              </Col>
            </Row>
            <form id="myForm" onSubmit={handleSubmit(onSubmit)}>
              <div style={{ padding: "0 20px " }}>
                <div className="form-group creatadmin">
                  <Row>
                    <Col md={4}>
                      <label>Task Title:<span style={{ color: "red" }}>*</span></label>
                      <input
                        type="text"
                        {...register("task_title", { required: true })}
                        // id="task_title"
                        className="form-control underline-input"
                        value={taskData.task_title}
                        placeholder="Task Title"
                        onChange={inputEvent}
                      />
                      {errors.task_title && <p className="errormessage">Task title is Required</p>}
                    </Col>
                    {/* <Col md={4}>
                                    <label>Assign to:<span style={{ color: "red" }}>*</span></label>
                                    <select
                                        {...register('emp_name',{ required: true})}
                                        id="emp_name"
                                        className={classes.selectdrop}
                                        value={taskData.emp_name}
                                        onChange={(e) => {
                                          taskData.emp_name =e.target.value;
                                          console.log(e.target.id);
                                          setSelected(e.target.value)
                                          console.log(e.target.value);
                                          handleChangeRole(e)
                                        }}
                                    >
                                        <option value="">-Select Employee-</option>
                                        {look.map((m, ix) => {
                                        return <option key={m.user_id} id={m.user_id} value={m.first_name + " " +m.last_name}>{m.first_name + " " +m.last_name}</option>
                                        })};
                                    </select>
                                    {errors.emp_name && (
                                        <p className="errormessage">Employee Name is Required</p>
                                    )}
                                    
                                    </Col>         */}
                    <Col md={4}>
                      <label>Select Employee:</label>
                      <Select
                        className="basic-multi-select"
                        classNamePrefix="select"
                        // {...register('emp_name',{ required: true})}
                        // id="emp_name"
                        value={userData}
                        onChange={onSelectUser}
                        options={availableUserById}
                      >
                      </Select>
                      {/* {userState == true ? <p className="errormessage">Employee Name is Required</p> : null} */}
                    </Col>
                    <Col md={4}>
                      <label >
                        Priority<span style={{ color: "red" }}>*</span>:
                      </label>
                      <select className="form-control underline-input"
                        value={taskData.priority}
                        {...register("priority", { required: true })}
                        // value={taskData.priority}
                        onChange={inputEvent}
                      >
                        <option value="">-Select Priority-</option>
                        <option value="high">High</option>
                        <option value="moderate">Moderate</option>
                        <option value="low">Low</option>
                      </select>
                      {errors.priority && (
                        <p className="errormessage">priority is Required</p>
                      )}
                    </Col>
                  </Row>
                  <div className="form-group creatadmin">
                    <Row>
                      <Col md={4}>
                        <label>
                          Start Date:
                        </label>
                        <input
                          type="datetime-local"
                          {...register('task_start_time')}
                          // id="task_start_time"
                          className="form-control underline-input"
                          value={taskData.task_start_time?.split('Z')[0]}
                          onChange={inputEvent}
                          placeholder='Start Date'
                        />
                        {errors.task_start_time && (
                          <p className="errormessage">StartTime is Required</p>
                        )}
                      </Col>
                      <Col md={4}>
                        <label>
                          End Date:
                        </label>
                        <input
                          type="datetime-local"
                          {...register("task_end_time")}
                          // id="task_end_time"
                          className="form-control underline-input"
                          placeholder='End Date'
                          value={taskData.task_end_time?.split('Z')[0]}
                          onChange={inputEvent}
                        />
                        {errors.task_end_time && (
                          <p className="errormessage">End Date is Required</p>
                        )}
                      </Col>
                      <Col md={4}>
                        <label>Documents:</label>
                        <input
                          type="file"
                          multiple
                          {...register("doc", { required: false })}
                          className="form-control underline-input"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <label >
                          Status<span style={{ color: "red" }}>*</span>:
                        </label>
                        {/* <select className="form-control underline-input"
                          {...register("status", { required: true })}
                          value={taskData.status}
                          // onChange={(e) => {
                          //   // setSelectedStatusId(e.target.value);
                          //   handleChangeStatus(e);
                          // }}
                          onChange={inputEvent}
                        >
                          <option value="">-Select Status-</option>
                          {taskstatus.map((m, ix) => {
                            return <option id={m.id} value={m.id}>{m.name}</option>
                          })};
                        </select>
                        {errors.status && (
                          <p className="errormessage">status is Required</p>
                        )} */}
                        <select className="form-control underline-input"
                          value={taskData.status}
                          {...register("status", { required: true })}
                          // onChange={inputEvent}
                          onChange={(e) => {
                            setSelectedStatusId(e.target.value);
                            handleChangeStatus(e);
                            taskData.status = e.target.value;
                            setSelectedTaskStatus(true);
                          }}
                        >
                          {
                            taskstatus.map((m, ix) => {
                              return <option id={m.id} value={m.id}>{m.name}</option>
                            })
                          };
                        </select>
                        {selectedTaskStatus == false ? <p className="errormessage">Status is Required</p> : null}
                      </Col>
                      <Col md={4}>
                        <label >
                          Task Type<span style={{ color: "red" }}>*</span>:
                        </label>
                        <select className="form-control underline-input"
                          value={taskData.task_type}
                          {...register("task_type", { required: true })}
                          onChange={inputEvent}
                        >
                          <option value="">-Select Task Type-</option>
                          <option value="Task">Task</option>
                          <option value="Bug">Bug</option>
                        </select>
                        {errors.task_type && (
                          <p className="errormessage">Task Type is Required</p>
                        )}
                      </Col>
                    </Row>
                    <div className="form-group creatadmin">
                      <Row>
                        <Col md={12}>
                          <label>Description:</label>
                          <textarea
                            type="text"
                            {...register("task_description", { required: false })}
                            // id="task_description"
                            className="form-control underline-input"
                            value={taskData.task_description}
                            onChange={inputEvent}
                            rows={10}
                          />
                          {errors.task_description && <p className="errormessage">Task Description is Required</p>}
                        </Col>
                      </Row>
                      <div
                        style={{
                          float: "right",
                          display: "flex",
                          padding: "5px",
                        }}
                      >
                        {/* <Button
                                                color="secondary"
                                                style={{
                                                marginTop: "20px",
                                                marginLeft: "5px",
                                                float: "right",
                                                }}
                                            >
                                                Reset
                                            </Button> */}
                        <Button
                          color="primary"
                          type="submit"
                          style={{
                            marginTop: "20px",
                            marginLeft: "5px",
                            float: "right",
                          }}
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </CardBody >
      </Card >
    </div >
  );
};
export default EditTask;