import React, { useState, useEffect } from "react";
import { Card, CardBody, CardText, Col, Row, Button, Table } from "reactstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import Pagination from "@mui/material/Pagination";
import { toast } from "react-toastify";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import base_url from "../../api/bootapi";
import moment from "moment";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  setStarted,
  selectStarted,
  setStopped,
  selectStopped,
  logout,
  selectEmployee,
  setSessionIdData,
  selectSessionId,
} from "../features/empSlice";
import { customStyles, useStyles } from "./../../Utilities/CSSUtilities";

const EmployeeAttendance = () => {
  const params = useParams();
  console.log(params);
  const classes = useStyles();
  const jwtToken = "JWT " + localStorage.getItem("JWTToken");
  const [datevalue, setdatevalue] = useState("Click to Open");
  const [userData, setUserData] = useState({});
  const [attendance, setAttendance] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [backdropOpen, setBackdropOpen] = useState(false);
  const dispatch = useDispatch();
  const employeData = useSelector(selectEmployee);
  const startedData = useSelector(selectStarted);
  const stoppedData = useSelector(selectStopped);
  const sessionIdData = useSelector(selectSessionId);
  const navigate = useNavigate();
  //const [value1,setValue1] = useState(1);
  const [searchdata, setsearch] = useState();
  const [datefilter, setdatefilter] = useState();
  const [datesRange, setDatesRange] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [paginationPageNo, setPaginationPageNo] = useState(1);
  const [getPageNo, setGetPageNo] = useState();
  const [rows, setRows] = React.useState(50);
  const [sortState, setsortState] = useState("start_time");
  const [direction, setdirection] = useState("Desc");
  const [userStatus, setUserStatus] = useState(1);
  const [totalcount, setTotalCount] = useState();
  const [totalabsentcount, setTotalAbsentCount] = useState();
  const [countNumber, setCountNumber] = useState();
  const [numberOfElements, setNumberOfElements] = useState();
  const [selectedDelete, setSelectedDelete] = useState([]);
  const [display1, setdisplay] = useState("inline");
  const [getbreak, setBreak] = useState([]);
  const isoDateString = new Date().toISOString();
  const datenow = isoDateString.split("T")[0];
  const [date1, setDate1] = useState(datenow);
  const [date2, setDate2] = useState(datenow);

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
    getPermissions();
    return () => {
      setAttendance();
      setUserData();
    };
  }, [rows]);

  const getPermissions = () => {
    const resource_obj = [];
    for (let i = 0; i < employeData.role_permissions.length; i++) {
      resource_obj.push(employeData.role_permissions[i].resource_id);
    }
    var available_resource = resource_obj.includes(4);

    if(available_resource) {
      loadUsers(params.user_id, pageNo);
    } else {
      navigate('/errorPage')
    }
  };

  const getTotalTime = (val1) => {
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    let start_time = val1.split("Z")[0].split("T")[1];
    let end_time = datenow.split("T")[1];
    var mins = moment
      .utc(moment(end_time, "HH:mm:ss").diff(moment(start_time, "HH:mm:ss")))
      .format("HH:mm");
    return mins;
  };
  const today = new Date();
  let toDate = moment(today).format("YYYY-MM-DD");
  let fromDate = toDate.split("-")[0] + "-" + toDate.split("-")[1] + "-01";

  const loadUsers = (empid, value1) => {
    setBackdropOpen(true);
    axios
      .get(
        `${base_url.api1}/office_app/attendance/date_range?company_code=` + employeData.company_code + `&emp_id=` + params.user_id + `&page=` + value1 + `&size=` + rows + `&from=` + fromDate + `&to=` + toDate,
        {
          headers: {
            Authorization: jwtToken,
          },
        }
      )
      .then((response) => {
        console.log(response);
        console.log(response.data.employee_detail);
        setBackdropOpen(false);
        if (response.data.status_code == 201) {
          let userDetails = response.data.employee_detail;
          let userdata = response.data.Attendance;
          console.log(userdata);
          setAttendance();
          setUserData({
            first_name: userDetails.first_name + " " + userDetails.last_name,
            email: userDetails.email,
            phone: userDetails.phone,
            designation: userDetails.designation,
          });
        } else {
          let userDetails = response.data.employee_detail;
          let userdata = response.data.Attendance;
          console.log(userdata);
          let attData = response.data.Attendance;
          let attObj = [];
          for (let i = 0; i < attData.length; i++) {
            attObj.push({
              applied_rule_id: attData[i].applied_rule_id,
              company_id: attData[i].company_id,
              createad_at: attData[i].createad_at,
              emp_id: attData[i].emp_id,
              emp_name: attData[i].emp_name,
              end_time: attData[i].end_time,
              id: attData[i].id,
              ip_address: attData[i].ip_address,
              breaks: attData[i].breaks,
              late_start_time: attData[i].late_start_time,
              over_time_duration: attData[i].over_time_duration,
              session_date: attData[i].session_date,
              start_time: attData[i].start_time,
              type_of_shift: attData[i].type_of_shift,
              updated_at: attData[i].updated_at,
              working_duration: attData[i].working_duration,
              total_work: getTotalTime(attData[i].start_time),
              break_duration: attData[i].breaks_array.reduce(
                (a, b) => a + b,
                0
              ),
            });
          }
          setAttendance(attObj);
          setBackdropOpen(false);
          setUserData({
            first_name: userDetails.first_name + " " + userDetails.last_name,
            email: userDetails.email,
            phone: userDetails.phone,
            designation: userDetails.designation,
          });
          setGetPageNo(response.data.total_pages);
          //console.log(response.data.total_pages);
          setTotalCount(response.data.total_present);
          setTotalAbsentCount(response.data.absent_count);
          setCountNumber(value1 - 1);
          setNumberOfElements(response.data.Attendance.length);
          //console.log(response.data.Users.length);
          setSelectedDelete([]);
          setdatefilter(false);
        }
      })
      .catch((error) => {
        setBackdropOpen(false);
        setdatefilter(false);
        console.log("sangam", error);
        // let errorData = JSON.stringify(error.response.data);
        // console.log(JSON.stringify(error.response.data.status_code));
        // setAttendance();
        // setUserData();
      });
  };

  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const [modalCreate, setCreate] = React.useState(false);

  const closeModal = () => {
    setCreate(false);
  };

  const createModalConfig = () => {
    setCreate(true);
  };
  const submitSearch = (e, value) => {};

  const handlepage = (event, value) => {
    console.log(value);
    setPaginationPageNo(value);
    setPageNo(value);
    loadUsers(params.user_id, value);
  };

  const sortUser = (e) => {
    setBackdropOpen(true);
    setsortState(e.target.id);
    console.log();
    if (direction == "Desc") {
      setdirection("ASC");
    } else if (direction == "ASC") {
      setdirection("Desc");
    }
    let sortby = e.target.id;

    axios
      .get(
        `${base_url.api1}/office_app/attendance/list?company_code=` + employeData.company_code + `&emp_id=` + params.user_id + `&sortby=` + sortby + `&Order=` + direction + `&page=` + pageNo,
        {
          headers: {
            Authorization: jwtToken,
          },
        }
      )
      .then(
        (response) => {
          setGetPageNo(response.data.total_pages);
          setTotalCount(response.data.total_present);
          setTotalAbsentCount(response.data.absent_count);
          setCountNumber(pageNo - 1);
          setNumberOfElements(response.data.Attendance.length);
          setsearch(false);
          setBackdropOpen(false);
          let attData = response.data.Attendance;
          let attObj = [];
          for (let i = 0; i < attData.length; i++) {
            attObj.push({
              applied_rule_id: attData[i].applied_rule_id,
              company_id: attData[i].company_id,
              createad_at: attData[i].createad_at,
              emp_id: attData[i].emp_id,
              emp_name: attData[i].emp_name,
              end_time: attData[i].end_time,
              id: attData[i].id,
              ip_address: attData[i].ip_address,
              breaks: attData[i].breaks,
              late_start_time: attData[i].late_start_time,
              over_time_duration: attData[i].over_time_duration,
              session_date: attData[i].session_date,
              start_time: attData[i].start_time,
              type_of_shift: attData[i].type_of_shift,
              updated_at: attData[i].updated_at,
              working_duration: attData[i].working_duration,
              total_work: getTotalTime(attData[i].start_time),
              break_duration: attData[i].breaks_array.reduce(
                (a, b) => a + b,
                0
              ),
            });
          }
          setAttendance(attObj);
        },
        (error) => {
          setBackdropOpen(false);
          setAttendance();
          console.log(JSON.stringify(error.response.data));
        }
      );
  };

  const [selectemployee, setSelectedEmployee] = useState(false);
  const [selectEmployeeData, setSelectedEmployeeData] = useState();
  const [employeeId, setEmployeeId] = useState();
  const handleApply = (event, picker, value1) => {
    setBackdropOpen(true);
    setdatevalue(
      `${picker.startDate.format("DD-MM-YYYY")} to ${picker.endDate.format(
        "DD-MM-YYYY"
      )}`
    );
    let data = {
      from: picker.startDate.format("YYYY-MM-DD"),
      to: picker.endDate.format("YYYY-MM-DD"),
    };

    setDatesRange(data);
    setDate1(data.from);
    setDate2(data.to);
    if (selectemployee) {
      axios
        .get(
          `${base_url.api1}/office_app/attendance/date_range?company_code=` + employeData.company_code + `&emp_id=` + params.user_id + `&from=` + data.from + `&to=` + data.to + `&page=` + value1 + `&size=` + rows,
          {
            headers: {
              Authorization: jwtToken,
            },
          }
        )
        .then(
          (response) => {
            setGetPageNo(response.data.total_pages);
            setTotalCount(response.data.total_present);
            setTotalAbsentCount(response.data.absent_count);
            setCountNumber(value1 - 1);
            setNumberOfElements(response.data.Attendance.length);
            setEmployeeId(response.data.Attendance.emp_id);
            setsearch(false);
            setBackdropOpen(false);
            setdatefilter(true);
            let attData = response.data.Attendance;
            let attObj = [];
            for (let i = 0; i < attData.length; i++) {
              attObj.push({
                applied_rule_id: attData[i].applied_rule_id,
                company_id: attData[i].company_id,
                createad_at: attData[i].createad_at,
                emp_id: attData[i].emp_id,
                emp_name: attData[i].emp_name,
                end_time: attData[i].end_time,
                id: attData[i].id,
                ip_address: attData[i].ip_address,
                breaks: attData[i].breaks,
                late_start_time: attData[i].late_start_time,
                over_time_duration: attData[i].over_time_duration,
                session_date: attData[i].session_date,
                start_time: attData[i].start_time,
                type_of_shift: attData[i].type_of_shift,
                updated_at: attData[i].updated_at,
                working_duration: attData[i].working_duration,
                overtime_duration: attData[i].overtime_duration,
                total_work: getTotalTime(attData[i].start_time),
                break_duration: attData[i].breaks_array.reduce(
                  (a, b) => a + b,
                  0
                ),
              });
            }
            setAttendance(attObj);
          },
          (error) => {
            setBackdropOpen(false);
            setdatefilter(true);
            setAttendance();
            console.log(JSON.stringify(error.response.data));
          }
        );
    } else {
      axios
        .get(
          `${base_url.api1}/office_app/attendance/date_range?company_code=` + employeData.company_code +`&emp_id=` + params.user_id + `&from=` + data.from + `&to=` + data.to + `&page=` + value1 + `&size=` + rows,
          {
            headers: {
              Authorization: jwtToken,
            },
          }
        )
        .then(
          (response) => {
            setGetPageNo(response.data.total_pages);
            setTotalCount(response.data.total_present);
            setTotalAbsentCount(response.data.absent_count);
            setCountNumber(value1 - 1);
            setNumberOfElements(response.data.Attendance.length);
            setEmployeeId(response.data.Attendance.emp_id);
            setsearch(false);
            setBackdropOpen(false);
            setdatefilter(true);
            let attData = response.data.Attendance;
            let attObj = [];
            for (let i = 0; i < attData.length; i++) {
              attObj.push({
                applied_rule_id: attData[i].applied_rule_id,
                company_id: attData[i].company_id,
                createad_at: attData[i].createad_at,
                emp_id: attData[i].emp_id,
                emp_name: attData[i].emp_name,
                end_time: attData[i].end_time,
                id: attData[i].id,
                ip_address: attData[i].ip_address,
                breaks: attData[i].breaks,
                late_start_time: attData[i].late_start_time,
                over_time_duration: attData[i].over_time_duration,
                session_date: attData[i].session_date,
                start_time: attData[i].start_time,
                type_of_shift: attData[i].type_of_shift,
                updated_at: attData[i].updated_at,
                working_duration: attData[i].working_duration,
                overtime_duration: attData[i].overtime_duration,
                total_work: getTotalTime(attData[i].start_time),
                break_duration: attData[i].breaks_array.reduce(
                  (a, b) => a + b,
                  0
                ),
              });
            }
            setAttendance(attObj);
          },
          (error) => {
            setBackdropOpen(false);
            setdatefilter(true);
            setAttendance();
            console.log(JSON.stringify(error.response.data));
          }
        );
    }
  };

  const goback = () => {
    navigate("/employee-list");
  };

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Card className="commoncomponent_cardcss">
        <CardBody>
          <div style={{ margin: "5px" }}>
            <Row>
              <Col md={6}>
                <CardText
                  className={classes.headtext}
                  style={{ marginTop: "-10px" }}
                >
                  <strong>Attendance</strong>
                </CardText>
              </Col>
              <Col md={6}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ float: "right" }}
                  onClick={goback}
                >
                  &#x2630; List
                </Button>
              </Col>
            </Row>
            <br />
            <div className="newlisting_headertabdesign">
              <Row>
                {/* <label className="area_rowslable">Employee Details</label><br/>
                    <label className="area_rowslable">Name:</label><label className="area_rowslable">{userData.first_name} {" "} {userData.last_name}</label>:<br/>
                    <label className="area_rowslable">Email:</label><label className="area_rowslable">{userData.email}</label><br/>
                    <label className="area_rowslable">Phone:</label> <label className="area_rowslable">{userData.phone}</label><br/>
                    <label className="area_rowslable">Designation:</label> <label className="area_rowslable">{userData.designation}</label><br/> */}
                <Col md={1.5}>
                  <label className="area_rowslable">Date :</label>
                  <DateRangePicker
                    onApply={(event, picker) => handleApply(event, picker, 1)}
                  >
                    <p
                      className="datepickerrangevaluetext"
                      style={{ borderRadius: "10px" }}
                    >
                      {datevalue}
                    </p>
                  </DateRangePicker>
                  {datefilter ? (
                    <div>
                      <a
                        className={classes.clrsrch}
                        onClick={() => {
                          setdisplay("inline");
                          setdatevalue("Click to open");
                          setBackdropOpen(true);
                          setdatefilter(false);
                          setPageNo(1);
                          loadUsers(params.user_id, 1);
                        }}
                      >
                        Clear Filter
                      </a>
                    </div>
                  ) : null}
                </Col>
                <Col md={10.5}></Col>
              </Row>
              <br />
              <br />
              <Row>
                <Col md={8}>
                  <Row>
                    <Col>
                      <label className="area_rowslable">Name:</label>
                      <label className="area_rowslable">
                        {userData.first_name} {userData.last_name}
                      </label>
                      <br />
                    </Col>
                    <Col>
                      <label className="area_rowslable">Phone:</label>{" "}
                      <label className="area_rowslable">{userData.phone}</label>
                      <br />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label className="area_rowslable">Email:</label>
                      <label className="area_rowslable">{userData.email}</label>
                      <br />
                    </Col>
                    <Col>
                      <label className="area_rowslable">Designation:</label>{" "}
                      <label className="area_rowslable">
                        {userData.designation}
                      </label>
                      <br />
                    </Col>
                  </Row>
                </Col>
                <Col md={4}>
                  <Col>
                    <label className="area_rowslable">Total Present:</label>
                    <label className="area_rowslable">{totalcount}</label>
                    <br />
                  </Col>
                  <Col>
                    <label className="area_rowslable">Total Absent:</label>{" "}
                    <label className="area_rowslable">{totalabsentcount}</label>
                    <br />
                  </Col>
                </Col>
              </Row>
            </div>
            <br></br>
            <Table responsive striped>
              <thead>
                <tr>
                  <th>SN</th>
                  <th style={{ minWidth: "100px" }}>
                    StartTime{" "}
                    <UnfoldMoreIcon
                      id="start_time"
                      onClick={sortUser}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ minWidth: "155px" }}>
                    StopTime{" "}
                    <UnfoldMoreIcon
                      id="end_time"
                      onClick={sortUser}
                      style={{ cursor: "pointer", display: `${display1}` }}
                    />
                  </th>
                  <th style={{ width: "200px" }}>
                    working Hours{" "}
                    <UnfoldMoreIcon onClick={sortUser} id="working_duration" />
                  </th>
                  <th style={{ width: "175px" }}>Break Duration </th>
                </tr>
              </thead>
              <tbody>
                {attendance != null ? (
                  attendance &&
                  attendance.map((attend, index) => (
                    <tr>
                      <th>{countNumber * rows + 1 + index}</th>
                      {/* <td>
                      {attend.start_time
                          ? moment(attend.start_time.split(".")[0]).format(
                              "DD MMM YYYY hh.mm a"
                            )
                          : null}
                      </td>
                       <td>
                      {attend.end_time
                          ? moment(attend.end_time.split(".")[0]).format(
                              "DD MMM YYYY hh.mm a"
                            )
                          : null}
                      </td> */}
                      <td>
                        {attend.start_time
                          ? moment(attend.start_time.split("T")[0]).format(
                              "DD MMM YYYY"
                            )
                          : null}
                        &nbsp;
                        {attend.start_time?.split("T")[1].split("Z")[0]}
                      </td>
                      <td>
                        {attend.end_time
                          ? moment(attend.end_time.split("T")[0]).format(
                              "DD MMM YYYY"
                            )
                          : null}
                        &nbsp;
                        {attend.end_time?.split("T")[1].split("Z")[0]}
                      </td>
                      <td>
                        {attend.working_duration != 0 ||
                        attend.working_duration != null
                          ? moment
                              .utc(attend.working_duration * 1000)
                              .format("HH:mm") + " hr"
                          : attend.total_work + " hr"}
                      </td>
                      <td>
                        {attend.break_duration
                          ? moment
                              .utc(attend.break_duration * 1000)
                              .format("HH:mm") + " hr"
                          : null}
                      </td>
                    </tr>
                  ))
                ) : (
                  <p className={classes.headertekst}>{null}</p>
                )}
              </tbody>
            </Table>
            <div>
              <Row>
                <Col md={6}>
                  {attendance?.length ? (
                    <p style={{ marginTop: "15px" }}>
                      {countNumber * rows + 1} to{" "}
                      {countNumber * rows + numberOfElements} of {totalcount}{" "}
                      records
                    </p>
                  ) : (
                    <p className="formfooterrecordstyle">No Record Found</p>
                  )}
                </Col>
                <Col md={6}>
                  <div className={classes.root}>
                    {searchdata || datefilter ? (
                      <Pagination
                        page={paginationPageNo}
                        count={getPageNo}
                        onChange={submitSearch}
                      />
                    ) : (
                      <Pagination
                        page={paginationPageNo}
                        count={getPageNo}
                        onChange={handlepage}
                      />
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default EmployeeAttendance;