const MainDash = (props) => {
	return (
		<div style={{ padding: '10px' }}>
			<div className="card1" style={{ backgroundColor: props.bgcolor }}>
				<h1>{props.numbers}</h1>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
				>
					<h2 style={{ margin: 0, padding: 0 }}>{props.title}</h2>
					<img src={props.image} alt="img" />
				</div>
			</div>
		</div>
	);
};

export default MainDash;
