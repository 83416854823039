import React , { useState, useEffect }from 'react';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { CardText, Col, Row, Button, Table } from "reactstrap";
import AddIcon from "@mui/icons-material/Add";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Pagination from "@mui/material/Pagination";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import { customStyles, useStyles } from "./../../Utilities/CSSUtilities";
import axios from "axios";
import base_url from '../../api/bootapi';
import { useDispatch, useSelector } from "react-redux";
import { logout, selectEmployee } from "../../components/features/empSlice";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";

const Performance = () => {
  const params = useParams();
  console.log(params);
  const [backdropOpen, setBackdropOpen] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [setConfig, setConfigValue] = useState();
  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const [modalCreate, setCreate] = React.useState(false);
  const [users,setUser] =useState([]);
  const navigate =useNavigate();
  const employeeData = useSelector(selectEmployee);
  const jwtToken = "JWT " + localStorage.getItem("JWTToken");
  const [pageno, setpageno] = useState(0);
  const [sortState, setsortState] = useState("id");
  const [direction, setdirection] = useState("DESC");
  const [pageNo, setPageNo] = useState(1);
  const [paginationPageNo, setPaginationPageNo] = useState(1);
  const [getPageNo, setGetPageNo] = useState();
  const [rows, setRows] = React.useState(50);
  const [totalcount, setTotalCount] = useState();
  const [countNumber, setCountNumber] = useState();
  const [numberOfElements, setNumberOfElements] = useState();
  const [searchdata, setsearch] = useState();
  const [selectedDelete, setSelectedDelete] = useState();
  const [permissionObj, setPermissionObj] = useState();
  
  const [employeename, setEmployeeName] = useState();
  const [employeephoto, setEmployeePhoto] = useState();
  const [totalabsent, settotalAbsent] = useState();
  const [shiftincomplete, setShiftIncomplete] = useState();
  const [pendingtask, setpendingTask] = useState();
  const [overduetask, setoverdueTask] = useState();
 

  useEffect( () => {
    getPermissions(params);
  },[]);

  const getPermissions = (params) => {
    const resource_obj = [];

    for (let i = 0; i < employeeData.role_permissions.length; i++) {
      resource_obj.push(employeeData.role_permissions[i].resource_id);
    }
    setPermissionObj(resource_obj);
    var available_resource74 = resource_obj.includes(74);
    var available_resource75 = resource_obj.includes(75);
    var available_resource76 = resource_obj.includes(76);
    var available_resource77 = resource_obj.includes(77);

    if(available_resource74 && (available_resource75 || available_resource76 || available_resource77)) {
      getAllPerformanceList(params.user_id);
    } else {
      navigate('/errorPage');
    }
  };

  const getAllPerformanceList = () => {
    let count_data = localStorage.getItem("performance_count");
    setBackdropOpen(true);
    axios
      .get(`${base_url.api1}/office_app/users_performance?company_code=` + employeeData.company_code + `&from=` + fromDate +`&to=`+ toDate + `&emp_id=`+params.user_id , {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setBackdropOpen(false);
          console.log(response.data);
          count_data ={ 
            'employeename':response.data.employee_detail.first_name +" "+response.data.employee_detail.last_name, 
            'employeephoto': response.data.employee_detail.photo,
            'totalemployee': response.data.absent_count,
            'shiftincomplete': response.data.shift_incomplete,
            'pendingtask': response.data.task.Pending,
            'overduetask': response.data.task.Overdue,              
          }
          localStorage.setItem("performance_count", JSON.stringify(count_data));
          setEmployeeName(response.data.employee_detail.first_name +" "+response.data.employee_detail.last_name);
          setEmployeePhoto(response.data.employee_detail.photo);
          settotalAbsent(response.data.absent_count);
          setShiftIncomplete(response.data.shift_incomplete);       
          setpendingTask(response.data.task.Pending);
          setoverdueTask(response.data.task.Overdue);
        },
        (error) => {
            console.log(JSON.stringify(error.response.data));
            setBackdropOpen(false);
        }
      );
  };

  const closeModal = () => {
    setCreate(false);
  }

  const createModalConfig = (elem) => {
    console.log(elem.target.id);
    setCreate(true);
  };



  const createPerformance = (data2) => {
    let emp_name = data2.emp_name;
    let status = JSON.parse(data2.status);
    let emp_behaviour = data2.emp_behaviour;
    let percentage = data2.percentage;
   

    let data = {
      emp_name,
      status,
      emp_behaviour,
      percentage,
    };

    setBackdropOpen(true);
    console.log(data);
    axios
     .post(`${base_url.api1}/myappapi/performance/create?company_code=`+ employeeData.company_code, data, {
      headers: {
        Authorization: jwtToken,
      },
      })
      .then((response) => {
        setBackdropOpen(false);
        setCreate(false);
        getAllPerformanceList()
      })
      .catch((error) => {
        setBackdropOpen(false);
        console.log(JSON.stringify(error));
      });
  };

  const [editOpen, setEdit] = React.useState(false);
  const [editPerformanceId, seteditPerformanceId] = useState();
  const [editUser, seteditUser] = useState();
  const [editPerformanceStatus, setPerformanceStatus] = useState();
  const [editEmpBehaviour, setEmpBehaviour] = useState();
  const [editPerformance, setPerformance] = useState();
  const editRoleData = (elem,user) => {
    console.log(user);
    seteditPerformanceId(user.performance_id);
    seteditUser(user.emp_name);
    setPerformanceStatus(user.emp_status);
    setEmpBehaviour(user.emp_behaviour);
    setPerformance(user.percentage);
    setEdit(true);
  };

  const editOldCategory= () =>{
  
    let data = {
      emp_name: editUser,
      emp_status: Number(editPerformanceStatus),
      emp_behaviour: editEmpBehaviour.trim(),
      percentage: Number(editPerformance)
    };

    setBackdropOpen(true);
    console.log("hello",editPerformanceId);
    axios
      .put(`${base_url.api1}/myappapi/performance/update?company_code=`+ employeeData.company_code +`&performance_id=`+ editPerformanceId , data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        setBackdropOpen(false);
        setEdit(false);
        getAllPerformanceList();
      })
      .catch((error) => {
        setBackdropOpen(false);
      });
  }

  const [deletePerformanceId, setdeletePerformanceId] = useState();
  const [deleteOpen, setDelete] = React.useState(false);
  const deletePerformance = (elem,user) => {
    console.log(user);
    setdeletePerformanceId(user.performance_id);
    setDelete(true);
  };
  const deleteCurrentCategory= () =>{
    
    setBackdropOpen(true);
    axios
      .delete(`${base_url.api1}/myappapi/performance/delete?company_code=`+ employeeData.company_code + `&performance_id=` +deletePerformanceId ,{
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        setBackdropOpen(false);
        setDelete(false);
        getAllPerformanceList();
      })
      .catch((error) => {
        setBackdropOpen(false);
      });
  }

  const sortUser = (e) => {
    setsortState(e.target.id);
    console.log();
    if (direction == "Desc") {
      setdirection("ASC");
    } else if (direction == "ASC") {
      setdirection("Desc");
    }
    let sortby = e.target.id

    axios
      .get(`${base_url.api1}/myappapi/performance/list?company_code=` + employeeData.company_code + `&sortby=`+sortby+`&Order=`+direction +`&page=`+pageNo, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setUser(response.data.Performance);
          document.getElementById("myForm").reset();
          setGetPageNo(response.data.total_pages);
          //console.log(response.data.total_pages);
          setTotalCount(response.data.total_count);
          setCountNumber(pageNo-1);
          setNumberOfElements(response.data.Users.length);
          //console.log(response.data.Users.length);
          setSelectedDelete([]);
          setsearch(false);
          setBackdropOpen(false);
        },
        (error) => {
          console.log(JSON.stringify(error.response.data));
          setUser();
        }
      );
  };

  const today = new Date();
  let toDate = moment(today).format("YYYY-MM-DD");
  let fromDate = toDate.split("-")[0] + "-" + toDate.split("-")[1] + "-01";

  let userImage;
      if(employeeData.photo) {
      userImage = base_url.file1 + employeeData.photo;
      console.log(userImage);
  }

  return (
    <>
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="commoncomponent_cardcss">
        <div>
          <Row>
            <Col md={6}>
              <CardText
                style={{ marginTop: "-10px" }}
              >
                <h1>Performance </h1>
              </CardText>
            </Col>
            <Col md={6} className="d-flex justify-content-end">
              {permissionObj?.includes(75)?
                <Button
                  color="primary"
                  onClick={createModalConfig}
                >
                  + Add
                </Button>
              :null}
            </Col>
            <Modal
              ariaHideApp={false}
              isOpen={modalCreate}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <h2>
                <strong>Adding Performance</strong>
              </h2>
              <form onSubmit={handleSubmit(createPerformance)}>
                <div>
                  <div className="form-group creatadmin">
                    <Row>
                      <Col md={12}>
                        <label>
                          Employee<span style={{ color: "red" }}>*</span>:
                        </label>
                        <input
                          type="text"
                          className="form-control underline-input"
                          placeholder="Employee Name"
                          id="emp_name"
                          autoComplete="off"
                          {...register("emp_name",{ required: true })}
                        />
                        {errors.emp_name && (
                          <p className="errormessage">Employee Name is Required</p>
                        )}
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col md={12}>
                        <label>
                          Employee Status:
                        </label>
                        <select
                        {...register("status",{ required: true })}
                        >
                        <option value="true">Active</option>
                        <option value="false">InActive</option>
                      </select>
                      {errors.status && (
                        <p className="errormessage">Status is Required</p>
                      )}
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col md={12}>
                        <label>
                        Employee Behaviour<span style={{ color: "red" }}>*</span>:
                        </label>
                        <input
                          type="text"
                          className="form-control underline-input"
                          placeholder="Emp Behaviour"
                          id="emp_behaviour"
                          autoComplete="off"
                          {...register("emp_behaviour",{ required: true })}
                        />
                        {errors.emp_behaviour && (
                          <p className="errormessage">Employee Behaviour  is Required</p>
                        )}
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col md={12}>
                        <label>
                        Employee Performance<span style={{ color: "red" }}>*</span>:
                        </label>
                        <input
                          type="text"
                          className="form-control underline-input"
                          placeholder="Percentage"
                          id="percentage"
                          autoComplete="off"
                          {...register("percentage",{ required: true })}
                        />
                        {errors.percentage && (
                          <p className="errormessage">Employee Performance  is Required</p>
                        )}
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col md={12}>
                        <Row className="justify-content-end mt-2">
                          <Button
                            color="secondary"
                            onClick={() => setCreate(false)}
                          >
                            Cancel
                          </Button>
                          <Button
                            type="submit"
                            color="primary"
                            className="btn listing_addbutton ml-1"
                          >
                            Submit
                          </Button>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </div>
              </form>
            </Modal>
          </Row>
          <br/>
          <div className="newlisting_headertabdesign">
            <Table responsive striped>
              <thead>
                <tr>
                  <th>
                    Employee Name{" "}
                    <UnfoldMoreIcon onClick={sortUser} id="performance_id"/>
                  </th>
                  <th>
                    Total Absent{" "}
                    <UnfoldMoreIcon onClick={sortUser} id="status"/>
                  </th>
                  <th>
                    Total Incompleteshift{" "}
                    <UnfoldMoreIcon onClick={sortUser}  id="emp_behaviour"/>
                  </th>
                  <th>
                     Total Pendingtask{" "}
                    <UnfoldMoreIcon onClick={sortUser}  id="percentage"/>
                  </th>
                  <th>
                     Total Overduetask{" "}
                    <UnfoldMoreIcon onClick={sortUser}  id="percentage"/>
                  </th>
                  {/* <th style={{ textAlign: "center", width: "175px" }}>
                    Action
                  </th> */}
                </tr>
              </thead>
              <tbody>
                    <tr>
                      <a>
                      <td>
                      {<img src={userImage} width="60" height="30" style={{borderRadius: '100px'}}/>}
                      <br />
                      {employeename}
                      </td>
                      </a>   
                      <td>{totalabsent +" "+ "Day"}</td>
                      <td>{shiftincomplete +" "+ "Day"}</td>                     
                      <td>{pendingtask +" "+ "Task"}</td>                     
                      <td>{overduetask+" "+ "Task"}</td>                     
                      {/* <td>
                        {permissionObj?.includes(76)?
                          <Button id={user.id}
                            onClick={(e)=>{
                              e.preventDefault();
                              editRoleData(e, user);
                            }}
                            style={{
                              marginTop: "20px",
                              marginLeft: "5px",
                            }}
                            color="info">Edit
                          </Button>
                        :null}
                        <Modal
                          ariaHideApp={false}
                          isOpen={editOpen}
                          onRequestClose={closeModal}
                          style={customStyles}
                          contentLabel="Example Modal"
                        >
                          <h2>
                            <strong>Edit Performance</strong>
                          </h2>
                          <form id="editForm" onSubmit={(e) => {
                              e.preventDefault();
                              editOldCategory(e);
                            }}>
                            <div>
                              <div className="form-group creatadmin">
                                <Row>
                                  <Col md={12}>
                                    <label>Emp Name<span style={{ color: "red" }}>*</span>:</label>
                                    <input
                                      type="text"
                                      className="form-control underline-input"
                                      value={editUser}
                                      onChange={(e) => {
                                        seteditUser(e.target.value);
                                      }}
                                      required
                                    />
                                  </Col>
                                </Row>
                                <br />
                                <Row>
                                  <Col md={12}>
                                    <label>Status:</label>
                                    <select
                                      name="status"
                                      value={editPerformanceStatus}
                                      onChange={(e) => {
                                        setPerformanceStatus(e.target.value);
                                      }}
                                      required
                                    >
                                      <option value="1">Active</option>
                                      <option value="0">InActive</option>
                                    </select>
                                  </Col>
                                </Row>
                                <br />
                                <Row>
                                  <Col md={12}>
                                    <label>Employee Behaviour<span style={{ color: "red" }}>*</span>:</label>
                                    <input
                                      type="text"
                                      className="form-control underline-input"
                                      value={editEmpBehaviour}
                                      onChange={(e) => {
                                        setEmpBehaviour(e.target.value);
                                      }}
                                      required
                                    />
                                  </Col>
                                </Row>
                                <br />
                                <Row>
                                  <Col md={12}>
                                    <label>Employee Performance<span style={{ color: "red" }}>*</span>:</label>
                                    <input
                                      type="text"
                                      className="form-control underline-input"
                                      value={editPerformance}
                                      onChange={(e) => {
                                        setPerformance(e.target.value);
                                      }}
                                      required
                                    />
                                  </Col>
                                </Row>
                              </div>
                              <Row style={{ float: "right" }}>
                                <Button
                                  color="secondary"
                                  style={{
                                    marginTop: "20px",
                                    marginRight: "10px",
                                  }}
                                  onClick={() => setEdit(false)}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  color="primary"
                                  type="submit"
                                  style={{ marginTop: "20px" }}
                                >
                                  Submit
                                </Button>
                              </Row>
                            </div>
                          </form>
                        </Modal>
                        {permissionObj?.includes(77)?
                          <Button color="danger" id={user.id}
                            onClick={(e) => {
                              e.preventDefault();
                              deletePerformance(e, user);
                            }}
                            style={{
                              marginTop: "20px",
                              marginLeft: "5px",
                              float: "right",
                            }}>Delete
                          </Button>
                        :null}
                        <Modal
                        ariaHideApp={false}
                        isOpen={deleteOpen}
                        onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel="Example Modal"
                      >
                        <h5>
                          Are you sure you want to delete current record ?
                        </h5>
                        <div style={{ float: "right" }}>
                          <Button
                            color="danger"
                            type="submit"
                            onClick={deleteCurrentCategory}
                          >
                            Delete
                          </Button>
                          &nbsp;&nbsp;&nbsp;
                          <Button
                            color="secondary"
                            onClick={() => setDelete(false)}
                          >
                            Cancel
                          </Button>
                        </div>
                      </Modal> 
                      </td>          */}
                    </tr>             
            </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Performance ;