import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CardText, Col, Row, Button, Table } from "reactstrap";
import AddIcon from "@mui/icons-material/Add";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import Pagination from "@mui/material/Pagination";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import { customStyles2, useStyles } from "./../../Utilities/CSSUtilities";
import axios from "axios";
import moment from "moment";
import base_url from "../../api/bootapi";
import { logout, selectEmployee } from "../../components/features/empSlice";
import { useDispatch, useSelector } from "react-redux";

const ViewTask = () => {
  const [backdropOpen, setBackdropOpen] = useState(false);
  const params = useParams();
  console.log(params);
  const [modalCreate, setCreate] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [taskData, setTaskData] = useState([]);
  const navigate = useNavigate();
  const jwtToken = "JWT " + localStorage.getItem("JWTToken");
  const classes = useStyles();
  const employeeData = useSelector(selectEmployee);
  const [searchdata, setsearch] = useState();
  const [display1, setdisplay] = useState("inline");
  const [numberOfElements, setNumberOfElements] = useState();
  const [descContent, setDescContent] = useState();
  const [descModelOpen, setDescModelOpen] = useState(false);
  const [rows, setRows] = React.useState(50);
  const [pageNo, setPageNo] = useState(1);
  const [countNumber, setCountNumber] = useState();
  const [totalcount, setTotalCount] = useState();
  const [paginationPageNo, setPaginationPageNo] = useState(1);
  const [getPageNo, setGetPageNo] = useState();
  const [datefilter, setdatefilter] = useState();
  const [sortState, setsortState] = useState("task_title");
  const [direction, setdirection] = useState("Desc");
  const dispatch = useDispatch();
  const history = useNavigate();

  useEffect(() => {
    getPermissions();
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
    return () => {
      setTaskData();
      setAvailableRoleById();
    };
  }, [rows]);

  const getPermissions = () => {
    const resource_obj = [];
    for (let i = 0; i < employeeData.role_permissions.length; i++) {
      resource_obj.push(employeeData.role_permissions[i].resource_id);
    }
    var available_resource = resource_obj.includes(5);

    if(available_resource) {
      getAllTaskList(params.user_id, pageNo);
      userByRoleidAll();
    } else {
      navigate('/errorPage')
    }
  };

  const goback = () => {
    navigate("/employee-list");
  };

  const [getId, setId] = useState([]);
  const getAllTaskList = () => {
    setBackdropOpen(true);
    axios
      .get(
        `${base_url.api1}/office_app/assign_task/list?company_code=` + employeeData.company_code + `&emp_id=` + params.user_id,
        {
          headers: {
            Authorization: jwtToken,
          },
        }
      )
      .then(
        (response) => {
          setBackdropOpen(false);
          console.log(response.data);
          setTaskData(response.data.Tasks);
          setGetPageNo(response.data.total_pages);
          setTotalCount(response.data.total_count);
          setCountNumber(pageNo - 1);
          setNumberOfElements(response.data.Tasks.length);
          document.getElementById("myForm").reset();
        },
        (error) => {
          setBackdropOpen(false);
          console.log(JSON.stringify(error.response.data));
        }
      );
  };

  const [forwardOpen, setforwardEdit] = React.useState(false);
  const [editTaskId, seteditTaskId] = useState();
  const forwardTask = (elem, Tasks) => {
    console.log(Tasks);
    seteditTaskId(Tasks.id);
    setforwardEdit(true);
  };
  const editforwardCategory = (data2) => {
    setBackdropOpen(true);
    let data = {
      assignee_id: employeeData.emp_id,
      assignee_name: employeeData.emp_name,
      company_code: employeeData.company_code,
      emp_id: Number(selecteduser),
      emp_name: data2.emp_name,
      task_log: employeeData.emp_name + " forwarded this task to ",
    };
    axios
      .put(
        `${base_url.api1}/office_app/assign_task?company_code=` + employeeData.company_code + `&id=` + editTaskId, data,
        {
          headers: {
            Authorization: jwtToken,
          },
        }
      )
      .then((response) => {
        setBackdropOpen(false);
        setforwardEdit(false);
        getAllTaskList();
      })
      .catch((error) => {
        setBackdropOpen(false);
      });
  };

  const [statusOpen, setstatusEdit] = React.useState(false);
  const [TaskId, setTaskId] = useState();
  const [editStatus, seteditStatus] = useState();

  const [editDescription, seteditDescription] = useState();
  const editTaskStatus = (elem, Tasks) => {
    console.log(Tasks);
    setTaskId(Tasks.id);
    seteditStatus(Tasks.status);
    setstatusEdit(true);
  };
  const editOldCategory = (data2) => {
    setBackdropOpen(true);
    let data = {
      status: editStatus,
    };
    axios
      .put(
        `${base_url.api1}/office_app/assign_task?company_code=` + employeeData.company_code + `&id=` + TaskId, data,
        {
          headers: {
            Authorization: jwtToken,
          },
        }
      )
      .then((response) => {
        setBackdropOpen(false);
        setstatusEdit(false);
        getAllTaskList();
      })
      .catch((error) => {
        setBackdropOpen(false);
      });
  };

  const handleEvent = (event, picker) => {
    console.log(picker.startDate);
  };

  const handleCallback = (start, end, label) => {
    console.log(start, end, label);
  };

  const [searchinput, setsearchinput] = useState();
  const submitSearch = (e, value) => {
    setdisplay("none");
    setsearch(true);
    let srchdata = e.target[0].value.trim();
    setsearchinput(srchdata);
    console.log(srchdata);
    axios
      .get(
        `${base_url.api1}/office_app/assign_task/list?company_code=` + employeeData.company_code + `&emp_id=` + employeeData.emp_id + `&search=` + srchdata,
        {
          headers: {
            Authorization: jwtToken,
          },
        }
      )
      .then(
        (response) => {
          setTaskData(response.data.project_info);
          console.log(response.data.project_info);
        },
        (error) => {
          console.log(JSON.stringify(error.response.data));
          setTaskData();
        },
        (error) => {
          console.log(JSON.stringify(error.response.data));
        }
      );
  };

  const submitSearchpage = (value) => {
    setdisplay("none");
    setsearch(true);
    setBackdropOpen(false);
    axios
      .get(
        `${base_url.api1}/office_app/assign_task/list?company_code=` + employeeData.company_code + `&emp_id=` + employeeData.emp_id + `&search=` + searchinput,
        {
          headers: {
            Authorization: jwtToken,
          },
        }
      )
      .then(
        (response) => {
          setTaskData(response.data.project_info);
          console.log(response.data.project_info);
          setNumberOfElements(response.data.project.length);
        },
        (error) => {
          console.log(JSON.stringify(error.response.data));
          setTaskData();
        },
        (error) => {
          console.log(JSON.stringify(error.response.data));
        }
      );
  };

  const [availableroles, setAvailableroles] = useState([]);

  const lookup = availableroles;

  const [selected, setSelected] = useState(-1);
  const value = selected !== -1 && lookup[selected];

  const [selecteduser, setSelectedrole] = useState();
  const [defaultRole, setdefaultRole] = useState();

  const handleChangeRole = (event) => {
    console.log(event);
    setdefaultRole(event.target.value);
    console.log(event.target.value);
    const index = event.target.selectedIndex;
    console.log(index);
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    console.log(optionElementId);
    const optionElementValue = optionElement.getAttribute("value");
    console.log(optionElementValue);

    setSelectedrole(optionElementId);
    setdefaultRole(optionElementValue);
  };

  const [availableRoleById, setAvailableRoleById] = useState([]);
  const userByRoleidAll = () => {
    setBackdropOpen(true);
    axios
      .get(
        `${base_url.api1}/myappapi/all_users_by_role?company_code=` +  employeeData.company_code + `&status=True`,
        {
          headers: {
            Authorization: jwtToken,
          },
        }
      )
      .then((response) => {
        console.log(response.data.Users);
        setAvailableRoleById(response.data.Users);
        setBackdropOpen(false);
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
        setBackdropOpen(false);
      });
  };

  const look = availableRoleById;

  const [getselected, setgetSelected] = useState(-1);
  const value2 = getselected !== -1 && look[getselected];

  const [selectedroleById, setSelectedroleById] = useState();
  const [defaultRoleById, setdefaultRoleById] = useState();
  const handleChangeRoleById = (event) => {
    console.log(event);
    setdefaultRoleById(event.target.value);
    console.log(event.target.value);
    const index = event.target.selectedIndex;
    console.log(index);
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    console.log(optionElementId);
    const optionElementValue = optionElement.getAttribute("value1");
    console.log(optionElementValue);

    setSelectedroleById(optionElementId);
    setdefaultRoleById(optionElementValue);
  };

  const closeModal = () => {
    setCreate(false);
  };

  const handlepage = (event, value) => {
    console.log(value);
    setPaginationPageNo(value);
    setPageNo(value);
    getAllTaskList(params.user_id, value);
  };

  const sortTask = (e) => {
    setsortState(e.target.id);
    console.log();
    if (direction == "Desc") {
      setdirection("ASC");
    } else if (direction == "ASC") {
      setdirection("Desc");
    }
    let sortby = e.target.id;

    axios
      .get(
        `${base_url.api1}/office_app/assign_task/list?company_code=` + employeeData.company_code + `&sortby=` + sortby + `&Order=` + direction + `&page=` + pageNo + `&emp_id=` + params.user_id,
        {
          headers: {
            Authorization: jwtToken,
          },
        }
      )
      .then(
        (response) => {
          setTaskData(response.data.Tasks);
          document.getElementById("myForm").reset();
          setGetPageNo(response.data.total_pages);
          setTotalCount(response.data.total_count);
          setCountNumber(pageNo - 1);
          setNumberOfElements(response.data.Tasks.length);
          setsearch(false);
          setBackdropOpen(false);
        },
        (error) => {
          console.log(JSON.stringify(error.response.data));
          setTaskData();
        }
      );
  };

  return (
    <div>
      <Backdrop
        open={backdropOpen}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="commoncomponent_cardcss">
        <div>
          <Row>
            <Col md={6}>
              <CardText style={{ marginTop: "-10px" }}>
                <h1>Task Sheet </h1>
              </CardText>
            </Col>
            <Col md={6}>
              <Button
                variant="contained"
                color="primary"
                style={{ float: "right" }}
                onClick={goback}
              >
                &#x2630; Back
              </Button>
            </Col>
          </Row>
          <br />
          <div className="newlisting_headertabdesign">
            <Table responsive striped>
              <thead>
                <tr>
                  <th style={{ width: "50px" }}>SN</th>
                  <th>
                    Task Title{" "}
                    <UnfoldMoreIcon onClick={sortTask} id="task_title" />
                  </th>
                  <th style={{ width: "175px" }}>
                    End Date{" "}
                    <UnfoldMoreIcon onClick={sortTask} id="task_end_time" />
                  </th>
                  <th style={{ width: "175px" }}>
                    Task Status{" "}
                    <UnfoldMoreIcon onClick={sortTask} id="task_status_name" />
                  </th>
                  <th style={{ width: "175px" }}>
                    Priority <UnfoldMoreIcon onClick={sortTask} id="priority" />
                  </th>
                  <th>
                    Task Description{" "}
                    <UnfoldMoreIcon onClick={sortTask} id="task_description" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {taskData != null ? (
                  taskData &&
                  taskData.map((Tasks, index) => (
                    <tr>
                      <th>{index + 1}</th>
                      <td>{Tasks.task_title}</td>
                      <td>
                        {Tasks.task_end_time
                          ? moment(Tasks.task_end_time.split(".")[0]).format(
                              "DD MMM YYYY"
                            )
                          : null}
                      </td>
                      <td>{Tasks.task_status_name}</td>
                      <td>{Tasks.priority}</td>
                      <td className="taskDesc">
                        {Tasks.task_description}
                        {Tasks.task_description != null ? (
                          <span
                            className="taskDescspan"
                            onClick={() => {
                              setDescModelOpen(true);
                              setDescContent(Tasks.task_description);
                            }}
                          >
                            View More
                          </span>
                        ) : null}
                        <Modal
                          ariaHideApp={false}
                          isOpen={descModelOpen}
                          onRequestClose={() => setDescModelOpen(false)}
                          style={customStyles2}
                          contentLabel="Example Modal"
                        >
                          <h4>Task Description</h4>
                          {descContent ? (
                            <textarea
                              style={{ minWidth: "600px" }}
                              className="form-control"
                              value={descContent}
                              rows={10}
                              readonly
                            />
                          ) : (
                            <p> No Record Available</p>
                          )}
                          <div
                            style={{
                              display: "flex",
                              margin: "10px 0",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              color="secondary"
                              onClick={() => setDescModelOpen(false)}
                            >
                              Close
                            </Button>
                          </div>
                        </Modal>
                      </td>
                    </tr>
                  ))
                ) : (
                  <p className={classes.headertekst}>No Tasks Found</p>
                )}
              </tbody>
            </Table>
            <div>
              <Row>
                <Col md={6}>
                  {taskData?.length ? (
                    <p style={{ marginTop: "15px" }}>
                      {countNumber * rows + 1} to{" "}
                      {countNumber * rows + numberOfElements} of {totalcount}{" "}
                      records
                    </p>
                  ) : (
                    <p className="formfooterrecordstyle">No Record Found</p>
                  )}
                </Col>
                <Col md={6}>
                  <div className={classes.root}>
                    {searchdata || datefilter ? (
                      <Pagination
                        page={paginationPageNo}
                        count={getPageNo}
                        onChange={submitSearch}
                      />
                    ) : (
                      <Pagination
                        page={paginationPageNo}
                        count={getPageNo}
                        onChange={handlepage}
                      />
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewTask;
