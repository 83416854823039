import React, { useState, useEffect } from "react";
import moment from "moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CardText, Col, Row, Button, Table } from "reactstrap";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import Pagination from "@mui/material/Pagination";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import axios from "axios";
import base_url from "../../api/bootapi";
import { customStyles, useStyles } from "./../../Utilities/CSSUtilities";
import PasswordStrengthBar from "react-password-strength-bar";
import { useDispatch, useSelector } from "react-redux";
import { login, logout, selectEmployee } from "../../components/features/empSlice";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useToasts } from "react-toast-notifications";
import FormControl from "@mui/material/FormControl";
import { Select } from "@mui/material";
import { MenuItem } from "@mui/material";
import CallAuditapi from "../services/auditservice";
import "./Chat.css";
import socketIO, { io } from "socket.io-client";
import $ from 'jquery';
import jQuery from 'jquery'
import ReactScrollToBottom from "react-scroll-to-bottom";

const ENDPOINT = 'http://localhost:4500/';
let u = [];

const Chat = () => {

  useEffect(() => {

  })
  const classes = useStyles();
  const params = useParams();
  const dispatch = useDispatch();
  //console.log(params);
  const jwtToken = "JWT " + localStorage.getItem("JWTToken");
  const [employeeData, setEmployee] = useState([]);
  const navigate = useNavigate();
  const [searchStatus, setSearchStatus] = useState();
  const [datevalue, setdatevalue] = useState("Joined Date");
  const [datefilter, setdatefilter] = useState();
  const [backdropOpen, setBackdropOpen] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [updatePassModel, setUpdatePassModel] = React.useState(false);
  const [inputtype, setinputtype] = useState("password");
  const employeData = useSelector(selectEmployee);
  const [users, setUser] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [paginationPageNo, setPaginationPageNo] = useState(1);
  const [getPageNo, setGetPageNo] = useState();
  const [rows, setRows] = React.useState(20);
  const [sortState, setsortState] = useState("email");
  const [direction, setdirection] = useState("Desc");
  const [userStatus, setUserStatus] = useState(1);
  const [totalcount, setTotalCount] = useState();
  const [countNumber, setCountNumber] = useState();
  const [numberOfElements, setNumberOfElements] = useState();
  const [selectedDelete, setSelectedDelete] = useState([]);
  const [datesrange, setdatesrange] = useState();
  const [display1, setdisplay] = useState("inline");
  const [permissionObj, setPermissionObj] = useState();

  useEffect(() => {
    // getPermissions();
    const socket = socketIO(ENDPOINT, { transports: ['websocket'] });
    let click = true

    // LISTENING TO SOCKET
    socket.on('no_chats', () => {
      var msg_area = document.querySelector('.incoming_msg')
      msg_area.innerHTML = ''

      console.log('no chats')
    })

    socket.on('lo_chats', (data) => {
      console.log(data, 'chats');
      if (click) {
        click = false
        console.log('first_click')
        let chat_data = data.chats;
        console.log(chat_data.length);
        for (let i = 0; i < chat_data.length; i++) {
          const element = chat_data[i];
          if (element.sender_id == employeData.emp_id) {
            console.log('outgoing message', element.message)
            let msg_area = document.querySelector('.incoming_msg')
            let mainDiv = document.createElement('div')
            let className = 'outgoing_msg';
            mainDiv.classList.add(className)
            let markup = `
            <div class="sent_msg">
            <p>${element.message}</p>
            <span class="time_date">${element.createad_at}</span> 
            </div>
          `
            mainDiv.innerHTML = markup
            msg_area.appendChild(mainDiv)
          } else {
            console.log('incoming message', element.message)
            let msg_area = document.querySelector('.incoming_msg')
            let mainDiv = document.createElement('div')
            let className = 'received_msg';
            mainDiv.classList.add(className)
            let markup = `
            <div class="received_withd_msg">
            <p>${element.message}</p>
            <span class="time_date">${element.createad_at}</span> 
            </div>
            `
            mainDiv.innerHTML = markup
            msg_area.appendChild(mainDiv)
          }
        }
      } else {
        // let outgoing_msg_area = document.querySelector('.outgoing_msg')
        // let received_msg_area = document.querySelector('.received_msg')
        // let outgoing_mainDiv = document.querySelector('.sent_msg')
        // let incoming_mainDiv = document.querySelector('.received_withd_msg')
        // var x = document.querySelector('.outgoing_msg')
        // x.innerHTML = "";
        // var x = document.querySelector('.received_msg')
        // x.innerHTML = "";
        var msg_area = document.querySelector('.incoming_msg')
        msg_area.innerHTML = ''

        let chat_data = data.chats;
        for (let i = 0; i < chat_data.length; i++) {
          const element = chat_data[i];
          if (element.sender_id == employeData.emp_id) {
            let msg_area = document.querySelector('.incoming_msg')
            let mainDiv = document.createElement('div')
            let className = 'outgoing_msg';
            mainDiv.classList.add(className)
            let markup = `
            <div class="sent_msg">
            <p>${element.message}</p>
            <span class="time_date">${element.createad_at}</span> 
            </div>
          `
            mainDiv.innerHTML = markup
            msg_area.appendChild(mainDiv)
          } else {
            let received_msg_area = document.querySelector('.incoming_msg')
            let mainDiv = document.createElement('div')
            let className = 'received_msg';
            mainDiv.classList.add(className)
            let markup = `
            <div class="received_withd_msg">
            <p>${element.message}</p>
            <span class="time_date">${element.createad_at}</span> 
            </div>
            `
            mainDiv.innerHTML = markup
            received_msg_area.appendChild(mainDiv)
          }
        }
      }
    })

    socket.on('sendMessages', (data, id) => {
      if (employeData.emp_id == id) {
        console.log('data came')
        console.log(data)
        console.log(id)
        let msg_area = document.querySelector('.incoming_msg')
        let mainDiv = document.createElement('div')
        let className = 'received_msg';
        mainDiv.classList.add(className)
        let markup = `
        <div class="received_withd_msg">
        <p>${data}</p>

        </div>
        `
        mainDiv.innerHTML = markup
        msg_area.appendChild(mainDiv)
      }
    })

    // SEND MESSAGE EVENTLISTENER
    let messageArea = document.querySelector('#sendMessage');
    messageArea.addEventListener('keyup', (e) => {
      if (e.key == 'Enter') {
        console.log('Send key pressed');
        const receiver_id = u['receiver_id'];
        const receiver_name = u['receiver_name'];
        const sender_id = employeData.emp_id;
        const sender_name = employeData.emp_name.split(" ")[0];
        let msg_area = document.querySelector('.incoming_msg')
        let mainDiv = document.createElement('div')
        let className = 'outgoing_msg';
        mainDiv.classList.add(className)
        let markup = `
        <div class="sent_msg">
        <p>${e.target.value}</p>
        </div>
      
          `
        mainDiv.innerHTML = markup
        msg_area.appendChild(mainDiv)
        socket.emit('sendMessage', ({ receiver_id: receiver_id, receiver_name: receiver_name, sender_id: sender_id, sender_name: sender_name, message: e.target.value, token: jwtToken }))
        messageArea.value = '';
      }
    })

    chatList();
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
    return () => {
      setEmployee();
    };
  }, [rows]);

  const [emplyoeeChat, SetEmployeeChat] = useState([]);
  const [teamChat, SetTeamChat] = useState([]);

  const todayDate = moment().format("MMM Do YY");

  const chatList = (value1) => {

    setBackdropOpen(true);
    axios
      .get(
        `${base_url.api1}/office_app/chat_list?company_code=` + employeData.company_code + `&emp_id=` + employeData.emp_id,
        {
          headers: {
            Authorization: jwtToken,
          },
        }
      )
      .then(
        (response) => {
          SetEmployeeChat(response.data.employees);
          SetTeamChat(response.data.team_list);
          setBackdropOpen(false);
          console.log(response.data.employees, 'employees')
          console.log(response.data.team_list, 'team_list')
          // document.getElementById("myForm").reset();
        },
        (error) => {
          console.log(JSON.stringify(error.response.data));
          setBackdropOpen(false);
          SetEmployeeChat();
          SetTeamChat();
        }
      );
  };
  let click = false
  const [allId, setAllId] = useState();
  const handleKeyUp = (e, user) => {
    const receiver_id = document.getElementById("receiver_name").value;
    const rec_id = document.getElementById("receiver_id").value;
    const socket = socketIO(ENDPOINT, { transports: ['websocket'] });
    const sender_name = employeData.emp_name.split(" ")[0];
    console.log(sender_name, 'sender_name');
    socket.emit('user_connected', { user: user, sender_name: sender_name, sender_id: employeData.emp_id, token: jwtToken })
    setAllId(user.user_id);
    const users_array = {}
    users_array[user.first_name] = user.user_id;




  };

  function myFunction2(e, user) {
    console.log(user, 'xxx')
    var x = document.getElementById("myDIV2");
    if (x.style.display === "none") {
      x.style.display = "block";
      u['receiver_id'] = user.user_id;
      u['receiver_name'] = user.first_name;
    } else {
      x.style.display = "block";
      u['receiver_id'] = user.user_id;
      u['receiver_name'] = user.first_name;
      console.log(u, 'u')
    }

  }

  return (
    <>
      <div class="container">
        <div class="messaging">
          <div class="inbox_msg">
            <div class="inbox_people">
              <div class="headind_srch">
                <div class="recent_heading">
                  <h4>Recent</h4>
                </div>
                <div class="srch_bar">
                  <div class="stylish-input-group">
                    <input type="text" class="search-bar" placeholder="Search" />
                    <span class="input-group-addon">
                      <button type="button"> <i class="fa fa-search" aria-hidden="true"></i> </button>
                    </span> </div>
                </div>
              </div>
              <div class="inbox_chat">
                {emplyoeeChat.map((user, index) => (
                  <>
                    <div class="chat_list active_chat">
                      <div class="chat_people">
                        <div class="chat_img"> <img src="https://ptetutorials.com/images/user-profile.png" alt="sunil" /> </div>
                        <div class="chat_ib">
                          <h5 id={user.user_id} onClick={(e) => {
                            e.preventDefault();
                            handleKeyUp(e, user)
                            myFunction2(e, user);
                            ;
                          }}
                          >
                            {user.first_name} {"  "} {user.last_name}<span class="chat_date">{todayDate}</span></h5>
                          <p>Test, which is a new approach to have all solutions
                            astrology under one roof.</p>
                        </div>
                      </div>
                      <input type="hidden" id="sender_id" name="sender_id" value={employeData.emp_id} />
                      <input type="hidden" id="receiver_id" name="receiver_id" value={user.user_id} />
                      <input type="hidden" id="receiver_name" name="receiver_name" value={user.first_name} />
                      <input type="hidden" id="sender_name" name="sender_name" value={employeData.first_name} />
                      <input type="hidden" id="type" name="type" value='user' />
                    </div>
                  </>
                ))}
              </div>
            </div>
            <div id="myDIV2">

              {/* {()? icon1 : icon0}; */}
              <div class="mesgs">
                <div class="msg_history">
                  <div class="incoming_msg">
                  {/* <ReactScrollToBottom className="area"> */}
                    {/* <div class="received_msg">

                    </div>
                    <div class="outgoing_msg"> */}
                    {/* <div class="sent_msg">
                        <p>Apollo University, Delhi, India Test</p>
                        <span class="time_date"> 11:01 AM    |    Today</span> </div>
                      <div class="sent_msg">
                        <p>Apollo University, Delhi, India Test</p>
                        <span class="time_date"> 11:01 AM    |    Today</span> </div> */}

                    {/* </div> */}
                  {/* </ReactScrollToBottom> */}
                  </div>


                
                </div>
                <div class="type_msg">
                  <div class="input_msg_write">
                    <input type="text" class="write_msg" id="sendMessage" placeholder="Type a message" />
                    <button class="msg_send_btn" type="button"><i class="fa fa-paper-plane-o" aria-hidden="true"></i></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div></div>
      <script>

      </script>
    </>
  )
}

export default Chat