import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import "./../css/Responsive.css";
import "./../css/MainDashboard.css";
import MainDashboardBodytop from "./MainDashboardBodytop";
import { useDispatch, useSelector } from "react-redux";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import {
  logout,
  selectEmployee,
} from "../../components/features/empSlice";

const MainDashboard = () => {
  const [backdropOpen, setBackdropOpen] = useState(false);
  const employeeData = useSelector(selectEmployee);
  const [permissionObj, setPermissionObj] = useState();

  useEffect(() => {
    getPermissions();
  }, []);

  const getPermissions = () => {
    const resource_obj = [];

    for (let i = 0; i < employeeData.role_permissions.length; i++) {
      resource_obj.push(employeeData.role_permissions[i].resource_id);
    }
    setPermissionObj(resource_obj);
  };

  return (
    <div className="dashboardcomponent_cardcss">
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {permissionObj?.includes(0) ? <>
        <div className="d-flex justify-content-between">
          <h3 className="font-weight-bold" style={{ color: "#2a4184" }}>
            Welcome To Employee Management
          </h3>
        </div>
        <div className="maindash_body">
          <MainDashboardBodytop />
        </div>
      </> : null}
    </div>
  );
};

export default MainDashboard;
