import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { CardText, Col, Row, Button, Table } from 'reactstrap';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Pagination from '@mui/material/Pagination';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Modal from 'react-modal';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import base_url from '../../api/bootapi';
import { customStyles2, useStyles } from './../../Utilities/CSSUtilities';
import PasswordStrengthBar from 'react-password-strength-bar';
import { useDispatch, useSelector } from 'react-redux';
import { logout, selectEmployee } from '../../components/features/empSlice';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useToasts } from 'react-toast-notifications';
import FormControl from '@mui/material/FormControl';
import { Select } from '@mui/material';
import { MenuItem } from '@mui/material';
import CallAuditapi from '../services/auditservice';

const AllEmployees = (props) => {
	const classes = useStyles();
	const params = useParams();
	const dispatch = useDispatch();
	//console.log(params);
	const jwtToken = 'JWT ' + localStorage.getItem('JWTToken');
	const [employeeData, setEmployee] = useState([]);
	const navigate = useNavigate();
	const [searchStatus, setSearchStatus] = useState();
	const [datevalue, setdatevalue] = useState('Joined Date');
	const [datefilter, setdatefilter] = useState();
	const [backdropOpen, setBackdropOpen] = useState(false);
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();
	const [updatePassModel, setUpdatePassModel] = React.useState(false);
	const [inputtype, setinputtype] = useState('password');
	const employeData = useSelector(selectEmployee);
	const [users, setUser] = useState([]);
	const [pageNo, setPageNo] = useState(1);
	const [paginationPageNo, setPaginationPageNo] = useState(1);
	const [getPageNo, setGetPageNo] = useState();
	const [rows, setRows] = React.useState(20);
	const [sortState, setsortState] = useState('email');
	const [direction, setdirection] = useState('Desc');
	//const [userStatus, setUserStatus] = useState(1);
	const [totalcount, setTotalCount] = useState();
	const [countNumber, setCountNumber] = useState();
	const [numberOfElements, setNumberOfElements] = useState();
	const [selectedDelete, setSelectedDelete] = useState([]);
	const [datesrange, setdatesrange] = useState();
	const [display1, setdisplay] = useState('inline');
	const [permissionObj, setPermissionObj] = useState();
	const [userStatus, setUserStatus] = useState('All');
	const [dateFilterStatus, setdateFilterStatus] = useState(false);
	let datestatus = false;

	const { addToast } = useToasts();

	const closeModal = () => {
		setUpdatePassModel(false);
	};

	const updatePassword = () => {
		setUpdatePassModel(true);
	};

	const handleRows = (event) => {
		setRows(event.target.value);
	};

	const createEmp = (CreateEmployee) => {
		navigate(CreateEmployee);
	};

	useEffect(() => {
		console.log(userStatus);
		getPermissions();
		window.addEventListener('storage', function (e) {
			if (e.key === null) {
				dispatch(logout());
				navigate('/');
			}
		});
		return () => {
			setEmployee();
			//getPermissions();
		};
	}, [rows]);

	useEffect(() => {
		//console.log('Another use effect');
		if (userStatus == 'All') {
			if (dateFilterStatus) {
				handleDatesRange();
			} else {
				getAllUserList(1);
			}
		}
		if (userStatus == 'Active') {
			getAllActiveUserList(1);
		}
		if (userStatus == 'InActive') {
			getAllInActiveUserList(1);
		}
	}, [userStatus, dateFilterStatus]);

	const getPermissions = () => {
		const resource_obj = [];
		//console.log('Get Permission');
		for (let i = 0; i < employeData.role_permissions.length; i++) {
			resource_obj.push(employeData.role_permissions[i].resource_id);
		}
		setPermissionObj(resource_obj);
		// var available_resource1 = resource_obj.includes(1);
		// var available_resource2 = resource_obj.includes(2);
		// var available_resource3 = resource_obj.includes(3);
		// var available_resource4 = resource_obj.includes(4);
		// var available_resource5 = resource_obj.includes(5);
		// var available_resource6 = resource_obj.includes(6);
		// var available_resource7 = resource_obj.includes(7);
		var available_resource80 = resource_obj.includes(80);

		// if(available_resource1 && (available_resource2 || available_resource3 || available_resource4 || available_resource5 || available_resource6 || available_resource7)) {
		if (available_resource80) {
			// getAllStatusData();
			if (localStorage.getItem('UserPageNo')) {
				let currentPage = localStorage.getItem('UserPageNo');
				setPageNo(currentPage);
				setPaginationPageNo(Number(currentPage));
				if (userStatus == 'All') {
					if (dateFilterStatus) {
						handleDatesRange();
					} else {
						getAllUserList(currentPage);
					}
				}
				if (userStatus == 'Active') {
					getAllActiveUserList(currentPage);
				}
				if (userStatus == 'InActive') {
					getAllInActiveUserList(currentPage);
				}

				//getAllActiveUserList(currentPage);
				//getAllInActiveUserList(currentPage);
			} else {
				setPageNo(1);
				if (userStatus == 'All') {
					if (dateFilterStatus) {
						handleDatesRange();
					} else {
						getAllUserList(pageNo);
					}
				}
				if (userStatus == 'Active') {
					getAllActiveUserList(pageNo);
				}
				if (userStatus == 'InActive') {
					getAllInActiveUserList(pageNo);
				}

				//getAllActiveUserList(1);
				//getAllInActiveUserList(1);
			}
		} else {
			console.log('coming else');
			navigate('/errorPage');
		}
	};

	const handleEvent = (event, picker) => {
		console.log(picker.startDate);
	};

	const handleCallback = (start, end, label) => {
		console.log(start, end, label);
	};

	const getAllUserList = (value1) => {
		setBackdropOpen(true);
		console.log('Get all user List is called');
		axios
			.get(
				`${base_url.api1}/myappapi/users/list?company_code=` +
					employeData.company_code +
					`&page=` +
					value1 +
					`&size=` +
					rows,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then(
				(response) => {
					console.log(datesrange);
					if (dateFilterStatus == true && userStatus == 'All') {
						handleDatesRange();
					} else {
						if (userStatus == 'All') {
							setEmployee(response.data.Users);
						}
						if (userStatus == 'Active') {
							const activeEmployees = response.data.Users.filter(
								(user) => user.is_active
							);
							setEmployee(activeEmployees);
						}
						if (userStatus == 'InActive') {
							const inActiveEmployees = response.data.Users.filter(
								(user) => user.is_active == false
							);
							setEmployee(inActiveEmployees);
						}
						setGetPageNo(response.data.total_pages);
						console.log(response.data.Users);
						setTotalCount(response.data.total_count);
						setCountNumber(value1 - 1);
						setNumberOfElements(response.data.Users.length);
						//console.log(response.data.Users.length);
						setSelectedDelete([]);
						//setdatefilter(false);
						setSearchStatus(false);
						setBackdropOpen(false);
						document.getElementById('myForm').reset();
					}
				},
				(error) => {
					console.log(JSON.stringify(error.response.data));
					//setdatefilter(false);
					setBackdropOpen(false);
					setEmployee();
				}
			);
	};
	const getAllActiveUserList = (value1) => {
		console.log('Get all Active user List is called');
		if (dateFilterStatus) {
			handleDatesRange();
		} else {
			setBackdropOpen(true);
			console.log(value1);
			axios
				.get(
					`${base_url.api1}/myappapi/users/list?company_code=` +
						employeData.company_code +
						`&status=True` +
						`&page=` +
						value1 +
						`&size=` +
						rows,
					{
						headers: {
							Authorization: jwtToken,
						},
					}
				)
				.then(
					(response) => {
						console.log(response.data.Users);
						setEmployee(response.data.Users);
						setGetPageNo(response.data.total_pages);
						//console.log(response.data.total_pages);
						setTotalCount(response.data.total_count);
						setCountNumber(value1 - 1);
						setNumberOfElements(response.data.Users.length);
						//console.log(response.data.Users.length);
						setSelectedDelete([]);
						//setdatefilter(false);
						setSearchStatus(false);
						setBackdropOpen(false);
						document.getElementById('myForm').reset();
					},
					(error) => {
						console.log(JSON.stringify(error.response.data));
						//setdatefilter(false);
						setBackdropOpen(false);
						setEmployee();
					}
				);
		}
	};
	const getAllInActiveUserList = (value1) => {
		if (dateFilterStatus) {
			handleDatesRange();
		} else {
			setBackdropOpen(true);
			console.log(value1);
			axios
				.get(
					`${base_url.api1}/myappapi/users/list?company_code=` +
						employeData.company_code +
						`&status=False` +
						`&page=` +
						value1 +
						`&size=` +
						rows,
					{
						headers: {
							Authorization: jwtToken,
						},
					}
				)
				.then(
					(response) => {
						console.log(response.data.Users);
						setEmployee(response.data.Users);
						setGetPageNo(response.data.total_pages);
						//console.log(response.data.total_pages);
						setTotalCount(response.data.total_count);
						setCountNumber(value1 - 1);
						setNumberOfElements(response.data.Users.length);
						//console.log(response.data.Users.length);
						setSelectedDelete([]);
						//setdatefilter(false);
						setSearchStatus(false);
						setBackdropOpen(false);
						document.getElementById('myForm').reset();
					},
					(error) => {
						console.log(JSON.stringify(error.response.data));
						//setdatefilter(false);
						setBackdropOpen(false);
						setEmployee();
					}
				);
		}
	};

	const sortUser = (e) => {
		setsortState(e.target.id);
		console.log();
		if (direction == 'Desc') {
			setdirection('ASC');
		} else if (direction == 'ASC') {
			setdirection('Desc');
		}
		let sortby = e.target.id;

		axios
			.get(
				`${base_url.api1}/myappapi/users/list?company_code=` +
					employeData.company_code +
					`&sortby=` +
					sortby +
					`&Order=` +
					direction +
					`&page=` +
					pageNo,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then(
				(response) => {
					setEmployee(response.data.Users);
					document.getElementById('myForm').reset();
					setGetPageNo(response.data.total_pages);
					//console.log(response.data.total_pages);
					setTotalCount(response.data.total_count);
					setCountNumber(pageNo - 1);
					setNumberOfElements(response.data.Users.length);
					//console.log(response.data.Users.length);
					setSelectedDelete([]);
					setdatefilter(false);
					setSearchStatus(false);
					setBackdropOpen(false);
				},
				(error) => {
					console.log(JSON.stringify(error.response.data));
					setEmployee();
				}
			);
	};

	const closedModal = () => {
		setDelete(false);
	};

	const [DeleteId, setDeleteId] = useState();
	const [DeleteName, setDeleteName] = useState();
	const [deleteOpen, setDelete] = React.useState(false);
	const deleteUser = (elem, user) => {
		console.log(user);
		setDeleteId(user.user_id);
		setDeleteName(user.first_name + ' ' + user.last_name);
		setDelete(true);
	};
	const deleteCurrentCategory = () => {
		axios
			.delete(
				`${base_url.api1}/myappapi/users/delete?company_code=` +
					employeData.company_code +
					`&user_id=` +
					DeleteId,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then(
				(response) => {
					let log =
						employeData.emp_name + ' deleted the employee ' + DeleteName;
					CallAuditapi(log);
					setBackdropOpen(false);
					setDelete(false);
					getAllUserList(pageNo);
				},
				(error) => {
					setBackdropOpen(false);
					console.log(JSON.stringify(error.response.data));
					setEmployee(null);
				}
			);
	};

	const [chkPass, setchkPass] = useState();
	const [chkPassState, setchkPassState] = useState();
	const handlePassword = (e) => {
		var decimal =
			/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
		console.log(e.target.value);
		if (e.target.value.match(decimal)) {
			setchkPass(false);
			setchkPassState(e.target.value);
			return true;
		} else {
			setchkPass(true);
			// setchkPassState(e.target.value);
			return false;
		}
	};

	const changePassword = (e) => {
		let email = e.target[0].value;
		let new_password = chkPassState.trim();
		let data = {
			email,
			new_password,
		};
		axios
			.post(
				`${base_url.api1}/myappapi/users/changepassword?company_code=` +
					employeData.company_code,
				data,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then(
				(response) => {
					addToast(response.data.message, {
						appearance: 'success',
						autoDismiss: true,
					});
					let log = employeData.emp_name + ' changed password.';
					CallAuditapi(log);
					setPasswordOpen(false);
					AllEmployees();
				},
				(error) => {
					console.log(JSON.stringify(error.response.data.message));
				}
			);
	};

	const [selecteduser, setselecteduser] = useState();
	const [modalPasswordOpen, setPasswordOpen] = React.useState(false);
	const openPasswordModal = (elem) => {
		// console.log(elem.target.id);
		setselecteduser(elem.email);
		setPasswordOpen(true);
	};

	const closePasswordModal = (elem) => {
		setPasswordOpen(false);
	};

	const handlepage = (event, value) => {
		console.log(value);
		setPaginationPageNo(value);
		setPageNo(value);
		if (searchStatus) {
			submitPageSearch(value);
		} else {
			getAllUserList(value);
		}
	};
	const handleDatesRange = () => {
		console.log('Handle Date range function is called');
		setBackdropOpen(true);

		axios
			.get(
				`${base_url.api1}/myappapi/users/date?company_code=` +
					employeData.company_code +
					`&page=` +
					1 +
					`&size=` +
					rows +
					`&from=` +
					datesrange.from +
					`&to=` +
					datesrange.to,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then(
				(response) => {
					if (userStatus == 'All') {
						setEmployee(response.data.Users);
					}
					if (userStatus == 'Active') {
						const activeEmployees = response.data.Users.filter(
							(user) => user.status == true
						);
						console.log('Active Employees', activeEmployees);
						setEmployee(activeEmployees);
					}
					if (userStatus == 'InActive') {
						const inActiveEmployees = response.data.Users.filter(
							(user) => user.status == false
						);
						setEmployee(inActiveEmployees);
					}
					setdateFilterStatus(true);
					setGetPageNo(response.data.total_pages);
					//console.log(response.data.total_pages);
					setTotalCount(response.data.total_count);
					//setCountNumber(value1-1);
					setNumberOfElements(response.data.Users.length);
					setSelectedDelete([]);
					setSearchStatus(false);
					setBackdropOpen(false);
					setdatefilter(true);
					document.getElementById('myForm').reset();
				},
				(error) => {
					setEmployee();
					console.log(JSON.stringify(error.response.data));
					setdatefilter(false);
					setBackdropOpen(false);
				}
			);
	};

	const handleApply = (event, picker) => {
		setBackdropOpen(true);
		setdatevalue(
			`${picker.startDate.format('DD-MM-YYYY')} to ${picker.endDate.format(
				'DD-MM-YYYY'
			)}`
		);
		setPageNo(0);
		setPaginationPageNo(1);
		let data = {
			from: picker.startDate.format('YYYY-MM-DD'),
			to: picker.endDate.format('YYYY-MM-DD'),
			//sortby: sortState,
			// Order: direction,
		};
		setdatesrange(data);
		axios
			.get(
				`${base_url.api1}/myappapi/users/date?company_code=` +
					employeData.company_code +
					`&page=` +
					1 +
					`&size=` +
					rows +
					`&from=` +
					data.from +
					`&to=` +
					data.to,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then(
				(response) => {
					if (userStatus == 'All') {
						setEmployee(response.data.Users);
					}
					if (userStatus == 'Active') {
						const activeEmployees = response.data.Users.filter(
							(user) => user.status == true
						);
						setEmployee(activeEmployees);
					}
					if (userStatus == 'InActive') {
						const inActiveEmployees = response.data.Users.filter(
							(user) => user.status == false
						);
						setEmployee(inActiveEmployees);
					}

					setGetPageNo(response.data.total_pages);
					//console.log(response.data.total_pages);
					setTotalCount(response.data.total_count);
					//setCountNumber(value1-1);
					setNumberOfElements(response.data.Users.length);
					setSelectedDelete([]);
					setSearchStatus(false);
					setBackdropOpen(false);
					setdatefilter(true);
					setdateFilterStatus(true);
					document.getElementById('myForm').reset();
				},
				(error) => {
					setEmployee();
					console.log(JSON.stringify(error.response.data));
					setdatefilter(true);
					setBackdropOpen(false);
				}
			);
	};

	const [searchData, setSearchData] = useState();
	const submitSearch = (e, value) => {
		setdisplay('none');
		setPageNo(1);
		setSearchStatus(true);
		let srchdata = e.target[0].value.trim();
		setSearchData(srchdata);
		console.log(srchdata);
		setBackdropOpen(true);
		axios
			.get(
				`${base_url.api1}/myappapi/users/list?company_code=` +
					employeData.company_code +
					`&page=` +
					1 +
					`&size=` +
					rows +
					`&search=` +
					srchdata,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then(
				(response) => {
					setEmployee(response.data.Users);
					setGetPageNo(response.data.total_pages);
					setCountNumber(pageNo - 1);
					setNumberOfElements(response.data.Users.length);
					setTotalCount(response.data.total_count);
					setBackdropOpen(false);
				},
				(error) => {
					console.log(JSON.stringify(error.response.data));
					setBackdropOpen(false);
					setEmployee();
				}
			);
	};

	const submitPageSearch = (value) => {
		setdisplay('none');
		setPageNo(value);
		setBackdropOpen(true);
		axios
			.get(
				`${base_url.api1}/myappapi/users/list?company_code=` +
					employeData.company_code +
					`&page=` +
					value +
					`&size=` +
					rows +
					`&search=` +
					searchData,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then(
				(response) => {
					setEmployee(response.data.Users);
					setGetPageNo(response.data.total_pages);
					setCountNumber(value - 1);
					setNumberOfElements(response.data.Users.length);
					setTotalCount(response.data.total_count);
					setBackdropOpen(false);
				},
				(error) => {
					console.log(JSON.stringify(error.response.data));
					setBackdropOpen(false);
					setEmployee();
				}
			);
	};
	//useEffect;
	const handleChangeStatus = (value) => {
		//console.log(value);
		setUserStatus(value);
		//console.log(userStatus);
	};

	// const [selectstatus, setSelectedStatus] = useState(false);
	// const [selectStatusData, setSelectedStatusData] = useState();
	// const onSelectStatus = (selectedList, selectedItem) => {
	//   console.log("Entering",selectedList);
	//   setBackdropOpen(true);
	//   setSelectedStatus(true);
	//   setSelectedStatusData(selectedList);
	//   axios
	//     .get(`${base_url.api1}/myappapi/users/list?company_code=`+ employeData.company_code+`&status=True` +`&page=1&size=`+ rows , {
	//       headers: {
	//         Authorization: jwtToken,
	//       },
	//     })
	//     .then(
	//       (response) => {
	//         setGetPageNo(response.data.total_pages);
	//         setTotalCount(response.data.total_present);
	//         setCountNumber(0);
	//         setNumberOfElements(response.data.Users.length);
	//         // setStatusId(response.data.Users.id);
	//         setBackdropOpen(false);
	//     },
	//   (error) => {
	//       setBackdropOpen(false);
	//       console.log(JSON.stringify(error.response.data));
	//   }
	//   );
	// };

	//   const [availableStatus, setAvailableStatus] = useState([]);
	// const [StatusId, setstatusId] = useState([]);
	//   // const [empAllId, setEmpAllId] = useState([]);
	//   const statusByID = () => {
	//     axios
	//       .get(`${base_url.api1}/myappapi/users/list?company_code=`+ employeData.company_code+`&status=True` +`&page=1&size=`+ rows , {
	//         headers: {
	//           Authorization: jwtToken,
	//         },
	//       })
	//       .then(
	//         (response) => {
	//           setAvailableStatus(response.data.Users);
	//           setBackdropOpen(false);
	//         },
	//         (error) => {
	//           console.log(JSON.stringify(error.response.data));
	//           setBackdropOpen(false);
	//           setAvailableStatus();
	//         });
	//   };

	//   const handleChangeStatus = (event) => {
	//     const lookedup = availableStatus;
	//     console.log(lookedup);

	//     const [newselect, setnewSelect] = useState(-1);

	//     const [getnewselect, setnewgetSelect] = useState(-1);
	//     const value4 = getnewselect !== -1 && lookedup[getnewselect];

	//     const [selectedstatusById, setSelectedstatusById] = useState();
	//     const [defaultStatusById, setdefaultStatusById] = useState();

	//       console.log(event);
	//       setdefaultStatus(event.target.value);
	//       setdefaultStatusById(event.target.value);
	//       console.log(event.target.value);
	//       const index = event.target.selectedIndex;
	//       console.log(index);
	//       const optionElement = event.target.childNodes[index];
	//       const optionElementId = optionElement.getAttribute("id");
	//       console.log(optionElementId);
	//       const optionElementValue = optionElement.getAttribute("value");
	//       console.log(optionElementValue);

	//       setSelectedstatusById(optionElementId);
	//       setdefaultStatusById(optionElementValue);
	//     };

	return (
		<div>
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={backdropOpen}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<div className="commoncomponent_cardcss">
				<div style={{ margin: '5px' }}>
					<Row>
						<Col md={6}>
							<CardText
								className={classes.headtext}
								style={{ marginTop: '-10px' }}
							>
								<h2>All Employee List</h2>
							</CardText>
						</Col>
						<Col md={6}>
							{permissionObj?.includes(2) ? (
								<Button
									className={classes.createBtn}
									color="primary"
									onClick={() => createEmp('/create-employee')}
									style={{
										float: 'right',
									}}
								>
									+ Add
								</Button>
							) : null}
						</Col>
					</Row>
					<br />
					<div className="newlisting_headertabdesign">
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								flexWrap: 'wrap',
							}}
						>
							<Row>
								<div style={{ marginRight: '7px' }}>
									<form className="master">
										<div style={{ marginTop: '-10px', width: 'fit-content' }}>
											<label className="area_rowslable">Limit</label>
											<br />
											<FormControl className={classes.formControl}>
												<Select
													labelId="demo-simple-select-label"
													id="demo-simple-select"
													value={rows}
													onChange={handleRows}
													className={classes.selectlimit}
												>
													<MenuItem value={20}>20</MenuItem>
													<MenuItem value={50}>50</MenuItem>
													<MenuItem value={100}>100</MenuItem>
												</Select>
											</FormControl>
										</div>
									</form>
								</div>
								<div style={{ marginTop: '-10px' }}>
									<label className="area_rowslable">Date</label>
									<DateRangePicker
										onEvent={handleEvent}
										onCallback={handleCallback}
										onApply={handleApply}
									>
										<p className="datepickerrangevaluetext">{datevalue}</p>
									</DateRangePicker>
									{datefilter ? (
										<div>
											<a
												className={classes.clrsrch}
												onClick={() => {
													setdisplay('inline');
													setdatevalue('Joined Date');
													setBackdropOpen(true);
													setdirection('Desc');
													setPageNo(1);
													setdateFilterStatus(false);
													setdatefilter(false);
													setdatesrange('');
													setUserStatus('All');
													//getAllUserList(1);
												}}
											>
												Clear Filter
											</a>
										</div>
									) : null}
								</div>
								<div style={{ marginTop: '-10px' }}>
									<label className="area_rowslable">Status</label>
									<br />
									{/* <FormControl className={classes.formControl}> */}
									<FormControl
										variant="standard"
										sx={{ m: 1, minWidth: 80, marginTop: 0, maxHeight: 80 }}
									>
										<select
											className={classes.selectlimit}
											labelId="simple-select-label"
											id="simple-select"
											value={userStatus}
											onChange={(e) => {
												handleChangeStatus(e.target.value);
											}}
										>
											{/* <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem> */}
											<option value={'All'}>All</option>
											<option value={'Active'}>Active</option>
											<option value={'InActive'}>InActive</option>
										</select>
									</FormControl>
								</div>
								{/* <div style={{ marginTop: "-10px", marginRight: "14px" }}>
                <label><strong>Employee Status:</strong></label>
                <select onClick={() =>{
                  getAllStatusData(1);
                  setBackdropOpen(false);
                }}>
                <option value="True">Active</option>
                <option value="False">InActive</option>
                </select>
                </div> */}
								{/* <div> */}
								{/* <label><strong>Task Status:</strong></label> */}
								{/* <Select
                      <Select
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={onSelectStatus}
                        options={selectstatus}
                        options={availableStatus}
                      >
                      </Select> */}
								{/* <select className="form-control underline-input"
                         onChange={(e) => {
                          setSelectedstatus(e.target.value)
                          handleChangeStatus(e)
                        }} */}
								{/* // onChange={onSelectStatus}
                        // options={selectstatus}
                         >
                         <option value="">-Select Status-</option>
                        {lookedup.map((m, ix) => {
                          return <option key={m.id} id={m.id} value={m.name}>{m.name}</option>
                        })}; 
                       </select>  
                       </div> */}
							</Row>
							<div>
								<div className="form-group">
									<div>
										<div className="clearsearchclass">
											<form
												id="myForm"
												className="d-flex"
												onSubmit={(e) => {
													e.preventDefault();
													submitSearch(e);
												}}
											>
												<input
													style={{
														padding: '5px',
														border: '2px solid',
														marginTop: '-1px',
													}}
													className="form-control"
													placeholder="Search.."
													name="search"
													required
												/>
												<button className={classes.srchbtn} type="submit">
													<i className="fa fa-search"></i>
												</button>
											</form>
											{searchStatus ? (
												<a
													onClick={() => {
														setdisplay('inline');
														setBackdropOpen(true);
														setPageNo(1);
														setdirection('Desc');
														getAllUserList(1);
													}}
												>
													Clear Search
												</a>
											) : null}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="newlisting_headertabdesign">
						<Table responsive striped>
							<thead>
								<tr>
									<th style={{ width: '50px' }}>SN</th>
									<th style={{ width: '175px' }}>
										Name <UnfoldMoreIcon onClick={sortUser} id="first_name" />
									</th>
									<th style={{ width: '175px' }}>
										Email <UnfoldMoreIcon onClick={sortUser} id="email" />
									</th>
									<th style={{ width: '175px' }}>
										JoinedDate{' '}
										<UnfoldMoreIcon onClick={sortUser} id="date_joined" />
									</th>
									<th style={{ width: '175px' }}>
										Designation{' '}
										<UnfoldMoreIcon onClick={sortUser} id="designation" />
									</th>
									<th style={{ width: '175px' }}>
										Role Name{''}
										<UnfoldMoreIcon onClick={sortUser} id="role_name" />
									</th>
									<th style={{ width: '175px' }}>
										Status <UnfoldMoreIcon onClick={sortUser} id="status" />
									</th>
									<th style={{ width: '175px' }}>
										Is Verified{' '}
										<UnfoldMoreIcon onClick={sortUser} id="status" />
									</th>
									<th style={{ width: '100px' }}>Action</th>
								</tr>
							</thead>

							<tbody>
								{employeeData != null ? (
									employeeData &&
									employeeData.map((user, index) => (
										<tr>
											<th>{countNumber * rows + 1 + index}</th>
											<td>
												{user.first_name} {'  '} {user.last_name}
											</td>
											<td>
												<a style={{ cursor: 'pointer', color: 'blue' }}>
													{user.email}
												</a>
												<br />
												{user.phone}
											</td>
											<td>
												{user.date_joined
													? moment(user.date_joined.split('.')[0]).format(
															'DD MMM YYYY'
													  )
													: null}
											</td>
											<td>{user.designation}</td>
											<td>{user.role_name}</td>
											<td>{user.status == true ? 'Active' : 'InActive'}</td>
											<td>
												{user.is_verified == true ? 'Verified' : 'Unverified'}
											</td>
											<td>
												<div
													style={{
														position: 'relative',
														textAlign: 'center',
													}}
												>
													<div
														className="workflowdashboard_fa-ellipsis"
														data-toggle="dropdown"
													>
														<i
															className="fa fa-ellipsis-v "
															aria-hidden="true"
														></i>
													</div>
													<div
														className="dropdown-menu workflowdashboarddropdown"
														style={{ width: '200px' }}
													>
														{permissionObj?.includes(3) ? (
															<>
																{user.role_id != '1' ? (
																	<p
																		onClick={() =>
																			navigate('/edit-employee/' + user.user_id)
																		}
																		color="info"
																	>
																		Edit
																	</p>
																) : (
																	<>
																		{employeData.emp_role_id == '1' ? (
																			<p
																				onClick={() =>
																					navigate(
																						'/edit-employee/' + user.user_id
																					)
																				}
																				color="info"
																			>
																				Edit
																			</p>
																		) : null}
																	</>
																)}
															</>
														) : null}
														{permissionObj?.includes(4) ? (
															<p
																onClick={() =>
																	navigate('/attendance/' + user.user_id)
																}
																color="info"
															>
																Attendance
															</p>
														) : null}
														{permissionObj?.includes(5) ? (
															<p
																onClick={() =>
																	navigate(
																		'/view-employee-task/' + user.user_id
																	)
																}
																color="info"
															>
																View Task
															</p>
														) : null}
														<p
															onClick={() =>
																navigate('/performance/' + user.user_id)
															}
															color="info"
														>
															View Performance
														</p>
														{permissionObj?.includes(6) ? (
															<>
																{user.role_id != '1' ? (
																	<p
																		id={user.user_id}
																		onClick={(e) => {
																			e.preventDefault();
																			deleteUser(e, user);
																		}}
																		color="danger"
																	>
																		Delete
																	</p>
																) : null}
															</>
														) : null}
														<Modal
															ariaHideApp={false}
															isOpen={deleteOpen}
															onRequestClose={closedModal}
															style={customStyles2}
															contentLabel="Example Modal"
														>
															<h5>
																Are you sure you want to delete current record ?
															</h5>
															<div style={{ float: 'right' }}>
																<Button
																	color="danger"
																	type="submit"
																	onClick={deleteCurrentCategory}
																>
																	Delete
																</Button>
																&nbsp;&nbsp;&nbsp;
																<Button
																	color="secondary"
																	onClick={() => setDelete(false)}
																>
																	Cancel
																</Button>
															</div>
														</Modal>
														{permissionObj?.includes(7) ? (
															<>
																{user.role_id != '1' ? (
																	<p onClick={() => openPasswordModal(user)}>
																		Update Password
																	</p>
																) : (
																	<>
																		{employeData.emp_role_id == '1' ? (
																			<p
																				onClick={() => openPasswordModal(user)}
																			>
																				Update Password
																			</p>
																		) : null}
																	</>
																)}
															</>
														) : null}
														<Modal
															ariaHideApp={false}
															isOpen={modalPasswordOpen}
															// onAfterOpen={afterOpenModal}
															onRequestClose={closeModal}
															style={customStyles2}
															contentLabel="Example Modal"
														>
															<form
																onSubmit={(e) => {
																	e.preventDefault();
																	changePassword(e);
																}}
															>
																<div>
																	<h3 style={{ color: 'black' }}>
																		Update Password
																	</h3>
																	<div className="form-group creatadmin">
																		<Row>
																			<Col>
																				<label>Email:</label>
																				<input
																					type="text"
																					className="form-control underline-input"
																					value={selecteduser}
																					readOnly
																					required
																				/>
																			</Col>
																		</Row>
																	</div>
																	<div className="form-group creatadmin">
																		<Row>
																			<Col>
																				<label>Password:</label>
																				<div style={{ position: 'relative' }}>
																					<input
																						type={inputtype}
																						className="form-control underline-input"
																						value={chkPassState}
																						onChange={(e) =>
																							setchkPassState(e.target.value)
																						}
																						required
																					/>
																					{inputtype === 'password' ? (
																						<i
																							className="fa fa-eye-slash fonticonpositioning"
																							onClick={() =>
																								setinputtype('text')
																							}
																							aria-hidden="true"
																						></i>
																					) : (
																						<i
																							className="fa fa-eye fonticonpositioning"
																							onClick={() =>
																								setinputtype('password')
																							}
																							aria-hidden="true"
																						></i>
																					)}
																				</div>
																				<PasswordStrengthBar
																					password={chkPassState}
																				/>
																			</Col>
																		</Row>
																	</div>
																	<div className="form-group creatadmin">
																		<div className="d-flex justify-content-end mt-2">
																			<Button
																				color="secondary"
																				onClick={() => setPasswordOpen(false)}
																			>
																				Cancel
																			</Button>
																			<button
																				className="btn listing_addbutton ml-1"
																				type="submit"
																			>
																				Update
																			</button>
																		</div>
																	</div>
																</div>
															</form>
														</Modal>
													</div>
												</div>
											</td>
										</tr>
									))
								) : (
									<p className={classes.headertekst}>{null}</p>
								)}
							</tbody>
						</Table>
						<div>
							<Row>
								<Col md={6}>
									{employeeData?.length ? (
										<p style={{ marginTop: '15px' }}>
											{countNumber * rows + 1} to{' '}
											{countNumber * rows + numberOfElements} of {totalcount}{' '}
											records
										</p>
									) : (
										<p className="formfooterrecordstyle">No Record Found</p>
									)}
								</Col>
								<Col md={6}>
									<div className={classes.root}>
										{/* {searchStatus || datefilter ? (
                  <Pagination
                    page={paginationPageNo}
                    count={getPageNo}
                    onChange={submitSearch}
                  />
                ) : ( */}
										<Pagination
											page={paginationPageNo}
											count={getPageNo}
											onChange={handlepage}
										/>
										{/* )} */}
									</div>
								</Col>
							</Row>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AllEmployees;
