import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { CardText, Col, Row, Button, Table } from 'reactstrap';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Pagination from '@mui/material/Pagination';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { toast } from 'react-toastify';
import CallAuditapi from '../services/auditservice';
import './../css/LoginForm.css';
import Modal from 'react-modal';
import { set, useForm } from 'react-hook-form';
import axios from 'axios';
import base_url from '../../api/bootapi';
import { customStyles, useStyles } from './../../Utilities/CSSUtilities';
import PasswordStrengthBar from 'react-password-strength-bar';
import { useDispatch, useSelector } from 'react-redux';
import { logout, selectEmployee } from '../../components/features/empSlice';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useToasts } from 'react-toast-notifications';
import FormControl from '@mui/material/FormControl';
import { cardClasses, Select } from '@mui/material';

import { MenuItem } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import addprojecticon from '../images/addproject.png';
import companyProfile from '../images/company_profile.jpg';
import editicon from '../images/edit.png';
import deleteicon from '../images/delete.png';
import close from '../images/closeicon.png';
import profileman from '../images/profileman.png';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import GoogleIcon from '@mui/icons-material/Google';
import TwitterIcon from '@mui/icons-material/Twitter';
import StarIcon from '@mui/icons-material/Star';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import Swal from 'sweetalert2';

const UserProf = (props) => {
	const classes = useStyles();
	const params = useParams();
	const jwtToken = 'JWT ' + localStorage.getItem('JWTToken');
	const [backdropOpen, setBackdropOpen] = useState(false);
	const [value, setValue] = React.useState('1');
	const [profileValue, setProfileValue] = React.useState('1');
	const formData = new FormData();

	const [firstName, setFirstName] = useState();
	const [lastName, setLastName] = useState();
	const [dob, setDob] = useState();
	const [gender, setGender] = useState();
	const [mobile, setMobile] = useState();
	const [email, setEmail] = useState();

	const [department, setDepartment] = useState();
	const [designation, setDesignation] = useState();
	const [role, setRole] = useState();
	const [reportingManager, setReportingManager] = useState();
	const [totalLeaves, setTotalLeaves] = useState();
	const [usedLeaves, setUsedLeaves] = useState();
	const employeeData = useSelector(selectEmployee);
	const [companyDetails, setCompanyDetails] = useState([]);
	const [userData, setUserData] = useState({});
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [oldPassword, setOldPassword] = useState('');
	const [passwordError, setPasswordError] = useState('');
	const [error, setError] = useState({});
	const { addToast } = useToasts();
	const [inputtype, setinputtype] = useState('password');
	const [confirmInputType, setConfirmInputType] = useState('password');
	const [oldInputtype, setOldInputtype] = useState('password');

	const [isImageUpload, setIsImageUpload] = useState(false);

	const employeData = useSelector(selectEmployee);

	//console.log(employeData);
	//console.log(employeData.emp_name);
	useEffect(() => {
		loadUsers(params.user_id);
		getcompanyData();
	}, []);

	const loadUsers = () => {
		setBackdropOpen(true);
		axios
			.get(
				`${base_url.api1}/myappapi/users/getuser?company_code=` +
					employeeData.company_code +
					`&user_id=` +
					params.user_id,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then((response) => {
				let userDetails = response.data.Users;
				console.log('User Details', response.data.Users);
				let projectManagerData = response.data.Users.project_managers;
				let ManagerData_obj = [];
				for (let i = 0; i < projectManagerData.length; i++) {
					let data = {
						value: projectManagerData[i].manager_id,
						label: projectManagerData[i].manager_name,
					};
					ManagerData_obj.push(data);
				}
				if (ManagerData_obj.length == 0) {
					setProjecManagerState(true);
				} else {
					setProjecManagerState(false);
				}
				setSelectedProjectManager1(ManagerData_obj);
				setSelectedProjectManager(ManagerData_obj);
				setUserData({
					first_name: userDetails.first_name,
					last_name: userDetails.last_name,
					email: userDetails.email,
					phone: userDetails.phone,
					blood_group: userDetails.blood_group,
					aadhaar_card: userDetails.aadhaar_card,
					date_of_birth: userDetails.date_of_birth,
					gender: userDetails.gender,
					role_id: userDetails.role_id,
					role_name: userDetails.role_name,
					total_leaves: userDetails.total_leaves,
					used_leaves: userDetails.used_leaves,
					status: userDetails.status,
					reporting_manager_name: userDetails.reporting_manager_name,
					reporting_manager_id: userDetails.reporting_manager_id,
					designation: userDetails.designation,
					department: userDetails.department,
					department_name: userDetails.department_name,
					company_id: userDetails.company_id,
					company_code: userDetails.company_code,
				});
				setSelectedRoleId(userDetails.role_id);
				setSelectedRoleName(userDetails.role_name);
				setgetSelected(userDetails.reporting_manager_id);
				setBackdropOpen(false);
				let profileBtn = document.getElementById('profilebtn');
				profileBtn.innerText('Edit');
			})
			.catch((error) => {
				console.log(error);
				setBackdropOpen(false);
			});
	};

	const getcompanyData = () => {
		axios
			.get(
				`${base_url.api1}/myappapi/company?company_code=` +
					employeData.company_code,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then(
				(response) => {
					setCompanyDetails(response.data.Company);
					console.log(response.data.Company);
				},
				(error) => {
					console.log(JSON.stringify(error.response.data));
					setCompanyDetails();
				}
			);
	};

	const [selectedRoleId, setSelectedRoleId] = useState();
	const [availableroles, setAvailableroles] = useState([]);
	const [selectedRoleName, setSelectedRoleName] = useState();
	const getRoles = () => {
		setBackdropOpen(true);
		axios
			.get(
				`${base_url.api1}/myappapi/role/list?company_code=` +
					employeeData.company_code,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then((response) => {
				console.log(response.data.Role);
				setAvailableroles(response.data.Role);
				setBackdropOpen(false);
			})
			.catch((error) => {
				toast.error(JSON.stringify(error.response.data.message));
				console.log(JSON.stringify(error));
				setBackdropOpen(false);
			});
	};
	const lookup = availableroles;

	const handleChangeRole = (event) => {
		const index = event.target.selectedIndex;
		const optionElement = event.target.childNodes[index];
		const optionElementId = optionElement.getAttribute('id');
		console.log(optionElementId);
		const optionElementValue = optionElement.getAttribute('value');
		console.log(optionElementValue);

		setSelectedRoleId(optionElementId);
		setSelectedRoleName(optionElementValue);
	};

	const [availableRoleById, setAvailableRoleById] = useState([]);
	const userByRoleidAll = () => {
		setBackdropOpen(true);
		axios
			.get(
				`${base_url.api1}/myappapi/all_users_by_role?role_id=` +
					1 +
					`,` +
					3 +
					`&company_code=` +
					employeeData.company_code,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then((response) => {
				console.log(response.data.Users);
				setAvailableRoleById(response.data.Users);
				setBackdropOpen(false);
			})
			.catch((error) => {
				console.log(JSON.stringify(error));
				setBackdropOpen(false);
			});
	};

	const look = availableRoleById;
	// console.log(look);

	const [getselected, setgetSelected] = useState(-1);

	const convertBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const fileReader = new FileReader();
			fileReader.readAsDataURL(file);
			fileReader.onload = () => {
				resolve(fileReader.result);
			};
			fileReader.onerror = (error) => {
				reject(error);
			};
		});
	};

	const [baseimage, setbaseimage] = useState('');
	const [logofilesize, setlogofilesize] = useState(false);
	const uploadLogo = async (data) => {
		const file = data.target.files[0];
		formData.append('image', file, file.name);
		const base64 = await convertBase64(file);
		setbaseimage(base64);
	};
	const chklogosize = (e) => {
		var chk = false;
		const numberfiles = e.target.files;
		for (let i = 0; i < numberfiles.length; i++) {
			if (numberfiles[i].size / 1048576 > 10) {
				chk = true;
			}
		}
		setlogofilesize(chk);
	};

	const [availableManager, setAvailableManager] = useState();
	const [managerData, setManager] = useState();
	const getManagerData = () => {
		axios
			.get(
				`${base_url.api1}/myappapi/all_users_by_role?role_id=1,3&company_code=` +
					employeeData.company_code,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then((response) => {
				// console.log(response.data.Users);
				setManager(response.data.Users);
				let manager_data = response.data.Users;
				const manager_object = [];
				for (let i = 0; i < manager_data.length; i++) {
					let data = {
						value: manager_data[i].user_id,
						label: manager_data[i].first_name + ' ' + manager_data[i].last_name,
					};
					manager_object.push(data);
				}
				setAvailableManager(manager_object);
			})
			.catch((error) => {
				console.log(JSON.stringify(error));
			});
	};

	const [selectedReportingManagerId, setSelectedReportingManagerId] =
		useState();
	const [selectedReportingManagerName, setSelectedReportingManagerName] =
		useState();
	const handleChangeReportingManager = (event) => {
		const index = event.target.selectedIndex;
		const optionElement = event.target.childNodes[index];
		const optionElementId = optionElement.getAttribute('id');
		console.log(optionElementId);
		const optionElementValue = optionElement.getAttribute('value');
		console.log(optionElementValue);

		setSelectedReportingManagerId(optionElementId);
		setSelectedReportingManagerName(optionElementValue);
	};

	const [projecManagerState, setProjecManagerState] = useState(false);
	const [projecManagerData, setSelectedProjectManager] = useState();
	const [projecManagerData1, setSelectedProjectManager1] = useState(true);
	const onSelectProjectManager = (selectedList, selectedItem) => {
		console.log('Entering', selectedList);
		let mng_obj = [];
		for (let i = 0; i < selectedList.length; i++) {
			let data = {
				manager_id: selectedList[i].value,
				manager_name: selectedList[i].label,
			};
			mng_obj.push(data);
		}
		if (mng_obj.length == 0) {
			setProjecManagerState(true);
			setSelectedProjectManager1(null);
			setSelectedProjectManager(null);
		} else {
			setProjecManagerState(false);
			setSelectedProjectManager(mng_obj);
			setSelectedProjectManager1(selectedList);
		}
	};

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const edit = () => {
		let profileBtn = document.getElementById('profilebtn');
		if (profileBtn.innerText == 'Edit') {
			let fields = document.getElementsByClassName('profilefield');
			console.log(fields);

			profileBtn.innerText = 'Update';
			console.log(userData);
			for (let i = 0; i < fields.length; i++) {
				fields[i].removeAttribute('disabled', 'true');
			}
		} else {
			submitData(userData);
		}
	};
	const submitData = (data) => {
		let profileBtn = document.getElementById('profilebtn');

		const newErrors = {};
		if (userData.first_name.length === 0) {
			newErrors.first_name = 'First name cannot be empty';
			console.log('First name cannot be empty');
		}
		if (userData.date_of_birth.length === 0) {
			newErrors.date_of_birth = 'Date of birth cannot be empty';
		}
		/* if (userData.mobile.length === 0) {
			newErrors.mobile = 'Mobile cannot be empty';
		} */
		if (userData.first_name.length === 0) {
			newErrors.email = 'Email cannot be empty';
		}
		if (userData.phone.length === 0) {
			newErrors.phone = 'Phone cannot be empty';
		}
		if (userData.aadhaar_card.length) {
			if (
				userData.aadhaar_card.length < 12 ||
				userData.aadhaar_card.length > 12
			) {
				newErrors.aadhaar_card = 'Adhaar Number is not valid';
			}
		}

		setError(newErrors);

		if (Object.keys(newErrors).length === 0) {
			// Submit the form if there are no errors
			// Add your form submission logic here
			console.log('Form submitted successfully:', userData);
			profileBtn.innerText = 'Edit';
			//submitData(userData);
			const updateForm = new FormData();
			updateForm.append('data', JSON.stringify(userData));
			setBackdropOpen(true);
			axios
				.put(
					`${base_url.api1}/myappapi/users/update?company_code=` +
						employeeData.company_code +
						`&user_id=` +
						params.user_id,
					updateForm,
					{
						headers: {
							Authorization: jwtToken,
						},
					}
				)
				.then(
					(response) => {
						Swal.fire({
							title: 'Success',
							text: 'Details updated successfully',
							icon: 'success',
							confirmButtonText: 'OK',
						});
						let log =
							employeeData.emp_name +
							`edited in ${employeeData.emp_name}profile`;
						CallAuditapi(log);
						setBackdropOpen(false);
					},
					(error) => {
						Swal.fire({
							title: 'Error',
							text: 'Error updating details',
							icon: 'error',
							confirmButtonText: 'OK',
						});
						console.log(JSON.stringify(error.response.data.message));
						setBackdropOpen(false);
					}
				);
		} else {
			console.log('Form contains errors:', newErrors);
		}
	};

	const editProffesion = () => {
		let fields = document.getElementsByClassName('professionfield');
		let proffBtn = document.getElementById('professionbtn');
		proffBtn.innerText = 'Update';
		console.log(fields);

		for (let i = 0; i < fields.length; i++) {
			fields[i].removeAttribute('disabled', 'true');
		}
	};

	const profileChange = (event, newValue) => {
		setProfileValue(newValue);
	};

	let userImage;
	if (employeeData.photo) {
		userImage = base_url.file1 + employeeData.photo;
		console.log(userImage);
	}

	const validatePassword = (password) => {
		const passwordRegex =
			/^(?=.*\d)(?=.*[A-Z])(?=.*[@#$%^&*!])[A-Za-z\d@#$%^&*!]{8,}$/;
		return passwordRegex.test(password);
	};
	const handlePasswordChange = (event) => {
		const { value } = event.target;
		setPassword(value);
	};
	const handleOldPasswordChange = (event) => {
		const { value } = event.target;
		setOldPassword(value);
	};

	const handleConfirmPasswordChange = (event) => {
		const { value } = event.target;
		setConfirmPassword(value);
	};

	const handleSubmit = (event) => {
		event.preventDefault();

		if (
			password.length === 0 ||
			confirmPassword.length === 0 ||
			oldPassword.length === 0
		) {
			setPasswordError('Please fill in all fields.');
		} else if (password !== confirmPassword) {
			setPasswordError('Passwords do not match.');
		} else if (!validatePassword(password)) {
			setPasswordError(
				'Password must contain at least 8 characters with at least 1 digit, 1 capital letter, and 1 special character.'
			);
		} else if (password !== confirmPassword) {
			setPasswordError('Passwords do not match.');
		} else {
			setPasswordError('');
			// Password and Confirm Password are valid. Proceed with your logic here.
			//console.log('Password and Confirm Password are valid.');
			let data = {
				email: employeData.emp_email,
				password: password,
				old_password: oldPassword,
			};

			setBackdropOpen(true);
			axios
				.post(
					`${base_url.api1}/myappapi/change_old_password?company_code=` +
						employeeData.company_code,
					data,
					{
						headers: {
							'Content-Type': 'application/json',
							Authorization: jwtToken,
						},
					}
				)
				.then((response) => {
					console.log(response);
					if (response.data.status) {
						Swal.fire({
							title: 'Success',
							text: response.data.message,
							icon: 'success',
							confirmButtonText: 'OK',
						});

						setPassword('');
						setConfirmPassword('');
						setOldPassword('');
						setinputtype('password');
						setConfirmInputType('password');
						setBackdropOpen(false);
					} else if (!response.data.status) {
						Swal.fire({
							title: 'Error',
							text: response.data.message,
							icon: 'error',
							confirmButtonText: 'OK',
						});

						setPassword('');
						setConfirmPassword('');
						setOldPassword('');
						setinputtype('password');
						setConfirmInputType('password');
						setBackdropOpen(false);
					}
				})
				.catch((error) => {
					console.log(error);
					setBackdropOpen(false);
					Swal.fire({
						title: 'Error',
						text: error,
						icon: 'error',
						confirmButtonText: 'OK',
					});
				});
		}
	};
	const newHandleChange = (e) => {
		const name = e.target.name;
		const value = e.target.value;

		setUserData((prevUserData) => ({
			...prevUserData,
			[name]: value,
		}));
		setError('');
	};
	// useEffect(() => {
	// 	if (isImageUpload) {
	// 	}
	// }, [isImageUpload]);
	const [newUserImage, setNewUserImage] = useState(null);
	const inputRef = useRef(null);

	const handleImageChange = (event) => {
		const file = event.target.files[0];
		const newFormData = new FormData();
		newFormData.append('image', file, file.name);
		let data = {};
		newFormData.append('data', JSON.stringify(data));
		console.log(newFormData.get('image'));
		//setIsImageUpload(true);
		setBackdropOpen(true);
		axios
			.put(
				`${base_url.api1}/myappapi/users/update?company_code=` +
					employeeData.company_code +
					`&user_id=` +
					params.user_id,
				newFormData,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then(
				(response) => {
					Swal.fire({
						title: 'Success',
						text: 'Image uploaded successfully',
						icon: 'success',
						confirmButtonText: 'OK',
					});
					setBackdropOpen(false);
				},
				(error) => {
					Swal.fire({
						title: 'Success',
						text: 'Error uploading image',
						icon: 'error',
						confirmButtonText: 'OK',
					});
					console.log(JSON.stringify(error.response.data.message));
					setBackdropOpen(false);
				}
			);
	};

	const handleCameraClick = () => {
		inputRef.current.click(); // Trigger click on the hidden file input
	};

	return (
		<>
			<div>
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={backdropOpen}
				>
					<CircularProgress color="inherit" />
				</Backdrop>
				<div className="profile_container">
					<div className="profile_header">
						<TabContext value={value}>
							<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
								<TabList
									onChange={handleChange}
									aria-label="lab API tabs example"
								>
									<Tab label="User Profile" value="1" />

									<Tab label="Company Profile" value="2" />
								</TabList>
							</Box>
							<TabPanel value="1">
								<div
									style={{
										position: 'relative',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									<div className="profile_main">
										<div className="profile_main_right">
											<div
												style={{
													display: 'flex',
													justifyContent: 'center',
													marginTop: '30px',
												}}
											>
												<div className="circle_image">
													<img src={userImage} alt="pic" />
													<div className="edit-pic">
														<CameraAltIcon onClick={handleCameraClick} />
													</div>
												</div>
												<input
													id="imageUpload"
													ref={inputRef}
													type="file"
													accept="image/*"
													style={{ display: 'none' }}
													onChange={(data) => {
														handleImageChange(data);
													}}
												/>
											</div>
											{isImageUpload && (
												<button
													className="update-profile"
													id="imageUploadBtn"
													style={{
														margin: 'auto',
														display: 'block',
													}}
													//onClick={}
												>
													Upload
												</button>
											)}

											<h2 className="profile_name">{firstName}</h2>

											<div className="social-media">
												<div className="social-media-icons">
													<FacebookOutlinedIcon />
												</div>
												<div className="social-media-icons">
													<GoogleIcon />
												</div>
												<div className="social-media-icons">
													<TwitterIcon />
												</div>
											</div>
										</div>
										<div className="profile_main_left">
											<TabContext value={profileValue}>
												<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
													<TabList
														onChange={profileChange}
														aria-label="profile"
													>
														<Tab label="Personal Details" value="1" />

														<Tab label="Professional department" value="2" />
														<Tab label="Password" value="3" />
													</TabList>
												</Box>
												<TabPanel value="1">
													<div
														style={{
															display: 'flex',
															flexDirection: 'column',
															gap: '20px',
															marginTop: '0px',
														}}
													>
														<div className="profile_grid_two">
															<div>
																<h3>First Name</h3>
																<input
																	name="first_name"
																	className="profilefield"
																	type="text"
																	value={userData.first_name}
																	disabled="false"
																	onChange={(e) => {
																		//setFirstName(e.target.value);
																		newHandleChange(e);
																	}}
																/>
																{error.first_name && (
																	<p
																		className="errormessage"
																		style={{ color: 'red', margin: '0px' }}
																	>
																		First Name is Required
																	</p>
																)}
															</div>

															<div>
																<h3>Last Name</h3>
																<input
																	name="last_name"
																	className="profilefield"
																	type="text"
																	disabled="false"
																	value={userData.last_name}
																	onChange={(e) => {
																		//setFirstName(e.target.value);
																		newHandleChange(e);
																	}}
																/>
															</div>

															<div>
																<h3>Gender</h3>
																<select
																	name="gender"
																	className="profilefield"
																	disabled="false"
																	value={userData.gender}
																	onChange={(e) => {
																		//setFirstName(e.target.value);
																		newHandleChange(e);
																	}}
																>
																	<option value="male">Male</option>
																	<option value="female">Female</option>
																	<option value="other">Other</option>
																</select>
															</div>
															<div>
																<h3>Date Of Birth</h3>
																<input
																	name="date_of_birth"
																	className="profilefield"
																	type="date"
																	disabled="false"
																	value={userData.date_of_birth}
																	onChange={(e) => {
																		//setFirstName(e.target.value);
																		newHandleChange(e);
																	}}
																/>
															</div>
															<div>
																<h3>Blood Group</h3>
																<input
																	name="blood_group"
																	className="profilefield"
																	disabled="false"
																	value={userData.blood_group}
																	onChange={(e) => {
																		//setFirstName(e.target.value);
																		newHandleChange(e);
																	}}
																/>
																{error.blood_group && (
																	<p
																		className="errormessage"
																		style={{ color: 'red', margin: '0px' }}
																	>
																		Blood Group is Required
																	</p>
																)}
															</div>
															<div>
																<h3>Adhaar Card No</h3>
																<input
																	name="aadhaar_card"
																	className="profilefield"
																	type="number"
																	id="aadhaar_card"
																	maxLength={12}
																	disabled="false"
																	value={userData.aadhaar_card}
																	onChange={(e) => {
																		//setFirstName(e.target.value);
																		newHandleChange(e);
																	}}
																/>
																{error.aadhaar_card && (
																	<p
																		className="errormessage"
																		style={{ color: 'red', margin: '0px' }}
																	>
																		{error.aadhaar_card}
																	</p>
																)}
															</div>
															<div>
																<h3>Mobile</h3>
																<input
																	name="phone"
																	className="profilefield"
																	type="number"
																	disabled="false"
																	value={userData.phone}
																	onChange={(e) => {
																		//setFirstName(e.target.value);
																		newHandleChange(e);
																	}}
																/>
																{error.phone && (
																	<p
																		className="errormessage"
																		style={{ color: 'red', margin: '0px' }}
																	>
																		Phone is Required
																	</p>
																)}
															</div>
															<div>
																<h3>Email Address</h3>
																<input
																	name="email"
																	className="profilefield"
																	type="text"
																	id="username"
																	disabled="false"
																	value={userData.email}
																	onChange={(e) => {
																		//setFirstName(e.target.value);
																		newHandleChange(e);
																	}}
																/>
																{error.email && (
																	<p
																		className="errormessage"
																		style={{ color: 'red', margin: '0px' }}
																	>
																		Email is Required
																	</p>
																)}
															</div>
														</div>

														<button
															className="update-profile"
															id="profilebtn"
															onClick={edit}
														>
															Edit
														</button>
													</div>
												</TabPanel>

												<TabPanel value="2">
													<div
														style={{
															display: 'flex',
															flexDirection: 'column',
															gap: '20px',
															marginTop: '40px',
														}}
													>
														<div className="profile_grid_two">
															<div>
																<h3>Department</h3>
																<input
																	className="professionfield"
																	type="text"
																	value={userData.department_name}
																	disabled="false"
																	onChange={(e) => {
																		setDepartment(e.target.value);
																	}}
																/>
															</div>

															<div>
																<h3>Designation</h3>
																<input
																	className="professionfield"
																	type="text"
																	id="username"
																	value={userData.designation}
																	disabled="false"
																	onChange={(e) => {
																		setDesignation(e.target.value);
																	}}
																/>
															</div>

															<div>
																<h3>Role</h3>
																<input
																	className="professionfield"
																	type="text"
																	id="username"
																	value={selectedRoleName}
																	disabled="false"
																	onChange={(e) => {
																		setRole(e.target.value);
																	}}
																/>
															</div>
															<div>
																<h3>Reporting Manager</h3>
																<input
																	className="professionfield"
																	type="text"
																	id="username"
																	value={userData.reporting_manager_name}
																	disabled="false"
																	onChange={(e) => {
																		setReportingManager(e.target.value);
																	}}
																/>
															</div>
															<div>
																<h3>Total Leaves</h3>
																<input
																	className="professionfield"
																	type="text"
																	id="username"
																	value={userData.total_leaves}
																	disabled="false"
																	onChange={(e) => {
																		setTotalLeaves(e.target.value);
																	}}
																/>
															</div>
															<div>
																<h3>Used Leaves</h3>
																<input
																	className="professionfield"
																	type="text"
																	id="username"
																	value={userData.used_leaves}
																	disabled="false"
																	onChange={(e) => {
																		setUsedLeaves(e.target.value);
																	}}
																/>
															</div>
														</div>

														<button
															className="update-profile"
															id="professionbtn"
															onClick={editProffesion}
															style={{ display: 'none' }}
														>
															Edit
														</button>
													</div>
												</TabPanel>

												<TabPanel value="3">
													<div
														style={{
															display: 'flex',
															flexDirection: 'column',
															justifyContent: 'center',
															alignItems: 'center',

															gap: '20px',
															marginTop: '60px',
														}}
													>
														<div
															style={{
																display: 'flex',
																flexDirection: 'column',
																gap: '20px',
																width: '50%',
															}}
														>
															<div>
																<h3>Old Password</h3>
																<div
																	style={{
																		position: 'relative',
																		width: '100%',
																	}}
																>
																	<input
																		className="profilefield"
																		type={oldInputtype}
																		value={oldPassword}
																		onChange={handleOldPasswordChange}
																	/>
																	<i
																		className={`fa ${
																			oldInputtype === 'password'
																				? 'fa-eye-slash'
																				: 'fa-eye'
																		} fonticonpositioning`}
																		style={{
																			position: 'absolute',
																			right: '7px',
																			top: '50%',
																			transform: 'translateY(-50%)',
																			fontSize: '1.4em',
																			color: '#94b2c6',
																			cursor: 'pointer',
																		}}
																		onClick={() =>
																			setOldInputtype(
																				oldInputtype === 'password'
																					? 'text'
																					: 'password'
																			)
																		}
																		aria-hidden="true"
																	/>
																</div>
															</div>
															<div>
																<h3>New Password</h3>
																<div
																	style={{
																		position: 'relative',
																		width: '100%',
																	}}
																>
																	<input
																		className="profilefield"
																		type={inputtype}
																		value={password}
																		onChange={handlePasswordChange}
																	/>
																	<i
																		className={`fa ${
																			inputtype === 'password'
																				? 'fa-eye-slash'
																				: 'fa-eye'
																		} fonticonpositioning`}
																		style={{
																			position: 'absolute',
																			right: '7px',
																			top: '50%',
																			transform: 'translateY(-50%)',
																			fontSize: '1.4em',
																			color: '#94b2c6',
																			cursor: 'pointer',
																		}}
																		onClick={() =>
																			setinputtype(
																				inputtype === 'password'
																					? 'text'
																					: 'password'
																			)
																		}
																		aria-hidden="true"
																	/>
																</div>
															</div>

															<div>
																<h3>Confirm Password</h3>
																<div
																	style={{
																		position: 'relative',
																		width: '100%',
																	}}
																>
																	<input
																		className="profilefield"
																		type={confirmInputType}
																		value={confirmPassword}
																		onChange={handleConfirmPasswordChange}
																	/>
																	<i
																		className={`fa ${
																			confirmInputType === 'password'
																				? 'fa-eye-slash'
																				: 'fa-eye'
																		} fonticonpositioning`}
																		style={{
																			position: 'absolute',
																			right: '7px',
																			top: '50%',
																			transform: 'translateY(-50%)',
																			fontSize: '1.4em',
																			color: '#94b2c6',
																			cursor: 'pointer',
																		}}
																		onClick={() =>
																			setConfirmInputType(
																				confirmInputType === 'password'
																					? 'text'
																					: 'password'
																			)
																		}
																		aria-hidden="true"
																	/>
																</div>
															</div>
														</div>
														{passwordError && (
															<span
																className="error"
																style={{
																	color: 'Red',
																	width: '300px',
																	textAlign: 'center',
																}}
															>
																{passwordError}
															</span>
														)}

														<button
															className="update-password"
															onClick={handleSubmit}
														>
															Change Password
														</button>
													</div>
												</TabPanel>
											</TabContext>
										</div>
									</div>
								</div>
							</TabPanel>
							<TabPanel value="2">
								<div
									style={{
										position: 'relative',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									<div className="company_profile_main">
										<div className="company_main_right">
											<div className="company_profile_photo">
												<img
													width="100%"
													height="100%"
													src={companyProfile}
													alt="man"
												/>
											</div>

											<h2 className="profile_name">
												OdiSoft Tachnology Pvt Limited
											</h2>

											<div style={{ textAlign: 'center', marginTop: '60px' }}>
												<p
													style={{
														padding: '0px',
														color: 'black',
														fontSize: '1.2em',
													}}
												>
													Rating Overview
												</p>
												<p
													style={{
														padding: '0px',
														color: 'black',
														fontSize: '2em',
													}}
												>
													4.0<span style={{ fontSize: '15px' }}>/5</span>
												</p>
												<div>
													<StarIcon className="star" />
													<StarIcon className="star" />
													<StarIcon className="star" />
													<StarIcon className="star" />
													<StarIcon style={{ color: 'grey' }} />
												</div>
											</div>
										</div>
										<div className="profile_main_left">
											<TabContext value={profileValue}>
												<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
													<TabList
														onChange={profileChange}
														aria-label="profile"
													>
														<Tab label="About" value="1" />

														<Tab label="Timeline" value="2" />
														<Tab label="Company Products" value="3" />
														<Tab label="Skills" value="4" />
													</TabList>
												</Box>
												<TabPanel value="1">
													<div
														style={{
															display: 'flex',
															flexDirection: 'column',
															gap: '20px',
															marginTop: '40px',
														}}
													>
														<div className="profile_grid_two">
															<div>
																<h3>Company Name</h3>
																<input
																	className="profilefield"
																	type="text"
																	value={companyDetails.company_name}
																	disabled="false"
																/>
															</div>

															<div>
																<h3>Company Code</h3>
																<input
																	className="profilefield"
																	type="text"
																	disabled="false"
																	value={companyDetails.company_code}
																/>
															</div>

															<div>
																<h3>Email</h3>
																<input
																	className="profilefield"
																	type="text"
																	disabled="false"
																	value={companyDetails.company_email}
																/>
															</div>
															<div>
																<h3>Phone</h3>
																<input
																	className="profilefield"
																	type="text"
																	disabled="false"
																	value={companyDetails.company_phone}
																/>
															</div>
															<div>
																<h3>Full Day Work Duration</h3>
																<input
																	className="profilefield"
																	type="text"
																	disabled="false"
																	value={`${
																		companyDetails.full_day_work_duration / 3600
																	} Hours`}
																/>
															</div>
															<div>
																<h3>Half Day Work Duration</h3>
																<input
																	className="profilefield"
																	type="text"
																	id="username"
																	disabled="false"
																	value={`${
																		companyDetails.half_day_work_duration / 3600
																	} Hours`}
																/>
															</div>
															<div>
																<h3>Half Days</h3>
																<input
																	className="profilefield"
																	type="text"
																	disabled="false"
																	value={companyDetails.half_days}
																/>
															</div>
															<div>
																<h3>Week Off</h3>
																<input
																	className="profilefield"
																	type="text"
																	id="username"
																	disabled="false"
																	value={companyDetails.week_off}
																/>
															</div>
														</div>
													</div>
												</TabPanel>

												<TabPanel value="2">
													<div
														style={{
															display: 'flex',
															flexDirection: 'column',
															gap: '20px',
															marginTop: '40px',
														}}
													>
														<div className="profile_grid_two">
															<div>
																<h3>Experience</h3>
																<input
																	className="professionfield"
																	type="text"
																	disabled="false"
																/>
															</div>

															<div>
																<h3>Hourly Rate</h3>
																<input
																	className="professionfield"
																	type="text"
																	id="username"
																	disabled="false"
																/>
															</div>

															<div>
																<h3>Total Projects</h3>
																<input
																	className="professionfield"
																	type="text"
																	id="username"
																	disabled="false"
																/>
															</div>
															<div>
																<h3>English Level</h3>
																<input
																	className="professionfield"
																	type="text"
																	id="username"
																	disabled="false"
																/>
															</div>
															<div>
																<h3>Availability</h3>
																<input
																	className="professionfield"
																	type="text"
																	id="username"
																	disabled="false"
																/>
															</div>
														</div>
													</div>
												</TabPanel>

												<TabPanel value="3">item3</TabPanel>

												<TabPanel value="4">item4</TabPanel>
											</TabContext>
										</div>
									</div>
								</div>
							</TabPanel>
						</TabContext>
					</div>
					<div></div>
				</div>
			</div>
		</>
	);
};

export default UserProf;
