import React , { useState, useEffect }from 'react';
import { ToastContainer, toast } from "react-toastify";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Link, useNavigate } from "react-router-dom";
import { CardText, Col, Row, Button, Table } from "reactstrap";
import AddIcon from "@mui/icons-material/Add";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Pagination from "@mui/material/Pagination";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import { customStyles, useStyles, customStyles2 } from "./../../Utilities/CSSUtilities";
import close from "./../../components/images/closeicon.png";
import holidayicon from "./../../components/images/event.png";
import axios from "axios";
import base_url from "../../api/bootapi";
import Select from 'react-select';
import { useDispatch, useSelector } from "react-redux";
import { 
    setStarted,
    selectStarted,
    setStopped,
    selectStopped,
    logout,
    selectEmployee,
    setSessionIdData,
    selectSessionId
   } from "../../components/features/empSlice";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { log } from 'react-modal/lib/helpers/ariaAppHider';
import { StreetviewOutlined } from '@mui/icons-material';
import CallAuditapi from "../services/auditservice";

const Team = () => {
  const [backdropOpen, setBackdropOpen] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const [modalCreate, setCreate] = React.useState(false);
  const [modalView, setView] = React.useState(false);
  const [configs,setConfig] =useState([]);
  const [teams,setTeams] =useState([]);
  const [teamMembers,setTeamMembers] =useState([]);
  const [basevalue, setBaseValue] = useState();
  const employeData = useSelector(selectEmployee);
  const jwtToken = "JWT " + localStorage.getItem("JWTToken");
  const [pageNo, setPageNo] = useState(1);
  const [paginationPageNo, setPaginationPageNo] = useState(1);
  const [getPageNo, setGetPageNo] = useState();
  const [rows, setRows] = React.useState(50);
  const [searchdata, setsearch] = useState(false);
  const [datefilter, setdatefilter] = useState();
  const classes = useStyles();
  const [numberOfElements, setNumberOfElements] = useState();
  const [totalcount, setTotalCount] = useState();
  const [selectedDelete, setSelectedDelete] = useState([]);
  const [countNumber, setCountNumber] = useState();
  const [datevalue, setdatevalue] = useState("Click to open");
  const [display1, setdisplay] = useState("inline");
  const [direction, setdirection] = useState("Desc");
  const [sortState, setsortState] = useState("key_name");
  const employeeData = useSelector(selectEmployee);
  const navigate = useNavigate();
  const [permissionObj, setPermissionObj] = useState();


  const closeModal = () => {
    setCreate(false);
  }

  const createModalConfig = () => {
    setCreate(true);
  };

  const ViewModal = () => {
    setView(false);
  }

  const ViewModalConfig = () => {
    setView(true);
  };

  useEffect( () => {
    getEmployeeData();
    getPermissions();
  },[rows]);

  const handleRows = (event) => {
    setRows(event.target.value);
  };
  const handleEvent = (event, picker) => {
    console.log(picker.startDate);
  };
        
  const handleCallback = (start, end, label) => {
    console.log(start, end, label);
  };

  const getPermissions = () => {
    const resource_obj = [];
    for (let i = 0; i < employeeData.role_permissions.length; i++) {
      resource_obj.push(employeeData.role_permissions[i].resource_id);
    }
    setPermissionObj(resource_obj);
    var available_resource22 = resource_obj.includes(22);
    var available_resource23 = resource_obj.includes(23);
    var available_resource24 = resource_obj.includes(24);
    var available_resource25 = resource_obj.includes(25);
    var available_resource26 = resource_obj.includes(26);
    var available_resource27 = resource_obj.includes(27);
    var available_resource28 = resource_obj.includes(28);

    if(available_resource22 && (available_resource23 || available_resource24 || available_resource25 || available_resource26 || available_resource27 || available_resource28)) {
      if (localStorage.getItem("ConfigPageNo")) {
        let currentPage = localStorage.getItem("ConfigPageNo");
        setPageNo(currentPage);
        setPaginationPageNo(Number(currentPage));
        getAllTeamList(currentPage);
      } else {
        setPageNo(1);
        setPaginationPageNo(1);
        getAllTeamList(1);
      }
    } else {
      navigate('/errorPage');
    }
  };

  const getAllTeamList = (value1) => {
    // console.log(value1);
    setBackdropOpen(true);

    axios
      .get(`${base_url.api1}/office_app/Company_team/list?company_code=`+ employeData.company_code +`&page=`+ value1 +`&size=`+ rows, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setBackdropOpen(false);
          console.log(response.data.Company_team);
          setTeams(response.data.Company_team);
          setGetPageNo(response.data.total_pages);
          setTotalCount(response.data.total_count);
          setCountNumber(value1-1);
          setNumberOfElements(response.data.Company_team.length);
          
        },
        (error) => {
          setBackdropOpen(false);
            console.log(JSON.stringify(error.response.data));
        }
      );
  };

  const getAllTeamMemberList = (value1) => {
    // console.log(value1);
    setBackdropOpen(true);
    axios
      .get(`${base_url.api1}/office_app/Team_Employees/list?company_code=`+ employeData.company_code +`&page=`+ value1 +`&size=`+ rows, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setBackdropOpen(false);
          console.log(response.data.Team_Employees);
          setTeamMembers(response.data.Team_Employees);
          setGetPageNo(response.data.total_pages);
          setTotalCount(response.data.total_count);
          setCountNumber(value1-1);
          setNumberOfElements(response.data.Team_Employees.length);
          
        },
        (error) => {
          setBackdropOpen(false);
            console.log(JSON.stringify(error.response.data));
        }
      );
  };

  const createTeam = (data2) => {
    let team_name = data2.team_name;
    let status = data2.status; 

    let data3 = {
        team_name,
        status,
      "Employees":selectedEmployee,
    };
    console.log(data3);
 
    setBackdropOpen(true);
    axios
      .post(`${base_url.api1}/office_app/Company_team?company_code=`+ employeData.company_code, data3, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        let log = employeeData.emp_name+" created the team "+data3.team_name;
        CallAuditapi(log);
        setBackdropOpen(false);
        setCreate(false);
        getAllTeamList(1);
      })
      .catch((error) => {
        setBackdropOpen(false);
        console.log(JSON.stringify(error));
      });
 
  };

  const [editOpen, setEdit] = React.useState(false);
  const [editTeamId, seteditTeamId] = useState();
  const [editTeamname, seteditTeamname] = useState();
  const [editStatus, seteditStatus] = useState();
  const [editTeamMember, setEditTeamMember] = useState();

  const editTeamData = (elem,Team) => {
    console.log(Team.Employees);
    let emp_obj=[];
    let emp_obj2=[];
    for (let i = 0; i < Team.Employees.length; i++) {
      let data = {
        value: Team.Employees[i].emp_id,
        label: Team.Employees[i].emp_name,
      }
      let data2 = {
        emp_id: Team.Employees[i].emp_id,
        emp_name: Team.Employees[i].emp_name,
      }
      emp_obj.push(data);
      emp_obj2.push(data2);
    }
    setSelectedEmployee(emp_obj2);
    setEditTeamMember(emp_obj);
    seteditTeamId(Team.id);
    seteditTeamname(Team.team_name);
    seteditStatus(Team.status);
    setEdit(true);
  };

  const updateTeamData= (data2) =>{
  
    let data3 = {
        team_name:editTeamname,
        status:editStatus,
        "Employees":selectedEmployee,
    };
    console.log(data3);

    setBackdropOpen(true);
    axios
      .put(`${base_url.api1}/office_app/Company_team?company_code=`+ employeData.company_code +`&id=` +editTeamId , data3, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        let log = employeeData.emp_name+" edited the team "+editTeamname;
        CallAuditapi(log);
        setBackdropOpen(false);
        setEdit(false);
        getAllTeamList(pageNo);
      })
      .catch((error) => {
        setBackdropOpen(false);
      });
  }

  const handlepage = (event, value) => {
    console.log(value);
    setPaginationPageNo(value);
    setPageNo(value);
    getAllTeamList(value);
  };

  const [deleteOpen, setDelete] = React.useState(false);
  const [teamName, setTeamName] = useState();
  const deleteTeam = (elem,Team) => {
    seteditTeamId(Team.id);
    setTeamName(Team.team_name);
    setDelete(true);
  };
  const deleteCurrentCategory= () =>{
    
    setBackdropOpen(true);
    axios
      .delete(`${base_url.api1}/office_app/Company_team?company_code=`+ employeData.company_code + `&id=`+ editTeamId ,{
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        let log = employeeData.emp_name+" deleted the team "+teamName;
        CallAuditapi(log);
        setBackdropOpen(false);
        setDelete(false);
        getAllTeamList(pageNo);
      })
      .catch((error) => {
        setBackdropOpen(false);
      });
  }

  const [searchinput, setsearchinput] = useState();
  const submitSearch = (e, value) => {
     setdisplay("none");
    setsearch(true);
    let srchdata = e.target[0].value.trim();
    setsearchinput(srchdata);
    setBackdropOpen(true);
    console.log(srchdata);
    axios
      .get(`${base_url.api1}/myappapi/appconfig/list?company_code=`+ employeData.company_code+ `&page=` + 1 + `&size=` + rows +`&search=`+srchdata, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setConfig(response.data.Appconfig);
          setGetPageNo(response.data.total_pages);
          setNumberOfElements(response.data.Appconfig.length);
          setTotalCount(response.data.total_count);
          setBackdropOpen(false);
        },
        (error) => {
          setBackdropOpen(false);
          console.log(JSON.stringify(error.response.data));
          setConfig();
        },
        (error) => {
          setBackdropOpen(false);
          console.log(JSON.stringify(error.response.data));
        }
      );
  };

  const sortUser = (e) => {
    setsortState(e.target.id);
    console.log();
    if (direction == "Desc") {
      setdirection("ASC");
    } else if (direction == "ASC") {
      setdirection("Desc");
    }
    let sortby = e.target.id

    axios
      .get(`${base_url.api1}/myappapi/appconfig/list?company_code=` + employeData.company_code + `&sortby=`+sortby+`&Order=`+direction +`&page=`+pageNo, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setConfig(response.data.Appconfig);
          document.getElementById("myForm").reset();
          setGetPageNo(response.data.total_pages);
          //console.log(response.data.total_pages);
          setTotalCount(response.data.total_count);
          setCountNumber(pageNo-1);
          setNumberOfElements(response.data.Users.length);
          //console.log(response.data.Users.length);
          setSelectedDelete([]);
          setdatefilter(false);
          setsearch(false);
          setBackdropOpen(false);
        },
        (error) => {
          console.log(JSON.stringify(error.response.data));
          setConfig();
        }
      );
  };

  const [selectedEmployeeState, setSelectedEmployeeState] = useState();
  const [selectedEmployee, setSelectedEmployee] = useState();
  const onSelectTeamEmployee = (selectedList, selectedItem) => {
    console.log("Entering",selectedList);
    let emp_obj=[];
    for (let i = 0; i < selectedList.length; i++) {
      let data = {
        emp_id: selectedList[i].value,
        emp_name: selectedList[i].label,
      }
      emp_obj.push(data);
    }
    console.log(emp_obj);
    if (emp_obj.length == 0) {
      console.log("In If Condition");
      setSelectedEmployeeState(true);
    } else {
      console.log("In Else Condition");
      setSelectedEmployeeState(false);
      setSelectedEmployee(emp_obj);
      setEditTeamMember(selectedList);
    }
  };

  const [availableEmployee, setAvailableEmployee] = useState();
  const getEmployeeData = () => {
    axios
      .get(`${base_url.api1}/myappapi/all_users_by_role?role_id=2,3,4&company_code=` + employeeData.company_code , {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        let manager_data = response.data.Users;
        const manager_object=[];
        for (let i = 0; i < manager_data.length; i++) {
          let data = {
            value: manager_data[i].user_id,
            label: manager_data[i].first_name+" "+manager_data[i].last_name,
          };
          manager_object.push(data);
        }
        setAvailableEmployee(manager_object);
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
      });
  };

  const closedModal = () => {
    setDelete(false);
  }
  const DeleteInHoliday = () => {
    setDelete(true);
  };


  return (
    <>
    <div>
      <ToastContainer />
      <Backdrop open={backdropOpen}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
     >
        <CircularProgress color="inherit" />
    </Backdrop>
      <div className="commoncomponent_cardcss">
        <div>
          <Row>
            <Col md={6}>
              <CardText
                style={{ marginTop: "-10px" }}
              >
                <h1>Team</h1>
              </CardText>
            </Col>
            <Col md={6} className="d-flex justify-content-end">
              {permissionObj?.includes(23)?
                <Button
                  color="primary"
                  onClick={createModalConfig}
                >
                  + Add
                </Button>
              :null}
            </Col>
            <Modal
              ariaHideApp={false}
              isOpen={modalCreate}
              onRequestClose={closeModal}
              style={customStyles2}
              contentLabel="Example Modal"
            >
          <div style={{ textAlign: "right", padding: "10px 20px" }}>
            <img className="closei" src={close} onClick={() =>setCreate(false)} />
          </div>
          <div className='modalContainer'>
            <div style={{ textAlign: "center" }}>
              <img className='modelIcon' src={holidayicon} />
            </div>
              <form onSubmit={handleSubmit(createTeam)}>
              <h2>
                Team
              </h2>
                <div>
                  <div className="form-group creatadmin">
                    <Row>
                      <Col md={12}>
                        <label>
                        Name Of Team<span style={{ color: "red" }}>*</span>:
                        </label>
                        <input
                          type="text"
                          className="form-control underline-input"
                          placeholder="Team Name"
                          id="team_name"
                          {...register("team_name",{ required: true })}
                        />
                        {errors.team_name && (
                          <p className="errormessage">Team Name is Required</p>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <label>Employee Name<span style={{ color: "red" }}>*</span></label>
                      <Select
                        isMulti
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={onSelectTeamEmployee}
                        options={availableEmployee}
                      >
                      </Select>
                      </Col>
                    </Row>
                    <Row>
                    <Col md={12}>
                        <label>
                          Status<span style={{ color: "red" }}>*</span>:
                        </label>
                        <select
                          className="form-control underline-input"
                          {...register("status", { required: true })}
                        >
                        <option value="1">Active</option>
                        <option value="0">InActive</option>
                        </select>
                            {errors.status && (
                              <p className="errormessage">Status is Required</p>
                            )}
                    </Col>        
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Row className="justify-content-end mt-2">
                          <Button
                            color="secondary"
                            onClick={() => setCreate(false)}
                          >
                            Close
                          </Button>
                          <Button
                            type="submit"
                            color="primary"
                            className="btn listing_addbutton ml-1"
                          >
                            Submit
                          </Button>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </div>
              </form>
              </div>
            </Modal>
          </Row>
          <br/>
          <Row>
            {/* <form className="master">
                <div style={{ marginTop: "-7px"}}>
                  <label className="area_rowslable">Limit</label>
                  <br />
                  <FormControl className={classes.formControl}>
                    <Select
                      className={classes.selectlimit}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={rows}
                      onChange={handleRows}
                    >
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </form> */}
              <form className="form-group"> 
                {datefilter ?(
                <div>
                  <a
                    className={classes.clrsrch}
                    onClick={() => {
                      setdisplay("inline");
                      setdatevalue("Click to open");
                    }}
                  >
                      Clear Filter
                    </a>
                  </div>
                ): null} 
              </form>
                <br />
              <Col md={0}>
                <div className="form-group">
                  <div>
                    <div className="clearsearchclass">
                      <form
                        id="myForm"
                        className="d-flex"
                        onSubmit={(e) => {
                          e.preventDefault();
                          submitSearch(e,1);
                        }}
                      >
                        <input
                          style={{
                            padding: "5px",
                            border: "2px solid",
                            marginTop: "-1px",
                          }}
                          className="form-control"
                          placeholder="Search.."
                          name="search"
                          required
                        />
                        <button
                          color='blue'
                          className={classes.srchbtn}
                          type="submit"
                        >
                          <i className="fa fa-search"></i>
                        </button>
                      </form>
                      {searchdata ? (
                         <a                         
                         onEvent={handleEvent}
                         onCallback={handleCallback} 
                         onClick={() => {
                             setdisplay("inline");
                             setBackdropOpen(true);
                             setPageNo(1);
                             getAllTeamList(1);
                            setsearchinput();
                            setsearch(false);   
                          }}                    
                         >
                          Clear Search
                        </a>
                      ) : null}
                    </div>                      
                  </div>
                </div>
              </Col>
        </Row>
        <br/>
          <div className="newlisting_headertabdesign">
            <Table responsive striped>
              <thead>
                <tr>
                  <th>S No.</th>
                  <th style={{ textAlign: "center"}}>
                    Team Name{" "}
                    <UnfoldMoreIcon onClick={sortUser} id="team_name"/>
                  </th>
                  {/* <th style={{width:"100px"}}>
                    Employee Name{" "}
                    <UnfoldMoreIcon onClick={sortUser} id="emp_name"/>
                  </th> */}
                  <th style={{ textAlign: "center", width: "175px" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {teams.map((Team , index) => (
                  <>
                  <tr>
                    <th>{index + 1 }</th>
                    <td style={{ textAlign: "center"}}>{Team.team_name}</td>
                    {/* {Team.Employees.map((data) => (
                       <td style={{width:"100px"}}>{data.emp_name}</td>
                    ))} */}
                    <td>
                    <div
                      style={{
                        position: "relative",
                        textAlign: "center",
                      }}
                    >
                        <div
                          className="workflowdashboard_fa-ellipsis"
                          data-toggle="dropdown"
                        >
                          <i
                            className="fa fa-ellipsis-v "
                            aria-hidden="true"
                          ></i>
                        </div>
                        <div
                          className="dropdown-menu workflowdashboarddropdown"
                          style={{ width: "200px" }}
                        >
                          {permissionObj?.includes(24)?
                            <p id={Team.id}
                              onClick={(e)=>{
                                e.preventDefault();
                                editTeamData(e, Team)
                              }}>
                              Edit
                            </p>
                          :null}
                          <Modal
                            isOpen={editOpen}
                            onRequestClose={closedModal}
                            style={customStyles2}
                            ariaHideApp={false}
                            contentLabel="Example Modal"
                          >
                          <div style={{ textAlign: "right", padding: "10px 20px" }}>
                            <img className="closei" src={close} onClick={() =>setCreate(false)} />
                          </div>
                          <div className='modalContainer'>
                            <div style={{ textAlign: "center" }}>
                              <img className='modelIcon' src={holidayicon} />
                            </div>
                            <h2>
                              <strong>Edit Team</strong>
                            </h2>
                            <form id="editForm"
                              onSubmit={(e) => {
                                e.preventDefault();
                                updateTeamData(e);
                              }}
                            >
                              <div>
                                <div className="form-group creatadmin">
                                  <Row>
                                    <Col md={12}>
                                      <label>
                                      Name Of Team<span style={{ color: "red" }}>*</span>:
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control underline-input"
                                        placeholder="Team Name"
                                        value={editTeamname}
                                        onChange={(e) => {
                                        seteditTeamname(e.target.value);
                                        }}
                                      />
                                      {errors.team_name && (
                                        <p className="errormessage">Team Name is Required</p>
                                      )}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={12}>
                                      <label>Employee Name<span style={{ color: "red" }}>*</span></label>
                                      <Select
                                        value={editTeamMember}
                                        isMulti={true}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        onChange={onSelectTeamEmployee}
                                        options={availableEmployee}
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                  <Col md={12}>
                                      <label>
                                        Status<span style={{ color: "red" }}>*</span>:
                                      </label>
                                      <select
                                        className="form-control underline-input"
                                        value={editStatus}
                                        onChange={(e) => {
                                        seteditStatus(e.target.value);
                                        }}
                                        required
                                      >
                                      <option value="1">Active</option>
                                      <option value="0">InActive</option>
                                      </select>
                                          {errors.status && (
                                            <p className="errormessage">Status is Required</p>
                                          )}
                                  </Col>        
                                  </Row>
                                  <Row>
                                    <Col md={12}>
                                      <Row className="justify-content-end mt-2">
                                        <Button
                                          color="secondary"
                                          onClick={() => setEdit(false)}
                                        >
                                          Cancel
                                        </Button>
                                        <Button
                                          type="submit"
                                          color="primary"
                                          className="btn listing_addbutton ml-1"
                                        >
                                          Submit
                                        </Button>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </form>
                            </div>
                          </Modal>
                          {permissionObj?.includes(26)?
                            <p>
                              <a
                                onClick={() =>
                                  navigate("/view-team/" + Team.id)
                                }
                                color="info"
                              >
                                View Team
                              </a>
                            </p>
                          :null}
                          <Modal
                            ariaHideApp={false}
                            isOpen={modalView}
                            onRequestClose={ViewModal}
                            style={customStyles}
                            contentLabel="Example Modal"
                          >
                            <h2>
                              <strong>View Team Member</strong>
                            </h2>
                            <br/>
                            <div className="newlisting_headertabdesign">
                              <Table responsive striped>
                                <thead>
                                  <tr>
                                    <th style={{ width: "180px" }}>
                                      SN{" "}<UnfoldMoreIcon id="emp_name"/>
                                    </th>
                                    <th style={{ width: "175px" }}>
                                      Team Members Name{" "}<UnfoldMoreIcon id="session_date"/>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Team.Employees.map((data,ind) => (
                                    <>
                                      <tr> 
                                        <th>{ind + 1 }</th>
                                        <td style={{width:"100px"}}>{data.emp_name}</td>
                                      </tr>
                                    </> 
                                  ))}  
                                </tbody>
                              </Table>
                            </div>
                            <br />
                            <Row>
                              <Col md={12}>
                                <Row className="justify-content-end mt-2">
                                  <Button
                                    color="secondary"
                                    onClick={() => setView(false)}
                                  >
                                    Close
                                  </Button>
                                </Row>
                              </Col>
                            </Row>
                          </Modal>
                          {permissionObj?.includes(25)?
                            <p
                              id={Team.id}
                              onClick={(e) => {
                                deleteTeam(e, Team);
                                DeleteInHoliday();
                              }} color="danger">Delete
                            </p>                          
                          :null}
                          <Modal
                            ariaHideApp={false}
                            isOpen={deleteOpen}
                            onRequestClose={closedModal}
                            style={customStyles2}
                            contentLabel="Example Modal"
                          >
                            <h5>
                              Are you sure you want to delete current record ?
                            </h5>
                            <div style={{ float: "right" }}>
                              <Button
                                color="danger"
                                type="submit"
                                onClick={deleteCurrentCategory}
                              >
                                Delete
                              </Button>
                              &nbsp;&nbsp;&nbsp;
                              <Button
                                color="secondary"
                                onClick={() => setDelete(false)}
                              >
                                Cancel
                              </Button>
                            </div>
                          </Modal> 
                        </div>
                      </div>
                    </td>
                   </tr>
                   </>
                  ))}  
              </tbody>
            </Table>
            <div>
            {/* <Row>
            <Col md={6}>
              {configs?.length ? (
                <p style={{ marginTop: "15px" }}>
                  {countNumber * rows + 1} to{" "}
                  {countNumber * rows + numberOfElements} of {totalcount} records
                </p>
              ) : (
                <p className="formfooterrecordstyle">No Record Found</p>
              )}
            </Col>
            <Col md={6}>
              <div className={classes.root}>
                  <Pagination
                    page={paginationPageNo}
                    count={getPageNo}
                    onChange={handlepage}
                  />
              </div>
              </Col>
              </Row> */}
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Team ;