import React , { useState, useEffect }from 'react';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Link, useNavigate,useParams } from "react-router-dom";
import { CardText, Col, Row, Button, Table } from "reactstrap";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Pagination from "@mui/material/Pagination";
import Modal from "react-modal";
import { customStyles2, useStyles } from "./../../Utilities/CSSUtilities";
import axios from "axios";
import moment from "moment";
import base_url from '../../api/bootapi';
import { logout, selectEmployee } from "../../components/features/empSlice";
import { useDispatch, useSelector } from "react-redux";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Select from 'react-select';
import FormControl from '@mui/material/FormControl';
import attachmenturl from '../../api/attachmenturl';
import CallAuditapi from "../services/auditservice";


const Task = (props) => {
  const [backdropOpen, setBackdropOpen] = useState(false);
  const params = useParams(); 
  const dispatch = useDispatch(); 
  const [modalShowDoc, setViewTaskDoc] = React.useState(false);
  const [taskData,setTaskData] =useState([]);
  const navigate =useNavigate();
  const jwtToken = "JWT " + localStorage.getItem("JWTToken");
  const classes = useStyles();
  const employeeData = useSelector(selectEmployee);
  const [display, setDisplay] = useState("inline");
  const [numberOfElements, setNumberOfElements] = useState();
  const [rows, setRows] = React.useState(50);
  const [dateFilter, setDateFilter] = useState(false);
  const [dateValue, setDateValue] = useState("Task Date");
  const [direction, setDirection] = useState("Desc");
  const [pageNo, setPageNo] = useState(1);
  const [paginationPageNo, setPaginationPageNo] = useState(1);
  const [getPageNo, setGetPageNo] = useState();
  const [totalCount, setTotalCount] = useState();
  const [datesRange, setDatesRange] = useState();
  const [countNumber, setCountNumber] = useState();
  const [sortState, setSortState] = useState("task_title");
  var urlData;
  const [permissionObj, setPermissionObj] = useState();
  const [display1, setdisplay] = useState("inline");
  const [searchdata, setsearch] = useState(false);


  useEffect( () => {
    getPermissions();
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
  },[rows]);

  const handleRows = (event) => {
    setRows(event.target.value);
  };

  const getPermissions = () => {
    const resource_obj = [];
    for (let i = 0; i < employeeData.role_permissions.length; i++) {
      resource_obj.push(employeeData.role_permissions[i].resource_id);
    }
    setPermissionObj(resource_obj);
    var available_resource = resource_obj.includes(12);

    if(available_resource) {
      UserByRoleID();
      TaskStatusData();
      TaskPriorityData();
      if (localStorage.getItem("TaskPageNo")) {
        let currentPage = localStorage.getItem("TaskPageNo");
        setPageNo(currentPage);
        setPaginationPageNo(Number(currentPage));
        urlData = `&project_id=`+ params.project_id+`&page=`+currentPage+`&size=`+ rows;
        getAllData(urlData);
      } else {
        setPageNo(1);
        setPaginationPageNo(1);
        urlData = `&project_id=`+ params.project_id+`&page=1&size=`+ rows;
        getAllData(urlData);
      }
    } else {
      navigate('/errorPage');
    }
  };

  const closeModal = () => {
    setViewTaskDoc(false);
    setDeleteModal(false);
  }

  const goback = () => {
    navigate('/projects');
  }; 

  const [deleteTaskId, setDeleteTaskId] = useState();
  const [deleteOpen, setDeleteModal] = React.useState(false);
  const [deleteTasktitle, setDeleteTasktitle] = useState();
  const deleteCurrentTask = () => {
    setBackdropOpen(true);
    axios
      .delete(`${base_url.api1}/office_app/assign_task?company_code=` + employeeData.company_code +`&id=` + deleteTaskId  , {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          let log = employeeData.emp_name+" deleted in task.";
          CallAuditapi(log);
          setBackdropOpen(false);
          setDeleteModal(false);
          urlData = `&project_id=`+ params.project_id+`&page=`+pageNo+`&size=`+ rows;
          getAllData(urlData);
        },
        (error) => {
          setBackdropOpen(false);
          setDeleteModal(false);
          console.log(JSON.stringify(error.response.data));
        }
      );
  };

  const handlePage = (event, value) => {
    setPaginationPageNo(value);
    setPageNo(value);
    console.log(value-1);
    urlData = `&project_id=`+ params.project_id+`&page=`+value+`&size=`+ rows;
    getAllData(urlData);
  };

  const handleEvent = (event, picker) => {
    console.log(picker.startDate);
  };
    
  const handleCallback = (start, end, label) => {
    console.log(start, end, label);
  };

  const handleApply = (event, picker) => {
    setBackdropOpen(true);
    setDateValue(
      `${picker.startDate.format("DD-MM-YYYY")} to ${picker.endDate.format(
        "DD-MM-YYYY"
      )}`
    );
    setPageNo(0);
    setPaginationPageNo(1);
    let data = {
      from: picker.startDate.format("YYYY-MM-DD"),
      to: picker.endDate.format("YYYY-MM-DD"),
      // sortby: sortState,
      // Order: direction,
    };
    setDatesRange(data);
    axios
      .get(`${base_url.api1}/office_app/assign_task/range?company_code=` + employeeData.company_code +`&project_id=`+params.project_id +`&from=`+data.from+`&to=`+data.to+`&page=`+pageNo+`&size=`+ rows, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setTaskData(response.data.Tasks);
          setGetPageNo(response.data.total_pages);
          setTotalCount(response.data.total_count);
          setCountNumber(pageNo-1);
          setNumberOfElements(response.data.Tasks.length);
          setsearch(false);
          setBackdropOpen(false);
          setDateFilter(true);
          document.getElementById("myForm").reset();
        },
        (error) => {
          setTaskData();
          console.log(JSON.stringify(error.response.data));
          setDateFilter(true);
          setBackdropOpen(false);
        });
  };

  const [employeeId , setEmployeeId] = useState();
  const [selectEmployeeState, setSelectedEmployee] = useState(false);
  const [selectEmployeeData, setSelectedEmployeeData] = useState();
  const [selectEmployeeId, setSelectedEmployeeId] = useState();
  const onSelectEmployee = (selectedList, selectedItem) => {
    console.log("Entering",selectedList);
    setBackdropOpen(true);
    setSelectedEmployee(true);
    setSelectedEmployeeData(selectedList);
    setSelectedEmployeeId(selectedList.value);
    if(selectedList.value==null){
      urlData = `&project_id=`+ params.project_id+`&page=1&size=`+ rows;
      getAllData(urlData);
    } else {
      urlData = `&project_id=`+ params.project_id+`&page=1&size=`+ rows+ `&emp_id=`+selectedList.value;
      getAllData(urlData);
    }
  };


  const [availableEmployee, setAvailableEmployee] = useState({value: null, label: null});
  const UserByRoleID = () => {
    axios
      .get(`${base_url.api1}/myappapi/all_users_by_role?company_code=` + employeeData.company_code + `&status=True`  , {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        let emp_data = response.data.Users;
        let emp_obj = [];
        for (let i = 0; i < emp_data.length; i++) {
          let data = {
            value: emp_data[i].user_id,
            label: emp_data[i].first_name +" "+ emp_data[i].last_name,
          };
          emp_obj.push(data);
        }
        setAvailableEmployee(emp_obj);
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
      });
  };

  const [selectstatus, setSelectedStatus] = useState(false);
  const [selectStatusData, setSelectedStatusData] = useState();
  const [selectStatusId, setSelectedStatusId] = useState();
  const onSelectStatus = (selectedList, selectedItem) => {
    console.log("Entering",selectedList);
    setBackdropOpen(true);
    setSelectedStatus(true);
    setSelectedStatusData(selectedList);
    setSelectedStatusId(selectedList.value);
    if(selectedList.value==null){
      if(selectEmployeeState){
        urlData = `&project_id=`+ params.project_id+`&page=1&size=`+ rows+`&emp_id=`+selectEmployeeId;
        getAllData(urlData);
      } else {
        urlData = `&project_id=`+ params.project_id+`&page=1&size=`+ rows;
        getAllData(urlData);
      }
    } else {
      if(selectEmployeeState){
        urlData = `&project_id=`+ params.project_id+`&page=1&size=`+ rows+ `&status=`+selectedList.value+`&emp_id=`+selectEmployeeId;
        getAllData(urlData);
      } else {
        urlData = `&project_id=`+ params.project_id+`&page=1&size=`+ rows+ `&status=`+selectedList.value;
        getAllData(urlData);
      }
    }
  };

  const [availableStatus, setAvailableStatus] = useState([]);
  const TaskStatusData = () => {
    axios
      .get(`${base_url.api1}/office_app/Task_status/list?company_code=` + employeeData.company_code, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        let status_data = response.data.Status;
        let status_obj = [];
        for (let i = 0; i < status_data.length; i++) {
          let data = {
            value: status_data[i].id,
            label: status_data[i].name,
          };
          status_obj.push(data);
        }
        setAvailableStatus(status_obj);
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
      });
  };


  const [selectpriority, setSelectedPriority] = useState(false);
  const [selectPriorityData, setSelectedPriorityData] = useState();
  const onSelectPriority = (selectedList, selectedItem) => {
    console.log("Entering",selectedList);
    setBackdropOpen(true);
    setSelectedPriority(true);
    setSelectedPriorityData(selectedList);
    if(selectedList.value==null){
      if(selectEmployeeState){
        urlData = `&project_id=`+ params.project_id+`&page=1&size=`+ rows+`&emp_id=`+selectEmployeeId;
        getAllData(urlData);
      } else {
        urlData = `&project_id=`+ params.project_id+`&page=1&size=`+ rows;
        getAllData(urlData);
      }
    } else if(selectstatus){
      if(selectEmployeeState){
        urlData = `&project_id=`+ params.project_id+`&page=1&size=`+ rows+ `&status=`+selectStatusId+`&emp_id=`+selectEmployeeId;
        getAllData(urlData);
      } else {
        urlData = `&project_id=`+ params.project_id+`&page=1&size=`+ rows+ `&status=`+selectStatusId;
        getAllData(urlData);
      }
    }else{
    if(selectstatus&&selectEmployeeState) {
      urlData = `&project_id=`+ params.project_id+`&page=1&size=`+ rows+ `&status=`+selectStatusId+`&emp_id=`+selectEmployeeId+ `&priority=`+selectedList.label;
      getAllData(urlData);
    } else {
      urlData = `&project_id=`+ params.project_id+`&page=`+pageNo+`&size=`+ rows+ `&priority=`+selectedList.label;
      getAllData(urlData);
    }
  }
  };

  const [availablePriority, setAvailablePriority] = useState([]);
  const TaskPriorityData = () => {
    axios
      .get(`${base_url.api1}/office_app/Task_priority/all?company_code=` + employeeData.company_code,{
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        let priority_data = response.data.Priority;
        let priority_obj = [];
        for (let i = 0; i < priority_data.length; i++) {
          let data = {
            value: priority_data[i].id,
            label: priority_data[i].name,
          };
          priority_obj.push(data);
        }
        setAvailablePriority(priority_obj);
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
      });
  };


  const sortTask = (e) => {
    setBackdropOpen(true);
    setSortState(e.target.id);
    console.log();
    if (direction == "Desc") {
      setDirection("ASC");
    } else if (direction == "ASC") {
      setDirection("Desc");
    }
    let sortby = e.target.id

    urlData = `&project_id=`+ params.project_id+`&page=`+pageNo+`&size=`+ rows+ `&sortby=`+sortby+`&Order=`+direction;
    getAllData(urlData);
  };

  const [Document,setDocument] = useState([]);

  const downloadAll = (filedata) => {
    setBackdropOpen(true);
    let data={
      file_name:filedata.document_name,
      file_path:attachmenturl+filedata.document_path,
    }
    let file_type = data.file_name.split(".");
    var url = `${base_url.api1}/office_app/download`

    fetch(url,{
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        Authorization: jwtToken,
        'Content-Type': 'application/json',
      }
    })
    .then((response) => readAllChunks(response))
    .then(async function (response) {
      const downloadFileType = localStorage.getItem(
        "task_document_type"
      );
      var blob = new Blob(response, { type: downloadFileType });
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = data.file_name;
      link.click();
      setBackdropOpen(false);
    })
    .catch((error) => {
      setBackdropOpen(false);
    });
  };

  const readAllChunks = (readableStream) => {
    localStorage.setItem(
      "task_document_type",
      readableStream.headers.get("content-type")
    );
    const reader = readableStream.body.getReader();
    const chunks = [];

    function pump() {
      return reader.read().then(({ value, done }) => {
        if (done) {
          return chunks;
        }
        chunks.push(value);
        return pump();
      });
    }
    return pump();
  };

  const getAllData = (urlData) => {
    setBackdropOpen(true);
    console.log(pageNo-1);
    axios
    .get(`${base_url.api1}/office_app/assign_task/list?company_code=`+ employeeData.company_code + urlData, {
      headers: {
        Authorization: jwtToken,
      },
    })
    .then(
      (response) => {
        setTaskData(response.data.Tasks);
        setGetPageNo(response.data.total_pages);
        setTotalCount(response.data.total_count);
        setCountNumber(pageNo-1);
        setNumberOfElements(response.data.Tasks.length);
        setBackdropOpen(false);
      },
      (error) => {
        setBackdropOpen(false);
        console.log(JSON.stringify(error.response.data));
        setTaskData();
        setGetPageNo(0);
        setTotalCount(0);
        setCountNumber(0);
        setNumberOfElements(0);
      }
    );
  }

  const [deleteTaskDocId, setDeleteTaskDocId] = useState();
  const [deleteDocOpen, setDeleteDocModal] = React.useState(false);
  const deleteCurrentTaskDoc = () => {
    setBackdropOpen(true);
    axios
      .delete(`${base_url.api1}/office_app/tasks_document?company_code=` + employeeData.company_code +`&id=` + deleteTaskDocId  , {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          let log = employeeData.emp_name+" deleted in task document.";
          CallAuditapi(log);
          setBackdropOpen(false);
          setDeleteDocModal(false);
          urlData = `&project_id=`+ params.project_id+`&page=`+pageNo+`&size=`+ rows;
          getAllData(urlData);
        },
        (error) => {
          setBackdropOpen(false);
          setDeleteDocModal(false);
          console.log(JSON.stringify(error.response.data));
        }
      );
  };

  const [searchinput, setsearchinput] = useState();
  const submitSearch = (e, value) => {
    setBackdropOpen(true);
     setdisplay("none");
    setsearch(true);
    let srchdata = e.target[0].value.trim();
    setsearchinput(srchdata);
    console.log(srchdata);
    axios
      .get(`${base_url.api1}/office_app/assign_task/list?company_code=`+ employeeData.company_code +`&project_id=`+ params.project_id+`&search=`+srchdata, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setBackdropOpen(false);
          setTaskData(response.data.Tasks);
          console.log(response.data.Tasks);
          setGetPageNo(response.data.total_pages);
          setNumberOfElements(response.data.Tasks.length);
          setTotalCount(response.data.total_count);
          
        },
        (error) => {
          setBackdropOpen(false);
          console.log(JSON.stringify(error.response.data));
          setTaskData();
        },
        (error) => {
          setBackdropOpen(false);
          console.log(JSON.stringify(error.response.data));
          
        }
      );
  };



  return (
    <div>
      <Backdrop open={backdropOpen}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="commoncomponent_cardcss">
        <div>
          <Row>
            <Col md={6}>
              <CardText
                style={{ marginTop: "-10px" }}
              >
                <h1>{params.project_name}</h1>
              </CardText>
            </Col>
            <Col>
              {permissionObj?.includes(13)?
                <Button
                  color="primary"
                  style={{ float: "right",marginLeft:"10px" }}
                  onClick={ () =>navigate('/add-task/'+params.project_id+'/'+params.project_name)}
                >
                  + Add
                </Button>
              :null}
            {/* </Col> 
            <Col> */}
              <Button
                color="secondary"
                style={{ float: "right" }}
                onClick={goback}
              >
                &#x2630; Projects
              </Button>
            </Col>
          </Row>
          <br/>
          <Row>
            <Col md={6}>
              <form className="master">
                <div style={{ marginTop: "-10px" }}>
                  <label className="area_rowslable">Limit</label>
                  <br />
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 80 ,marginTop: 0, maxHeight: 80}}>
                    <select
                      className={classes.selectlimit}
                      labelId="simple-select-label"
                      id="simple-select"
                      value={rows}
                      onChange={handleRows}
                    >
                      <option value={20}>20</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                  </FormControl>
                </div>
              </form>
            </Col>
            <Col md={6}>
                    <div className="form-group" style={{ marginTop: "30px" }}>
                      <div>
                        <div className="clearsearchclass">
                          <form
                            id="myForm"
                            className="d-flex"
                            onSubmit={(e) => {
                              e.preventDefault();
                              submitSearch(e);
                          }}
                          >
                            <input
                              style={{
                                padding: "5px",
                                border: "2px solid",
                                marginTop: "-1px",
                              }}
                              className="form-control"
                              placeholder="Search.."
                              name="search"
                              required
                            />
                            <button
                              color="blue"
                              className={classes.srchbtn}
                              type="submit"
                            >
                              <i className="fa fa-search"></i>
                            </button>
                          </form>
                          {searchdata ? (
                            <a    
                            onClick={() => {
                                setdisplay("inline");
                                setBackdropOpen(true);
                                setPageNo(1);
                                urlData = `&project_id=`+ params.project_id+`&page=1&size=`+ rows;
                                getAllData(urlData);
                                setsearchinput();
                                setsearch(false);
                              }}                      
                            >
                              Clear Search
                            </a>
                          ) : null}
                        </div>                      
                      </div>
                    </div>
                  </Col>
          </Row>
          <br/>
          <Row>
            <Col md={3}>
              <label><strong>Date:</strong></label>
              <DateRangePicker
              onEvent={handleEvent}
              onCallback={handleCallback}
                onApply={handleApply}
              >
                <p className="datepickerrangevaluetext">{dateValue}</p>
              </DateRangePicker>
              {dateFilter ? (
                <div>
                  <a
                    className={classes.clrsrch}
                    onClick={() => {
                      setDisplay("inline");
                      setDateValue("Task Date");
                      setBackdropOpen(true);
                      setDirection("Desc");
                      setPageNo(1);
                      urlData = `&project_id=`+ params.project_id+`&page=1&size=`+ rows;
                      getAllData(urlData);
                      setDateFilter(false);
                    }}
                  >
                    Clear Filter
                  </a>
                </div>
              ) : null}
            </Col>
            <Col md={3}>
              <label><strong>Employee Name:</strong></label>
              <Select
                className="basic-multi-select"
                classNamePrefix="select"
                value={selectEmployeeData}
                onChange={onSelectEmployee}
                options={availableEmployee}
              />
              {selectEmployeeState ? (
                <div>
                  <a
                    className={classes.clrsrch}
                    onClick={() => {
                      setBackdropOpen(true);
                      urlData = `&project_id=`+ params.project_id+`&page=1&size=`+ rows;
                      getAllData(urlData);
                      setSelectedEmployeeData({value: null, label: null});
                      setSelectedEmployee(false);
                    }}
                  >
                    Clear Filter
                  </a>
                </div>
              ) : null}
            </Col>
            <Col md={3}>
              <label><strong>Task Status:</strong></label>
              <Select
                className="basic-multi-select"
                classNamePrefix="select"
                value={selectStatusData}
                onChange={onSelectStatus}
                options={availableStatus}
              />
              {selectstatus ? (
                <div>
                  <a
                    className={classes.clrsrch}
                    onClick={() => {
                      setBackdropOpen(true);
                      urlData = `&project_id=`+ params.project_id+`&page=1&size=`+ rows;
                      getAllData(urlData);
                      setSelectedStatusData({value: null, label: null});
                      setSelectedStatus(false);
                    }}
                  >
                    Clear Filter
                  </a>
                </div>
              ) : null}
            </Col>
            <Col md={3}>
              <label><strong>Task Priority:</strong></label>
              <Select
                className="basic-multi-select"
                classNamePrefix="select"
                value={selectPriorityData}
                onChange={onSelectPriority}
                options={availablePriority}
              />
              {selectpriority ? (
                <div>
                  <a
                    className={classes.clrsrch}
                    onClick={() => {
                      setBackdropOpen(true);
                      urlData = `&project_id=`+ params.project_id+`&page=1&size=`+ rows;
                      getAllData(urlData);
                      setSelectedPriorityData({value: null, label: null});
                      setSelectedPriority(false);
                    }}
                  >
                    Clear Filter
                  </a>
                </div>
              ) : null}
            </Col>
          </Row>
          <br/>
          <div className="newlisting_headertabdesign">
            <Table responsive striped>
              <thead>
                <tr>
                  <th style={{ width: "50px" }}>SN</th>
                  <th style={{ width: "475px" }}>
                    Task Title{" "}
                    <UnfoldMoreIcon onClick={sortTask} id="task_title"/>
                  </th>
                  <th style={{ width: "275px" }}>
                     Assignee to {" "}
                    <UnfoldMoreIcon onClick={sortTask} id="emp_name"/>
                  </th>
                  <th style={{ width: "175px" }}>
                     End Date{" "}
                    <UnfoldMoreIcon onClick={sortTask} id="task_end_time"/>
                  </th>
                  <th style={{ width: "175px" }}>
                    Task Status{" "}
                    <UnfoldMoreIcon onClick={sortTask} id="status"/>
                  </th>
                  <th style={{ width: "175px" }}>
                    Priority{" "}
                    <UnfoldMoreIcon onClick={sortTask} id="priority"/>
                  </th>
                  <th style={{ width: "175px" }}>
                    Documents{" "}
                    </th>
                  <th style={{ textAlign: "center", width: "175px" }}>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {taskData != null ? (
                  taskData &&
                  taskData.map((Tasks , index) => (
                    <tr>
                      <th>{countNumber * rows + 1 + index}</th>
                        <td>{Tasks.task_title}</td>  
                        <td>{Tasks.emp_name} </td>
                        <td>{Tasks.task_end_time? moment(Tasks.task_end_time.split(".")[0]).format("DD MMM YYYY"): null}</td>
                        <td>{Tasks.task_status_name}</td>
                        <td>{Tasks.priority}</td>
                        <td>
                          {permissionObj?.includes(16)?<>
                            {Tasks.document_path.length > 0?
                              <Button
                                id={Tasks.document_path}
                                style={{ marginTop: "20px" }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setBackdropOpen(true);
                                  setViewTaskDoc(true);
                                  setDocument(Tasks.document_path);
                                  setBackdropOpen(false);
                                }}
                                color="info">
                                  View Files
                              </Button>
                            :null}
                          </>:null}
                          <Modal
                            ariaHideApp={false}
                            isOpen={modalShowDoc}
                            onRequestClose={closeModal}
                            style={customStyles2}
                            contentLabel="Example Modal"
                          >
                            <h2><strong>View Documents</strong></h2>
                            <br/>
                            <div className="newlisting_headertabdesign">
                              <Table responsive striped>
                                <thead>
                                  <tr>
                                    <th style={{ width: "180px" }}>Document Name{" "}</th>
                                    <th style={{ width: "180px" }}>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Document != null ? (
                                    Document &&
                                    Document.map((Dts , index) => (
                                      <tr>
                                        <td>{Dts.document_name}</td>
                                        <td>
                                          {permissionObj?.includes(17)?
                                            <p type="button" style={{color:'blue'}} onClick={()=>downloadAll(Dts)}>Download</p>
                                          :null}
                                          {permissionObj?.includes(18)?
                                          //   <p type="button" style={{color:'red'}}>Delete</p>
                                          // :null}
                                          <p id={Dts.id}
                                          onClick={() => {
                                            setDeleteTaskDocId(Dts.id);
                                            setDeleteDocModal(true);
                                          }} type="button" style={{color:'red'}}>Delete
                                        </p>                          
                                      :null}
                                      <Modal
                                        ariaHideApp={false}
                                        isOpen={deleteDocOpen}
                                        onRequestClose={closeModal}
                                        style={customStyles2}
                                        contentLabel="Example Modal"
                                      >
                                        <h5>Are you sure you want to delete current document ?</h5>
                                        <div style={{ float: "right" }}>
                                          <Button
                                            color="danger"
                                            type="submit"
                                            onClick={deleteCurrentTaskDoc}
                                          >
                                            Delete
                                          </Button>
                                          &nbsp;&nbsp;&nbsp;
                                          <Button
                                            color="secondary"
                                            onClick={() => setDeleteDocModal(false)}
                                          >
                                            Cancel
                                          </Button>
                                        </div>
                                      </Modal> 
                                        </td>
                                      </tr>
                                  ))) : (
                                    <p style={{width:'200%'}}>No Documents Found</p>
                                  )}
                                </tbody>
                              </Table>
                            </div>
                            <br />
                            <Row>
                              <Col md={12}>
                                <Row className="justify-content-end mt-2">
                                  <Button
                                    color="secondary"
                                    onClick={() => setViewTaskDoc(false)}
                                  >
                                    Close
                                  </Button>
                                </Row>
                              </Col>
                            </Row>
                          </Modal>
                        </td>
                        <td>
                          <div style={{
                              position: "relative",
                              textAlign: "center",
                            }}
                          >
                            <div
                              className="workflowdashboard_fa-ellipsis"
                              data-toggle="dropdown"
                            >
                              <i className="fa fa-ellipsis-v " aria-hidden="true"></i>
                            </div>
                            <div
                              className="dropdown-menu workflowdashboarddropdown"
                              style={{ width: "200px" }}
                            >
                              {permissionObj?.includes(14)?
                                <p id={Tasks.id} onClick={() =>navigate('/edit-task/'+params.project_id+'/'+params.project_name +'/'+Tasks.id)}>
                                  Edit
                                </p>
                              :null}
                              {permissionObj?.includes(15)?
                                <p id={Tasks.id}
                                  onClick={() => {
                                    setDeleteTaskId(Tasks.id);
                                    setDeleteModal(true);
                                  }} color="danger">Delete
                                </p>                          
                              :null}
                              <Modal
                                ariaHideApp={false}
                                isOpen={deleteOpen}
                                onRequestClose={closeModal}
                                style={customStyles2}
                                contentLabel="Example Modal"
                              >
                                <h5>Are you sure you want to delete current record ?</h5>
                                <div style={{ float: "right" }}>
                                  <Button
                                    color="danger"
                                    type="submit"
                                    onClick={deleteCurrentTask}
                                  >
                                    Delete
                                  </Button>
                                  &nbsp;&nbsp;&nbsp;
                                  <Button
                                    color="secondary"
                                    onClick={() => setDeleteModal(false)}
                                  >
                                    Cancel
                                  </Button>
                                </div>
                              </Modal> 
                            </div>
                          </div>
                        </td>
                    </tr>
                ))) : (
                  <p className={classes.headertekst}>No Tasks Found</p>
                )}
              </tbody>
            </Table>
            <div>
              <Row>
                <Col md={6}>
                  {taskData?.length ? (
                    <p style={{ marginTop: "15px" }}>
                      {countNumber * rows + 1} to{" "}
                      {countNumber * rows + numberOfElements} of {totalCount} records
                    </p>
                  ) : (
                    <p className="formfooterrecordstyle">{null}</p>
                  )}
                </Col>
                <Col md={6}>
                <div className={classes.root}>
                {searchdata ? (
                  <Pagination
                    page={paginationPageNo}
                    count={getPageNo}
                    onChange={submitSearch}
                  />
                ) : (
                  <Pagination
                    page={paginationPageNo}
                    count={getPageNo}
                    onChange={handlePage}
                  />
                )}
              </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Task;