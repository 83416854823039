import React , {useState,useEffect}from 'react';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CardText, Col, Row, Button, Table } from "reactstrap";
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import DateRangePicker from "react-bootstrap-daterangepicker";
import MenuItem from '@mui/material/MenuItem';
import AddIcon from "@mui/icons-material/Add";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Pagination from "@mui/material/Pagination";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import { customStyles, useStyles } from "../../../Utilities/CSSUtilities";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import base_url from '../../../api/bootapi';
import { 
  logout,
  selectEmployee,
 } from "./../../features/empSlice";
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import Checkbox from '@mui/material/Checkbox';
import { ToastProvider, useToasts } from "react-toast-notifications";
import CallAuditapi from "../../services/auditservice";

const nodes= [
    {
        value: 0,
        label: "Dashboard",
        disabled: true,
        checked: true,
    },
    {
        value: 1,
        label: "Employees",
        children: [
            { value: 80, label: "Can View Employees List" },
            { value: 2, label: "Can Create Employees" },
            { value: 3, label: "Can Edit Employees" },
            { value: 4, label: "Can View Employee Attendence" },
            { value: 5, label: "Can View Employee Task" },
            { value: 6, label: "Can Delete Employee" },
            { value: 7, label: "Can Update Employee Password" },
        ],
    },
    {
        value: 8,
        label: "Project",
        children: [
            { value: 9, label: "Can Add Project" },
            { value: 10, label: "Can Edit Project" },
            { value: 11, label: "Can Delete Project" },
            { value: 12, label: "Can View Project Task" },
            { value: 13, label: "Can Add Task" },
            { value: 14, label: "Can Edit Task" },
            { value: 15, label: "Can Delete Task" },
            { value: 16, label: "Can View Task Document" },
            { value: 17, label: "Can Download Task Document" },
            { value: 18, label: "Can Delete Task Document" },
        ],
    },
    {
        value: 78,
        label: "MyTask",
    },
    {
        value: 82,
        label: "AllTasks",
    },
    {
        value: 19,
        label: "Resignation",
        children: [
            { value: 20, label: "Can Approve Resignation" },
            { value: 21, label: "Can Decline Resignation" },
        ],
    },
    {
        value: 22,
        label: "Team",
        children: [
            { value: 23, label: "Can Create Team" },
            { value: 24, label: "Can Edit Team" },
            { value: 25, label: "Can Delete Team" },
            { value: 26, label: "Can View Team Members" },
            { value: 27, label: "Can Add Team Members" },
            { value: 28, label: "Can Delete Team Members" },
        ],
    },
    {
        value: 29,
        label: "Attendence",
        children: [
            {
                value: 30,
                label: "Attendance Report",
                children:[
                    { value: 31, label:"Can Add Attendence"},
                ]
            },
            { value: 32, label: "Absent List", },
            { value: 33, label: "OverTime List", },
            { value: 34, label: "Break Exceed List", },
            { value: 35, label: "Shift Incomplete List", },
        ],
    },
    {
        value: 36,
        label: "Leave",
        children: [
            {
                value: 37,
                label: "Applications",
                children:[
                    { value: 38, label:"Can Approve Leave Application"},
                    { value: 39, label:"Can Decline Leave Application"},
                ]
            },
            { value: 40, label: "Approved", },
            { value: 41, label: "Declined", },
            { value: 42, label: "Pending", },
        ],
    },
    {
        value: 43,
        label: "Settings",
        children: [
            { 
                value: 79,
                label: "Audit",
            },
            { 
                value: 44, 
                label: "Holiday",
                children:[
                    { value: 81, label: "Can View Holiday List" },
                    { value: 45, label:"Can Add Holiday"},
                    { value: 46, label:"Can Edit Holiday"},
                    { value: 47, label:"Can Delete Holiday"},
                ]
            },
            {
                value: 48, 
                label: "Type of Holiday",
                children:[
                    { value: 49, label:"Can Add Type of Holiday"},
                    { value: 50, label:"Can Edit Type of Holiday"},
                    { value: 51, label:"Can Delete Type of Holiday"},
                ]
            },
            {
                value: 52, 
                label: "Type of Leave",
                children:[
                    { value: 53, label:"Can Add Type of Leave"},
                    { value: 54, label:"Can Edit Type of Leave"},
                    { value: 55, label:"Can Delete Type of Leave"},
                ]
            },
            {
                value: 56, 
                label: "Task Status",
                children:[
                    { value: 57, label:"Can Add Task Status"},
                    { value: 58, label:"Can Edit Task Status"},
                    { value: 59, label:"Can Delete Task Status"},
                ]
            },
            {
                value: 60,
                label: "Task Priority",
                children:[
                    { value: 61, label:"Can Add Task Priority"},
                    { value: 62, label:"Can Edit Task Priority"},
                    { value: 63, label:"Can Delete Task Priority"},
                ]
            },
            {
                value: 64, 
                label: "Role",
                children:[
                    { value: 65, label:"Can Add Role"},
                    { value: 66, label:"Can Edit Role"},
                    { value: 67, label:"Can Delete Role"},
                    { value: 68, label:"Can View/Edit Role Permissions"},
                ]
            },
            {
                value: 69, 
                label: "Department",
                children:[
                    { value: 70, label:"Can Add Department"},
                    { value: 71, label:"Can Edit Department"},
                    { value: 72, label:"Can Delete Department"},
                ]
            },
        ],
    },
    {
        value: 73,
        label: "Status Mail",
    },
    {
        value: 74,
        label: "Performance",
        children: [
            { value: 75, label: "Can Add Performance" },
            { value: 76, label: "Can Edit Performance" },
            { value: 77, label: "Can Delete Performance" },
        ],
    },
]
 
export default function Permissions() {
    const [backdropOpen, setBackdropOpen] = useState(true);
    const jwtToken = "JWT " + localStorage.getItem("JWTToken");
    let navigate = useNavigate();
    const params = useParams();
    const [checked, setChecked] = useState();
    const [checked2, setChecked2] = React.useState(false);
    const [expanded, setExpanded] = useState([]);
    const employeeData = useSelector(selectEmployee);
    const { addToast } = useToasts();
    const dispatch = useDispatch();

    useEffect(() => {
        window.addEventListener("storage", function (e) {
          if (e.key === null) {
            dispatch(logout());
            navigate("/");
          }
        });
        getPermissions();
    }, []);

    const getPermissions = () => {
        const resource_obj = [];

        for (let i = 0; i < employeeData.role_permissions.length; i++) {
          resource_obj.push(employeeData.role_permissions[i].resource_id);
        }
        var available_resource = resource_obj.includes(68);
    
        if(available_resource) {
            getRolesResources();
        } else {
            navigate('/errorPage');
        }
    }

    const getRolesResources = () => {
        axios
            .get(`${base_url.api1}/myappapi/roleresource/get?company_code=`+employeeData.company_code+`&role_id=`+params.role_id, {
                headers: {
                    Authorization: jwtToken,
                },
            })
            .then((res) => {
                let resourceId = [];
                for (let i = 0; i < res.data.Roleresource.length; i++) {
                    resourceId.push(res.data.Roleresource[i].resource_id);
                }
                const unique = getUnique(resourceId);
                setChecked(unique);
                setBackdropOpen(false);
            })
            .catch((err) => {
                addToast("Role Resources Not Found!", {
                    appearance: "error",
                    autoDismiss: true,
                });
                setBackdropOpen(false);
            });
    };

    function getUnique(array) {
        var uniqueArray = [];
        for (let i = 0; i < array.length; i++) {
            if (uniqueArray.indexOf(array[i]) === -1) {
                uniqueArray.push(array[i]);
            }
        }
        return uniqueArray;
    }

    const onExpand = (expanded) => {
        setExpanded(expanded);
    };
    const onCheck = (checked) => {
        setChecked(checked);
    };

    const handleChange = (event) => {
        if (event.target.checked) {
            // console.log("Entering");
            checkAllPermission();
        } else {
            // console.log("Closing");
            unCheckAllPermission();
        }
        setChecked2(event.target.checked);
    };

    const assignPermission = ()=>{
        setBackdropOpen(true);
        let data = [];
        for (let j = 0; j < checked.length; j++) {
            data.push({
                role_id: Number(params.role_id),
                resource_id: Number(checked[j]),
                status: true,
            });
        }
        let finalData = {
            resources: data,
        }
        axios
            .put(`${base_url.api1}/myappapi/roleresource/update?company_code=`+employeeData.company_code+`&role_id=`+params.role_id, finalData, {
                headers: {
                    Authorization: jwtToken,
                },
            })
            .then((response) => {
                addToast("Role Resources Updated Successfully.", {
                    appearance: "success",
                    autoDismiss: true,
                });
                let log = employeeData.emp_name+" Updated Resources for Role "+params.role_name+".";
                CallAuditapi(log);      
                navigate("/roles");
                setBackdropOpen(false);
            })
            .catch((error) => {
                addToast("Role Resources Not Updated Successfully!", {
                    appearance: "error",
                    autoDismiss: true,
                });
                setBackdropOpen(false);
            });
    }

    const checkAllPermission = () => {
        let checkAlldata = [];
        for (let i = 0; i <= 82; i++) {
          checkAlldata.push(i);
        }
        onCheck(checkAlldata);
    };

    const unCheckAllPermission = () => {
        onCheck([0]);
    };

    const goback = () => {
        navigate("/roles");
    };
    return (
        <div>
            <Backdrop open={backdropOpen} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className="commoncomponent_cardcss">
                <div>
                    <Row>
                        <Col md={6}>
                            <CardText>
                                <strong>Permission</strong>
                            </CardText>
                        </Col>
                        <Col md={6} className="d-flex justify-content-end">
                            <Button color="primary" className="btn listing_addbutton" onClick={goback}>
                                &#x2630; Back
                            </Button>
                        </Col>
                    </Row>
                    <div className="form-inner-admin">
                        <div className="form-group creatadmin">
                        <Row>
                            <Col md={3}>
                            <label>
                                <strong>Role Name: </strong>
                                <b style={{ color: "black" }}>{params.role_name}</b>
                            </label>
                            </Col>
                            <Col md={3} style={{ marginLeft: "30px" }}>
                            <Button
                                onClick={assignPermission}
                                className="btn listing_addbutton"
                                color='success'
                                style={{
                                    padding: "10px 15px",
                                    float: "left",
                                    marginTop: "5px",
                                }}
                            >
                                Update
                            </Button>
                            </Col>
                        </Row>
                        </div>
                        <Row style={{ position: "relative" }} className="rolesrowalignment">
                            <Col md={8}>
                                <CheckboxTree
                                    style={{ position: "relative" }}
                                    nodes={nodes}
                                    checked={checked}
                                    expanded={expanded}
                                    onExpand={onExpand}
                                    onCheck={onCheck}
                                    showExpandAll={true}
                                    icons={{
                                        check: <span className="rct-icon rct-icon-check" />,
                                        uncheck: <span className="rct-icon rct-icon-uncheck" />,
                                        halfCheck: (
                                        <span className="rct-icon rct-icon-half-check" />
                                        ),
                                        expandClose: (
                                        <span className="rct-icon rct-icon-expand-all" />
                                        ),
                                        expandOpen: (
                                        <span className="rct-icon rct-icon-collapse-all" />
                                        ),
                                        expandAll: (
                                        <span className="rct-icon rct-icon-expand-all" />
                                        ),
                                        collapseAll: (
                                        <span className="rct-icon rct-icon-collapse-all" />
                                        ),
                                        parentClose: (
                                        <span className="rct-icon rct-icon-parent-close" />
                                        ),
                                        parentOpen: (
                                        <span className="rct-icon rct-icon-parent-open" />
                                        ),
                                        leaf: <span className="rct-icon rct-icon-leaf" />,
                                    }}
                                />
                            </Col>
                            <Col md={4} className="roleabsoluteposition">
                                <Checkbox
                                    style={{
                                        marginTop: "-10px",
                                        marginLeft: "-10px",
                                        height: "40px",
                                    }}
                                    checked={checked2}
                                    onChange={handleChange}
                                    color="primary"
                                    inputProps={{ "aria-label": "primary checkbox" }}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    )
}
