import React , { useState, useEffect }from 'react';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Link, useNavigate,useParams } from "react-router-dom";
import { CardText, Col, Row, Button, Table } from "reactstrap";
import AddIcon from "@mui/icons-material/Add";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Pagination from "@mui/material/Pagination";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import { customStyles2, useStyles } from "./../../Utilities/CSSUtilities";
import axios from "axios";
import moment from "moment";
import base_url from '../../api/bootapi';
import { logout, selectEmployee } from "../../components/features/empSlice";
import { useDispatch, useSelector } from "react-redux";
import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
// import MenuItem from '@mui/material/MenuItem';
import Select from "react-select";
 import CallAuditapi from "../services/auditservice";


const Project = () => {
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [modalCreate, setCreate] = React.useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [projectData,setProjectData] =useState([]);
  const navigate =useNavigate();
  const jwtToken = "JWT " + localStorage.getItem("JWTToken");
  const classes = useStyles();
  const employeeData = useSelector(selectEmployee);
  const params = useParams(); 
  const [viewtask,setviewTask ]= useState([]);
  const [searchdata, setsearch] = useState(false);
  const [display1, setdisplay] = useState("inline");
  const [numberOfElements, setNumberOfElements] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [direction, setdirection] = useState("Desc");
  const [rows, setRows] = React.useState(50);
  const [paginationPageNo, setPaginationPageNo] = useState(1);
  const [getPageNo, setGetPageNo] = useState();
  const [totalcount, setTotalCount] = useState();
  const [countNumber, setCountNumber] = useState();
  const [sortState, setsortState] = useState("project_name");
  const [taskData,setTaskData] =useState([]);
  const dispatch = useDispatch(); 
  const project = useNavigate();
  const [permissionObj, setPermissionObj] = useState();

  useEffect( () => {
    getPermissions();
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
  },[rows]);

  const handleRows = (event) => {
    setRows(event.target.value);
  };

  const getPermissions = () => {
    const resource_obj = [];
    for (let i = 0; i < employeeData.role_permissions.length; i++) {
      resource_obj.push(employeeData.role_permissions[i].resource_id);
    }
    setPermissionObj(resource_obj);
    var available_resource8 = resource_obj.includes(8);
    var available_resource9 = resource_obj.includes(9);
    var available_resource10 = resource_obj.includes(10);
    var available_resource11 = resource_obj.includes(11);
    var available_resource12 = resource_obj.includes(12);
    var available_resource13 = resource_obj.includes(13);
    var available_resource14 = resource_obj.includes(14);
    var available_resource15 = resource_obj.includes(15);
    var available_resource16 = resource_obj.includes(16);
    var available_resource17 = resource_obj.includes(17);
    var available_resource18 = resource_obj.includes(18);

    if(available_resource8 && (available_resource9 || available_resource10 || available_resource11 || available_resource12 || available_resource13 || available_resource14 || available_resource15 || available_resource16 || available_resource17 || available_resource18)) {
      userByRoleID();
      getTeams();
      if (localStorage.getItem("ProjectPageNo")) {
        let currentPage = localStorage.getItem("ProjectPageNo");
        setPageNo(currentPage);
        setPaginationPageNo(Number(currentPage));
        getAllProjectList(currentPage);
      } else {
        setPageNo(1);
        setPaginationPageNo(1);
        getAllProjectList(1);
      }
    } else {
      navigate('/errorPage');
    }
  };


  const getAllProjectList = (value1) => {
    setBackdropOpen(true);
    axios
      .get(`${base_url.api1}/office_app/project/list?company_code=`+ employeeData.company_code+`&page=`+value1+`&size=`+ rows, {
        headers: {
            Authorization: jwtToken,
            },
      })
      .then(
        (response) => {
          setBackdropOpen(false);
          console.log(response.data);
          setProjectData(response.data.Projects);
          setGetPageNo(response.data.total_pages);
          setTotalCount(response.data.total_count);
          setCountNumber(value1-1);
          setNumberOfElements(response.data.Projects.length);
          document.getElementById("myForm").reset();
        },
        (error) => {
          setBackdropOpen(false);
            console.log(JSON.stringify(error.response.data));
            setProjectData();
        }
      );
  };

  const closedModal = () => {
    setDelete(false);
  }

  const closeModal = () => {
    setCreate(false);
  }

  const createModalProject = () => {
    setCreate(true);
  };

  const handlepage = (event, value) => {
    console.log(value);
    setPaginationPageNo(value);
    setPageNo(value);
    getAllProjectList(value);
  };

  const [projectValueDropStatus, setProjectValueDropStatus] = useState(false);

  const createproject = (data2) => {
    let project_name = data2.project_name;
    let status = data2.status;

    let team_name;
    let emp_name;
    let emp_id;
    let team_id;
    let postData;

    if(selectedTeam != '' || userData.length !=0) {
      console.log(selectedTeamId);
      console.log(userData);
    } else {
      console.log('No team selected');
      setUserState(true);
      return
    }


    if (selectedTeam != '') {
      postData = {
        project_name,
        status,
        team_id: [selectedTeamId]
      }
    } else {
      postData = {
        project_name,
        status,
        new_team: [{
          team_name: project_name+'_Team',
          status: "Active",
          Employees: selectedEmployeeData
        }]
      }
    }
    setBackdropOpen(true);
    axios
      .post(`${base_url.api1}/office_app/project?company_code=`+ employeeData.company_code, postData,{
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        let log = employeeData.emp_name+" created the project "+ postData.project_name;
          CallAuditapi(log);
        setBackdropOpen(false);
        setCreate(false);
        getAllProjectList(pageNo);
        document.getElementById("addform").reset();
      })
      .catch((error) => {
        setBackdropOpen(false);
        console.log(JSON.stringify(error));
        document.getElementById("addform").reset();
      });
  };

  const [editOpen, setEdit] = React.useState(false);
  const [editProjectId, seteditProjectId] = useState();
  const [editProjectTitle, seteditProjectTitle] = useState();
  const [editStatus, seteditStatus] = useState();
  const editProjectData = (elem,project) => {
    console.log(project);
    seteditProjectId(project.id);
    seteditProjectTitle(project.project_name);
    seteditStatus(project.status);
    setEdit(true);
  };
  const editOldCategory= (data2) =>{
    setBackdropOpen(true);
    let data = {
      project_name : editProjectTitle,
      status: editStatus,
    };
    axios
      .put(`${base_url.api1}/office_app/project?company_code=`+ employeeData.company_code + `&id=` + editProjectId  , data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        setBackdropOpen(false);
        setEdit(false);
        getAllProjectList(pageNo);
      })
      .catch((error) => {
        setBackdropOpen(false);
      });
  }

  const DeleteInProject = () => {
    setDelete(true);
  };


  const [DeleteId, setDeleteId] = useState();
  const [deleteOpen, setDelete] = React.useState(false);
  const deleteProject = (elem,project) => {
    console.log(project);
    setDeleteId(project.id);
  };
  const deleteCurrentCategory = () => {
    axios
      .delete(`${base_url.api1}/office_app/project?company_code=` + employeeData.company_code +`&id=` + DeleteId  , {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setBackdropOpen(false);
          setDelete(false);
          getAllProjectList(pageNo);
        },
        (error) => {
          setBackdropOpen(false);
          console.log(JSON.stringify(error.response.data));
        }
      );
  };


  const [projectId , setprojectId] = useState();
  const viewTask = (elem,project) => {
    setBackdropOpen(true);
    console.log(project);
    setprojectId(project.id);
    axios
      .get(`${base_url.api1}/office_app/assign_task/list?company_code=` + employeeData.company_code + `&project_id=`+ projectId, {
        headers: {
        Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setBackdropOpen(false);
          console.log(response.data.Tasks);
          setviewTask(response.data.Tasks);
          console.log(response.data.Tasks);
          //document.getElementById("myForm").reset();
        },
        (error) => {
            setBackdropOpen(false);
            console.log(JSON.stringify(error.response.data));
        }
      );
  };
  

  const [searchinput, setsearchinput] = useState();
  const submitSearch = (e, value) => {
     setdisplay("none");
    setsearch(true);
    let srchdata = e.target[0].value.trim();
    setsearchinput(srchdata);
    console.log(srchdata);
    setBackdropOpen(true)
    axios
      .get(`${base_url.api1}/office_app/project/list?company_code=`+ employeeData.company_code +`&search=`+srchdata, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setBackdropOpen(false);
          setProjectData(response.data.Projects);
          console.log(response.data.Projects);
          setGetPageNo(response.data.total_pages);
          setNumberOfElements(response.data.Projects.length);
          setTotalCount(response.data.total_count);
        },
        (error) => {
          setBackdropOpen(false);
          console.log(JSON.stringify(error.response.data));
          setProjectData();
        },
        (error) => {
          setBackdropOpen(false);
          console.log(JSON.stringify(error.response.data));
        }
      );
  };


const sortProject = (e) => {
  setBackdropOpen(true);
  setsortState(e.target.id);
  if (direction == "Desc") {
    setdirection("ASC");
  } else if (direction == "ASC") {
    setdirection("Desc");
  }
  let sortby = e.target.id
  console.log(sortby)

  axios
    .get(`${base_url.api1}/office_app/project/list?company_code=` + employeeData.company_code + `&sortby=`+sortby+`&Order=`+direction +`&page=`+pageNo+`&size=`+rows, {
      headers: {
        Authorization: jwtToken,
      },
    })
    .then(
      (response) => {
        setProjectData(response.data.Projects);
        document.getElementById("myForm").reset();
        setGetPageNo(response.data.total_pages);
        //console.log(response.data.total_pages);
        setTotalCount(response.data.total_count);
        setCountNumber(pageNo-1);
        setNumberOfElements(response.data.Projects.length);
        //console.log(response.data.Users.length);
        setsearch(false);
        setBackdropOpen(false);
      },
      (error) => {
        console.log(JSON.stringify(error.response.data));
        setProjectData();
        setBackdropOpen(false);
      }
    );
};


const [allEmployee, setAllEmployee] = useState();
const [empId, setEmpId] = useState([]);
  // const [empAllId, setEmpAllId] = useState([]);
  const userByRoleID = () => {
    axios
      .get(`${base_url.api1}/myappapi/all_users_by_role?company_code=` + employeeData.company_code + `&status=True`  , {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        console.log(response.data.Users);
        setEmpId(response.data.user_id);
        let emp_data = response.data.Users;
        // const emp_obj = [{
        //   value: null,
        //   label: "-Select Employee-",
        // }];
        const emp_obj =[];
        for (let i = 0; i < emp_data.length; i++) {
          let data = {
            value: emp_data[i].user_id,
            label: emp_data[i].first_name +" "+ emp_data[i].last_name,
          };
          emp_obj.push(data);
        }
        setAllEmployee(emp_obj);
        setGetPageNo(response.data.total_pages);
        setTotalCount(response.data.total_count);
        setCountNumber(0);
        setNumberOfElements(response.data.Project.length);
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
      });
  };

  const [userState, setUserState] = useState();
  const [userData, setSelectedUser] = useState([]);
  const [selectedEmployeeData, setSelectedEmployeeData] = useState();

  const onSelectUser = (selectedList, selectedItem) => {
    console.log("Entering",selectedList);
    setSelecteTeam('');
    setSelectedUser(selectedList);
    setUserState(false);
    let emp_obj = [];
    for (let i = 0; i < selectedList.length; i++) {
      emp_obj.push({
        status: "Active",
        emp_name: selectedList[i].label,
        emp_id: selectedList[i].value,
      })
    }
    setSelectedEmployeeData(emp_obj);
  };


  // const [selectedEmployeeState, setSelectedEmployeeState] = useState();
  // const [selectedEmployee, setSelectedEmployee] = useState();
  // const onSelectProjectManager = (selectedList, selectedItem) => {
  //   console.log("Entering",selectedList);
  //   let mng_obj=[];
  //   for (let i = 0; i < selectedList.length; i++) {
  //     let data = {
  //       emp_id: selectedList[i].value,
  //       emp_name: selectedList[i].label,
  //     }
  //     mng_obj.push(data);
  //   }
  //   if (mng_obj.length == 0) {
  //     setSelectedEmployeeState(true);
  //   } else {
  //     setSelectedEmployeeState(false);
  //     setSelectedEmployee(mng_obj);
  //   }
  // };


  const [availableTeams, setAvailableTeams] = useState();
  const getTeams = () => {
    setBackdropOpen(true)
    axios
      .get(`${base_url.api1}/office_app/Company_team/all?company_code=`+employeeData.company_code+`&status=1`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        setBackdropOpen(false)
        setAvailableTeams(response.data.Company_team);
      })
      .catch((error) => {
        setBackdropOpen(false)
        setAvailableTeams();
        console.log(JSON.stringify(error));
      });
  };

  // const lookup = availableTeams;
  //   console.log(lookup);
  const [selected, setSelected] = useState(-1);
  // const value = selected !== 0 && lookup[selected];
  const [defaultTeam, setdefaultTeam] = useState();
  const [selectedTeam, setSelecteTeam] = useState('');
  const [selectedTeamId, setSelecteTeamId] = useState();
  const handleChangeTeam = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    const optionElementValue = optionElement.getAttribute("value");
    setSelecteTeam(optionElementValue);
    setSelecteTeamId(optionElementId);
    setSelectedUser([]);
    setUserState(false);
  };


  return (
    <div>
      <Backdrop open={backdropOpen}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="commoncomponent_cardcss">
        <div>
          <Row>
            <Col md={6}>
              <CardText
                style={{ marginTop: "-10px" ,marginLeft:"5px"}}
              >
                <h1>Project </h1>
              </CardText>
            </Col>
            <Col md={6} className="d-flex justify-content-end">
              {permissionObj?.includes(9)?
                <Button
                  color="primary"
                  onClick={createModalProject}
                  // onClick={ () =>createProject('/create-task')}
                >
                  + Add
                </Button>
              :null}
            </Col>
            <Modal
              ariaHideApp={false}
              isOpen={modalCreate}
              onRequestClose={closeModal}
              style={customStyles2}
              contentLabel="Example Modal"
            >
              <h2>
                <strong>Create Project</strong>
              </h2>
              <form id="myForm" onSubmit={handleSubmit(createproject)}>
                <div>
                  <div className="form-group creatadmin">
                    <Row>
                      <Col md={12}>
                        <label>
                        Project Title<span style={{ color: "red" }}>*</span>:
                        </label>
                        <input
                          type="text"
                          className="form-control underline-input"
                          placeholder="Project title"
                          id="project_name"
                          {...register("project_name",{ required: true })}
                        />
                        {errors.project_name && (
                          <p className="errormessage">Project title  is Required</p>
                        )}
                      </Col>
                    </Row>
                    <br/>
                    <Row>
                      <Col md={12}>
                        <label >
                          Project Status<span style={{ color: "red" }}>*</span>:
                        </label>
                        <select className="form-control underline-input"
                        {...register("status",{ required: true })}
                        >
                        <option value="1">Active</option>
                        <option value="0">InActive</option>
                      </select>
                      {errors.status && (
                        <p className="errormessage">Status is Required</p>
                      )}
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col md={12}>
                        <label>
                          Team Name<span style={{ color: "red" }}>*</span>:
                        </label>
                        <br />
                        <select  className="form-control underline-input"
                          name="team_name"
                          onChange={handleChangeTeam}
                          value={selectedTeam}
                        >
                          <option value="">-Select Team-</option>
                          {availableTeams?.map((p, index) => (
                            <option key={index + 1} id={p.id} value={p.team_name}>
                              {p.team_name}
                            </option>
                          ))}
                        </select>
                      </Col>
                      <Col md={12}>
                        <p style={{textAlign:"center"}}>or</p>
                      </Col>
                      <Col md={12}>
                        <Select
                          className="basic-multi-select"
                          classNamePrefix="select"
                          placeholder={'Create a New Team'}
                          value={userData}
                          isMulti
                          onChange={onSelectUser}
                          options={allEmployee}
                        />
                      </Col>
                      {userState? <p className="errormessage">Select a Team or Create a New Team</p>:null}
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Row className="justify-content-end mt-2">
                          <Button
                            color="secondary"
                            onClick={() => setCreate(false)}
                            style={{
                            marginTop: "20px",
                            marginLeft: "5px",
                            }}
                          >
                            Close
                          </Button>
                          <Button
                            type="submit"
                            className="btn listing_addbutton ml-1"
                            style={{
                            marginTop: "20px",
                            marginLeft: "5px",
                            float: "right",
                            }}
                            color="info"
                          >
                            Submit
                          </Button>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </div>
              </form>
            </Modal>
            </Row>
            <Row>
            <form className="master">
                  <div style={{ marginTop: "10px" }}>
                    <label className="area_rowslable">Limit</label>
                    <br />
                    {/* <FormControl className={classes.formControl}> */}
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 80 ,marginTop: 0, maxHeight: 80}}>
                      <select
                        className={classes.selectlimit}
                        labelId="simple-select-label"
                        id="simple-select"
                        value={rows}
                        onChange={handleRows}
                      >
                        {/* <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem> */}
                        <option value={20}>20</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                      </select>
                    </FormControl>
                  </div>
                </form>
            <Col md={0}>
                <div className="form-group" style={{ marginTop: "30px" }}>
                  <div>
                    <div className="clearsearchclass">
                      <form
                        id="myForm"
                        className="d-flex"
                        onSubmit={(e) => {
                          e.preventDefault();
                          submitSearch(e);
                      }}
                      >
                        <input
                          style={{
                            padding: "5px",
                            border: "2px solid",
                            marginTop: "-1px",
                          }}
                          className="form-control"
                          placeholder="Search.."
                          name="search"
                          required
                        />
                        <button
                          color="blue"
                          className={classes.srchbtn}
                          type="submit"
                        >
                          <i className="fa fa-search"></i>
                        </button>
                      </form>
                      {searchdata ? (
                        <a    
                        onClick={() => {
                            setdisplay("inline");
                            setBackdropOpen(true);
                            setPageNo(1);
                            getAllProjectList(1);
                            setsearchinput();
                            setsearch(false);
                            
                          }}                      
                        >
                          Clear Search
                        </a>
                      ) : null}
                    </div>                      
                  </div>
                </div>
              </Col>
          </Row>
          <br/>
          <div className="newlisting_headertabdesign">
            <Table responsive striped>
              <thead>
                <tr>
                  <th style={{ width: "50px" }}>SNo.</th>
                  <th style={{ width: "275px" }}>
                    Project Title{" "}
                    <UnfoldMoreIcon onClick={sortProject} id="project_name"/>
                  </th>
                  <th style={{ width: "275px" }}>
                    Project Status{" "}
                    <UnfoldMoreIcon  onClick={sortProject} id="status"/>
                  </th>
                  <th style={{ textAlign: "center", width: "75px" }}>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {projectData != null ? (
                  projectData &&
                  projectData.map((project , index) => (
                    <tr>
                      <th>{countNumber * rows + 1 + index}</th>
                      <td>{project.project_name}</td>  
                      <td>{project.status=="1" ? "Active" : "Inactive"}</td>                    
                      <td>
                      <div style={{
                          position: "relative",
                          textAlign: "center",
                        }}
                      >
                        <div
                          className="workflowdashboard_fa-ellipsis"
                          data-toggle="dropdown"
                        >
                          <i
                            className="fa fa-ellipsis-v "
                            aria-hidden="true"
                          ></i>
                        </div>
                        <div
                          className="dropdown-menu workflowdashboarddropdown"
                          style={{ width: "200px" }}
                        >
                          {permissionObj?.includes(10)?
                            <p id={project.id}
                              onClick={(e)=>{
                                e.preventDefault();
                                editProjectData(e, project)}
                              }
                            >
                              Edit
                            </p>
                          :null}
                          <Modal
                            isOpen={editOpen}
                            onRequestClose={closeModal}
                            style={customStyles2}
                            ariaHideApp={false}
                            contentLabel="Example Modal"
                          >
                            <h2>
                              <strong>Edit Project</strong>
                            </h2>
                            <form id="editForm" onSubmit={(e) => {
                                e.preventDefault();
                                editOldCategory(e);
                              }}>
                              <div>
                                <div className="form-group creatadmin">
                                  <Row>
                                    <Col md={12}>
                                      <label>Project Title<span style={{ color: "red" }}>*</span>:</label>
                                      <input
                                        type="text"
                                        id="project_name"
                                        className="form-control underline-input"
                                        value={editProjectTitle}
                                        onChange={(e) => {
                                          seteditProjectTitle(e.target.value);
                                        }}
                                        required
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={12}>
                                      <label>Status<span style={{ color: "red" }}>*</span>:</label>
                                      <select
                                      className="form-control underline-input"
                                        name="status"
                                        value={editStatus}
                                        onChange={(e) => {
                                          seteditStatus(e.target.value);
                                        }}
                                        required
                                      >
                                        <option value="1">Active</option>
                                        <option value="0">InActive</option>
                                      </select>
                                    </Col>
                                  </Row>
                                  <br/>
                                </div>
                                <Row style={{ float: "right" }}>
                                  <Button
                                    color="secondary"
                                    style={{
                                      marginTop: "20px",
                                      marginRight: "10px",
                                    }}
                                    onClick={() => setEdit(false)}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    color="primary"
                                    type="submit"
                                    style={{ marginTop: "20px" }}
                                  >
                                    Submit
                                  </Button>
                                </Row>
                              </div>
                            </form>
                          </Modal>
                          {permissionObj?.includes(11)?(
                            <p
                              id={project.id}
                              onClick={(e) => {
                                deleteProject(e, project);
                                DeleteInProject();
                              }} color="danger">Delete
                            </p>                          
                          ):null}
                          <Modal
                            ariaHideApp={false}
                            isOpen={deleteOpen}
                            onRequestClose={closedModal}
                            style={customStyles2}
                            contentLabel="Example Modal"
                          >
                            <h5>
                              Are you sure you want to delete current record ?
                            </h5>
                            <div style={{ float: "right" }}>
                              <Button
                                color="danger"
                                type="submit"
                                onClick={deleteCurrentCategory}
                              >
                                Delete
                              </Button>
                              &nbsp;&nbsp;&nbsp;
                              <Button
                                color="secondary"
                                onClick={() => setDelete(false)}
                              >
                                Cancel
                              </Button>
                            </div>
                          </Modal>
                          {permissionObj?.includes(12)?
                            <p><a onClick={()=>navigate('/view-tasks/'+project.id+'/'+project.project_name)} color="info">View task</a></p>
                          :null}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))) : (
                  <p className={classes.headertekst}>No Projects Found</p>
                )}
                
            </tbody>
            </Table>
            <div>
               <Row>
                <Col md={6}>
              {projectData?.length ? (
                <p style={{ marginTop: "15px" }}>
                  {countNumber * rows + 1} to{" "}
                  {countNumber * rows + numberOfElements} of {totalcount} records
                </p>
              ) : (
                <p className="formfooterrecordstyle">{null}</p>
              )}
            </Col>
            <Col md={6}>
              <div className={classes.root}>
              {searchdata  ? (
                  <Pagination
                    page={paginationPageNo}
                    count={getPageNo}
                    onChange={submitSearch}
                  />
                ) : (
                  <Pagination
                    page={paginationPageNo}
                    count={getPageNo}
                    onChange={handlepage}
                  />
                )}
              </div>
            </Col>
            </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Project;