// import React from "react";
// import "./../css/Responsive.css";
import React, { useState, useEffect, useRef } from "react";
import { Link,useParams, useNavigate } from "react-router-dom";
import {
Card,
CardBody,
CardSubtitle,
CardText,
Col,
Row,
Button,
} from "reactstrap";
import axios from "axios";
import base_url from "../../api/bootapi";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Select from 'react-select';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from "@mui/material/CircularProgress";
import { useStyles } from "../../Utilities/CSSUtilities";
import PasswordStrengthBar from "react-password-strength-bar";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { 
  setStarted,
  selectStarted,
  setStopped,
  selectStopped,
  logout,
  selectEmployee,
  setSessionIdData,
  selectSessionId
 } from "../features/empSlice";
import { useToasts } from 'react-toast-notifications';

const CompanyProfile = (props) => {
const params = useParams(); 
console.log(params);
const jwtToken = "JWT " + localStorage.getItem("JWTToken");    
const { register, handleSubmit, formState: { errors } } = useForm();
const [backdropOpen, setBackdropOpen] = useState(false);
const navigate = useNavigate();
const [companyData, setCompanyData] = useState({});
const [inputtype, setinputtype] = useState("password");
const [chkPass, setchkPass] = useState();
const [chkPassState, setchkPassState] = useState();
const classes = useStyles();
const formData = new FormData();

const dispatch = useDispatch();
const employeeData = useSelector(selectEmployee);
const startedData = useSelector(selectStarted);
const stoppedData = useSelector(selectStopped);
const sessionIdData = useSelector(selectSessionId);
const { addToast } = useToasts();


useEffect(() => {
    loadCompany(params.user_id);
    getPermissions();
    },[]);


    
    const getPermissions = () => {
     loadCompany();
    };

  
     const onSubmit = (data2) => {

// //   let first_name = data2.first_name;
// //   let last_name = data2.last_name;
// //   let password = data2.password;
// //   let email = data2.email;
// //   let phone = data2.phone;
// //   let date_of_birth = data2.date_of_birth;
// //   let gender = data2.gender;
// //   let role_name = data2.role_name;
// //   let total_leaves = data2.total_leaves;
// //   let used_leaves = data2.used_leaves;
// //   let status = JSON.parse(data2.status);
// //   let is_verified = true;
  
// //   let manager_name = data2.manager_name;
// //   let designation = data2.designation;
// //   let department = data2.department;
// //   let photo = data2.photo;
// //   let is_owner = false;
// //   let company_id = employeeData.company_id;
// //   let company_code = employeeData.company_code;
  
// //       let data = {
// //         first_name,
// //         last_name,
// //         password,
// //         email,
// //         phone,
// //         date_of_birth,
// //         gender,
// //         role_name,
// //         total_leaves,
// //         used_leaves,
// //         role_id,
// //         status,
// //         designation,
// //         department,
// //         photo,
// //         is_verified,
// //         is_owner,
// //         company_id ,
// //         company_code,
    
// //       };
// //        console.log(data);
// //        formData.append("data", JSON.stringify(data));
    };  
    

const loadCompany = () => {
  setBackdropOpen(true);
    axios
      .get(`${base_url.api1}/myappapi/company?company_code=` + employeeData.company_code , {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
         setBackdropOpen(false);
        setCompanyData(response.data.Company);
      })
      .catch((error) => {
        console.log(error);
        setBackdropOpen(false);
      });
  };
 

  
  return (
    <div>
     <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Card className="commoncomponent_cardcss">
        <CardBody>
          <CardSubtitle className="font-weight-bold"></CardSubtitle>
          <div>
          <Row>
              <Col md={6}>
              <br></br>
                <CardText className={classes.headtext}
                  style={{ marginTop: "-10px" }}>
                  <strong>Company Profile</strong>
                </CardText>
              </Col>
              <Col md={6} className="d-flex justify-content-end">
              <br/>
                <Button
                  color="primary"
                //   style={{ float: "right" }}
                  onClick={ () =>navigate('/edit-companyprofile')}
                >
                   Edit
                </Button>
              </Col>
            </Row>
            <form id="myForm" onSubmit={handleSubmit(onSubmit)}>
            <div style={{ padding: "0 20px " }}>
                <div className="form-group creatadmin">
                <Row>
                    <Col md={4}>
                      <label>
                        Company Name:
                      </label>
                     <input
                      type="text"
                      id="company_name"
                      className="form-control underline-input"
                      value={companyData.company_name}
                      />
                    </Col>
                    <Col md={4}>
                      <label>
                        Company Email:
                      </label>
                     <input
                      type="text"
                      id="company_email"
                      className="form-control underline-input"
                      value={companyData.company_email}
                      />
                    </Col>
                    <Col md={4}>
                      <label>
                        Phone:
                      </label>
                     <input
                      id="company_phone"
                      className="form-control underline-input"
                      value={companyData.company_phone}
                      />
                    </Col>  
                </Row>
                <br/>
                <Row>    
                    {/* <Col md={4}>
                      <label>
                      Total Users:
                      </label>
                      <input
                        id="user_id"
                        className="form-control underline-input"
                        value={companyData.user_id}
                        />
                    </Col> */}
                    <Col md={4}>
                      <label>
                      Company Size:
                      </label>
                      <input
                      id="company_size"
                      className="form-control underline-input"
                      value={companyData.company_size}
                      />
                    </Col>
                    <Col md={4}>
                      <label>Registered Date:</label>
                      <input
                        className="form-control underline-input"
                        value={companyData.created_date  ? moment(companyData.created_date.split(".")[0]).format(
                            "DD/MM/YYYY"
                          ): null}
                      />
                    </Col>
                    <Col md={4}>
                      <label>
                        Full day Work Duration:
                      </label>
                     <input
                      id="full_day_work_duration"
                      className="form-control underline-input"
                      value={companyData.full_day_work_duration ? moment((companyData.full_day_work_duration)/3600):null}
                      />
                    </Col>        
                  </Row>
                  <br/>
                  <Row> 
                  <Col md={4}>
                      <label>
                        Half day Work Duration:
                      </label>
                     <input
                      id="half_day_work_duration"
                      className="form-control underline-input"
                      value={companyData.half_day_work_duration ? moment((companyData.half_day_work_duration)/3600):null}
                      />
                    </Col> 
                    <Col md={4}>
                      <label>
                      Half Days:
                      </label>
                      <input
                      id="half_days"
                      className="form-control underline-input"
                      value={companyData.half_days}
                      />
                    </Col>                       
                  </Row>
                  <br/>
                </div>
                    {/* <div
                      style={{
                        float: "right",
                        display: "flex",
                        padding: "5px",
                      }}
                    >
                      <Button
                        color="secondary"
                        style={{
                          marginTop: "20px",
                          marginLeft: "5px",
                          float: "right",
                        }}
                      >
                        Reset
                      </Button>
                      <Button
                        color="primary"
                        type="submit"
                        style={{
                          marginTop: "20px",
                          marginLeft: "5px",
                          float: "right",
                        }}
                      >
                        Submit
                      </Button>
                    </div> */}
            </div>
            </form>
          </div>
          </CardBody>
      </Card>
    </div>
  )
}

export default CompanyProfile
