import React , { useState, useEffect }from 'react';
import moment from "moment";
import { Link, useNavigate ,useParams} from "react-router-dom";
import { CardText, Col, Row, Button, Table } from "reactstrap";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Pagination from "@mui/material/Pagination";
import DateRangePicker from "react-bootstrap-daterangepicker";
import 'bootstrap-daterangepicker/daterangepicker.css';
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import axios from "axios";
import base_url from '../../../api/bootapi';
import { customStyles, useStyles ,customStyles2} from "./../../../Utilities/CSSUtilities";
import { useDispatch, useSelector } from "react-redux";
import { logout, selectEmployee } from "../../../components/features/empSlice";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useToasts } from 'react-toast-notifications';
import { toast } from "react-toastify";
import holidayicon from "../../../components/images/event.png";
import close from "../../../components/images/closeicon.png";
import editicon from "../../../components/images/edit.png";

const TypeofHoliday = () => {
  const classes = useStyles();
  const params = useParams();
  //console.log(params); 
  const jwtToken = "JWT " + localStorage.getItem("JWTToken");
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [types, setType] = useState([]);
  const [modalType, setCreate] = React.useState(false);
  const navigate =useNavigate();
  const [searchdata, setsearch] = useState();
  const [datevalue, setdatevalue] = useState("Click to open");
  const [datefilter, setdatefilter] = useState();
  const [backdropOpen, setBackdropOpen] = useState(false);
  const employeeData = useSelector(selectEmployee);
  const [pageNo, setPageNo] = useState(1);
  const [paginationPageNo, setPaginationPageNo] = useState(1);
  const [getPageNo, setGetPageNo] = useState();
  const [rows, setRows] = React.useState(50);
  const [sortState, setsortState] = useState("created_date");
  const [direction, setdirection] = useState("Desc");
  const [totalcount, setTotalCount] = useState();
  const [countNumber, setCountNumber] = useState();
  const [numberOfElements, setNumberOfElements] = useState();
  const [selectedDelete, setSelectedDelete] = useState([]);
  const [datesrange, setdatesrange] = useState();
  const [display1, setdisplay] = useState("inline");
  const [permissionObj, setPermissionObj] = useState();

  const { addToast } = useToasts();

  const createTypeofHoliday = (data2) => {
    setBackdropOpen(true);
    let type_of_holiday =data2.type_of_holiday;
    let status = data2.status;
    let company_code = employeeData.company_code;
  
    let data = {
      type_of_holiday,
      company_code,
      status
    };
    axios 
      .post(`${base_url.api1}/myappapi/typeofholiday/create?company_code=` + employeeData.company_code, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          getAllTypeofHoliday(pageNo);
          setCreate(false);
          
        },
        (error) => {
          console.log(error);
          setBackdropOpen(false);
          setCreate(false);
        }
      );
  };

  useEffect( () => {
    getPermissions();
  },[rows]);

  const handleRows = (event) => {
    setRows(event.target.value);
  };
  
    
  const getPermissions = () => {
    const resource_obj = [];

    for (let i = 0; i < employeeData.role_permissions.length; i++) {
      resource_obj.push(employeeData.role_permissions[i].resource_id);
    }
    setPermissionObj(resource_obj);
    var available_resource48 = resource_obj.includes(48);
    var available_resource49 = resource_obj.includes(49);
    var available_resource50 = resource_obj.includes(50);
    var available_resource51 = resource_obj.includes(51);

    if(available_resource48 && (available_resource49 || available_resource50 || available_resource51)) {
      if (localStorage.getItem("TypeofHolidayPageNo")) {
        let currentPage = localStorage.getItem("TypeofHolidayPageNo");
        setPageNo(currentPage);
        setPaginationPageNo(Number(currentPage));
        getAllTypeofHoliday(currentPage);
      } else {
        setPageNo(1);
        setPaginationPageNo(1);
        getAllTypeofHoliday(1);
      }
    } else {
      navigate('/errorPage');
    }
  };

  const [editOpen, setEdit] = React.useState(false);
  const [editTypeofHolidayId, seteditTypeofHolidayId] = useState();
  const [editTypeofHoliday,seteditTypeofHoliday] = useState();
  const [editTypeofHolidayStatus, seteditTypeofHolidayStatus] = useState();
  const editTypeofHolidayData = (elem,typeofholiday) => {
    console.log(typeofholiday);
    seteditTypeofHolidayId(typeofholiday.id);
    seteditTypeofHoliday(typeofholiday.type_of_holiday);
    seteditTypeofHolidayStatus(typeofholiday.status);
    setEdit(true);
  };
  const editOldTypeofHoliday= (data2) =>{
        setBackdropOpen(false);
        let data = {
          type_of_holiday: editTypeofHoliday.trim(),
          status: editTypeofHolidayStatus.trim(),
        };
    
        axios
          .put(`${base_url.api1}/myappapi/typeofholiday/update?company_code=`+ employeeData.company_code + `&id=` + editTypeofHolidayId  , data, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((response) => {
            // setBackdropOpen(false);
            setEdit(false);
            getAllTypeofHoliday(pageNo);
          })
          .catch((error) => {
            // setBackdropOpen(false);
          });
      }

//   const handleEvent = (event, picker) => {
//     console.log(picker.startDate);
//   };
    
  const handleCallback = (start, end, label) => {
    console.log(start, end, label);
  };

  const getAllTypeofHoliday = (value1) => {
    console.log(value1);
    setBackdropOpen(true);
    axios
      .get(`${base_url.api1}/myappapi/typeofholiday/list?company_code=` + employeeData.company_code+`&page=`+value1+`&size=`+ rows, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setType(response.data.Typeofholiday);
          setGetPageNo(response.data.total_pages);
          setTotalCount(response.data.total_count);
          setCountNumber(value1-1);
          setNumberOfElements(response.data.Typeofholiday.length);
          setSelectedDelete([]);
          setsearch(false);
          setBackdropOpen(false);
          document.getElementById("myForm").reset();
        },
        (error) => {
          console.log(JSON.stringify(error.response.data));
          setBackdropOpen(false);
          setType();
        }
      );
  };

  const closedModal = () => {
    setDelete(false);
  }
  const closeModal = () => {
    setCreate(false);
  }

  const createModalConfig = () => {
    setCreate(true);
  };

  const DeleteType = () => {
    setDelete(true);
  };

  const [DeleteId, setDeleteId] = useState();
  const [deleteOpen, setDelete] = React.useState(false);
  const deleteTypeofHoliday = (elem,typeofholiday) => {
    console.log(typeofholiday);
    setDeleteId(typeofholiday.id);
  };
  const deleteCurrentCategory = () => {
    axios
      .delete(`${base_url.api1}/myappapi/typeofholiday/delete?company_code=` + employeeData.company_code +`&id=` + DeleteId  , {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setBackdropOpen(false);
          setDelete(false);
          getAllTypeofHoliday(pageNo);
        },
        (error) => {
          setBackdropOpen(false);
          console.log(JSON.stringify(error.response.data));
        }
      );
  };

  const handleEvent = (event, picker) => {
    console.log(picker.startDate);
  };

  const handlepage = (event, value) => {
    if(searchdata==true)
    console.log(value);
    setPaginationPageNo(value);
    setPageNo(value);
    getAllTypeofHoliday(value);
  };

  const [searchinput, setsearchinput] = useState();
  const submitSearch = (e, value) => {
    setdisplay("none");
    setsearch(true);
    let srchdata = e.target[0].value.trim();
    setsearchinput(srchdata);
    console.log(srchdata);
    setBackdropOpen(true);
    axios
      .get(`${base_url.api1}/myappapi/typeofholiday/list?company_code=` + employeeData.company_code +`&page=`+1+`&size=`+rows+`&search=` + srchdata, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setType(response.data.Typeofholiday);
          setGetPageNo(response.data.total_pages);
          setNumberOfElements(response.data.Typeofholiday.length);
          setTotalCount(response.data.total_count);
          console.log(response.data);
          setBackdropOpen(false);
        },
        (error) => {
          console.log(JSON.stringify(error.response.data));
          setBackdropOpen(false);
          setType();
        },
        (error) => {
          console.log(JSON.stringify(error.response.data));
        }
      );
  };

  const sortUser = (e) => {
    setsortState(e.target.id);
    console.log();
    if (direction == "Desc") {
      setdirection("ASC");
    } else if (direction == "ASC") {
      setdirection("Desc");
    }
    let sortby = e.target.id

    axios
      .get(`${base_url.api1}/myappapi/typeofholiday/list?company_code=` + employeeData.company_code + `&sortby=`+sortby+`&Order=`+direction +`&page=`+pageNo, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setType(response.data.Typeofholiday);
          document.getElementById("myForm").reset();
          setGetPageNo(response.data.total_pages);
          //console.log(response.data.total_pages);
          setTotalCount(response.data.total_count);
          setCountNumber(pageNo-1);
          setNumberOfElements(response.data.Users.length);
          //console.log(response.data.Users.length);
          setSelectedDelete([]);
          setsearch(false);
          setBackdropOpen(false);
        },
        (error) => {
          console.log(JSON.stringify(error.response.data));
          setType();
        }
      );
  };
  
  return (
    <div>
      <Backdrop open={backdropOpen}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="commoncomponent_cardcss">
        <div style={{ margin: "5px" ,marginTop: "-30px"}}>
          <Row>
            {/* <Col md={6}>
              <CardText
                className={classes.headtext}
                style={{ marginTop: "-10px" }}
              >
                <h2>Type of Holiday</h2>
              </CardText>
            </Col> */}
            {/* <Col md={6}  className="d-flex justify-content-end">
              {permissionObj?.includes(49)?
                <Button
                  color="primary"
                  onClick={createModalConfig}
                >
                  + Add
                </Button>
              :null}
            </Col> */}
             {permissionObj?.includes(49)?
             <div className="newhead">
              <h4>Type of Holiday</h4>
              <div onClick={createModalConfig}>
                <h6>New</h6> <p>+</p>
              </div>
            </div>:null}
            <Modal
              ariaHideApp={false}
              isOpen={modalType}
              onRequestClose={closedModal}
              style={customStyles}
            >
              <div style={{ textAlign: "right", padding: "10px 20px" }}>
                <img className="closei" src={close} onClick={() =>setCreate(false)} />
              </div>
              <div className='modalContainer'>
                <div style={{ textAlign: "center" }}>
                  <img className='modelIcon' src={holidayicon} />
            </div>
              <h2>
                <strong>Add Type of Holiday</strong>
              </h2>
              <form onSubmit={handleSubmit(createTypeofHoliday)}>
                <div>
                  <div className="form-group creatadmin">
                    <Row>
                        <label>
                          Type of Holiday<span style={{ color: "red" }}>*</span>:
                        </label>
                        <input
                          type="text"
                          className="form-control underline-input"
                          placeholder="Holiday Type"
                          id="holidayType"
                          {...register("type_of_holiday",{ required: true })}
                        />
                        {errors.type_of_holiday && (
                          <p className="errormessage">Holiday Type is Required</p>
                        )}
                    </Row>
                    <Row>
                        <label>
                          Status:
                        </label>
                        <select
                          className="form-control underline-input"
                          {...register("status", { required: true })}
                        >
                        <option value={true}>Active</option>
                        <option value={false}>InActive</option>
                        </select>
                            {errors.status && (
                              <p className="errormessage">Status is Required</p>
                            )}
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Row className="justify-content-end mt-2">
                          <Button
                            color="secondary"
                            onClick={() => setCreate(false)}
                          >
                            Cancel
                          </Button>
                          <Button
                            color='primary'
                            type="submit"
                            className="btn listing_addbutton ml-1"
                          >
                            Submit
                          </Button>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </div>
              </form>
              </div>
            </Modal>
          </Row>
          <div className="newlisting_headertabdesign">
            <Row>
              <form className="master">
                  <div style={{ marginTop: "6px",marginRight:"5px" }}>
                    <label className="area_rowslable">Limit</label>
                    <br />
                    <FormControl className={classes.formControl}>
                      <Select
                        className={classes.selectlimit}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={rows}
                        onChange={handleRows}
                      >
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </form>
                <br />
              <Col md={0}>
                <div className="form-group">
                  <div>
                    <div className="clearsearchclass">
                      <form
                        id="myForm"
                        className="d-flex"
                        onSubmit={(e) => {
                          setBackdropOpen(false);
                          e.preventDefault();
                          submitSearch(e,1);
                        }}
                      >
                        <input
                          style={{
                            padding: "5px",
                            border: "2px solid",
                            marginTop: "-1px",
                          }}
                          className="form-control"
                          placeholder="Search.."
                          name="search"
                          required
                        />
                        <button
                          color='blue'
                          className={classes.srchbtn}
                          type="submit"
                        >
                          <i className="fa fa-search"></i>
                        </button>
                      </form>
                      {searchdata ? (
                         <a    
                         onClick={() => {
                             setdisplay("inline");
                             setBackdropOpen(true);
                             setPageNo(1);
                             getAllTypeofHoliday(1);
                           }}                      
                         >
                          Clear Search
                        </a>
                      ) : null}
                    </div>                      
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <br/>
          <div className="newlisting_headertabdesign">
            <Table responsive striped>
            <thead>
                <tr>
                  <th style={{  width: "175px"}}>SNo.</th>
                  <th style={{  width: "475px"}}>  Type of Holiday {" "}
                    <UnfoldMoreIcon onClick={sortUser} id="type_of_holiday"/>
                  </th>
                  <th style={{ textAlign: "center", width: "575px"}}>
                    Status{" "}
                    <UnfoldMoreIcon onClick={sortUser} id="status"/>
                  </th>
                  <th style={{ textAlign: "center", width: "100px" }}>Action</th>
                </tr>
              </thead>              
              <tbody>
                {types != null ? (
                  types &&
                  types.map((typeofholiday , index) => (
                  <tr>
                    <th>{countNumber * rows + 1 + index}</th>
                    <td>{typeofholiday.type_of_holiday}</td> 
                    <td style={{ textAlign: "center", width: "175px" }}>{typeofholiday.status == true ? "Active":"InActive"}</td>
                    {/* <td style={{ textAlign: "center", width: "175px" }}>{holiday.type_of_holiday}</td> */}
                    <td>
                      <div style={{
                          position: "relative",
                          textAlign: "center",
                        }}
                      >
                        <div
                          className="workflowdashboard_fa-ellipsis"
                          data-toggle="dropdown"
                        >
                          <i
                            className="fa fa-ellipsis-v "
                            aria-hidden="true"
                          ></i>
                        </div>
                        <div
                          className="dropdown-menu workflowdashboarddropdown"
                          style={{ width: "200px" }}
                        >
                          {permissionObj?.includes(50)?
                            <p id={typeofholiday.id}
                              onClick={(e)=>{
                                e.preventDefault();
                                editTypeofHolidayData(e, typeofholiday)}}>
                              Edit
                            </p>
                          :null}
                          <Modal
                            isOpen={editOpen}
                            onRequestClose={closeModal}
                            style={customStyles}
                            ariaHideApp={false}
                            contentLabel="Example Modal"
                          >
                          <div style={{ textAlign: "right", padding: "10px 20px" }}>
                            <img className="closei" src={close} onClick={() => setEdit(false)} />
                          </div>
                          <div className='modalContainer'>
                            <div style={{ textAlign: "center" }}>
                              <img className='modelIcon' src={editicon} />
                            </div>
                            <h2>
                              <strong>Edit Type of Holiday</strong>
                            </h2>
                            <form id="editForm" onSubmit={(e) => {
                                e.preventDefault();
                                editOldTypeofHoliday(e);
                              }}>
                              <div>
                                <div className="form-group creatadmin">
                                  <Row>
                                    <Col md={12}>
                                      <label>Type of Holiday <span style={{ color: "red" }}>*</span>:</label>
                                      <input
                                        type="text"
                                        className="form-control underline-input"
                                        value={editTypeofHoliday}
                                        onChange={(e) => {
                                          seteditTypeofHoliday(e.target.value);
                                        }}
                                        required
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={12}>
                                      <label>Status:</label>
                                      <select
                                        name="status"
                                        className="form-control underline-input"
                                        value={editTypeofHolidayStatus}
                                        onChange={(e) => {
                                          seteditTypeofHolidayStatus(e.target.value);
                                        }}
                                      >
                                        <option value={true}>Active</option>
                                        <option value={false}>InActive</option>
                                      </select>
                                    </Col>
                                  </Row>
                                  <br/>
                                  
                                </div>
                                {/* <Row style={{ float: "right" }}>
                                  <Button
                                    color="secondary"
                                    style={{
                                      marginTop: "20px",
                                      marginRight: "10px",
                                    }}
                                    onClick={() => setEdit(false)}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    color="primary"
                                    type="submit"
                                    style={{ marginTop: "20px" }}
                                  >
                                    Submit
                                  </Button>
                                </Row> */}
                                <div style={{ display: "flex", justifyContent: "space-between", margin: "30px 0px" }}>
                                  <button className='modalbtn backbtn' onClick={() => setEdit(false)}>Back</button>
                                  <button className='modalbtn submitbtn' >Submit</button>
                                </div>
                              </div>
                            </form>
                            </div>
                          </Modal>
                          {permissionObj?.includes(51)?
                            <p
                              id={typeofholiday.id}
                              onClick={(e) => {
                                deleteTypeofHoliday(e, typeofholiday);
                                DeleteType();
                              }} color="danger">Delete
                            </p>
                          :null}
                          <Modal
                            ariaHideApp={false}
                            isOpen={deleteOpen}
                            onRequestClose={closedModal}
                            style={customStyles2}
                            contentLabel="Example Modal"
                          >
                            <h5>
                              Are you sure you want to delete current record ?
                            </h5>
                            <div style={{ float: "right" }}>
                              <Button
                                color="danger"
                                type="submit"
                                onClick={deleteCurrentCategory}
                              >
                                Delete
                              </Button>
                              &nbsp;&nbsp;&nbsp;
                              <Button
                                color="secondary"
                                onClick={() => setDelete(false)}
                              >
                                Cancel
                              </Button>
                            </div>
                          </Modal> 
                        </div>
                      </div>
                    </td>
                  </tr>
                ))) : (
                  <p className={classes.headertekst}>{null}</p>
                )}
              </tbody>
            </Table>
            <div>
            <Row>
            <Col md={6}>
              {types?.length ? (
                <p style={{ marginTop: "15px" }}>
                  {countNumber * rows + 1} to{" "}
                  {countNumber * rows + numberOfElements} of {totalcount} records
                </p>
              ) : (
                <p className="formfooterrecordstyle">No Record Found</p>
              )}
            </Col>
            <Col md={6}>
              <div className={classes.root}>
                {searchdata ? (
                  <Pagination
                    page={paginationPageNo}
                    count={getPageNo}
                    onChange={submitSearch}
                  />
                ) : (
                  <Pagination
                    page={paginationPageNo}
                    count={getPageNo}
                    onChange={handlepage}
                  />
                )}
              </div>
            </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  </div>
 );
}

export default TypeofHoliday