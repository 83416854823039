import React , { useState, useEffect }from 'react';
import { ToastContainer, toast } from "react-toastify";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Link, useNavigate } from "react-router-dom";
import { CardText, Col, Row, Button, Table } from "reactstrap";
import AddIcon from "@mui/icons-material/Add";
import DateRangePicker from "react-bootstrap-daterangepicker";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Pagination from "@mui/material/Pagination";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
import Select from 'react-select';
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import { customStyles, useStyles } from "./../../Utilities/CSSUtilities";
import axios from "axios";
import base_url from '../../api/bootapi';
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { 
    setStarted,
    selectStarted,
    setStopped,
    selectStopped,
    logout,
    selectEmployee,
    setSessionIdData,
    selectSessionId,
    setEmpIpAddress
  } from "../../components/features/empSlice";

const AbsentList = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [backdropOpen, setBackdropOpen] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [modalIsOpen2, setIsOpen2] = React.useState(false);
    const [modalCreate, setCreate] = React.useState(false);
    const [absence,setAbsence] =useState([]);
    const employeData = useSelector(selectEmployee);
    const jwtToken = "JWT " + localStorage.getItem("JWTToken");
    const [datefilter, setdatefilter] = useState();
    const IpAddress = localStorage.getItem("EmpIpAddress");
    const [pageNo, setPageNo] = useState(1);
    const [paginationPageNo, setPaginationPageNo] = useState(1);
    const [getPageNo, setGetPageNo] = useState();
    const [rows, setRows] = React.useState(50);
    const [numberOfElements, setNumberOfElements] = useState();
    const [countNumber, setCountNumber] = useState();
    const [totalcount, setTotalCount] = useState();
    const [display1, setdisplay] = useState("inline");
    const [datevalue, setdatevalue] = useState("Click to open");
    const [searchdata, setsearch] = useState();
    const [selectedDelete, setSelectedDelete] = useState();
    const [direction, setdirection] = useState("Desc");
    const [sortState, setsortState] = useState("emp_name");
    const [dateFilterStatus, setDateFilterStatus] = useState();
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split("T")[0];
    const [permissionObj, setPermissionObj] = useState();

    useEffect( () => {
      getPermissions();
      window.addEventListener("storage", function (e) {
        if (e.key === null) {
          dispatch(logout());
          navigate("/");
        }
      });
      return () => {
        setAbsence();
        setAvailableRoleByAllId();
        };      
    },[rows]);

    const getPermissions = () => {
      const resource_obj = [];

      for (let i = 0; i < employeData.role_permissions.length; i++) {
        resource_obj.push(employeData.role_permissions[i].resource_id);
      }
      setPermissionObj(resource_obj);
      var available_resource = resource_obj.includes(32);

      if(available_resource) {
        userByRoleID();
        if (localStorage.getItem("UserPageNo")) {
          let currentPage = localStorage.getItem("UserPageNo");
          setPageNo(currentPage);
          setPaginationPageNo(Number(currentPage));
          getAllAbsentList(currentPage);
        } else {
          setPageNo(1);
          setPaginationPageNo(1);
          getAllAbsentList(1);
        }
      } else {
        console.log('coming else');
        navigate('/errorPage')
      }
    };

    const handleRows = (event) => {
        setRows(event.target.value);
      };

      const getAllAbsentList = (value1) => {
        setBackdropOpen(true);
        axios
          .get(`${base_url.api1}/office_app/absence/range?company_code=`+ employeData.company_code +`&from=`+ date1 +`&to=`+ date2 +`&page=`+value1+`&size=`+ rows, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then(
            (response) => {
              setBackdropOpen(false);
              console.log(response.data.Absence);
              setAbsence(response.data.Absence);
              setGetPageNo(response.data.total_pages);
              setTotalCount(response.data.total_count);
              setNumberOfElements(response.data.Absence.length);
              setCountNumber(value1-1);
              document.getElementById("myForm").reset();
            },
            (error) => {
              setBackdropOpen(false);
                console.log(JSON.stringify(error.response.data));
            }
          );
      };  

     const handlepage = (event, value) => {
        if(searchdata==true)
        console.log(value);
        setPaginationPageNo(value);
        setPageNo(value);
        getAllAbsentList(value);
      };

      const [searchinput, setsearchinput] = useState();
      const submitSearch = (e ,value1) => {
         setdisplay("none");
        setsearch(true);
        let srchdata = e.target[0].value.trim();
        setsearchinput(srchdata);
        console.log(srchdata);
        setBackdropOpen(true);
        axios
          .get(`${base_url.api1}/office_app/absence/range?company_code=` + employeData.company_code +`&from=`+ date1 +`&to=`+ date2 +`&page=`+value1+`&size=`+rows+`&search=` + srchdata, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then(
            (response) => {
              setBackdropOpen(false);
              console.log(response.data.Absence);
              setAbsence(response.data.Absence);
              setGetPageNo(response.data.total_pages);
              setTotalCount(response.data.total_count);
              setNumberOfElements(response.data.Absence.length);
              setCountNumber(value1-1);
              document.getElementById("myForm").reset();
            },
            (error) => {
              console.log(JSON.stringify(error.response.data));
              setBackdropOpen(false);
              setAbsence();
            },
            (error) => {
              console.log(JSON.stringify(error.response.data));
            }
          );
      };

      const [datesRange, setDatesRange] = useState();
      const [pastDateValue, setPastDateValue] = useState('');

      const handleApply = (event, picker, value1) => {
        setBackdropOpen(true);
        setdatevalue(
          `${picker.startDate.format("DD-MM-YYYY")} to ${picker.endDate.format(
            "DD-MM-YYYY"
          )}`
        );
        let data = {
          from: picker.startDate.format("YYYY-MM-DD"),
          to: picker.endDate.format("YYYY-MM-DD"),
        };
    
        setDatesRange(data);
        setDate1(data.from);
        setDate2(data.to);
        axios
        .get(`${base_url.api1}/office_app/absence/range?company_code=`+employeData.company_code+`&from=`+data.from+`&to=`+data.to+`&page=`+ value1 +`&size=`+rows , {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then(
            (response) => {
              setBackdropOpen(false);
              console.log(response.data.Absence);
              setAbsence(response.data.Absence);
              setGetPageNo(response.data.total_pages);
              setTotalCount(response.data.total_count);
              setNumberOfElements(response.data.Absence.length);
              setCountNumber(value1-1);
              document.getElementById("myForm").reset();
            },
            (error) => {
              setBackdropOpen(false);
              setdatefilter(true);
              setAbsence();
              console.log(JSON.stringify(error.response.data));
            }
          );
      };

      const [date1 , setDate1] = useState(datenow);
      const [date2 , setDate2] = useState(datenow);
      const [attendanceData,setAttendance] =useState([]);
      const [empId, setEmpId] = useState([]);

      const [selectemployee, setSelectedEmployee] = useState(false);
      const [selectEmployeeData, setSelectedEmployeeData] = useState();
      const onSelectEmployee = (selectedList, selectedItem) => {
        console.log("Entering",selectedList);
        setBackdropOpen(true);
        setSelectedEmployee(true);
        setSelectedEmployeeData(selectedList);
        if(selectedList.value==null){
        axios
          .get(`${base_url.api1}/office_app/absence/range?company_code=`+ employeData.company_code  +`&from=`+ date1 +`&to=`+ date2 +`&page=`+ 1 +`&size=`+ rows   , {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then(
            (response) => {
              setAbsence(response.data.Absence)
              setGetPageNo(response.data.total_pages);
              setTotalCount(response.data.total_count);
              setCountNumber(0);
              setNumberOfElements(response.data.Absence.length);
              setsearch(false);
              setBackdropOpen(false);
            },
            (error) => {
                setBackdropOpen(false);
                setAbsence();
                console.log(JSON.stringify(error.response.data));
            }
          );
        }else{
          axios
          .get(`${base_url.api1}/office_app/absence/range?company_code=`+ employeData.company_code + `&emp_id=`+selectedList.value +`&from=`+ date1 +`&to=`+ date2 +`&page=`+ 1 +`&size=`+ rows   , {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then(
            (response) => {
              setAbsence(response.data.Absence)
              setGetPageNo(response.data.total_pages);
              setTotalCount(response.data.total_count);
              setCountNumber(0);
              setNumberOfElements(response.data.Absence.length);
              setsearch(false);
              setBackdropOpen(false);
            },
            (error) => {
                setBackdropOpen(false);
                setAbsence();
                console.log(JSON.stringify(error.response.data));
            }
          );
        }
      };

      const [availableRoleByAllId, setAvailableRoleByAllId] = useState([]);
      const [empAllId, setEmpAllId] = useState([]);
      const userByRoleID = () => {
        axios
          .get(`${base_url.api1}/myappapi/all_users_by_role?company_code=` + employeData.company_code + `&status=True`, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((response) => {
            console.log(response.data.Users);
            // setAvailableRoleByAllId(response.data.Users);
            // setEmpAllId(response.data.user_id);
            setEmpId(response.data.user_id);
            let ofc_data = response.data.Users;
            const office_obj = [{
              value: null,
              label: "-Select Employee-",
            }];
            for (let i = 0; i < ofc_data.length; i++) {
              let data = {
                value: ofc_data[i].user_id,
                label: ofc_data[i].first_name+" "+ofc_data[i].last_name,
              };
              office_obj.push(data);
            }
            setAvailableRoleByAllId(office_obj);
          })
          .catch((error) => {
            console.log(JSON.stringify(error));
          });
      }

      const handleChangeDate = (e) =>{
        setBackdropOpen(true);
        let dt = e.target.value;
        setPastDateValue(dt);
        if(dt != '') {
          setDate2(datenow)
          let datepast = moment().subtract(dt,"d").format("YYYY-MM-DD");
          setDate1(datepast)
          axios.get(`${base_url.api1}/office_app/absence/range?company_code=`+employeData.company_code+`&from=` + datepast  + `&to=` + datenow +`&page=`+ pageNo +`&size=`+rows ,{
            headers: {
              Authorization: jwtToken,
            },
          })
          .then(
            (response) => {
              setBackdropOpen(false);
              setDateFilterStatus(true);
              console.log(response.data.Absence);
              setAbsence(response.data.Absence);
              setGetPageNo(response.data.total_pages);
              setTotalCount(response.data.total_count);
              setNumberOfElements(response.data.Absence.length);
              setCountNumber(pageNo-1);
              document.getElementById("myForm").reset();
            },
            (error) => {
              setBackdropOpen(false);
              setDateFilterStatus(true);
              console.log(JSON.stringify(error.response.data));
            }
          );
        } else {
          axios.get(`${base_url.api1}/office_app/attendance/date_range?company_code=`+ employeData.company_code + `&from=` + datenow  + `&to=` + datenow +`&page=`+ pageNo +`&size=`+rows +`&shit_incomplete=True`  ,{
            headers: {
              Authorization: jwtToken,
            },
          })
          .then(
            (response) => {
              console.log(response.data);
              setGetPageNo(response.data.total_pages);
              console.log(response.data.total_pages);
              setTotalCount(response.data.total_present);
              console.log(response.data.total_present)
              setCountNumber(pageNo-1);
              console.log(pageNo-1);
              setNumberOfElements(response.data.Attendance.length);
              console.log(response.data.Attendance.length);
               setsearch(false);
              setBackdropOpen(false);
            },
            (error) => {
              setBackdropOpen(false);
              setAttendance();
            }
          );
        }
      }

      const sortUser = (e) => {
        console.log("coming");
        setBackdropOpen(true);
        setsortState(e.target.id);
        console.log(e.target.id);
        if (direction == "Desc") {
          setdirection("ASC");
        } else if (direction == "ASC") {
          setdirection("Desc");
        }
        let sortby = e.target.id
        console.log(sortby);
        axios
          .get(`${base_url.api1}/office_app/absence/range?company_code=` + employeData.company_code +`&from=`+ date1 +`&to=`+ date2 + `&sortby=`+sortby+`&Order=`+direction +`&page=`+pageNo +`&size=` +rows , {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then(
            (response) => {
              setAbsence(response.data.Absence);
              document.getElementById("myForm").reset();
              setGetPageNo(response.data.total_pages);
              //console.log(response.data.total_pages);
              setTotalCount(response.data.total_count);
              setCountNumber(pageNo-1);
              setNumberOfElements(response.data.Absence.length);
              //console.log(response.data.Absence.length);
              setSelectedDelete([]);
              setdatefilter(false);
              setBackdropOpen(false);
        
            },
            (error) => {
              setBackdropOpen(false);
              setAttendance();
              console.log(JSON.stringify(error.response.data));
            }
          );
      };  
  return (
    <>
    <div>
    <Backdrop open={backdropOpen}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
     >
        <CircularProgress color="inherit" />
    </Backdrop>
      <div className="commoncomponent_cardcss">
        <div>
        <Row>
            <Col md={6}>
              <CardText
                style={{ marginTop: "-10px" }}
              >
                <h1>Absent List</h1>
              </CardText>
            </Col>
          </Row>
          <br/>
          <div className="newlisting_headertabdesign">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <Row>
                <div style={{ marginRight: "7px" }}>
                  {/* <form className="master">
                    <div style={{ marginTop: "-10px", width: "fit-content" }}>
                      <label className="area_rowslable">Limit</label>
                      <br />
                      <FormControl className={classes.formControl}>
                        <Select
                         className={classes.selectlimit}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          onChange={handleRows}
                          value={rows}
                        >
                          <MenuItem value={20}>20</MenuItem>
                          <MenuItem value={50}>50</MenuItem>
                          <MenuItem value={100}>100</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </form> */}
                </div>
                <div style={{ marginTop: "-10px", marginRight: "14px" }}>
                {/* <Button  
                     value="1"
                     className={classes.prevButton}
                     onClick={(e) => handleChangePrev(e)}
                     color="info">Prev</Button> */}
                     </div>
                <div style={{ marginTop: "-10px", marginRight: "14px" }}>
                  <label className="area_rowslable">Date</label>
                  <DateRangePicker
                        onApply={(event, picker)=>handleApply(event, picker,1)}
                      >
                    <p className="datepickerrangevaluetext" style={{borderRadius:"10px"}}>{datevalue}</p>
                  </DateRangePicker>
                  {datefilter ? (
                    <div>
                      <a
                        className={classes.clrsrch}
                        onClick={() => {
                           setdisplay("inline");
                          setdatevalue("Click to open");
                          setPastDateValue();
                          setBackdropOpen(true);
                          setdatefilter(false);
                          setPageNo(1);
                          getAllAbsentList(1,datenow,datenow);
                        }}
                      >
                        Clear Filter
                      </a>
                    </div>
                  ) : null}
                </div>
                <div style={{ marginTop: "-10px", marginRight: "14px" }}>
                {/* <Button
                     value="1"
                     className={classes.nextButton}
                     onClick={(e) => {
                                e.preventDefault();
                                handleChangeNext(e);
                                }}
                    color="info">Next</Button> */}
                  </div>
                    <div style={{ marginTop: "-10px", marginRight: "14px" }}>
                      <label>
                        <strong>Past Date:</strong>
                      </label>
                      <br />
                      <select
                        className={classes.select}
                        onChange={handleChangeDate}
                        value={pastDateValue}
                      >
                        <option value="">-Select Range-</option>
                        <option value="7"> 7 Days</option>
                        <option value="30"> 30 Days</option>
                        <option value="90"> 90 Days </option>
                        <option value="181">Half Yearly</option>
                        <option value="365">Yearly</option>
                      </select>
                      {dateFilterStatus ? (
                    <div>
                      <a
                        className={classes.clrsrch}
                        onClick={() => {
                          setPastDateValue('');
                          setdisplay("inline");
                          setdatevalue("Click to open");
                          setBackdropOpen(true);
                          setDateFilterStatus(false);
                          // setdatefilter(false);
                          setPageNo(1);
                          getAllAbsentList(1,datenow,datenow);
                        }}
                      >
                        Clear Filter
                      </a>
                    </div>
                  ) : null}
                    </div>
              </Row>
              <div>
              
              <div className="form-group">
                  <div>
                    <div className="clearsearchclass">
                      <form
                        id="myForm"
                        className="d-flex"
                        onSubmit={(e) => {
                        e.preventDefault();
                    }}
                      >
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <div className="arealimit" style={{ marginTop:"-20px",marginRight:"-65px"}}>
                        <label className="area_rowslable">Show:</label>
                        </div>
                      <FormControl variant="standard" sx={{ m: 1, minWidth: 80 ,marginTop: 0, maxHeight: 80}}>
                        <select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={rows}
                          onChange={handleRows}
                          label="Show"
                        >
                          <option value={20}>20</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                      </FormControl>
                      <div>
                      </div>
                      </form>
                    </div>                      
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br/>
          {/* <div className="newlisting_headertabdesign"> */}
          {/* <Row> */}
          {/* <Col md={2}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div className="arealimit" style={{ marginTop:"-20px",marginRight:"-65px"}}>
                        <label className="area_rowslable">Show:</label>
                        </div>
                      <FormControl variant="standard" sx={{ m: 1, minWidth: 80 ,marginTop: 0, maxHeight: 80}}>
                        <select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={rows}
                          onChange={handleRows}
                          label="Show"
                        >
                          <option value={20}>20</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                      </FormControl>
                </Col> */}
               {/* <Col md={3}>
               <label className="area_rowslable">Date</label>
                  <DateRangePicker
                        onApply={(event, picker)=>handleApply(event, picker,1)}
                      >
                    <p className="datepickerrangevaluetext" style={{borderRadius:"10px"}}>{datevalue}</p>
                  </DateRangePicker>
                  {datefilter ? (
                    <div>
                      <a
                        className={classes.clrsrch}
                        onClick={() => {
                          setdisplay("inline");
                          setdatevalue("Click to open");
                          setBackdropOpen(true);
                          setdatefilter(false);
                          setPageNo(1);
                          getAllAbsentList(1,datenow,datenow);
                        }}
                      >
                          ClearFilter
                      </a>
                    </div>
                  ) : null}
               </Col>
               <Col md={7}></Col> */}
          {/* </Row> */}
          {/* </div> */}
          <Row>
          <Col md={4}>
               <label><strong>Employee Name:</strong></label>
                      <Select
                        {...register('emp_name',{ required: true,})}
                        //className={classes.selectdrop}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        //value3={newselected}
                        value={selectEmployeeData}
                        onChange={onSelectEmployee}
                        options={availableRoleByAllId}
                      >
                      </Select>
                      {selectemployee ? (
                        <div>
                          <a
                            className={classes.clrsrch}
                            onClick={() => {
                              setBackdropOpen(true);
                              getAllAbsentList(1,datenow,datenow);
                              setSelectedEmployeeData({value: null, label: null});
                              setSelectedEmployee(false);
                            }}
                          >
                            Clear Filter
                          </a>
                        </div>
                      ) : null}
               </Col>
               <Col md={8}>

               </Col>
          </Row>
          <br/>
          <br/>
          <div className="newlisting_headertabdesign">
            <Table responsive striped>
              <thead>
                <tr>
                  <th style={{ textAlign: "center", width: "50px" }}>SN</th>
                  <th>
                    User{" "}
                    <UnfoldMoreIcon onClick={sortUser} id="emp_name"/>
                  </th>
                  <th>
                    Email{" "}
                    <UnfoldMoreIcon onClick={sortUser}  id="emp_email"/>
                  </th>
                  <th>
                    Date{""}
                    <UnfoldMoreIcon onClick={sortUser} id="session_date"/>
                  </th>
                </tr>
              </thead>
              <tbody>
                  {absence != null ? (
                    absence &&
                    absence.map((absent , index) => (
                    <tr>
                      <th>{countNumber * rows + 1 + index}</th>
                      <td >{absent.emp_name}</td>
                      <td>{absent.emp_email}</td>
                      <td>{absent.session_date
                          ? moment(absent.session_date.split(".")[0]).format(
                              "DD MMM YYYY"
                            )
                          : null}</td>
                      <td>{absent.log}</td>
                    </tr>
                    ))) : (
                  <p style={{width:'500%'}}>{null}</p>
                  )}
                
              </tbody>
            </Table>
            <div>
            <Row>
            <Col md={6}>
              {absence?.length ? (
                <p style={{ marginTop: "15px" }}>
                  {countNumber * rows + 1} to{" "}
                  {countNumber * rows + numberOfElements} of {totalcount} records
                </p>
              ) : (
                <p className="formfooterrecordstyle">No Records Found</p>
              )}
            </Col>
            <Col md={6}>
            <div className={classes.root}>
                {searchdata ? (
                  <Pagination
                    page={paginationPageNo}
                    count={getPageNo}
                    onChange={submitSearch}
                  />
                ) : (
                  <Pagination
                    page={paginationPageNo}
                    count={getPageNo}
                    onChange={handlepage}
                  />
                )}
              </div>
            </Col>
          </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default AbsentList