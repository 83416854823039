import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { CardText, Col, Row, Button, Table } from 'reactstrap';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Pagination from '@mui/material/Pagination';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Modal from 'react-modal';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import base_url from '../../../api/bootapi';
import {
	customStyles,
	useStyles,
	customStyles2,
} from './../../../Utilities/CSSUtilities';
import { useDispatch, useSelector } from 'react-redux';
import { logout, selectEmployee } from '../../../components/features/empSlice';
import holidayicon from '../../../components/images/event.png';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useToasts } from 'react-toast-notifications';
import { toast } from 'react-toastify';
import CallAuditapi from '../../services/auditservice';
import close from '../../../components/images/closeicon.png';
import editicon from '../../../components/images/edit.png';
import deleteicon from '../../../components/images/delete.png';

const HolidayList = () => {
	const classes = useStyles();
	const params = useParams();
	//console.log(params);
	const jwtToken = 'JWT ' + localStorage.getItem('JWTToken');
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();
	const [holidays, setHoliday] = useState([]);
	const [modalCreate, setCreate] = React.useState(false);
	const navigate = useNavigate();
	const [searchdata, setsearch] = useState();
	const [datevalue, setdatevalue] = useState('Click to open');
	const [datefilter, setdatefilter] = useState(false);
	const [backdropOpen, setBackdropOpen] = useState(false);
	const [inputtype, setinputtype] = useState('password');
	const employeeData = useSelector(selectEmployee);
	const [pageNo, setPageNo] = useState(1);
	const [paginationPageNo, setPaginationPageNo] = useState(1);
	const [getPageNo, setGetPageNo] = useState();
	const [rows, setRows] = React.useState(50);
	const [sortState, setsortState] = useState('holiday_name');
	const [direction, setdirection] = useState('Desc');
	const [totalcount, setTotalCount] = useState();
	const [countNumber, setCountNumber] = useState();
	const [numberOfElements, setNumberOfElements] = useState();
	const [selectedDelete, setSelectedDelete] = useState();
	const [datesrange, setdatesrange] = useState();
	const [display1, setdisplay] = useState('inline');
	const dispatch = useDispatch();
	const [permissionObj, setPermissionObj] = useState();

	const { addToast } = useToasts();

	const createHoliday = (data2) => {
		setBackdropOpen(true);
		let holiday_name = data2.holiday_name;
		let type_of_holiday = data2.type_of_holiday;
		let start_date = data2.start_date;
		let status = data2.status;
		let company_code = employeeData.company_code;

		let data = {
			holiday_name,
			type_of_holiday,
			start_date,
			company_code,
			status,
		};
		axios
			.post(
				`${base_url.api1}/myappapi/holiday/create?company_code=` +
					employeeData.company_code,
				data,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then(
				(response) => {
					let log =
						employeeData.emp_name + ' created Holiday ' + data.holiday_name;
					CallAuditapi(log);
					getAllHolidayList(pageNo);
					setCreate(false);
					document.getElementById('addform').reset();
				},
				(error) => {
					console.log(error);
					document.getElementById('addform').reset();
					setBackdropOpen(false);
					setCreate(false);
				}
			);
	};

	useEffect(() => {
		window.addEventListener('storage', function (e) {
			if (e.key === null) {
				dispatch(logout());
				navigate('/');
			}
		});
		getPermissions();
	}, [rows]);

	const handleRows = (event) => {
		setRows(event.target.value);
	};

	const getPermissions = () => {
		const resource_obj = [];

		for (let i = 0; i < employeeData.role_permissions.length; i++) {
			resource_obj.push(employeeData.role_permissions[i].resource_id);
		}
		setPermissionObj(resource_obj);
		// var available_resource44 = resource_obj.includes(44);
		// var available_resource45 = resource_obj.includes(45);
		// var available_resource46 = resource_obj.includes(46);
		// var available_resource47 = resource_obj.includes(47);
		var available_resource81 = resource_obj.includes(81);

		// if(available_resource44 && (available_resource45 || available_resource46 || available_resource47)) {
		if (available_resource81) {
			getTypeofHoliday();
			if (localStorage.getItem('HolidayPageNo')) {
				let currentPage = localStorage.getItem('HolidayPageNo');
				setPageNo(currentPage);
				setPaginationPageNo(Number(currentPage));
				getAllHolidayList(currentPage);
			} else {
				setPageNo(1);
				setPaginationPageNo(1);
				getAllHolidayList(1);
			}
		} else {
			navigate('/errorPage');
		}
	};

	const [editOpen, setEdit] = React.useState(false);
	const [editHolidayId, seteditHolidayId] = useState();
	const [editHolidayName, seteditHolidayName] = useState();
	const [editHolidayType, seteditHolidayType] = useState();
	const [editHolidayDate, seteditHolidayDate] = useState();
	const [editHolidayStatus, seteditHolidayStatus] = useState();
	const editHolidayData = (elem, holiday) => {
		console.log(holiday);
		seteditHolidayId(holiday.holiday_id);
		seteditHolidayName(holiday.holiday_name);
		seteditHolidayType(holiday.type_of_holiday);
		seteditHolidayDate(holiday.start_date);
		seteditHolidayStatus(holiday.status);
		setEdit(true);
	};
	const editOldHoliday = () => {
		setBackdropOpen(true);
		let data = {
			holiday_name: editHolidayName.trim(),
			type_of_holiday: editHolidayType,
			start_date: editHolidayDate,
			status: editHolidayStatus,
		};
		axios
			.put(
				`${base_url.api1}/myappapi/holiday/update?company_code=` +
					employeeData.company_code +
					`&holiday_id=` +
					editHolidayId,
				data,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then((response) => {
				let log = employeeData.emp_name + ' edited in Holiday';
				CallAuditapi(log);
				setBackdropOpen(false);
				setEdit(false);
				getAllHolidayList(pageNo);
			})
			.catch((error) => {
				setBackdropOpen(false);
			});
	};

	const handleEvent = (event, picker) => {
		console.log(picker.startDate);
	};

	const handleCallback = (start, end, label) => {
		console.log(start, end, label);
	};

	const getAllHolidayList = (value1) => {
		console.log(value1);
		setBackdropOpen(true);
		axios
			.get(
				`${base_url.api1}/myappapi/holiday/date?company_code=` +
					employeeData.company_code /* 
					`&page=` +
					value1 +
					`&size=` +
					rows + */ +
					`&from=01-01&to=12-31&Order=Desc&sortby=start_date`,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then(
				(response) => {
					console.log(response.data);
					setHoliday(response.data.Holiday);
					setGetPageNo(response.data.total_pages);
					setTotalCount(response.data.total_count);
					setCountNumber(value1 - 1);
					setNumberOfElements(response.data.Holiday.length);
					setSelectedDelete([]);
					setsearch(false);
					setBackdropOpen(false);
					document.getElementById('myForm').reset();
				},
				(error) => {
					console.log(JSON.stringify(error.response.data));
					setBackdropOpen(false);
					setHoliday();
				}
			);
	};

	const closedModal = () => {
		setDelete(false);
	};
	const closeModal = () => {
		setCreate(false);
	};

	const createModalHoliday = () => {
		setCreate(true);
	};

	const DeleteInHoliday = () => {
		setDelete(true);
	};

	const [DeleteId, setDeleteId] = useState();
	const [deleteOpen, setDelete] = React.useState(false);
	const deleteHoliday = (elem, holiday) => {
		console.log(holiday);
		setDeleteId(holiday.holiday_id);
	};
	const deleteCurrentHoliday = () => {
		axios
			.delete(
				`${base_url.api1}/myappapi/holiday/delete?company_code=` +
					employeeData.company_code +
					`&holiday_id=` +
					DeleteId,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then(
				(response) => {
					setBackdropOpen(false);
					setDelete(false);
					getAllHolidayList(pageNo);
				},
				(error) => {
					setBackdropOpen(false);
					console.log(JSON.stringify(error.response.data));
				}
			);
	};

	const handlepage = (event, value) => {
		setPaginationPageNo(value);
		setPageNo(value);
		if (searchinput != '' || searchinput != null || searchinput != undefined) {
			submitSearchpage(value);
		} else {
			getAllHolidayList(value);
		}
		console.log(value);
	};

	const [searchinput, setsearchinput] = useState();
	const submitSearch = (e, value) => {
		setdisplay('none');
		setsearch(true);
		let srchdata = e.target[0].value.trim();
		setsearchinput(srchdata);
		console.log(srchdata);
		setBackdropOpen(true);
		axios
			.get(
				`${base_url.api1}/myappapi/holiday/list?company_code=` +
					employeeData.company_code +
					`&page=` +
					value +
					`&size=` +
					rows +
					`&search=` +
					srchdata,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then(
				(response) => {
					setHoliday(response.data.Holiday);
					console.log(response.data.Holiday);
					setGetPageNo(response.data.total_pages);
					setNumberOfElements(response.data.Holiday.length);
					setTotalCount(response.data.total_count);
					setBackdropOpen(false);
				},
				(error) => {
					console.log(JSON.stringify(error.response.data));
					setHoliday();
					setBackdropOpen(false);
				},
				(error) => {
					console.log(JSON.stringify(error.response.data));
				}
			);
	};

	const submitSearchpage = (value) => {
		setdisplay('none');
		setsearch(true);
		setBackdropOpen(true);
		axios
			.get(
				`${base_url.api1}/myappapi/holiday/list?company_code=` +
					employeeData.company_code +
					`&page=` +
					value +
					`&size=` +
					rows +
					`&search=` +
					searchinput,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then(
				(response) => {
					setHoliday(response.data.Holiday);
					console.log(response.data.Holiday);
					setGetPageNo(response.data.total_pages);
					setNumberOfElements(response.data.Holiday.length);
					setTotalCount(response.data.total_count);
					setBackdropOpen(false);
				},
				(error) => {
					console.log(JSON.stringify(error.response.data));
					setHoliday();
					setBackdropOpen(false);
				},
				(error) => {
					console.log(JSON.stringify(error.response.data));
				}
			);
	};
	const [availabletypeofholidays, setAvailabletypeofholidays] = useState([]);
	const getTypeofHoliday = () => {
		setBackdropOpen(true);
		axios
			.get(
				`${base_url.api1}/myappapi/typeofholiday/list?company_code=` +
					employeeData.company_code +
					`&status=True`,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then((response) => {
				setBackdropOpen(false);
				console.log(response.data.Typeofholiday);
				setAvailabletypeofholidays(response.data.Typeofholiday);
			})
			.catch((error) => {
				setBackdropOpen(false);
				console.log(JSON.stringify(error));
			});
	};

	const lookup = availabletypeofholidays;
	console.log(lookup);
	const [selected, setSelected] = useState(-1);
	const value = selected !== 0 && lookup[selected];
	const [selectedtypeofholiday, setSelectedtypeofholiday] = useState();
	const [defaultTypeofHoliday, setdefaultTypefHoliday] = useState();
	const handleChangeTypeofHoliday = (event) => {
		console.log(event);
		setdefaultTypefHoliday(event.target.value);
		console.log(event.target.value);
		const index = event.target.selectedIndex;
		console.log(index);
		const optionElement = event.target.childNodes[index];
		const optionElementId = optionElement.getAttribute('id');
		console.log(optionElementId);
		const optionElementValue = optionElement.getAttribute('value');
		console.log(optionElementValue);

		setSelectedtypeofholiday(optionElementId);
		setdefaultTypefHoliday(optionElementValue);
	};

	const sortUser = (e) => {
		setsortState(e.target.id);
		console.log();
		if (direction == 'Desc') {
			setdirection('ASC');
		} else if (direction == 'ASC') {
			setdirection('Desc');
		}
		let sortby = e.target.id;

		axios
			.get(
				`${base_url.api1}/myappapi/holiday/list?company_code=` +
					employeeData.company_code +
					`&sortby=` +
					sortby +
					`&Order=` +
					direction +
					`&page=` +
					pageNo,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then(
				(response) => {
					setHoliday(response.data.Holiday);
					document.getElementById('myForm').reset();
					setGetPageNo(response.data.total_pages);
					//console.log(response.data.total_pages);
					setTotalCount(response.data.total_count);
					setCountNumber(pageNo - 1);
					setNumberOfElements(response.data.Users.length);
					//console.log(response.data.Users.length);
					setSelectedDelete([]);
					setsearch(false);
					setBackdropOpen(false);
				},
				(error) => {
					console.log(JSON.stringify(error.response.data));
					setHoliday();
				}
			);
	};
	const handleApply = (event, picker) => {
		setBackdropOpen(true);
		setdatevalue(
			`${picker.startDate.format('DD-MM-YYYY')} to ${picker.endDate.format(
				'DD-MM-YYYY'
			)}`
		);
		setPageNo(0);
		setPaginationPageNo(1);
		let data = {
			from: picker.startDate.format('MM-DD'),
			to: picker.endDate.format('MM-DD'),
			//sortby: sortState,
			// Order: direction,
		};
		setdatesrange(data);
		axios
			.get(
				`${base_url.api1}/myappapi/holiday/date?company_code=` +
					employeeData.company_code +
					`&from=` +
					data.from +
					`&to=` +
					data.to,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then(
				(response) => {
					setHoliday(response.data.Holiday);
					setGetPageNo(response.data.total_pages);
					setNumberOfElements(response.data.Holiday.length);
					setTotalCount(response.data.total_count);
					setsearch(false);
					setBackdropOpen(false);
					setdatefilter(true);
					document.getElementById('myForm').reset();
					setSelectedDelete([]);
					document.getElementById('myForm').reset();
				},
				(error) => {
					setHoliday();
					console.log(JSON.stringify(error.response.data));
					setdatefilter(true);
					setBackdropOpen(false);
				}
			);
	};

	return (
		<div>
			<Backdrop
				open={backdropOpen}
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<div className="commoncomponent_cardcss">
				<div style={{ margin: '5px', marginTop: '-30px' }}>
					<Row>
						{/* <Col md={6}>
              <CardText
                className={classes.headtext}
                style={{ marginTop: "-10px" }}
              >
                <h2>Holiday List</h2>
              </CardText>
            </Col>
            <Col md={6}  className="d-flex justify-content-end">
              {permissionObj?.includes(45)?
                <Button
                  color="primary"
                  onClick={createModalHoliday}
                >
                  + Add
                </Button>
              :null}
            </Col> */}
						{permissionObj?.includes(45) ? (
							<div className="newhead">
								<h4>Holiday List</h4>
								<div onClick={createModalHoliday}>
									<h6>New</h6> <p>+</p>
								</div>
							</div>
						) : null}

						<Modal
							ariaHideApp={false}
							isOpen={modalCreate}
							onRequestClose={closedModal}
							style={customStyles}
							contentLabel="Example Modal"
						>
							<div style={{ textAlign: 'right', padding: '10px 20px' }}>
								<img
									className="closei"
									src={close}
									onClick={() => setCreate(false)}
								/>
							</div>
							<div className="modalContainer">
								<div style={{ textAlign: 'center' }}>
									<img className="modelIcon" src={holidayicon} />
								</div>
								<form onSubmit={handleSubmit(createHoliday)} id="addform">
									<h1>Create Holiday</h1>
									<div>
										<div style={{ marginTop: '20px' }}>
											<h3>
												Holiday Name<span>*</span>
											</h3>
											<input
												type="text"
												className="modalinput"
												placeholder="Holiday Name"
												id="holiday Name"
												{...register('holiday_name', { required: true })}
											/>
											{errors.holiday_name && (
												<p className="errormessage">Holiday Name is Required</p>
											)}
										</div>
										<div>
											<h3>
												Holiday Type<span>*</span>
											</h3>
											<select
												{...register('type_of_holiday', { required: true })}
												className="modalinput"
												onChange={(e) => {
													setSelected(e.target.value);
													handleChangeTypeofHoliday(e);
												}}
												value={selected}
											>
												<option value="">-Type of Holiday -</option>
												{lookup.map((m, ix) => {
													return (
														<option
															key={m.id}
															id={m.id}
															value={m.type_of_holiday}
														>
															{m.type_of_holiday}
														</option>
													);
												})}
												;
											</select>
											{errors.type_of_holiday && (
												<p className="errormessage">Holiday Type is Required</p>
											)}
										</div>
										<div>
											<h3>
												Date<span>*</span>
											</h3>
											<input
												type="Date"
												className="modalinput"
												id="holidayDate"
												{...register('start_date', { required: true })}
											/>
											{errors.start_date && (
												<p className="errormessage">Date is Required</p>
											)}
										</div>
										<div>
											<h3>
												Status<span>*</span>
											</h3>
											<select
												className="modalinput"
												{...register('status', { required: true })}
											>
												<option value={true}>Active</option>
												<option value={false}>InActive</option>
											</select>
											{errors.status && (
												<p className="errormessage">Status is Required</p>
											)}
										</div>

										<div
											style={{
												display: 'flex',
												justifyContent: 'space-between',
												margin: '30px 0px',
											}}
										>
											<button
												className="modalbtn backbtn"
												onClick={() => setCreate(false)}
											>
												Back
											</button>
											<button className="modalbtn submitbtn">Submit</button>
										</div>
									</div>
								</form>
								<div></div>
								<br />
							</div>
						</Modal>
					</Row>
					<div className="newlisting_headertabdesign">
						<Row>
							<form className="master">
								<div style={{ marginTop: '15px' }}>
									<label className="area_rowslable">Limit</label>
									<br />
									<FormControl className={classes.formControl}>
										<Select
											className={classes.selectlimit}
											labelId="simple-select-label"
											id="simple-select"
											value={rows}
											onChange={handleRows}
										>
											<MenuItem value={20}>20</MenuItem>
											<MenuItem value={50}>50</MenuItem>
											<MenuItem value={100}>100</MenuItem>
										</Select>
									</FormControl>
								</div>
							</form>
							<br />
							<form className="form-group">
								<div
									style={{
										display: 'flex',
										justify: 'space-between',
										flexWrap: 'wrap',
									}}
								>
									<div style={{ marginTop: '1px', marginLeft: '5px' }}>
										<label className="area_rowslable">Date:</label>
										<DateRangePicker
											onEvent={handleEvent}
											onCallback={handleCallback}
											onApply={handleApply}
										>
											<p className="datepickerrangevaluetext">{datevalue}</p>
										</DateRangePicker>
										{datefilter ? (
											<div>
												<a
													className={classes.clrsrch}
													onClick={() => {
														setdisplay('inline');
														setdatevalue('Click to open');
														setBackdropOpen(true);
														setdirection('Desc');
														setPageNo(1);
														getAllHolidayList(1);
														setdatefilter(false);
													}}
												>
													Clear Filter
												</a>
											</div>
										) : null}
									</div>
								</div>
							</form>
							<br />
							<Col md={0}>
								<div className="form-group">
									<div>
										<div className="clearsearchclass">
											<form
												id="myForm"
												className="d-flex"
												onSubmit={(e) => {
													e.preventDefault();
													submitSearch(e, 1);
												}}
											>
												<input
													style={{
														padding: '5px',
														border: '2px solid',
														marginTop: '-1px',
													}}
													className="form-control"
													placeholder="Search.."
													name="search"
													required
												/>
												<button
													color="blue"
													className={classes.srchbtn}
													type="submit"
												>
													<i className="fa fa-search"></i>
												</button>
											</form>
											{searchdata ? (
												<a
													onClick={() => {
														setdisplay('inline');
														setBackdropOpen(true);
														setPageNo(1);
														getAllHolidayList(1);
														setsearchinput();
														setdatefilter(false);
													}}
												>
													Clear Search
												</a>
											) : null}
										</div>
									</div>
								</div>
							</Col>
						</Row>
					</div>
					<div className="newlisting_headertabdesign">
						<Table responsive striped>
							<thead>
								<tr>
									<th style={{ textAlign: 'center', width: '50px' }}>SNo.</th>
									<th>
										Holiday Name{' '}
										<UnfoldMoreIcon onClick={sortUser} id="holiday_name" />
									</th>
									<th>
										Date <UnfoldMoreIcon onClick={sortUser} id="start_date" />
									</th>
									<th style={{ textAlign: 'center', width: '175px' }}>
										Status <UnfoldMoreIcon onClick={sortUser} id="status" />
									</th>
									<th style={{ textAlign: 'center', width: '175px' }}>
										Type of Holiday{' '}
										<UnfoldMoreIcon onClick={sortUser} id="type_of_holiday" />
									</th>
									<th style={{ textAlign: 'center', width: '175px' }}>
										Action
									</th>
								</tr>
							</thead>
							<tbody>
								{holidays != null ? (
									holidays &&
									holidays.map((holiday, index) => (
										<tr>
											<th>{countNumber * rows + 1 + index}</th>
											<td>{holiday.holiday_name}</td>
											<td>
												{holiday.start_date
													? moment(holiday.start_date.split('.')[0]).format(
															'DD MMM YYYY'
													  )
													: null}
											</td>
											<td style={{ textAlign: 'center', width: '175px' }}>
												{holiday.status == true ? 'Active' : 'InActive'}
											</td>
											<td style={{ textAlign: 'center', width: '175px' }}>
												{holiday.type_of_holiday}
											</td>
											<td>
												<div
													style={{
														position: 'relative',
														textAlign: 'center',
													}}
												>
													<div
														className="workflowdashboard_fa-ellipsis"
														data-toggle="dropdown"
													>
														<i
															className="fa fa-ellipsis-v "
															aria-hidden="true"
														></i>
													</div>
													<div
														className="dropdown-menu workflowdashboarddropdown"
														style={{ width: '200px' }}
													>
														{permissionObj?.includes(46) ? (
															<p
																id={holiday.holiday_id}
																onClick={(e) => {
																	e.preventDefault();
																	editHolidayData(e, holiday);
																}}
															>
																Edit
															</p>
														) : null}
														<Modal
															isOpen={editOpen}
															onRequestClose={closeModal}
															style={customStyles}
															ariaHideApp={false}
															contentLabel="Example Modal"
														>
															<div
																style={{
																	textAlign: 'right',
																	padding: '10px 20px',
																}}
															>
																<img
																	className="closei"
																	src={close}
																	onClick={() => setEdit(false)}
																/>
															</div>
															<div className="modalContainer">
																<div style={{ textAlign: 'center' }}>
																	<img className="modelIcon" src={editicon} />
																</div>
																<h1>Edit Holiday</h1>
																<form
																	id="editForm"
																	onSubmit={(e) => {
																		e.preventDefault();
																		editOldHoliday(e);
																	}}
																>
																	<div>
																		<div style={{ marginTop: '20px' }}>
																			<h3>
																				Holiday Name<span>*</span>
																			</h3>
																			<input
																				type="text"
																				className="modalinput"
																				value={editHolidayName}
																				onChange={(e) => {
																					seteditHolidayName(e.target.value);
																				}}
																				required
																			/>
																		</div>
																		<div>
																			<h3>
																				Holiday Type<span>*</span>
																			</h3>
																			<select
																				{...register('type_of_holiday')}
																				className="modalinput"
																				onChange={(e) => {
																					setSelected(e.target.value);
																					handleChangeTypeofHoliday(e);
																				}}
																				value={selected}
																				required
																			>
																				<option value="">
																					-Type of Holiday-
																				</option>
																				{lookup.map((m) => {
																					return (
																						<option
																							key={m.id}
																							id={m.id}
																							value={m.type_of_holiday}
																						>
																							{m.type_of_holiday}
																						</option>
																					);
																				})}
																				;
																			</select>
																		</div>
																		<div>
																			<h3>
																				Date<span>*</span>
																			</h3>
																			<input
																				type="date"
																				className="modalinput"
																				value={editHolidayDate}
																				onChange={(e) => {
																					seteditHolidayDate(e.target.value);
																				}}
																				required
																			/>
																		</div>
																		<div>
																			<h3>
																				Status<span>*</span>
																			</h3>
																			<select
																				className="modalinput"
																				name="status"
																				value={editHolidayStatus}
																				onChange={(e) => {
																					seteditHolidayStatus(e.target.value);
																				}}
																				required
																			>
																				<option value={true}>Active</option>
																				<option value={false}>InActive</option>
																			</select>
																		</div>

																		<div
																			style={{
																				display: 'flex',
																				justifyContent: 'space-between',
																				margin: '30px 0px',
																			}}
																		>
																			<button
																				className="modalbtn backbtn"
																				onClick={() => setEdit(false)}
																			>
																				Back
																			</button>
																			<button className="modalbtn submitbtn">
																				Submit
																			</button>
																		</div>
																	</div>
																</form>
															</div>
														</Modal>
														{permissionObj?.includes(47) ? (
															<p
																id={holiday.holiday_id}
																onClick={(e) => {
																	deleteHoliday(e, holiday);
																	DeleteInHoliday();
																}}
																color="danger"
															>
																Delete
															</p>
														) : null}

														<Modal
															ariaHideApp={false}
															isOpen={deleteOpen}
															onRequestClose={closedModal}
															style={customStyles2}
															contentLabel="Example Modal"
														>
															<h5>
																Are you sure you want to delete current record ?
															</h5>
															<div style={{ float: 'right' }}>
																<Button
																	color="danger"
																	type="submit"
																	onClick={deleteCurrentHoliday}
																>
																	Delete
																</Button>
																&nbsp;&nbsp;&nbsp;
																<Button
																	color="secondary"
																	onClick={() => setDelete(false)}
																>
																	Cancel
																</Button>
															</div>
														</Modal>
													</div>
												</div>
											</td>
										</tr>
									))
								) : (
									<p className={classes.headertekst}>{null}</p>
								)}
							</tbody>
						</Table>
						<div>
							<Row>
								<Col md={6}>
									{holidays?.length ? (
										<p style={{ marginTop: '15px' }}>
											{countNumber * rows + 1} to{' '}
											{countNumber * rows + numberOfElements} of {totalcount}{' '}
											records
										</p>
									) : (
										<p className="formfooterrecordstyle">No Record Found</p>
									)}
								</Col>
								<Col md={6}>
									<div className={classes.root}>
										{searchdata || datefilter ? (
											<Pagination
												page={paginationPageNo}
												count={getPageNo}
												onChange={submitSearch}
											/>
										) : (
											<Pagination
												page={paginationPageNo}
												count={getPageNo}
												onChange={handlepage}
											/>
										)}
									</div>
								</Col>
							</Row>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HolidayList;
