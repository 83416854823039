// import { configureStore } from '@reduxjs/toolkit'
// import { combineReducers } from 'redux'
// import { persistStore, persistReducer } from 'redux-persist'
// import storage from 'redux-persist/lib/storage'

// import user from './user'

// const reducer = combineReducers({
//     user,
// })

// const store = configureStore({
//     reducer,
// })
// export default store;

import {
    combineReducers,
    configureStore,
    getDefaultMiddleware,
  } from "@reduxjs/toolkit";
  import empReducer from "../components/features/empSlice";
  import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
  } from "redux-persist";
  import storage from "redux-persist/lib/storage";
  
  const persistConfig = {
    key: "root",
    storage,
    whitelist: ["emp"],
  };
  const reducers = combineReducers({
    emp: empReducer,
  });
  
  const persistedReducer = persistReducer(persistConfig, reducers);
  
  export const store = configureStore({
    reducer: persistedReducer,
  
    middleware: getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  });
  export const persistor = persistStore(store);
  