import React , { useState, useEffect }from 'react';
import { ToastContainer, toast } from "react-toastify";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Link, useNavigate } from "react-router-dom";
import { CardText, Col, Row, Button, Table } from "reactstrap";
import AddIcon from "@mui/icons-material/Add";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Pagination from "@mui/material/Pagination";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import { customStyles, useStyles, customStyles2 } from "./../../Utilities/CSSUtilities";
import axios from "axios";
import base_url from '../../api/bootapi';
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { logout, selectEmployee } from "../../components/features/empSlice";
import DateRangePicker from "react-bootstrap-daterangepicker";
import FormControl from '@mui/material/FormControl';


const DeclinedLeaveApplication = () => {
  const [backdropOpen, setBackdropOpen] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const jwtToken = "JWT " + localStorage.getItem("JWTToken");
  const [leaveData,setLeaveData] =useState([]);
  const employeData = useSelector(selectEmployee);
  const [pageNo, setPageNo] = useState(1);
  const [paginationPageNo, setPaginationPageNo] = useState(1);
  const [getPageNo, setGetPageNo] = useState(0);
  const [rows, setRows] = useState(3);
  const [countNumber, setCountNumber] = useState();
  const [numberOfElements, setNumberOfElements] = useState();
  const [totalCount, setTotalCount] = useState();
  const [searchData, setSearchData] = useState();
  const [searchStatus, setSearchStatus] = useState();
  const [dateFilter, setDateFilter] = useState();
  const [dateStatus, setDateStatus] = useState(false);
  const classes = useStyles();
  const [descContent, setDescContent] = useState();
  const [descModelOpen, setDescModelOpen] = useState(false);
  const navigate = useNavigate();
  useEffect( () => {
    getPermissions();
  },[rows]);

  const handleRows = (event) => {
    setRows(event.target.value);
  };

  const getPermissions = () => {
    const resource_obj = [];

    for (let i = 0; i < employeData.role_permissions.length; i++) {
      resource_obj.push(employeData.role_permissions[i].resource_id);
    }
    var available_resource = resource_obj.includes(41);

    if(available_resource) {
      if (localStorage.getItem("LeavePageNo")) {
        let currentPage = localStorage.getItem("LeavePageNo");
        setPageNo(currentPage);
        setPaginationPageNo(Number(currentPage));
        getAllLeaveList(currentPage);
      } else {
        setPageNo(1);
        setPaginationPageNo(1);
        getAllLeaveList(1);
      }
    } else {
      navigate('/errorPage');
    }
  };


  const getAllLeaveList = (value1) => {
    setBackdropOpen(true);
    if((employeData.emp_role_id == 1) || (employeData.emp_role_id == 2) || (employeData.emp_role_id == 3)) {
      axios
        .get(`${base_url.api1}/myappapi/leaveapp/list?company_code=`+employeData.company_code+`&page=`+value1+`&size=`+rows+`&status=0`, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then(
          (response) => {
            setGetPageNo(response.data.total_pages);
            setLeaveData(response.data.leaveapp);
            setCountNumber(value1-1);
            setTotalCount(response.data.total_count);
            setNumberOfElements(response.data.leaveapp.length);
            setSearchStatus(false);
            setBackdropOpen(false);
          },
          (error) => {
            setGetPageNo(0);
            setLeaveData();
            setCountNumber(0);
            setNumberOfElements(0);
            setSearchStatus(false);
            setBackdropOpen(false);
            console.log(JSON.stringify(error.response.data));
          }
        );
    } else {
      axios
        .get(`${base_url.api1}/myappapi/leaveapp/list?company_code=`+employeData.company_code+`&emp_id=`+employeData.emp_id+`&page=`+value1+`&size=`+rows+`&status=0`, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then(
          (response) => {
            setGetPageNo(response.data.total_pages);
            setLeaveData(response.data.leaveapp);
            setCountNumber(value1-1);
            setTotalCount(response.data.total_count);
            setNumberOfElements(response.data.leaveapp.length);
            setSearchStatus(false);
            setBackdropOpen(false);
          },
          (error) => {
            setGetPageNo(0);
            setLeaveData();
            setCountNumber(0);
            setNumberOfElements(0);
            setSearchStatus(false);
            setBackdropOpen(false);
            console.log(JSON.stringify(error.response.data));
          }
        );
    }
  };

  const [dateValue, setDateValue] = useState("Click to open");
  const handleApply = (event, picker, value1) => {
    setBackdropOpen(true);
    setDateValue(
      `${picker.startDate.format("DD-MM-YYYY")} to ${picker.endDate.format(
        "DD-MM-YYYY"
      )}`
    );
    let data = {
      from: picker.startDate.format("YYYY-MM-DD"),
      to: picker.endDate.format("YYYY-MM-DD"),
    };
    setDateFilter(data);
    setDateStatus(true);

    if((employeData.emp_role_id == 1) || (employeData.emp_role_id == 2) || (employeData.emp_role_id == 3)) {
      axios
        .get(`${base_url.api1}/myappapi/leaveapp/date?company_code=`+employeData.company_code+`&from=`+data.from+`&to=`+data.to+`&page=`+value1+`&size=`+rows+`&status=0`, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then(
          (response) => {
            setGetPageNo(response.data.total_pages);
            setLeaveData(response.data.Leaves);
            setCountNumber(value1-1);
            setTotalCount(response.data.total_count);
            setNumberOfElements(response.data.Leaves.length);
            setSearchStatus(false);
            setBackdropOpen(false);
          },
          (error) => {
            setGetPageNo(0);
            setLeaveData();
            setCountNumber(0);
            setNumberOfElements(0);
            setSearchStatus(false);
            setBackdropOpen(false);
          }
        );
    } else {
      axios
      .get(`${base_url.api1}/myappapi/leaveapp/date?company_code=`+employeData.company_code+`&from=`+data.from+`&to=`+data.to+`&page=`+value1+`&size=`+rows+`&emp_id=`+employeData.emp_id+`&status=0`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setGetPageNo(response.data.total_pages);
          setLeaveData(response.data.Leaves);
          setCountNumber(value1-1);
          setTotalCount(response.data.total_count);
          setNumberOfElements(response.data.Leaves.length);
          setSearchStatus(false);
          setBackdropOpen(false);
        },
        (error) => {
          setGetPageNo(0);
          setLeaveData();
          setCountNumber(0);
          setNumberOfElements(0);
          setSearchStatus(false);
          setBackdropOpen(false);
        }
      );
    }
  };

  const handlePageApply = (value1) => {
    setBackdropOpen(true);
    if((employeData.emp_role_id == 1) || (employeData.emp_role_id == 2) || (employeData.emp_role_id == 3)) {
      axios
        .get(`${base_url.api1}/myappapi/leaveapp/date?company_code=`+employeData.company_code+`&from=`+dateFilter.from+`&to=`+dateFilter.to+`&page=`+value1+`&size=`+rows+`&status=0`, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then(
          (response) => {
            setGetPageNo(response.data.total_pages);
            setLeaveData(response.data.Leaves);
            setCountNumber(value1-1);
            setTotalCount(response.data.total_count);
            setNumberOfElements(response.data.Leaves.length);
            setSearchStatus(false);
            setBackdropOpen(false);
          },
          (error) => {
            setGetPageNo(0);
            setLeaveData();
            setCountNumber(0);
            setNumberOfElements(0);
            setSearchStatus(false);
            setBackdropOpen(false);
          }
        );
    } else {
      axios
      .get(`${base_url.api1}/myappapi/leaveapp/date?company_code=`+employeData.company_code+`&from=`+dateFilter.from+`&to=`+dateFilter.to+`&page=`+value1+`&size=`+rows+`&emp_id=`+employeData.emp_id+`&status=0`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setGetPageNo(response.data.total_pages);
          setLeaveData(response.data.Leaves);
          setCountNumber(value1-1);
          setTotalCount(response.data.total_count);
          setNumberOfElements(response.data.Leaves.length);
          setSearchStatus(false);
          setBackdropOpen(false);
        },
        (error) => {
          setGetPageNo(0);
          setLeaveData();
          setCountNumber(0);
          setNumberOfElements(0);
          setSearchStatus(false);
          setBackdropOpen(false);
        }
      );
    }
  };

  const handlePage = (event, value) => {
    setPaginationPageNo(value);
    setPageNo(value);
    if(searchStatus) {
      console.log('Coming Search');
      submitPageSearch(value);
    } else if(dateStatus) {
      console.log('Coming Date');
      handlePageApply(value);
    } else {
      console.log('Coming Normal');
      getAllLeaveList(value);
    }
  };

  const submitSearch = (e) => {
    setSearchStatus(true);
    let srchdata = e.target[0].value.trim();
    setSearchData(srchdata);
    setBackdropOpen(true);
    if((employeData.emp_role_id == 1) || (employeData.emp_role_id == 2) || (employeData.emp_role_id == 3)) {
      axios
        .get(`${base_url.api1}/myappapi/leaveapp/list?company_code=`+employeData.company_code+`&page=1&size=`+rows+`&query=`+srchdata+`&status=0`, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then(
          (response) => {
            setGetPageNo(response.data.total_pages);
            setLeaveData(response.data.leaveapp);
            setCountNumber(0);
            setTotalCount(response.data.total_count);
            setNumberOfElements(response.data.leaveapp.length);
            setBackdropOpen(false);
          },
          (error) => {
            setGetPageNo(0);
            setLeaveData();
            setCountNumber(0);
            setNumberOfElements(0);
            setBackdropOpen(false);
            console.log(JSON.stringify(error.response.data));
          }
        );
    } else {
      axios
        .get(`${base_url.api1}/myappapi/leaveapp/list?company_code=`+employeData.company_code+`&emp_id=`+employeData.emp_id+`&page=1&size=`+rows+`&query=`+srchdata+`&status=0`, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then(
          (response) => {
            setGetPageNo(response.data.total_pages);
            setLeaveData(response.data.leaveapp);
            setCountNumber(0);
            setTotalCount(response.data.total_count);
            setNumberOfElements(response.data.leaveapp.length);
            setBackdropOpen(false);
          },
          (error) => {
            setGetPageNo(0);
            setLeaveData();
            setCountNumber(0);
            setNumberOfElements(0);
            setBackdropOpen(false);
            console.log(JSON.stringify(error.response.data));
          }
        );
    }
  };

  const submitPageSearch = (value1) => {
    setSearchStatus(true);
    setBackdropOpen(true);
    if((employeData.emp_role_id == 1) || (employeData.emp_role_id == 2) || (employeData.emp_role_id == 3)) {
      axios
        .get(`${base_url.api1}/myappapi/leaveapp/list?company_code=`+employeData.company_code+`&page=`+value1+`&size=`+rows+`&query=`+searchData+`&status=0`, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then(
          (response) => {
            setGetPageNo(response.data.total_pages);
            setLeaveData(response.data.leaveapp);
            setCountNumber(0);
            setTotalCount(response.data.total_count);
            setNumberOfElements(response.data.leaveapp.length);
            setBackdropOpen(false);
          },
          (error) => {
            setGetPageNo(0);
            setLeaveData();
            setCountNumber(0);
            setNumberOfElements(0);
            setBackdropOpen(false);
            console.log(JSON.stringify(error.response.data));
          }
        );
    } else {
      axios
        .get(`${base_url.api1}/myappapi/leaveapp/list?company_code=`+employeData.company_code+`&emp_id=`+employeData.emp_id+`&page=1&size=`+rows+`&query=`+searchData+`&status=0`, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then(
          (response) => {
            setGetPageNo(response.data.total_pages);
            setLeaveData(response.data.leaveapp);
            setCountNumber(0);
            setTotalCount(response.data.total_count);
            setNumberOfElements(response.data.leaveapp.length);
            setBackdropOpen(false);
          },
          (error) => {
            setGetPageNo(0);
            setLeaveData();
            setCountNumber(0);
            setNumberOfElements(0);
            setBackdropOpen(false);
            console.log(JSON.stringify(error.response.data));
          }
        );
    }
  };

  return (
    <>
    <div>
      <ToastContainer />
      <Backdrop open={backdropOpen}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="commoncomponent_cardcss">
        <div>
          <Row>
            <Col md={6}>
              <CardText
                style={{ marginTop: "-10px" }}
              >
                <h3>Declined Leave Application</h3>
              </CardText>
            </Col>
          </Row>
          <div className="newlisting_headertabdesign">
            <Row>
              <Col md={6}>
              <Row>
              <form className="master">
                  <div style={{ marginTop: "-5px" }}>
                    <label className="area_rowslable">Limit</label>
                    <br />
                    {/* <FormControl className={classes.formControl}> */}
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 80 ,marginTop: 0, maxHeight: 80}}>
                      <select
                        className={classes.selectlimit}
                        labelId="simple-select-label"
                        id="simple-select"
                        value={rows}
                        onChange={handleRows}
                      >
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </FormControl>
                  </div>
                </form>
                <form className="form-group">
                  <div style={{
                    display: "flex",
                    justify : "space-between",
                    flexWrap: "wrap",
                  }}>
                    <div style={{ marginTop: "-20px" }}>
                      <label className="area_rowslable">Date</label>
                      <DateRangePicker
                        onApply={(event, picker)=>handleApply(event, picker,1)}
                      >
                        <p className="datepickerrangevaluetext">{dateValue}</p>
                      </DateRangePicker>
                      {dateStatus ? (
                        <div style={{ float: "left" }} >
                          <a onClick={()=>{
                            getAllLeaveList(1);
                            setDateValue("Click to open");
                            setDateStatus(false);
                          }}
                          >Clear Filter</a>
                        </div>
                      ) : null} 
                    </div>
                  </div>
                </form>
                </Row>
                <br />
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <div>
                    <div className="clearsearchclass">
                      <form
                        id="myForm"
                        className="d-flex"
                        onSubmit={(e) => {
                          e.preventDefault();
                          submitSearch(e);
                        }}
                      >
                        <input
                          style={{
                            padding: "5px",
                            border: "2px solid",
                            marginTop: "-1px",
                          }}
                          className="form-control"
                          placeholder="Search.."
                          name="search"
                          required
                        />
                        <button
                          className={classes.srchbtn}
                          type="submit"
                        >
                          <i className="fa fa-search"></i>
                        </button>
                      </form>
                      {searchStatus ? (
                        <a className={classes.clrsrch}
                          onClick={()=>{
                            getAllLeaveList(1);
                            setSearchStatus(false);
                          }}
                        >Clear Search</a>
                      ) : null}
                    </div>                      
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <br/>
          <div className="newlisting_headertabdesign">
            <Table responsive striped>
              <thead>
                <tr>
                  <th style={{ width: "50px" }}>SN</th>
                  <th style={{ width: "175px" }}>
                    Employee Name{" "}
                    <UnfoldMoreIcon id="emp_name"/>
                  </th>
                  <th style={{ width: "175px" }}>
                    From Date{" "}
                    <UnfoldMoreIcon id="from_date"/>
                  </th>
                  <th style={{ width: "175px" }}>
                    To Date{" "}
                    <UnfoldMoreIcon id="to_date"/>
                  </th>
                  <th style={{ width: "175px" }}>
                    Type Of Leave{" "}
                    <UnfoldMoreIcon id="type"/>
                  </th>
                  <th style={{ width: "175px" }}>
                     Declined By{" "}
                    <UnfoldMoreIcon id="approved_by"/>
                  </th>
                  <th style={{ width: "175px" }}>
                    Reason{" "}
                    <UnfoldMoreIcon id="status"/>
                  </th>
                </tr>
              </thead>
              <tbody>
                {leaveData != null ? (
                  leaveData &&
                  leaveData.map((leave , index) => (
                    <tr>
                      <td>{countNumber * rows + 1 + index}</td>
                      <td>{leave.emp_name}</td>
                      <td>
                        {leave.from_date?
                          moment(leave.from_date.split(".")[0]).format("DD MMM YYYY")
                        : null}
                      </td>
                      <td>
                        {leave.to_date?
                          moment(leave.to_date.split(".")[0]).format("DD MMM YYYY")
                        : null}
                      </td> 
                      <td>{leave.type}</td>
                      <td>{leave.approved_by}</td>
                      <td className="leaveEllipsis">
                        {leave.description}
                        {leave.description != null ? (
                          <span
                            className="leaveEllipsisspan"
                            onClick={() => {
                              setDescModelOpen(true);
                              setDescContent(leave.description);
                            }}
                          >
                            View More
                          </span>
                        ) : null}
                        <Modal
                          ariaHideApp={false}
                          isOpen={descModelOpen}
                          onRequestClose={() => setDescModelOpen(false)}
                          style={customStyles2}
                          contentLabel="Example Modal"
                        >
                          <h4>Reason</h4>
                          {descContent ? (
                            <textarea
                              style={{ minWidth: "600px" }}
                              className="form-control"
                              value={descContent}
                              rows={10}
                              readonly
                            />
                          ) : (
                            <p> No Record Available</p>
                          )}
                          <div
                            style={{
                              display: "flex",
                              margin: "10px 0",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              color="secondary"
                              onClick={() => setDescModelOpen(false)}
                            >
                              Close
                            </Button>
                          </div>
                        </Modal>
                      </td>
                    </tr>
                ))) : (
                  <p className={classes.headertekst}>{null}</p>
                )}
            </tbody>
            </Table>
          </div>
          <Row>
            <Col md={6}>
              {leaveData?.length ? (
                <p style={{ marginTop: "15px" }}>
                  {countNumber * rows + 1} to{" "}
                  {countNumber * rows + numberOfElements} of {totalCount} records
                </p>
              ) : (
                <p className="formfooterrecordstyle">No Record Found</p>
              )}
            </Col>
            <Col md={6}>
              <div className={classes.root}>
                {/* {searchData || dateFilter ? (
                  <Pagination
                    page={paginationPageNo}
                    count={getPageNo}
                    // onChange={handlesearch}
                  />
                ) : ( */}
                  <Pagination
                    page={paginationPageNo}
                    count={getPageNo}
                    onChange={handlePage}
                  />
                {/* )} */}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
    </>
  );
};

export default DeclinedLeaveApplication;