import React, { useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import Home from './../../Images/newhome.png';
import Employee from './../../Images/newemployee.png';
import project from './../../Images/newproject.png';
import attend from './../../Images/newattend.png';
import mytask from './../../Images/newtask.png';
import alltask from './../../Images/tasks.png';
import holiday from './../../Images/newholiday.png';
import resign from './../../Images/newresignation.png';
import odisoftLogo from './../../Images/newlogo.png';
import leave from './../../Images/newleave.png';
import setting from './../../Images/newsetting.png';
import perform from './../../Images/newperformance.png';
import chat from './../../Images/chat.png';
import ToDoList from './../../Images/to-do-list.png';
import Configuration from './../../Images/configuration.png';
import { Navigate, useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';
// import { useStyles } from "./../../Utilities/CSSUtilities";
import axios from 'axios';
import Modal from 'react-modal';
import { useForm } from 'react-hook-form';
import base_url from '../../api/bootapi';
import moment from 'moment';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {
	setStarted,
	selectStarted,
	setStopped,
	selectStopped,
	logout,
	selectEmployee,
	setSessionIdData,
	selectSessionId,
	setOnBreak,
	selectOnBreak,
} from '../../components/features/empSlice';
import { useDispatch, useSelector } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useToasts } from 'react-toast-notifications';
import { customStyles, useStyles } from './../../Utilities/CSSUtilities';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import StarBorder from '@mui/icons-material/StarBorder';
import CallAuditapi from '../services/auditservice';
import permissionData from './permissionData';

const drawerWidth = 240;

const openedMixin = (theme) => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
});

const closedMixin = (theme) => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
});

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	...(open && {
		...openedMixin(theme),
		'& .MuiDrawer-paper': openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		'& .MuiDrawer-paper': closedMixin(theme),
	}),
}));

export default function NewDash({ Comp, sidebarvalue }) {
	const jwtToken = 'JWT ' + localStorage.getItem('JWTToken');
	const dispatch = useDispatch();
	let navigate = useNavigate();
	const { addToast } = useToasts();
	const employeeData = useSelector(selectEmployee);
	const EmpIpAddress = localStorage.getItem('EmpIpAddress');
	const [show, setShow] = useState(permissionData);

	useEffect(() => {
		window.addEventListener('storage', function (e) {
			if (e.key === null) {
				dispatch(logout());
				navigate('/');
			}
		});
		hideListItems(employeeData.role_permissions);
		setTimeout(() => {
			getPermissions();
		}, 3000);
	}, []);

	const getPermissions = () => {
		let value = 'JWT ' + localStorage.getItem('JWTToken');
		axios
			.get(`${base_url.api1}/myappapi/users/logout`, {
				headers: {
					Authorization: value,
				},
			})
			.then(
				(response) => {
					console.log(response);
					//console.log(employeeData.emp_email);
				},
				(error) => {
					let err = JSON.stringify(error.response.status);
					if (err == 401) {
						dispatch(logout());
						localStorage.clear();
						navigate('/');
						addToast(`${employeeData.emp_name} Logged Out.`, {
							appearance: 'success',
							autoDismiss: true,
						});
					}
					console.log(JSON.stringify(error.response.status));
				}
			);
	};

	const roleId_obj = [];
	const hideListItems = (elem) => {
		for (let i = 0; i < elem.length; i++) {
			const element = elem[i];
			roleId_obj.push(element.resource_id);
		}

		for (let i = 0; i <= 82; i++) {
			let match = roleId_obj.find((item) => {
				if (i == item) {
					let name = `show${i}`;
					setShow((prev) => {
						return {
							...prev,
							[name]: true,
						};
					});
				}
			});
		}
	};

	const theme = useTheme();

	const [backdropOpen, setBackdropOpen] = useState(false);
	const [open, setOpen] = React.useState(false);
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	// console.log(employeeData.photo.split('/var/www/crm.odisoft.in/')[1]);
	// const userImage = base_url.file1 + employeeData.photo.split('/var/www/crm.odisoft.in/')[1];
	// console.log(userImage);
	let userImage;
	if (employeeData.photo) {
		userImage = base_url.file1 + employeeData.photo;
		console.log(userImage);
	}
	const startedData = useSelector(selectStarted);
	const stoppedData = useSelector(selectStopped);
	const sessionIdData = useSelector(selectSessionId);
	const breakData = useSelector(selectOnBreak);

	const isoDateString = new Date().toISOString();
	const datenow = isoDateString.split('.')[0];

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const [openLeave, setOpenLeave] = React.useState(false);
	const handleClickLeave = () => {
		setOpenLeave(!openLeave);
	};

	const [openSettings, setOpenSettings] = React.useState(false);
	const handleClickSettings = () => {
		setOpenSettings(!openSettings);
	};

	const [openAttendance, setOpenAttendance] = React.useState(false);
	const handleClickAttendance = () => {
		setOpenAttendance(!openAttendance);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	const classes = useStyles();

	const [sessionId, setSessionId] = useState();
	const [startDate, setStartDate] = useState();
	const [sessionDate, setsSessionDate] = useState(datenow);
	const [sumOfBreak, setsumOfBreak] = useState([]);

	const startOffice = () => {
		const d = new Date();
		let diff = d.getTimezoneOffset();
		const datenow1 = moment(d).format('YYYY-MM-DDTH:mm:ss');
		let session_date = datenow1;
		if (startedData == 0) {
			let data = {
				emp_id: employeeData.emp_id,
				emp_name: employeeData.emp_name,
				company_id: employeeData.company_id,
				start_time: datenow1,
				end_time: null,
				ip_address: EmpIpAddress,
				session_date: session_date,
				emp_email: employeeData.emp_email,
			};
			setBackdropOpen(true);
			axios
				.post(
					`${base_url.api1}/office_app/attendance?company_code=` +
						employeeData.company_code,
					data,
					{
						headers: {
							Authorization: jwtToken,
						},
					}
				)
				.then((response) => {
					if (response.data.status_code == 201) {
						setBackdropOpen(false);
						dispatch(setStarted(1));
						dispatch(setStopped(0));
						dispatch(setOnBreak(0));
						addToast(`${employeeData.emp_name} Started Working Again.`, {
							appearance: 'success',
							autoDismiss: true,
						});
					} else {
						setBackdropOpen(false);
						setStartDate(
							response.data.data.start_time.slice(0, 10).replace('T', '')
						);
						setSessionId(response.data.data.id);
						dispatch(setSessionIdData(response.data.data.id));
						dispatch(setStarted(1));
						dispatch(setStopped(0));
						dispatch(setOnBreak(0));
						addToast(`${employeeData.emp_name} Started Working`, {
							appearance: 'success',
							autoDismiss: true,
						});
						let log = employeeData.emp_name + ' started working.';
						CallAuditapi(log);
					}
				})
				.catch((error) => {
					setBackdropOpen(false);
					let errorData = JSON.stringify(error);
					// addToast(`${errorData.response.data}`, {
					//   appearance: "error",
					//   autoDismiss: true,
					// });
				});
		} else {
			setBackdropOpen(false);
			addToast(`Already Started`, {
				appearance: 'success',
				autoDismiss: true,
			});
		}
	};

	const DeleteIndUSer = () => {
		setDelete(true);
	};
	const closeModal = () => {
		setDelete(false);
	};

	const [deleteOpen, setDelete] = React.useState(false);

	const breakOffice = () => {
		const d = new Date();
		let diff = d.getTimezoneOffset();
		const datenow2 = moment(d).format('YYYY-MM-DDTH:mm:ss');
		setBackdropOpen(true);
		let session_date = sessionDate.split('T')[0];
		console.log(session_date);
		if (startedData == 0) {
			setBackdropOpen(false);
			addToast(`Not Yet Started!`, {
				appearance: 'error',
				autoDismiss: true,
			});
		} else {
			if (stoppedData == 0) {
				let data = {
					session_date: sessionDate + '.000000Z',
					end_time: datenow2 + '.000000Z',
					emp_id: employeeData.emp_id,
				};

				axios
					.put(
						`${base_url.api1}/office_app/attendance?company_code=` +
							employeeData.company_code +
							`&session_date=` +
							session_date +
							`&emp_id=` +
							employeeData.emp_id,
						data,
						{
							headers: {
								Authorization: jwtToken,
							},
						}
					)
					.then((response) => {
						setBackdropOpen(false);
						dispatch(setStopped(0));
						dispatch(setSessionIdData(0));
						dispatch(setStarted(0));
						dispatch(setOnBreak(1));
						setBackdropOpen(false);
						addToast(`${employeeData.emp_name} Break Start`, {
							appearance: 'success',
							autoDismiss: true,
						});
					})
					.catch((error) => {
						setBackdropOpen(false);
						let errorData = JSON.stringify(error);
						addToast(`${errorData.response.data}`, {
							appearance: 'error',
							autoDismiss: true,
						});
					});
			} else {
				setBackdropOpen(false);
				addToast(`Already Stopped!`, {
					appearance: 'error',
					autoDismiss: true,
				});
			}
		}
	};

	const stopOffice = () => {
		const d = new Date();
		let diff = d.getTimezoneOffset();
		const datenow2 = moment(d).format('YYYY-MM-DDTH:mm:ss');
		setBackdropOpen(true);
		let session_date = sessionDate.split('T')[0];

		// if(startedData==0) {
		//   setBackdropOpen(false);
		//   addToast(`Not Yet Started!`, {
		//     appearance: "error",
		//     autoDismiss: true,
		//   });
		// } else {
		if (stoppedData == 0) {
			let data = {
				session_date: sessionDate + '.000000Z',
				end_time: datenow2 + '.000000Z',
				emp_id: employeeData.emp_id,
			};

			axios
				.put(
					`${base_url.api1}/office_app/attendance?company_code=` +
						employeeData.company_code +
						`&session_date=` +
						session_date +
						`&emp_id=` +
						employeeData.emp_id,
					data,
					{
						headers: {
							Authorization: jwtToken,
						},
					}
				)
				.then((response) => {
					setDelete(false);
					setBackdropOpen(false);
					dispatch(setStopped(0));
					dispatch(setSessionIdData(0));
					dispatch(setStarted(0));
					dispatch(setOnBreak(0));
					setsumOfBreak(response.data.data.breaks);
					addToast(`${employeeData.emp_name} Stopped Working.`, {
						appearance: 'success',
						autoDismiss: true,
					});
					let log = employeeData.emp_name + ' stoped working.';
					CallAuditapi(log);
				})
				.catch((error) => {
					setDelete(false);
					setBackdropOpen(false);
					let errorData = JSON.stringify(error);
					addToast(`${errorData.response.data}`, {
						appearance: 'error',
						autoDismiss: true,
					});
				});
		} else {
			setDelete(false);
			setBackdropOpen(false);
			addToast(`Already Stopped!`, {
				appearance: 'error',
				autoDismiss: true,
			});
		}
		// }
	};

	const [openAccountMenu, setOpenAccountMenu] = useState(null);
	const handleAccountOpen = (event) => setOpenAccountMenu(event.currentTarget);
	const handleAccountClose = () => setOpenAccountMenu(null);
	const handleLogout = () => {
		dispatch(setStopped(0));
		dispatch(setSessionIdData(0));
		dispatch(setStarted(0));
		dispatch(setOnBreak(0));
		let log = employeeData.emp_name + ' Logged Out.';
		CallAuditapi(log);
		dispatch(logout());
		navigate(`/`);
		addToast(`${employeeData.emp_name} Logged Out.`, {
			appearance: 'success',
			autoDismiss: true,
		});
	};

	return (
		<Box sx={{ display: 'flex' }}>
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={backdropOpen}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<CssBaseline />
			<AppBar position="fixed" open={open}>
				<Toolbar>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						onClick={handleDrawerOpen}
						edge="start"
						sx={{
							marginRight: 5,
							...(open && { display: 'none' }),
						}}
					>
						<MenuIcon />
					</IconButton>
					<Box display="flex" flexGrow={1}>
						{/* whatever is on the left side */}
						<Typography variant="h6" noWrap component="div">
							Employee Management
						</Typography>
					</Box>
					{/* whatever is on the right side */}
					Date : {moment(datenow.split('T')[0]).format('MMMM Do YYYY')}
					&nbsp;&nbsp;
					{startedData == 0 ? (
						<Button className="btn mr-3" color="primary" onClick={startOffice}>
							Start
						</Button>
					) : (
						<Button className="btn mr-3" color="primary" onClick={breakOffice}>
							Pause
						</Button>
					)}
					&nbsp;&nbsp;
					<Button
						className="btn mr-3"
						color="danger"
						onClick={(e) => {
							e.preventDefault();
							DeleteIndUSer();
						}}
					>
						Stop
					</Button>
					<Modal
						ariaHideApp={false}
						isOpen={deleteOpen}
						onRequestClose={closeModal}
						style={customStyles}
						contentLabel="Example Modal"
					>
						<h5>Are you sure you want to Stop ?</h5>
						<div style={{ float: 'right' }}>
							<Button color="danger" type="submit" onClick={stopOffice}>
								Stop
							</Button>
							&nbsp;&nbsp;&nbsp;
							<Button color="secondary" onClick={() => setDelete(false)}>
								Back
							</Button>
						</div>
					</Modal>
					&nbsp;&nbsp;
					{employeeData.photo === null ? (
						<AccountCircleIcon onClick={handleAccountOpen} />
					) : (
						<img
							src={userImage}
							width="30"
							height="30"
							style={{ borderRadius: '100px' }}
							onClick={handleAccountOpen}
						/>
					)}
					<Menu
						id="simple-menu"
						anchorEl={openAccountMenu}
						keepMounted
						open={Boolean(openAccountMenu)}
						onClose={handleAccountClose}
					>
						{/* <MenuItem onClick={() => navigate("/profile")}>Profile</MenuItem> */}
						{/* <MenuItem onClick={() => navigate("/userprofile/"+employeeData.emp_id)}>User Profile</MenuItem> */}
						{/* <AdminChangePassword handleAccountClose={handleAccountClose} /> */}
						<MenuItem
							onClick={() => {
								navigate('/user-prof/' + employeeData.emp_id);
								handleAccountClose();
							}}
						>
							Profile
						</MenuItem>

						<MenuItem onClick={handleLogout}>Logout</MenuItem>
					</Menu>
				</Toolbar>
			</AppBar>
			<Drawer variant="permanent" open={open}>
				<DrawerHeader>
					<img alt="Odisoft_logo" src={odisoftLogo} width="150" height="30" />
					<IconButton onClick={handleDrawerClose}>
						{theme.direction === 'rtl' ? (
							<ChevronRightIcon />
						) : (
							<ChevronLeftIcon />
						)}
					</IconButton>
				</DrawerHeader>
				<Divider />
				<List>
					{show.show0 ? (
						<ListItemButton
							onClick={() => navigate('/')}
							key="Dashboard"
							sx={{
								minHeight: 48,
								justifyContent: open ? 'initial' : 'center',
								px: 2.5,
							}}
						>
							<ListItemIcon
								sx={{
									minWidth: 0,
									mr: open ? 3 : 'auto',
									justifyContent: 'center',
								}}
							>
								<img src={Home} width="35" height="35" />
							</ListItemIcon>
							<ListItemText
								primary="Dashboard"
								sx={{ opacity: open ? 1 : 0 }}
							/>
						</ListItemButton>
					) : null}
					{show.show1 &&
					(show.show80 ||
						show.show2 ||
						show.show3 ||
						show.show4 ||
						show.show5 ||
						show.show6 ||
						show.show7) ? (
						<ListItemButton
							onClick={() => navigate('/employee-list')}
							key="Employees"
							sx={{
								minHeight: 48,
								justifyContent: open ? 'initial' : 'center',
								px: 2.5,
							}}
						>
							<ListItemIcon
								sx={{
									minWidth: 0,
									mr: open ? 3 : 'auto',
									justifyContent: 'center',
								}}
							>
								<img src={Employee} width="35" height="35" />
							</ListItemIcon>
							<ListItemText
								primary="Employees"
								sx={{ opacity: open ? 1 : 0 }}
							/>
						</ListItemButton>
					) : null}
					{show.show8 &&
					(show.show9 ||
						show.show10 ||
						show.show11 ||
						show.show12 ||
						show.show13 ||
						show.show14 ||
						show.show15 ||
						show.show16 ||
						show.show17 ||
						show.show18) ? (
						<ListItemButton
							onClick={() => navigate('/projects')}
							key="Projects"
							sx={{
								minHeight: 48,
								justifyContent: open ? 'initial' : 'center',
								px: 2.5,
							}}
						>
							<ListItemIcon
								sx={{
									minWidth: 0,
									mr: open ? 3 : 'auto',
									justifyContent: 'center',
								}}
							>
								<img src={resign} width="35" height="35" />
							</ListItemIcon>
							<ListItemText primary="Projects" sx={{ opacity: open ? 1 : 0 }} />
						</ListItemButton>
					) : null}
					{show.show78 ? (
						<ListItemButton
							onClick={() => navigate('/my-tasks')}
							key="MyTasks"
							sx={{
								minHeight: 48,
								justifyContent: open ? 'initial' : 'center',
								px: 2.5,
							}}
						>
							<ListItemIcon
								sx={{
									minWidth: 0,
									mr: open ? 3 : 'auto',
									justifyContent: 'center',
								}}
							>
								<img src={mytask} width="35" height="35" />
							</ListItemIcon>
							<ListItemText primary="My Tasks" sx={{ opacity: open ? 1 : 0 }} />
						</ListItemButton>
					) : null}

					{show.show82 ? (
						<ListItemButton
							onClick={() => navigate('/all-tasks')}
							key="AllTasks"
							sx={{
								minHeight: 48,
								justifyContent: open ? 'initial' : 'center',
								px: 2.5,
							}}
						>
							<ListItemIcon
								sx={{
									minWidth: 0,
									mr: open ? 3 : 'auto',
									justifyContent: 'center',
								}}
							>
								<img src={alltask} width="35" height="35" />
							</ListItemIcon>
							<ListItemText
								primary="All Tasks"
								sx={{ opacity: open ? 1 : 0 }}
							/>
						</ListItemButton>
					) : null}

					{show.show19 && (show.show20 || show.show21) ? (
						<ListItemButton
							onClick={() => navigate('/resignation')}
							key="Resignation"
							sx={{
								minHeight: 48,
								justifyContent: open ? 'initial' : 'center',
								px: 2.5,
							}}
						>
							<ListItemIcon
								sx={{
									minWidth: 0,
									mr: open ? 3 : 'auto',
									justifyContent: 'center',
								}}
							>
								<img src={resign} width="35" height="35" />
							</ListItemIcon>
							<ListItemText
								primary="Resignation"
								sx={{ opacity: open ? 1 : 0 }}
							/>
						</ListItemButton>
					) : null}
					{show.show22 &&
					(show.show23 ||
						show.show24 ||
						show.show25 ||
						show.show26 ||
						show.show27 ||
						show.show28) ? (
						<ListItemButton
							onClick={() => navigate('/team')}
							key="Team"
							sx={{
								minHeight: 48,
								justifyContent: open ? 'initial' : 'center',
								px: 2.5,
							}}
						>
							<ListItemIcon
								sx={{
									minWidth: 0,
									mr: open ? 3 : 'auto',
									justifyContent: 'center',
								}}
							>
								<img src={project} width="35" height="35" />
							</ListItemIcon>
							<ListItemText primary="Team" sx={{ opacity: open ? 1 : 0 }} />
						</ListItemButton>
					) : null}
					{show.show29 &&
					((show.show30 && show.show31) ||
						show.show32 ||
						show.show33 ||
						show.show34 ||
						show.show35) ? (
						<>
							<ListItemButton
								onClick={handleClickAttendance}
								sx={{
									minHeight: 48,
									justifyContent: open ? 'initial' : 'center',
									px: 2.5,
								}}
							>
								<ListItemIcon
									sx={{
										minWidth: 0,
										mr: open ? 3 : 'auto',
										justifyContent: 'center',
									}}
								>
									<img src={attend} width="35" height="35" />
								</ListItemIcon>
								<ListItemText
									primary="Attendance"
									sx={{ opacity: open ? 1 : 0 }}
								/>
								{openAttendance ? <ExpandLess /> : <ExpandMore />}
							</ListItemButton>
							<Collapse in={openAttendance} timeout="auto" unmountOnExit>
								<List
									component="div"
									style={{ paddingLeft: '20%' }}
									disablePadding
								>
									{show.show30 && show.show31 ? (
										<ListItemButton sx={{ pl: 4 }}>
											<ListItemText
												primary="Attendance Report"
												onClick={() => navigate('/attendance-list')}
											/>
										</ListItemButton>
									) : null}
									{show.show32 ? (
										<ListItemButton sx={{ pl: 4 }}>
											<ListItemText
												primary="Absent List"
												onClick={() => navigate('/absent-list')}
											/>
										</ListItemButton>
									) : null}
									{show.show33 ? (
										<ListItemButton sx={{ pl: 4 }}>
											<ListItemText
												primary="OverTime List"
												onClick={() => navigate('/overtime-list')}
											/>
										</ListItemButton>
									) : null}
									{show.show34 ? (
										<ListItemButton sx={{ pl: 4 }}>
											<ListItemText
												primary="Break Exceed List"
												onClick={() => navigate('/breakexceed-list')}
											/>
										</ListItemButton>
									) : null}
									{show.show35 ? (
										<ListItemButton sx={{ pl: 4 }}>
											<ListItemText
												primary="Shift Incomplete List"
												onClick={() => navigate('/shiftincompleted-list')}
											/>
										</ListItemButton>
									) : null}
								</List>
							</Collapse>
						</>
					) : null}

					{show.show36 &&
					((show.show37 && (show.show38 || show.show39)) ||
						show.show40 ||
						show.show41 ||
						show.show42) ? (
						<>
							<ListItemButton
								onClick={handleClickLeave}
								sx={{
									minHeight: 48,
									justifyContent: open ? 'initial' : 'center',
									px: 2.5,
								}}
							>
								<ListItemIcon
									sx={{
										minWidth: 0,
										mr: open ? 3 : 'auto',
										justifyContent: 'center',
									}}
								>
									<img src={leave} width="35" height="35" />
								</ListItemIcon>
								<ListItemText
									primary="Leave Application"
									sx={{ opacity: open ? 1 : 0 }}
								/>
								{openLeave ? <ExpandLess /> : <ExpandMore />}
							</ListItemButton>
							<Collapse in={openLeave} timeout="auto" unmountOnExit>
								<List
									component="div"
									style={{ paddingLeft: '20%' }}
									disablePadding
								>
									{show.show37 && (show.show38 || show.show39) ? (
										<ListItemButton sx={{ pl: 4 }}>
											{/* <ListItemIcon>
                      <StarBorder />
                    </ListItemIcon> */}
											<ListItemText
												primary="Applications"
												onClick={() => navigate('/leave-application')}
											/>
										</ListItemButton>
									) : null}
									{show.show40 ? (
										<ListItemButton sx={{ pl: 4 }}>
											{/* <ListItemIcon>
                      <StarBorder />
                    </ListItemIcon> */}
											<ListItemText
												primary="Approved"
												onClick={() => navigate('/approved-leave-application')}
											/>
										</ListItemButton>
									) : null}
									{show.show41 ? (
										<ListItemButton sx={{ pl: 4 }}>
											{/* <ListItemIcon>
                      <StarBorder />
                    </ListItemIcon> */}
											<ListItemText
												primary="Declined"
												onClick={() => navigate('/declined-leave-application')}
											/>
										</ListItemButton>
									) : null}
									{show.show42 ? (
										<ListItemButton sx={{ pl: 4 }}>
											{/* <ListItemIcon>
                      <StarBorder />
                    </ListItemIcon> */}
											<ListItemText
												primary="Pending"
												onClick={() => navigate('/pending-leave-application')}
											/>
										</ListItemButton>
									) : null}
								</List>
							</Collapse>
						</>
					) : null}
					{show.show43 &&
					(show.show79 ||
						(show.show44 &&
							(show.show81 || show.show45 || show.show46 || show.show47)) ||
						(show.show48 && (show.show49 || show.show50 || show.show51)) ||
						(show.show52 && (show.show53 || show.show54 || show.show55)) ||
						(show.show56 && (show.show57 || show.show58 || show.show59)) ||
						(show.show60 && (show.show61 || show.show62 || show.show63)) ||
						(show.show64 &&
							(show.show65 || show.show66 || show.show67 || show.show68)) ||
						(show.show69 && (show.show70 || show.show71 || show.show72))) ? (
						<>
							<ListItemButton
								onClick={handleClickSettings}
								sx={{
									minHeight: 48,
									justifyContent: open ? 'initial' : 'center',
									px: 2.5,
								}}
							>
								<ListItemIcon
									sx={{
										minWidth: 0,
										mr: open ? 3 : 'auto',
										justifyContent: 'center',
									}}
								>
									<img src={setting} width="35" height="35" />
								</ListItemIcon>
								<ListItemText
									primary="Settings"
									sx={{ opacity: open ? 1 : 0 }}
								/>
								{openSettings ? <ExpandLess /> : <ExpandMore />}
							</ListItemButton>
							<Collapse in={openSettings} timeout="auto" unmountOnExit>
								<List
									component="div"
									style={{ paddingLeft: '20%' }}
									disablePadding
								>
									{/* <ListItemButton sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <StarBorder />
                  </ListItemIcon>
                  <ListItemText primary="AppConfig" onClick={()=> navigate("/appconfig")} />
                </ListItemButton> */}
									{show.show79 ? (
										<ListItemButton sx={{ pl: 4 }}>
											{/* <ListItemIcon>
                      <StarBorder />
                    </ListItemIcon> */}
											<ListItemText
												primary="Audit"
												onClick={() => navigate('/audit-log')}
											/>
										</ListItemButton>
									) : null}
									{show.show44 &&
									(show.show81 || show.show45 || show.show46 || show.show47) ? (
										<ListItemButton sx={{ pl: 4 }}>
											{/* <ListItemIcon>
                      <StarBorder />
                    </ListItemIcon> */}
											<ListItemText
												primary="Holiday"
												onClick={() => navigate('/holiday-list')}
											/>
										</ListItemButton>
									) : null}
									{show.show48 &&
									(show.show49 || show.show50 || show.show51) ? (
										<ListItemButton sx={{ pl: 4 }}>
											<ListItemText
												primary="Type of Holiday"
												onClick={() => navigate('/holiday-type')}
											/>
										</ListItemButton>
									) : null}
									{show.show52 &&
									(show.show53 || show.show54 || show.show55) ? (
										<ListItemButton sx={{ pl: 4 }}>
											<ListItemText
												primary="Type of Leave"
												onClick={() => navigate('/leave-type')}
											/>
										</ListItemButton>
									) : null}
									{show.show56 &&
									(show.show57 || show.show58 || show.show59) ? (
										<ListItemButton sx={{ pl: 4 }}>
											<ListItemText
												primary="Task Status"
												onClick={() => navigate('/task-status')}
											/>
										</ListItemButton>
									) : null}
									{show.show60 &&
									(show.show61 || show.show62 || show.show63) ? (
										<ListItemButton sx={{ pl: 4 }}>
											<ListItemText
												primary="Task Priority"
												onClick={() => navigate('/task-priority')}
											/>
										</ListItemButton>
									) : null}
									{show.show64 &&
									(show.show65 || show.show66 || show.show67 || show.show68) ? (
										<ListItemButton sx={{ pl: 4 }}>
											<ListItemText
												primary="Roles"
												onClick={() => navigate('/roles')}
											/>
										</ListItemButton>
									) : null}
									{show.show69 &&
									(show.show70 || show.show71 || show.show72) ? (
										<ListItemButton sx={{ pl: 4 }}>
											<ListItemText
												primary="Department"
												onClick={() => navigate('/department')}
											/>
										</ListItemButton>
									) : null}
									<ListItemButton sx={{ pl: 4 }}>
										<ListItemText
											primary="Designation"
											onClick={() => navigate('/designation')}
										/>
									</ListItemButton>
								</List>
							</Collapse>
						</>
					) : null}
					{show.show73 ? (
						<ListItemButton
							onClick={() => navigate('/status-mail')}
							key="Status Mail"
							sx={{
								minHeight: 48,
								justifyContent: open ? 'initial' : 'center',
								px: 2.5,
							}}
						>
							<ListItemIcon
								sx={{
									minWidth: 0,
									mr: open ? 3 : 'auto',
									justifyContent: 'center',
								}}
							>
								<img src={holiday} width="35" height="35" />
							</ListItemIcon>
							<ListItemText
								primary="Status Mail"
								sx={{ opacity: open ? 1 : 0 }}
							/>
						</ListItemButton>
					) : null}
					<ListItemButton
						onClick={() => navigate('/chat')}
						key="Chat"
						sx={{
							minHeight: 48,
							justifyContent: open ? 'initial' : 'center',
							px: 2.5,
						}}
					>
						<ListItemIcon
							sx={{
								minWidth: 0,
								mr: open ? 3 : 'auto',
								justifyContent: 'center',
							}}
						>
							<img src={chat} width="35" height="35" />
						</ListItemIcon>
						<ListItemText primary="Chat" sx={{ opacity: open ? 1 : 0 }} />
					</ListItemButton>
					{/* {show.show74 && (show.show75 || show.show76 || show.show77) ?
            <ListItemButton
              onClick={()=>navigate("/performance")}
              key="Performance"
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <img src={perform} width="35" height="35" />
              </ListItemIcon>
              <ListItemText primary="Performance" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          :null} */}
				</List>
			</Drawer>
			<Box component="main" sx={{ flexGrow: 1, p: 3 }}>
				<DrawerHeader />
				<Comp />
			</Box>
		</Box>
	);
}
