import { makeStyles } from '@mui/styles';

const headtxt = 30;
const selectheight = "35px";
const selectWidth = "100%";

export const useStyles = makeStyles({
  root: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px',
  },
  headtext: {
    fontSize: headtxt,
    color: "#2a4184",
  },
  createBtn: {
    float: "right",
  },
  startBtn: {
    float: "right",
    background: '#34ba23',
    width: '5%',
  },
  stopBtn: {
    float: "right",
    background: 'red',
    width: '5%',
  },
  // srchbtn: {
  //   padding: "1px 10px",
  //   marginTop: "8px",
  //   background: "#ddd",
  //   fontSize: "17px",
  //   cursor: "pointer",
  // },
  clrsrch: {
    float: "right",
    marginTop: "5px",
    marginRight: "200px",
    cursor: "pointer",
  },
  selectdrop: {
    width: selectWidth,
    height: selectheight,
  },
  root: {
    float: "right",
  },
  formControl: {
    minWidth: "120px !important",
    borderRadius: "10px",
  },
  selectlimit: {
    border: "2px solid rgb(206, 212, 218)",                          
    height: "40px",
    paddingTop: "1px",
  },
  prevButton:{
    marginTop:"29px",
    marginRight: "-10px",
  },
  nextButton:{
    marginTop:"29px",
    marginLeft:"-10px",
    marginRight:"10px",
  },
  pastday:{
    marginTop:"-46px",
    marginLeft:"18px",
  },
  select:{
    marginTop:"-26px",
    border: "2px solid rgb(206, 212, 218)",                          
    height: "40px",
    paddingTop: "1px",
    borderRadius: "10px",
  },
  srchbtn:{
  borderTopRightRadius:"5px",
  borderBottomRightRadius:  "5px",
  marginTop:"-3px",
  marginLeft:"-1px",  
  padding: "1px 10px",
  background: "#ddd",
  fontSize: "17px",
  cursor: "pointer",
  height:"40px",
  },
  formcontrol:{
  marginTop:"-10px",
 },
 arealimit:{
   marginTop:"-10px"
 },
 nextIcon:{
  marginRight:"10px",
 },
 srchbtn1:{
  borderTopRightRadius:"5px",
  borderBottomRightRadius:  "5px",
  marginTop:"-3px",
  marginLeft:"-60px",  
  padding: "1px 10px",
  background: "#ddd",
  fontSize: "17px",
  cursor: "pointer",
  height:"40px",
  },
  fonticonpositioning :{
    position: "absolute",
    right: "7px",
    top:"40%",
    fontSize:"2.4em",
    color: "#94b2c6",
    cursor: "pointer",
} ,
  sangam:{
  position: "absolute",
  right: "7px",
  top:"50%",
  fontSize:"1.4em",
  color: "#94b2c6",
  cursor: "pointer",
},
});

export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    minWidth: "400px",
    maxWidth: "900px",
    maxHeight: "900px",
   padding : "0px",
   borderRadius: "15px",
   boxShadow: "0px 0px 15px 2px rgba(0, 139, 231 ,0.2)",
    transform: "translate(-50%, -50%)",
  },
};


export const customStyles2 = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    minWidth: "500px",
    maxWidth: "1000px",
    maxHeight: "1000px",
   padding : "20px",
   borderRadius: "5px",
   boxShadow: "0px 0px 15px 2px rgba(0, 139, 231 ,0.2)",
    transform: "translate(-50%, -50%)",
  },
};
