import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import {
	Card,
	CardBody,
	CardSubtitle,
	CardText,
	Col,
	Row,
	Button,
} from 'reactstrap';
import axios from 'axios';
import base_url from '../../api/bootapi';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Select from 'react-select';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useStyles } from './../../Utilities/CSSUtilities';
import { useDispatch, useSelector } from 'react-redux';
import {
	setStarted,
	selectStarted,
	setStopped,
	selectStopped,
	logout,
	selectEmployee,
	setSessionIdData,
	selectSessionId,
} from '../../components/features/empSlice';
import { useToasts } from 'react-toast-notifications';
import CallAuditapi from '../services/auditservice';
import { Mode } from '@mui/icons-material';

const EditEmployees = (props) => {
	const params = useParams();
	const jwtToken = 'JWT ' + localStorage.getItem('JWTToken');
	const [userData, setUserData] = useState({});
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
		reset,
	} = useForm();
	const [backdropOpen, setBackdropOpen] = useState(false);
	const navigate = useNavigate();
	const classes = useStyles();
	const formData = new FormData();
	const imageFormData = new FormData();

	const dispatch = useDispatch();
	const employeeData = useSelector(selectEmployee);
	const startedData = useSelector(selectStarted);
	const stoppedData = useSelector(selectStopped);
	const sessionIdData = useSelector(selectSessionId);
	const { addToast } = useToasts();

	useEffect(() => {
		getPermissions();
		window.addEventListener('storage', function (e) {
			if (e.key === null) {
				dispatch(logout());
				navigate('/');
			}
		});
		return () => {
			setUserData();
			setAvailableroles();
			setAvailableRoleById();
			setDepartmentData();
		};
	}, []);

	const getPermissions = () => {
		const resource_obj = [];
		for (let i = 0; i < employeeData.role_permissions.length; i++) {
			resource_obj.push(employeeData.role_permissions[i].resource_id);
		}
		var available_resource = resource_obj.includes(3);

		if (available_resource) {
			loadUsers(params.user_id);
			getRoles();
			userByRoleidAll();
			getManagerData();
			getDeparmentData();
			getDesignationData();
		} else {
			navigate('/errorPage');
		}
	};

	const inputEvent = (e) => {
		const value = e.target.value;
		const name = e.target.name;
		setUserData((prev) => {
			return {
				...prev,
				[name]: value,
			};
		});
	};

	const convertBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const fileReader = new FileReader();
			fileReader.readAsDataURL(file);
			fileReader.onload = () => {
				resolve(fileReader.result);
			};
			fileReader.onerror = (error) => {
				reject(error);
			};
		});
	};

	const [baseimage, setbaseimage] = useState('');
	const [logofilesize, setlogofilesize] = useState(false);
	const uploadLogo = (data) => {
		const file = data.target.files[0];
		formData.append('image', file, file.name);
		//const base64 = convertBase64(file);
		//setbaseimage(base64);
		console.log(formData.get('image'));
	};
	const chklogosize = (e) => {
		var chk = false;
		const numberfiles = e.target.files;
		for (let i = 0; i < numberfiles.length; i++) {
			if (numberfiles[i].size / 1048576 > 10) {
				chk = true;
			}
		}
		setlogofilesize(chk);
	};
	const goback = () => {
		navigate('/employee-list');
	};

	const onSubmit = (data2) => {
		console.log('On Submit Image', formData.get('image'));
		let first_name = data2.first_name;
		let last_name = data2.last_name;
		let email = data2.email;
		let phone = data2.phone;
		let date_of_birth = data2.date_of_birth ? data2.date_of_birth : null;
		let gender = data2.gender;
		let role_name = selectedRoleName;
		let role_id = Number(selectedRoleId);
		let total_leaves = Number(data2.total_leaves);
		let used_leaves = Number(data2.used_leaves);
		let status = JSON.parse(data2.status);
		let designation = data2.designation;
		let department = data2.department;
		let company_id = employeeData.company_id;
		let company_code = employeeData.company_code;
		let reporting_manager_name = selectedReportingManagerName;
		let reporting_manager_id = Number(selectedReportingManagerId);
		let manager = projecManagerData;

		let data = {
			first_name,
			last_name,
			email,
			phone,
			date_of_birth,
			gender,
			role_name,
			total_leaves,
			used_leaves,
			role_id,
			status,
			designation,
			department,
			company_id,
			company_code,
			reporting_manager_id,
			reporting_manager_name,
			manager,
		};
		console.log('Data', data);
		console.log('image', formData.get('image'));
		formData.append('data', JSON.stringify(data));
		/* for (let [key, value] of formData.entries()) {
			console.log(key);
		} */
		postUser(formData);
	};

	const loadUsers = () => {
		setBackdropOpen(true);
		axios
			.get(
				`${base_url.api1}/myappapi/users/getuser?company_code=` +
					employeeData.company_code +
					`&user_id=` +
					params.user_id,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then((response) => {
				let userDetails = response.data.Users;
				console.log(userDetails);
				let projectManagerData = response.data.Users.project_managers;
				console.log('Project Manager Data:', projectManagerData);
				let ManagerData_obj = [];
				for (let i = 0; i < projectManagerData.length; i++) {
					let data = {
						value: projectManagerData[i].manager_id,
						label: projectManagerData[i].manager_name,
					};
					ManagerData_obj.push(data);
				}
				if (ManagerData_obj.length == 0) {
					setProjecManagerState(true);
				} else {
					setProjecManagerState(false);
				}
				setSelectedProjectManager1(ManagerData_obj);
				setSelectedProjectManager(ManagerData_obj);
				setUserData({
					first_name: userDetails.first_name,
					last_name: userDetails.last_name,
					email: userDetails.email,
					phone: userDetails.phone,
					date_of_birth: userDetails.date_of_birth,
					gender: userDetails.gender,
					role_id: userDetails.role_id,
					role_name: userDetails.role_name,
					total_leaves: userDetails.total_leaves,
					used_leaves: userDetails.used_leaves,
					status: userDetails.status,
					reporting_manager_name: userDetails.reporting_manager_name,
					reporting_manager_id: userDetails.reporting_manager_id,
					designation: userDetails.designation,
					department: userDetails.department,
					company_id: userDetails.company_id,
					company_code: userDetails.company_code,
				});
				//new code
				setNewFormData({
					first_name: userDetails.first_name,
					last_name: userDetails.last_name,
					email: userDetails.email,
					phone: userDetails.phone,
					date_of_birth: userDetails.date_of_birth,
					gender: userDetails.gender,
					role_id: userDetails.role_id,
					role_name: userDetails.role_name,
					total_leaves: userDetails.total_leaves,
					used_leaves: userDetails.used_leaves,
					status: userDetails.status,
					reporting_manager_name: userDetails.reporting_manager_name,
					reporting_manager_id: userDetails.reporting_manager_id,
					designation: userDetails.designation,
					department: userDetails.department,
					company_id: userDetails.company_id,
					company_code: userDetails.company_code,
				});

				setSelectedRoleId(userDetails.role_id);
				setSelectedRoleName(userDetails.role_name);
				setSelectedReportingManagerId(userDetails.reporting_manager_id);
				setSelectedReportingManagerName(userDetails.reporting_manager_name);
				setgetSelected(userDetails.reporting_manager_id);
				let user_data = {
					userDetails,
					ManagerData_obj,
				};
				reset(user_data);
				setBackdropOpen(false);
			})
			.catch((error) => {
				console.log(error);
				setBackdropOpen(false);
			});
	};

	const postUser = (data) => {
		setBackdropOpen(true);
		axios
			.put(
				`${base_url.api1}/myappapi/users/update?company_code=` +
					employeeData.company_code +
					`&user_id=` +
					params.user_id,
				data,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then(
				(response) => {
					addToast(response.data.message, {
						appearance: 'success',
						autoDismiss: true,
					});
					let log = employeeData.emp_name + ' edited in employees';
					CallAuditapi(log);
					setBackdropOpen(false);
					navigate(`/employee-list`);
				},
				(error) => {
					addToast(error.response.data.message, {
						appearance: 'error',
						autoDismiss: true,
					});
					console.log(JSON.stringify(error.response.data.message));
					setBackdropOpen(false);
				}
			);
	};

	const [selectedRoleId, setSelectedRoleId] = useState();
	const [availableroles, setAvailableroles] = useState([]);
	const [selectedRoleName, setSelectedRoleName] = useState();
	const getRoles = () => {
		setBackdropOpen(true);
		let urlData;
		if (employeeData.emp_role_id == 1) {
			urlData = employeeData.company_code + `&status=True&super_admin=True`;
		} else {
			urlData = employeeData.company_code + `&status=True`;
		}
		axios
			.get(
				`${base_url.api1}/myappapi/role/list?company_code=` +
					employeeData.company_code,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then((response) => {
				console.log(response.data.Role);
				setAvailableroles(response.data.Role);
				setBackdropOpen(false);
			})
			.catch((error) => {
				toast.error(JSON.stringify(error.response.data.message));
				console.log(JSON.stringify(error));
				setBackdropOpen(false);
			});
	};
	const lookup = availableroles;
	// console.log(lookup);

	// const [selected, setSelected] = useState(-1);
	// const value = selected !== -1 && lookup[selected];

	const handleChangeRole = (event) => {
		const index = event.target.selectedIndex;
		const optionElement = event.target.childNodes[index];
		const optionElementId = optionElement.getAttribute('id');
		console.log(optionElementId);
		const optionElementValue = optionElement.getAttribute('value');
		console.log(optionElementValue);

		setSelectedRoleId(optionElementId);
		setSelectedRoleName(optionElementValue);
	};

	const [availableRoleById, setAvailableRoleById] = useState([]);
	const userByRoleidAll = () => {
		setBackdropOpen(true);
		axios
			.get(
				`${base_url.api1}/myappapi/all_users_by_role?role_id=1,2,3,4&company_code=` +
					employeeData.company_code,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then((response) => {
				console.log(response.data.Users);
				setAvailableRoleById(response.data.Users);
				setBackdropOpen(false);
			})
			.catch((error) => {
				console.log(JSON.stringify(error));
				setBackdropOpen(false);
			});
	};

	const look = availableRoleById;
	// console.log(look);

	const [getselected, setgetSelected] = useState(-1);

	const [availableManager, setAvailableManager] = useState();
	const [managerData, setManager] = useState();
	const getManagerData = () => {
		axios
			.get(
				`${base_url.api1}/myappapi/all_users_by_role?role_id=1,3&company_code=` +
					employeeData.company_code,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then((response) => {
				// console.log(response.data.Users);
				setManager(response.data.Users);
				let manager_data = response.data.Users;
				const manager_object = [];
				for (let i = 0; i < manager_data.length; i++) {
					let data = {
						value: manager_data[i].user_id,
						label: manager_data[i].first_name + ' ' + manager_data[i].last_name,
					};
					manager_object.push(data);
				}
				setAvailableManager(manager_object);
			})
			.catch((error) => {
				console.log(JSON.stringify(error));
			});
	};

	const [selectedReportingManagerId, setSelectedReportingManagerId] =
		useState();
	const [selectedReportingManagerName, setSelectedReportingManagerName] =
		useState();
	const handleChangeReportingManager = (event) => {
		const index = event.target.selectedIndex;
		const optionElement = event.target.childNodes[index];
		const optionElementId = optionElement.getAttribute('id');
		console.log(optionElementId);
		const optionElementValue = optionElement.getAttribute('value');
		console.log(optionElementValue);

		setSelectedReportingManagerId(optionElementId);
		setSelectedReportingManagerName(optionElementValue);
	};

	const [projecManagerState, setProjecManagerState] = useState(false);
	const [projecManagerData, setSelectedProjectManager] = useState();
	const [projecManagerData1, setSelectedProjectManager1] = useState();
	const onSelectProjectManager = (selectedList, selectedItem) => {
		console.log('Entering', selectedList);
		let mng_obj = [];
		for (let i = 0; i < selectedList.length; i++) {
			let data = {
				manager_id: selectedList[i].value,
				manager_name: selectedList[i].label,
			};
			mng_obj.push(data);
		}
		if (mng_obj.length == 0) {
			setProjecManagerState(true);
			setSelectedProjectManager1(null);
			setSelectedProjectManager(null);
		} else {
			setProjecManagerState(false);
			setSelectedProjectManager(mng_obj);
			setSelectedProjectManager1(selectedList);
		}
	};

	const [availableDepatment, setAvailableDepartment] = useState();
	const [departmentData, setDepartmentData] = useState();
	const getDeparmentData = () => {
		axios
			.get(
				`${base_url.api1}/myappapi/department/list?company_code=` +
					employeeData.company_code,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then((response) => {
				console.log(response.data.Department);
				setDepartmentData(response.data.Department);
				let department_data = response.data.Department;
				const department_object = [];
				for (let i = 0; i < department_data.length; i++) {
					let data = {
						value: department_data[i].id,
						label: department_data[i].department_name,
					};
					department_object.push(data);
				}
				setAvailableDepartment(department_object);
			})
			.catch((error) => {
				console.log(JSON.stringify(error));
			});
	};

	const [selectedDepartmentId, setSelectedDepartmentId] = useState();
	const [selectedDepartmentName, setSelectedDepartmentName] = useState();
	const handleChangeDepartment = (event) => {
		const index = event.target.selectedIndex;
		const optionElement = event.target.childNodes[index];
		const optionElementId = optionElement.getAttribute('id');
		console.log(optionElementId);
		const optionElementValue = optionElement.getAttribute('value');
		console.log(optionElementValue);
		setSelectedDepartmentId(optionElementId);
		setSelectedDepartmentName(optionElementValue);
	};

	const [selectedDesignationId, setSelectedDesignationId] = useState();
	const [selectedDesignationName, setSelectedDesignationName] = useState();
	const handleChangeDesignation = (event) => {
		const index = event.target.selectedIndex;
		const optionElement = event.target.childNodes[index];
		const optionElementId = optionElement.getAttribute('id');
		console.log(optionElementId);
		const optionElementValue = optionElement.getAttribute('value');
		console.log(optionElementValue);
		setSelectedDesignationId(optionElementId);
		setSelectedDesignationName(optionElementValue);
	};

	const [availableDesignation, setAvailableDesignation] = useState();
	const [designationData, setDesignationData] = useState();
	const getDesignationData = () => {
		axios
			.get(
				`${base_url.api1}/myappapi/designation_list?company_code=` +
					employeeData.company_code,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then((response) => {
				console.log(response.data.Designation);
				setDesignationData(response.data.Designation);
				let designation_data = response.data.Designation;
				const designation_object = [];
				for (let i = 0; i < designation_data.length; i++) {
					let data = {
						value: designation_data[i].id,
						label: designation_data[i].name,
					};
					designation_object.push(data);
				}
				setAvailableDesignation(designation_object);
			})
			.catch((error) => {
				console.log(JSON.stringify(error));
			});
	};

	//new code

	const [newFormData, setNewFormData] = useState({
		first_name: '',
		last_name: '',
		email: '',
		phone: '',
		date_of_birth: '',
		gender: 'Male',
		role_name: '',
		total_leaves: '',
		used_leaves: '',
		role_id: '',
		status: true,
		designation: '',
		department: '',
		company_id: '',
		company_code: '',
		reporting_manager_id: '',
		reporting_manager_name: '',
		manager: [],
		is_verified: true,
	});

	const [nErrors, setNewErrors] = useState({});

	const newhandleChange = (e) => {
		const value = e.target.value;
		const name = e.target.name;
		setNewFormData((prev) => {
			return {
				...prev,
				[name]: value,
			};
		});
		console.log('Name:', name, 'Value:', value);
	};

	const newHandleSubmit = (e) => {
		e.preventDefault();
		const newErrors = {};
		/* let primary_image = newFormData.image.item(0);
		if (primary_image) {
			formData.append('image', primary_image, primary_image.name);
		} */
		newFormData.manager = projecManagerData;
		//console.log(newFormData.first_name);

		// Custom validation for not empty fields
		if (!newFormData.first_name.trim()) {
			newErrors.first_name = 'First Name is Required';
		}
		if (!newFormData.email.trim()) {
			newErrors.email = 'Email is Required';
		}
		if (newFormData.status === '') {
			newErrors.status = 'Status is Required';
			console.log(newFormData.status);
		}
		if (!newFormData.designation) {
			newErrors.designation = 'Designation is Required';
		}
		if (!newFormData.department) {
			newErrors.department = 'Department is Required';
		}
		if (!newFormData.reporting_manager_name) {
			newErrors.reporting_manager_name = 'Reporting Manager is Required';
		}
		if (!newFormData.manager) {
			newErrors.project_manager = 'Project Manager is Required';
		}

		// Additional validation rules can be added here

		setNewErrors(newErrors);

		if (Object.keys(newErrors).length === 0) {
			// Submit the form if there are no errors
			// Add your form submission logic here
			console.log('Form submitted successfully:', newFormData);
			onSubmit(newFormData);
		} else {
			console.log('Form contains errors:', newErrors);
		}
	};

	return (
		<div>
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={backdropOpen}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Card className="commoncomponent_cardcss">
				<CardBody>
					<CardSubtitle className="font-weight-bold"></CardSubtitle>
					<div>
						<Row>
							<Col md={6}>
								<br></br>
								<CardText
									className={classes.headtext}
									style={{ marginTop: '-10px' }}
								>
									<strong>Edit Employee</strong>
								</CardText>
							</Col>
							<Col md={6}>
								<br />
								<Button
									variant="contained"
									color="primary"
									style={{ float: 'right' }}
									onClick={goback}
								>
									&#x2630; Back
								</Button>
							</Col>
						</Row>
						<form onSubmit={newHandleSubmit}>
							<div style={{ padding: '0 20px ' }}>
								<div className="form-group creatadmin">
									<Row>
										<Col md={3}>
											<label>
												First Name:<span style={{ color: 'red' }}>*</span>
											</label>
											{/* <input
                        type="text"
                        {...register("first_name", { required: true })} 
                        className="form-control underline-input"
                        value={userData.first_name}
                        onChange={inputEvent}
                      />
                      {errors.first_name && <p className="errormessage">First Name is Required</p>} */}
											<input
												{...register('first_name', { required: true })}
												className="form-control underline-input"
												value={newFormData.first_name}
												onChange={newhandleChange}
											/>
											{nErrors.first_name && (
												<span className="errormessage">
													This field is required
												</span>
											)}
										</Col>
										<Col md={3}>
											<label>Last Name:</label>
											<input
												type="text"
												{...register('last_name', { required: false })}
												className="form-control underline-input"
												value={newFormData.last_name}
												onChange={newhandleChange}
											/>
										</Col>
										<Col md={3}>
											<label>
												Email:<span style={{ color: 'red' }}>*</span>
											</label>
											<input
												type="email"
												{...register('email', { required: true })}
												className="form-control underline-input"
												placeholder="Email"
												value={newFormData.email}
												onChange={newhandleChange}
											/>
											{nErrors.email && (
												<p className="errormessage">Email is Required</p>
											)}
										</Col>
										<Col md={3}>
											<label>
												Status:<span style={{ color: 'red' }}>*</span>
											</label>
											<select
												className="form-control"
												{...register('status', { required: true })}
												value={newFormData.status}
												onChange={newhandleChange}
											>
												<option value={true}>Active</option>
												<option value={false}>InActive</option>
											</select>
											{nErrors.status && (
												<p className="errormessage">Status is Required</p>
											)}
										</Col>
									</Row>
									<Row>
										<Col md={3}>
											<label>Date of Birth:</label>
											<input
												type="date"
												{...register('date_of_birth', { required: false })}
												className="form-control underline-input"
												value={newFormData.date_of_birth}
												onChange={newhandleChange}
											/>
										</Col>
										<Col md={3}>
											<label>
												Designation:<span style={{ color: 'red' }}>*</span>
											</label>
											<select
												{...register('designation', {
													required: true,
												})}
												className={classes.selectdrop}
												value={newFormData.designation}
												onChange={(e) => {
													newFormData.designation = e.target.value;
													console.log(e.target.value);
													setSelectedDesignationId(e.target.value);
													handleChangeDesignation(e);
												}}
											>
												<option value="">-Select Designation-</option>
												{designationData?.map((p, ix) => {
													return (
														<option key={p.id} id={p.id} value={p.name}>
															{p.name}
														</option>
													);
												})}
												;
											</select>
											{nErrors.designation && (
												<p className="errormessage">Designation is Required</p>
											)}
										</Col>
										<Col md={3}>
											<label>Phone:</label>
											<input
												type="number"
												{...register('phone', { required: false })}
												className="form-control underline-input"
												value={newFormData.phone}
												onChange={newhandleChange}
											/>
										</Col>
										<Col md={3}>
											<label>Gender:</label>
											<select
												className={classes.selectdrop}
												{...register('gender', { required: false })}
												value={newFormData.gender}
												onChange={newhandleChange}
											>
												<option value="Male">Male</option>
												<option value="Female">Female</option>
												<option value="Other">Other</option>
											</select>
										</Col>
									</Row>
									<Row>
										<Col md={3}>
											<label>
												Role:<span style={{ color: 'red' }}>*</span>
											</label>
											<select
												{...register('role_name', { required: true })}
												className={classes.selectdrop}
												onChange={(e) => {
													handleChangeRole(e);
												}}
												value={selectedRoleName}
											>
												{lookup.map((m, ix) => {
													return (
														<option
															key={m.role_id}
															id={m.role_id}
															value={m.role_name}
														>
															{m.role_name}
														</option>
													);
												})}
												;
											</select>
										</Col>
										<Col md={3}>
											<label>Photo:</label>
											<input
												type="file"
												name="image"
												{...register('image', { required: false })}
												className="form-control underline-input"
												value={userData.photo}
												onChange={(data) => {
													uploadLogo(data);
													chklogosize(data);
													//newhandleChange(data);
												}}
												accept=".jpg, .jpeg, .bmp, .gif, .png"
											/>
											{logofilesize ? (
												<p className="errormessage">
													Please Upload Image Below 10mb.
												</p>
											) : null}
										</Col>
										<Col md={3}>
											<label>Total Leaves:</label>
											<input
												type="number"
												{...register('total_leaves', { required: false })}
												className="form-control underline-input"
												value={newFormData.total_leaves}
												onChange={newhandleChange}
											/>
										</Col>
										<Col md={3}>
											<label>Used Leaves:</label>
											<input
												type="number"
												{...register('used_leaves', { required: false })}
												className="form-control underline-input"
												value={newFormData.used_leaves}
												onChange={newhandleChange}
											/>
										</Col>
									</Row>
								</div>
								<Row>
									<Col md={3}>
										<label>
											Reporting Manager:<span style={{ color: 'red' }}>*</span>
										</label>
										<select
											{...register('reporting_manager_name')}
											className={classes.selectdrop}
											onChange={(e) => {
												setgetSelected(e.target.value);
												handleChangeReportingManager(e);
												newFormData.reporting_manager_name = e.target.value;
											}}
											value={newFormData.reporting_manager_name}
											id={newFormData.reporting_manager_id}
										>
											<option value="">-Select Reporting Manager-</option>
											{look?.map((m, ix) => {
												return (
													<option
														key={m.user_id}
														id={m.user_id}
														value={m.first_name + ' ' + m.last_name}
													>
														{m.first_name + ' ' + m.last_name}
													</option>
												);
											})}
											;
										</select>
										{nErrors.reporting_manager_name && (
											<p className="errormessage">
												Reporting Manager is Required
											</p>
										)}
									</Col>
									<Col md={3}>
										<label>
											Project Manager:<span style={{ color: 'red' }}>*</span>
										</label>
										<Select
											{...register('project_manager', {
												required: true,
											})}
											value={projecManagerData1}
											isMulti={true}
											className="basic-multi-select"
											classNamePrefix="select"
											onChange={onSelectProjectManager}
											options={availableManager}
										></Select>
										{nErrors.project_manager && (
											<p className="errormessage">
												Project Manager is Required
											</p>
										)}
									</Col>
									<Col md={3}>
										<label>
											Department:<span style={{ color: 'red' }}>*</span>
										</label>
										<select
											{...register('department', {
												required: true,
											})}
											className={classes.selectdrop}
											value={newFormData.department}
											onChange={(e) => {
												newFormData.department = e.target.value;
												console.log(e.target.value);
												setSelectedDepartmentId(e.target.value);
												handleChangeDepartment(e);
											}}
										>
											<option value="">-Select Department-</option>
											{departmentData?.map((d, ix) => {
												return (
													<option key={d.id} id={d.id} value={d.id}>
														{d.department_name}
													</option>
												);
											})}
											;
										</select>
										{nErrors.department && (
											<p className="errormessage">Department is Required</p>
										)}
									</Col>
								</Row>
								<div
									style={{
										float: 'right',
										display: 'flex',
										padding: '5px',
									}}
								>
									<Button
										color="primary"
										type="submit"
										style={{
											marginTop: '20px',
											marginLeft: '5px',
											float: 'right',
										}}
									>
										Submit
									</Button>
								</div>
							</div>
						</form>
					</div>
				</CardBody>
			</Card>
		</div>
	);
};
export default EditEmployees;
