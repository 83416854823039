import React , { useState, useEffect }from 'react';
import { ToastContainer, toast } from "react-toastify";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { Link, useNavigate } from "react-router-dom";
import { CardText, Col, Row, Button, Table } from "reactstrap";
import AddIcon from "@mui/icons-material/Add";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Pagination from "@mui/material/Pagination";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import { customStyles2, useStyles } from "./../../Utilities/CSSUtilities";
import axios from "axios";
import base_url from '../../api/bootapi';
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { 
  setStarted,
  selectStarted,
  setStopped,
  selectStopped,
  logout,
  selectEmployee,
  setSessionIdData,
  selectSessionId,
  setEmpIpAddress
} from "../../components/features/empSlice";
import RefreshIcon from '@mui/icons-material/Refresh';  
import { ColorLensOutlined } from '@mui/icons-material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
// import { Select } from '@mui/material';
import Select from 'react-select';
import { MenuItem } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const AttendanceList = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [backdropOpen, setBackdropOpen] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const jwtToken = "JWT " + localStorage.getItem("JWTToken");
  const IpAddress = localStorage.getItem("EmpIpAddress");
  const [attendanceData,setAttendance] =useState([]);
  const [indAttenance,setIndAttendance] = useState([]);
  const dispatch = useDispatch();
  const employeData = useSelector(selectEmployee);
  const startedData = useSelector(selectStarted);
  const stoppedData = useSelector(selectStopped);
  const sessionIdData = useSelector(selectSessionId);

  const isoDateString = new Date().toISOString();
  const datenow = isoDateString.split("T")[0];
  const [datefilter, setdatefilter] = useState();
  const [dateFilterStatus, setDateFilterStatus] = useState();
  const [date1 , setDate1] = useState(datenow);
  const [date2 , setDate2] = useState(datenow);
  //const [value1,setValue1] = useState(1);
  const [searchdata, setsearch] = useState();
  const [datesRange, setDatesRange] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [paginationPageNo, setPaginationPageNo] = useState(1);
  const [getPageNo, setGetPageNo] = useState();
  const [rows, setRows] = React.useState(50);
  const [sortState, setsortState] = useState("emp_name");
  const [direction, setdirection] = useState("Desc");
  const [userStatus, setUserStatus] = useState(1);
  const [totalcount, setTotalCount] = useState();
  const [countNumber, setCountNumber] = useState();
  const [numberOfElements, setNumberOfElements] = useState();
  const [selectedDelete, setSelectedDelete] = useState([]);
  const [display1, setdisplay] = useState("inline");
  const [getbreak,setBreak] = useState([]);
  const [permissionObj, setPermissionObj] = useState();


  useEffect( () => {
    getPermissions();
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
    return () => {
      setAttendance();
      setAvailableRoleByAllId();
      setIndAttendance();
    };
  },[rows]);

  const getPermissions = () => {
    const resource_obj = [];

    for (let i = 0; i < employeData.role_permissions.length; i++) {
      resource_obj.push(employeData.role_permissions[i].resource_id);
    }
    setPermissionObj(resource_obj);
    var available_resource30 = resource_obj.includes(30);
    var available_resource31 = resource_obj.includes(31);

    if(available_resource30 && available_resource31) {
      console.log('coming if');
      if (localStorage.getItem("UserPageNo")) {
        let currentPage = localStorage.getItem("UserPageNo");
        setPageNo(currentPage);
        setPaginationPageNo(Number(currentPage));
        getAllAttendanceList(currentPage,date1,date2);
      } else {
        setPageNo(1);
        setPaginationPageNo(1);
        getAllAttendanceList(1,date1,date2);
      }
    } else {
      console.log('coming else');
      navigate('/errorPage')
    }
    userByRoleidAll();
    userByRoleID();
  };



  const getIndAttList = (value1,dt1,dt2) => {
    console.log(dt1);
    console.log(dt2);
    setBackdropOpen(true);
    axios
      .get(`${base_url.api1}/office_app/attendance/date_range?company_code=`+ employeData.company_code + `&emp_id=` +employeData.emp_id +`&from=` + dt1  + `&to=` + dt2 + `&page=`+ value1 +`&size=`+ rows   , {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setGetPageNo(response.data.total_pages);
          setTotalCount(response.data.total_present);
          setCountNumber(value1-1);
          setNumberOfElements(response.data.Attendance.length);
          setEmployeeId(response.data.Attendance.emp_id);
          setsearch(false);
          setBackdropOpen(false);
          let attData = response.data.Attendance;
          let attObj=[];
          for (let i = 0; i < attData.length; i++) {
            attObj.push({
              applied_rule_id: attData[i].applied_rule_id,
              company_id: attData[i].company_id,
              createad_at: attData[i].createad_at,
              emp_id: attData[i].emp_id,
              emp_name: attData[i].emp_name,
              end_time: attData[i].end_time,
              id: attData[i].id,
              ip_address: attData[i].ip_address,
              breaks:attData[i].breaks,
              late_start_time: attData[i].late_start_time,
              over_time_duration: attData[i].over_time_duration,
              session_date: attData[i].session_date,
              start_time: attData[i].start_time,
              type_of_shift: attData[i].type_of_shift,
              updated_at: attData[i].updated_at,
              working_duration: attData[i].working_duration,
              overtime_duration:attData[i].overtime_duration,
              total_work: getTotalTime(attData[i].start_time),
              break_duration: attData[i].breaks_array.reduce((a, b) => a + b, 0),
            })
          }
          setAttendance(attObj)
        },
        (error) => {
            setBackdropOpen(false);
            setAttendance();
            console.log(JSON.stringify(error.response.data));
        }
      );
  };


  const getAllAttendanceList = (value1,dt1,dt2) => {
    console.log(dt1);
    console.log(dt2);
    setBackdropOpen(true);
    axios
      .get(`${base_url.api1}/office_app/attendance/date_range?company_code=`+ employeData.company_code +`&from=` + dt1  + `&to=` + dt2 + `&page=`+ value1 +`&size=`+ rows   , {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setGetPageNo(response.data.total_pages);
          setTotalCount(response.data.total_present);
          setCountNumber(value1-1);
          setNumberOfElements(response.data.Attendance.length);
          setEmployeeId(response.data.Attendance.emp_id);
          setsearch(false);
          setBackdropOpen(false);
          let attData = response.data.Attendance;
          let attObj=[];
          for (let i = 0; i < attData.length; i++) {
            attObj.push({
              applied_rule_id: attData[i].applied_rule_id,
              company_id: attData[i].company_id,
              createad_at: attData[i].createad_at,
              emp_id: attData[i].emp_id,
              emp_name: attData[i].emp_name,
              end_time: attData[i].end_time,
              id: attData[i].id,
              ip_address: attData[i].ip_address,
              breaks:attData[i].breaks,
              late_start_time: attData[i].late_start_time,
              over_time_duration: attData[i].over_time_duration,
              session_date: attData[i].session_date,
              start_time: attData[i].start_time,
              type_of_shift: attData[i].type_of_shift,
              updated_at: attData[i].updated_at,
              working_duration: attData[i].working_duration,
              overtime_duration:attData[i].overtime_duration,
              total_work: getTotalTime(attData[i].start_time),
              break_duration: attData[i].breaks_array.reduce((a, b) => a + b, 0),
            })
          }
          setAttendance(attObj)
          document.getElementById("myForm").reset();
        },
        (error) => {
            setBackdropOpen(false);
            setAttendance();
            console.log(JSON.stringify(error.response.data));
        }
      );
  };

  const createAttendance = (data2) => {
    console.log(officeState);
    let session_date = data2.start_time;
    let emp_name = officeState.first_name;
    let start_time =  data2.start_time;
    let end_time = null; 
    let emp_id = Number(officeState.user_id);

    let data = {
      emp_name,
      start_time,
      end_time,
      session_date,
      emp_id,
    };
    setBackdropOpen(true);
    console.log(data);
    axios
    .post(`${base_url.api1}/office_app/attendance?company_code=`+ employeData.company_code , data, {
      headers: {
        Authorization: jwtToken,
      },
    })
      .then((response) => {
        setCreate1(false);
        setBackdropOpen(false);
        navigate('/attendance-list');
        getAllAttendanceList(1,datenow,datenow); 
      })
      .catch((error) => {
        setCreate1(false);
        setBackdropOpen(false);
        getAllAttendanceList();
        console.log(JSON.stringify(error));
      });
  };
  const [availableRoleById, setAvailableRoleById] = useState([]);
  const [empId, setEmpId] = useState([]);
  const userByRoleidAll = () => {
    axios
      .get(`${base_url.api1}/myappapi/all_users_by_role?role_id=` + 2 + `,` + 3 + `,` + 4 +`&company_code=` + employeData.company_code , {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        // console.log(response.data.Users);
        // setAvailableRoleById(response.data.Users);
         setEmpId(response.data.user_id);
        let ofc_data = response.data.Users;
        const office_obj = [{
          value: null,
          label: "-Select Employee-",
        }];
        for (let i = 0; i < ofc_data.length; i++) {
          let data = {
            value: ofc_data[i].user_id,
            label: ofc_data[i].first_name+" "+ofc_data[i].last_name,
          };
          office_obj.push(data);
        }
        setAvailableRoleById(office_obj);
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
      });
  };
  const look = availableRoleById;
  console.log(look);

  const [officeset1, setofficeset1] = useState(true);
  const [officeState, setOffice] = useState();
  const onSelect = (selectedList, selectedItem) => {
    console.log("Entering",selectedList);
        let ofc_obj = {
          user_id: Number(selectedList.value),
          first_name: selectedList.label,
        };
      console.log(ofc_obj);
      if (ofc_obj.length == 0) {
        setofficeset1(true);
      } else {
        setofficeset1(false);
        setOffice(ofc_obj);
      }
  };


  const [selectemployee, setSelectedEmployee] = useState(false);
  const [selectEmployeeData, setSelectedEmployeeData] = useState();
  const onSelectEmployee = (selectedList, selectedItem) => {
    console.log("Entering",selectedList);
    setBackdropOpen(true);
    setSelectedEmployee(true);
    setSelectedEmployeeData(selectedList);
    if(selectedList.value==null){
    axios
      .get(`${base_url.api1}/office_app/attendance/date_range?company_code=`+ employeData.company_code +`&from=` + date1  + `&to=` + date2 + `&page=1&size=`+ rows , {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setGetPageNo(response.data.total_pages);
          setTotalCount(response.data.total_present);
          setCountNumber(0);
          setNumberOfElements(response.data.Attendance.length);
          setEmployeeId(response.data.Attendance.emp_id);
          setsearch(false);
          setBackdropOpen(false);
          let attData = response.data.Attendance;
          let attObj=[];
          for (let i = 0; i < attData.length; i++) {
            attObj.push({
              applied_rule_id: attData[i].applied_rule_id,
              company_id: attData[i].company_id,
              createad_at: attData[i].createad_at,
              emp_id: attData[i].emp_id,
              emp_name: attData[i].emp_name,
              end_time: attData[i].end_time,
              id: attData[i].id,
              ip_address: attData[i].ip_address,
              breaks:attData[i].breaks,
              late_start_time: attData[i].late_start_time,
              over_time_duration: attData[i].over_time_duration,
              session_date: attData[i].session_date,
              start_time: attData[i].start_time,
              type_of_shift: attData[i].type_of_shift,
              updated_at: attData[i].updated_at,
              working_duration: attData[i].working_duration,
              overtime_duration:attData[i].overtime_duration,
              total_work: getTotalTime(attData[i].start_time),
              break_duration: attData[i].breaks_array.reduce((a, b) => a + b, 0),
            })
          }
          setAttendance(attObj)
        },
        (error) => {
            setBackdropOpen(false);
            setAttendance();
            console.log(JSON.stringify(error.response.data));
        }
      );
    }else{
      axios
      .get(`${base_url.api1}/office_app/attendance/date_range?company_code=`+ employeData.company_code +`&from=` + date1  + `&to=` + date2 + `&page=1&size=`+ rows+ `&emp_id=`+selectedList.value   , {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setGetPageNo(response.data.total_pages);
          setTotalCount(response.data.total_present);
          setCountNumber(0);
          setNumberOfElements(response.data.Attendance.length);
          setEmployeeId(response.data.Attendance.emp_id);
          setsearch(false);
          setBackdropOpen(false);
          let attData = response.data.Attendance;
          let attObj=[];
          for (let i = 0; i < attData.length; i++) {
            attObj.push({
              applied_rule_id: attData[i].applied_rule_id,
              company_id: attData[i].company_id,
              createad_at: attData[i].createad_at,
              emp_id: attData[i].emp_id,
              emp_name: attData[i].emp_name,
              end_time: attData[i].end_time,
              id: attData[i].id,
              ip_address: attData[i].ip_address,
              breaks:attData[i].breaks,
              late_start_time: attData[i].late_start_time,
              over_time_duration: attData[i].over_time_duration,
              session_date: attData[i].session_date,
              start_time: attData[i].start_time,
              type_of_shift: attData[i].type_of_shift,
              updated_at: attData[i].updated_at,
              working_duration: attData[i].working_duration,
              overtime_duration:attData[i].overtime_duration,
              total_work: getTotalTime(attData[i].start_time),
              break_duration: attData[i].breaks_array.reduce((a, b) => a + b, 0),
            })
          }
          setAttendance(attObj)
        },
        (error) => {
            setBackdropOpen(false);
            setAttendance();
            console.log(JSON.stringify(error.response.data));
        }
      );
    }
  };
  const [selected, setSelected] = useState(-1); 

  const [getselected, setgetSelected] = useState(-1); 
  const value2 = getselected !== -1 && look[getselected];

  const [selectedroleById, setSelectedroleById] = useState();
  const [defaultRoleById, setdefaultRoleById] = useState();
  const handleChangeRoleById = (event) => {
    console.log(event);
    const index = event.target.selectedIndex;
    console.log(index);
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    console.log(optionElementId);
    const optionElementValue = optionElement.getAttribute("value1");
    console.log(optionElementValue); 

    setSelectedroleById(optionElementId);
    setdefaultRoleById(optionElementValue);
  };

  const [availableRoleByAllId, setAvailableRoleByAllId] = useState([]);
  const [empAllId, setEmpAllId] = useState([]);
  const userByRoleID = () => {
    axios
      .get(`${base_url.api1}/myappapi/all_users_by_role?company_code=` + employeData.company_code + `&status=True`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        console.log(response.data.Users);
        // setAvailableRoleByAllId(response.data.Users);
        // setEmpAllId(response.data.user_id);
        setEmpId(response.data.user_id);
        let ofc_data = response.data.Users;
        const office_obj = [{
          value: null,
          label: "-Select Employee-",
        }];
        for (let i = 0; i < ofc_data.length; i++) {
          let data = {
            value: ofc_data[i].user_id,
            label: ofc_data[i].first_name+" "+ofc_data[i].last_name,
          };
          office_obj.push(data);
        }
        setAvailableRoleByAllId(office_obj);
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
      });
  };
  const looked = availableRoleByAllId;
  console.log(looked);

  const [newselected, setnewSelected] = useState(-1); 

  const [getnewselected, setnewgetSelected] = useState(-1); 
  const value3 = getselected !== -1 && looked[getnewselected];

  const [selectedroleByAllId, setSelectedroleByAllId] = useState();
  const [defaultRoleByAllId, setdefaultRoleByAllId] = useState();
  const handleChangeRoleByAllId = (event) => {
    console.log(event);
    setdefaultRoleByAllId(event.target.value);
    console.log(event.target.value);
    const index = event.target.selectedIndex;
    console.log(index);
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    console.log(optionElementId);
    const optionElementValue = optionElement.getAttribute("value1");
    console.log(optionElementValue); 

    setSelectedroleByAllId(optionElementId);
    setdefaultRoleByAllId(optionElementValue);
  };
  
  const getTotalTime= (val1) =>{
    const d = new Date();
    // const isoDateString = new Date().toISOString();
    // const datenow = isoDateString.split(".")[0];
    const datenow = moment(d).format('YYYY-MM-DDTH:mm:ss');
    let start_time = val1.split("Z")[0].split('T')[1];
    let end_time = datenow.split('T')[1];
    var mins = moment.utc(moment(end_time, "HH:mm:ss").diff(moment(start_time, "HH:mm:ss"))).format("HH:mm");
    return mins;
  }


  const [datevalue, setdatevalue] = useState("Click to open");
  const [pastDateValue, setPastDateValue] = useState('');

  const handleApply = (event, picker, value1) => {
    setBackdropOpen(true);
    setdatevalue(
      `${picker.startDate.format("DD-MM-YYYY")} to ${picker.endDate.format(
        "DD-MM-YYYY"
      )}`
    );
    let data = {
      from: picker.startDate.format("YYYY-MM-DD"),
      to: picker.endDate.format("YYYY-MM-DD"),
    };

    setDatesRange(data);
    setDate1(data.from);
    setDate2(data.to);
    if(selectemployee){
    axios
    .get(`${base_url.api1}/office_app/attendance/date_range?company_code=`+employeData.company_code+`&emp_id=`+selectEmployeeData+`&from=`+data.from+`&to=`+data.to+`&page=`+ value1 +`&size=`+rows, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setGetPageNo(response.data.total_pages);
          setTotalCount(response.data.total_present);
          setCountNumber(value1-1);
          setNumberOfElements(response.data.Attendance.length);
          setEmployeeId(response.data.Attendance.emp_id);
          setsearch(false);
          setBackdropOpen(false);
          setdatefilter(true);
          let attData = response.data.Attendance;
          let attObj=[];
          for (let i = 0; i < attData.length; i++) {
            attObj.push({
              applied_rule_id: attData[i].applied_rule_id,
              company_id: attData[i].company_id,
              createad_at: attData[i].createad_at,
              emp_id: attData[i].emp_id,
              emp_name: attData[i].emp_name,
              end_time: attData[i].end_time,
              id: attData[i].id,
              ip_address: attData[i].ip_address,
              breaks:attData[i].breaks,
              late_start_time: attData[i].late_start_time,
              over_time_duration: attData[i].over_time_duration,
              session_date: attData[i].session_date,
              start_time: attData[i].start_time,
              type_of_shift: attData[i].type_of_shift,
              updated_at: attData[i].updated_at,
              working_duration: attData[i].working_duration,
              overtime_duration:attData[i].overtime_duration,
              total_work: getTotalTime(attData[i].start_time),
              break_duration: attData[i].breaks_array.reduce((a, b) => a + b, 0),
            })
          }
          setAttendance(attObj)
        },
        (error) => {
          setBackdropOpen(false);
          setdatefilter(true);
          setAttendance();
          console.log(JSON.stringify(error.response.data));
        }
      );
    }else{
      axios
      .get(`${base_url.api1}/office_app/attendance/date_range?company_code=`+employeData.company_code+`&from=`+data.from+`&to=`+data.to+`&page=`+ value1 +`&size=`+rows, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then(
          (response) => {
            setGetPageNo(response.data.total_pages);
            setTotalCount(response.data.total_present);
            setCountNumber(value1-1);
            setNumberOfElements(response.data.Attendance.length);
            setEmployeeId(response.data.Attendance.emp_id);
            setsearch(false);
            setBackdropOpen(false);
            setdatefilter(true);
            let attData = response.data.Attendance;
            let attObj=[];
            for (let i = 0; i < attData.length; i++) {
              attObj.push({
                applied_rule_id: attData[i].applied_rule_id,
                company_id: attData[i].company_id,
                createad_at: attData[i].createad_at,
                emp_id: attData[i].emp_id,
                emp_name: attData[i].emp_name,
                end_time: attData[i].end_time,
                id: attData[i].id,
                ip_address: attData[i].ip_address,
                breaks:attData[i].breaks,
                late_start_time: attData[i].late_start_time,
                over_time_duration: attData[i].over_time_duration,
                session_date: attData[i].session_date,
                start_time: attData[i].start_time,
                type_of_shift: attData[i].type_of_shift,
                updated_at: attData[i].updated_at,
                working_duration: attData[i].working_duration,
                overtime_duration:attData[i].overtime_duration,
                total_work: getTotalTime(attData[i].start_time),
                break_duration: attData[i].breaks_array.reduce((a, b) => a + b, 0),
              })
            }
            setAttendance(attObj)
          },
          (error) => {
            setBackdropOpen(false);
            setdatefilter(true);
            setAttendance();
            console.log(JSON.stringify(error.response.data));
          }
        );
    }
  };


  const handlePageApply = (value1,date1,date2) => {
    setBackdropOpen(true);
    axios
    .get(`${base_url.api1}/office_app/attendance/date_range?company_code=`+employeData.company_code+`&from=`+date1+`&to=`+date2+`&page=`+ value1 +`&size=`+rows, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
         // setDateFilterStatus(true);
          setGetPageNo(response.data.total_pages);
          setTotalCount(response.data.total_present);
          setCountNumber(value1-1);
          setNumberOfElements(response.data.Attendance.length);
          setEmployeeId(response.data.Attendance.emp_id);
          setsearch(false);
          setBackdropOpen(false);
          let attData = response.data.Attendance;
          let attObj=[];
          for (let i = 0; i < attData.length; i++) {
            attObj.push({
              applied_rule_id: attData[i].applied_rule_id,
              company_id: attData[i].company_id,
              createad_at: attData[i].createad_at,
              emp_id: attData[i].emp_id,
              emp_name: attData[i].emp_name,
              end_time: attData[i].end_time,
              id: attData[i].id,
              ip_address: attData[i].ip_address,
              breaks:attData[i].breaks,
              late_start_time: attData[i].late_start_time,
              over_time_duration: attData[i].over_time_duration,
              session_date: attData[i].session_date,
              start_time: attData[i].start_time,
              type_of_shift: attData[i].type_of_shift,
              updated_at: attData[i].updated_at,
              working_duration: attData[i].working_duration,
              overtime_duration:attData[i].overtime_duration,
              total_work: getTotalTime(attData[i].start_time),
              break_duration: attData[i].breaks_array.reduce((a, b) => a + b, 0),
            })
          }
          setAttendance(attObj);
          document.getElementById("myForm").reset();
        },
        (error) => {
        //  setDateFilterStatus(false);
          setBackdropOpen(false);
          setAttendance();
          console.log(JSON.stringify(error.response.data));
        }
      );
  };

  const handleChangeDate = (e) =>{
    setBackdropOpen(true);
    let dt = e.target.value;
    setPastDateValue(dt);
    if(dt != '') {
      setDate2(datenow)
      let datepast = moment().subtract(dt,"d").format("YYYY-MM-DD");
      setDate1(datepast)
      axios.get(`${base_url.api1}/office_app/attendance/date_range?company_code=`+ employeData.company_code + `&from=` + datepast  + `&to=` + datenow +`&page=`+ pageNo +`&size=`+rows  ,{
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          console.log(response.data);
          setGetPageNo(response.data.total_pages);
          console.log(response.data.total_pages);
          setTotalCount(response.data.total_present);
          console.log(response.data.total_present);
          setCountNumber(pageNo-1);
          console.log(pageNo-1);
          setNumberOfElements(response.data.Attendance.length);
          console.log(response.data.Attendance.length);
          setEmployeeId(response.data.Attendance.emp_id);
           setsearch(false);
          setBackdropOpen(false);
          setDateFilterStatus(true);
          let attData = response.data.Attendance;
          let attObj=[];
          for (let i = 0; i < attData.length; i++) {
            attObj.push({
              applied_rule_id: attData[i].applied_rule_id,
              company_id: attData[i].company_id,
              createad_at: attData[i].createad_at,
              emp_id: attData[i].emp_id,
              emp_name: attData[i].emp_name,
              end_time: attData[i].end_time,
              id: attData[i].id,
              ip_address: attData[i].ip_address,
              breaks:attData[i].breaks,
              late_start_time: attData[i].late_start_time,
              over_time_duration: attData[i].over_time_duration,
              session_date: attData[i].session_date,
              start_time: attData[i].start_time,
              type_of_shift: attData[i].type_of_shift,
              updated_at: attData[i].updated_at,
              working_duration: attData[i].working_duration,
              overtime_duration:attData[i].overtime_duration,
              total_work: getTotalTime(attData[i].start_time),
              break_duration: attData[i].breaks_array.reduce((a, b) => a + b, 0),
            })
          }
          setAttendance(attObj)
        },
        (error) => {
          setBackdropOpen(false);
          setDateFilterStatus(true);
          setAttendance();
        }
      );
    } else {
      axios.get(`${base_url.api1}/office_app/attendance/date_range?company_code=`+ employeData.company_code + `&from=` + datenow  + `&to=` + datenow +`&page=`+ pageNo +`&size=`+rows  ,{
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          console.log(response.data);
          setGetPageNo(response.data.total_pages);
          console.log(response.data.total_pages);
          setTotalCount(response.data.total_present);
          console.log(response.data.total_present)
          setCountNumber(pageNo-1);
          console.log(pageNo-1);
          setNumberOfElements(response.data.Attendance.length);
          console.log(response.data.Attendance.length);
          setEmployeeId(response.data.Attendance.emp_id);
           setsearch(false);
          setBackdropOpen(false);
          let attData = response.data.Attendance;
          let attObj=[];
         let totalBreak = response.data.Attendance.breaks;
          console.log(totalBreak);
          let breakObj=[];
          for (let i = 0; i < attData.length; i++) {
            attObj.push({
              applied_rule_id: attData[i].applied_rule_id,
              company_id: attData[i].company_id,
              createad_at: attData[i].createad_at,
              emp_id: attData[i].emp_id,
              emp_name: attData[i].emp_name,
              end_time: attData[i].end_time,
              id: attData[i].id,
              ip_address: attData[i].ip_address,
              breaks:attData[i].breaks,
              late_start_time: attData[i].late_start_time,
              over_time_duration: attData[i].over_time_duration,
              session_date: attData[i].session_date,
              start_time: attData[i].start_time,
              type_of_shift: attData[i].type_of_shift,
              updated_at: attData[i].updated_at,
              working_duration: attData[i].working_duration,
              overtime_duration:attData[i].overtime_duration,
              total_work: getTotalTime(attData[i].start_time),
              break_duration: attData[i].breaks_array.reduce((a, b) => a + b, 0),
            })
          }
          setAttendance(attObj)
        },
        (error) => {
          setBackdropOpen(false);
          setAttendance();
        }
      );
    }
  }

  const [prevDay , setprevDay] = useState();
  var startdate = moment();
  const [nextDay, setNextDay] = useState();
  const [currentDay,setCurrentDay] =useState(startdate);
  const [liveDate,setLiveDate] = useState()
 // console.log(currentDay);

  const handleChangePrev = (e) =>{
    
    let s1 ;
    s1 = currentDay.subtract(1, "days");
    console.log(s1.format("YYYY-MM-DD"));
    setCurrentDay(s1);
   setBackdropOpen(true);
   setdatevalue( `${s1.format("DD-MM-YYYY")}`);
      axios.get(`${base_url.api1}/office_app/attendance/date_range?company_code=`+ employeData.company_code + `&from=` + s1.format("YYYY-MM-DD")  + `&to=` + s1.format("YYYY-MM-DD") +`&page=`+ pageNo +`&size=`+rows  ,{
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          console.log(response.data);
          setLiveDate()
          setGetPageNo(response.data.total_pages);
          console.log(response.data.total_pages);
          setTotalCount(response.data.total_present);
          console.log(response.data.total_present)
          setCountNumber(pageNo-1);
          console.log(pageNo-1);
          setNumberOfElements(response.data.Attendance.length);
          console.log(response.data.Attendance.length);
          setEmployeeId(response.data.Attendance.emp_id);
           setsearch(false);
          setBackdropOpen(false);
          let attData = response.data.Attendance;
          let attObj=[];
          for (let i = 0; i < attData.length; i++) {
            attObj.push({
              applied_rule_id: attData[i].applied_rule_id,
              company_id: attData[i].company_id,
              createad_at: attData[i].createad_at,
              emp_id: attData[i].emp_id,
              emp_name: attData[i].emp_name,
              end_time: attData[i].end_time,
              id: attData[i].id,
              ip_address: attData[i].ip_address,
              breaks:attData[i].breaks,
              late_start_time: attData[i].late_start_time,
              over_time_duration: attData[i].over_time_duration,
              session_date: attData[i].session_date,
              start_time: attData[i].start_time,
              type_of_shift: attData[i].type_of_shift,
              updated_at: attData[i].updated_at,
              working_duration: attData[i].working_duration,
              overtime_duration:attData[i].overtime_duration,
              total_work: getTotalTime(attData[i].start_time),
              break_duration: attData[i].breaks_array.reduce((a, b) => a + b, 0),
            })
          }
          setAttendance(attObj)
        },
        (error) => {
          setBackdropOpen(false);
          setAttendance();
        }
      );
   
  }
 // console.log(currentDay);


  const handleChangeNext = (e) =>{
    let s2 ;
    s2 = currentDay.add(1, "days");
    console.log(s2.format("YYYY-MM-DD"));
    setCurrentDay(s2);
    setBackdropOpen(true);
    setdatevalue( `${s2.format("DD-MM-YYYY")}`);
      axios.get(`${base_url.api1}/office_app/attendance/date_range?company_code=`+ employeData.company_code + `&from=` + s2.format("YYYY-MM-DD")  + `&to=` + s2.format("YYYY-MM-DD") +`&page=`+ pageNo +`&size=`+rows  ,{
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          console.log(response.data);
          setGetPageNo(response.data.total_pages);
          console.log(response.data.total_pages);
          setTotalCount(response.data.total_present);
          console.log(response.data.total_present);
          setCountNumber(pageNo-1);
          console.log(pageNo-1);
          setNumberOfElements(response.data.Attendance.length);
          console.log(response.data.Attendance.length);
          setEmployeeId(response.data.Attendance.emp_id);
           setsearch(false);
          setBackdropOpen(false);
          let attData = response.data.Attendance;
          let attObj=[];
          for (let i = 0; i < attData.length; i++) {
            attObj.push({
              applied_rule_id: attData[i].applied_rule_id,
              company_id: attData[i].company_id,
              createad_at: attData[i].createad_at,
              emp_id: attData[i].emp_id,
              emp_name: attData[i].emp_name,
              end_time: attData[i].end_time,
              id: attData[i].id,
              ip_address: attData[i].ip_address,
              breaks:attData[i].breaks,
              late_start_time: attData[i].late_start_time,
              over_time_duration: attData[i].over_time_duration,
              session_date: attData[i].session_date,
              start_time: attData[i].start_time,
              type_of_shift: attData[i].type_of_shift,
              updated_at: attData[i].updated_at,
              working_duration: attData[i].working_duration,
              overtime_duration:attData[i].overtime_duration,
              total_work: getTotalTime(attData[i].start_time),
              break_duration: attData[i].breaks_array.reduce((a, b) => a + b, 0),
            })
          }
          setAttendance(attObj)
        },
        (error) => {
          setBackdropOpen(false);
          setAttendance();
        }
      );
  }


  const handlePage = (event, value) => {
    setPaginationPageNo(value);
    setPageNo(value);
    console.log("entening1");
    if(dateFilterStatus) {
      console.log("entering2");
      console.log(date1);
      console.log(date2);
      handlePageApply(value,date1,date2);
    } else if(datefilter){
      console.log("entering3"); 
      handlePageApply(value,date1,date2);
    }
    else {
      getAllAttendanceList(value);
    }
  };
  const [searchinput, setsearchinput] = useState();
  const handlesearch = (e, value1) => {
    setdisplay("none");
    setBackdropOpen(true);
    setsearch(true);
    let srchdata = e.target[0].value.trim();
    console.log(srchdata);
    setsearchinput(srchdata);
    axios
      .get(`${base_url.api1}/office_app/attendance/list?company_code=` + employeData.company_code + `&page=` + pageNo + `&size=` + rows +`&search=`+ srchdata , {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setGetPageNo(response.data.total_pages);
          setTotalCount(response.data.total_present);
          setCountNumber(pageNo-1);
          setNumberOfElements(response.data.Attendance.length);
          setEmployeeId(response.data.Attendance.emp_id);
          setBackdropOpen(false);
          let attData = response.data.Attendance;
          let attObj=[];
          for (let i = 0; i < attData.length; i++) {
            attObj.push({
              applied_rule_id: attData[i].applied_rule_id,
              company_id: attData[i].company_id,
              createad_at: attData[i].createad_at,
              emp_id: attData[i].emp_id,
              emp_name: attData[i].emp_name,
              end_time: attData[i].end_time,
              id: attData[i].id,
              ip_address: attData[i].ip_address,
              breaks:attData[i].breaks,
              late_start_time: attData[i].late_start_time,
              over_time_duration: attData[i].over_time_duration,
              session_date: attData[i].session_date,
              start_time: attData[i].start_time,
              type_of_shift: attData[i].type_of_shift,
              updated_at: attData[i].updated_at,
              working_duration: attData[i].working_duration,
              overtime_duration:attData[i].overtime_duration,
              total_work: getTotalTime(attData[i].start_time),
              break_duration: attData[i].breaks_array.reduce((a, b) => a + b, 0),
            })
          }
          setAttendance(attObj)
        },
        (error) => {
          console.log(JSON.stringify(error.response.data));
          setAttendance();
          setBackdropOpen(false);
        },

      );
  };

  const [employeeId , setEmployeeId] = useState();
  const [getId, setId] = useState();
  const ViewBreak = (elem,attendance) => {
    console.log(attendance);
    getIndAttendanceList(attendance.id);
    setId(attendance.id);
  };

  const getIndAttendanceList = (select_att_id) => {
    setBackdropOpen(true);
    axios
      .get(`${base_url.api1}/office_app/attendance?company_code=` + employeData.company_code + `&id=`+ select_att_id, {
        headers: {
        Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setBackdropOpen(false);
          console.log(response.data.Attendance);
          setIndAttendance(response.data.Attendance);
          console.log(response.data.Attendance);
          //document.getElementById("myForm").reset();
        },
        (error) => {
            setBackdropOpen(false);
            console.log(JSON.stringify(error.response.data));
        }
      );
  };

  const handleRows = (event) => {
    setRows(event.target.value);
  };

  const [modalCreate, setCreate] = React.useState(false);
  const closeModal = () => {
    setCreate(false);
  }

  const [modalCreate1, setCreate1] = React.useState(false);
  const closeModal1 = () => {
    setCreate1(false);
  }

  const createModalConfig = () => {
    setCreate(true);
  };

  const createModalAttendance = () => {
    setCreate1(true);
  }

  const sortUser = (e) => {
    console.log("coming");
    setBackdropOpen(true);
    setsortState(e.target.id);
    console.log(e.target.id);
    if (direction == "Desc") {
      setdirection("ASC");
    } else if (direction == "ASC") {
      setdirection("Desc");
    }
    let sortby = e.target.id
    console.log(sortby);
    axios
      .get(`${base_url.api1}/office_app/attendance/list?company_code=` + employeData.company_code + `&sortby=`+sortby+`&Order=`+direction +`&page=`+pageNo +`&size=` +rows , {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setGetPageNo(response.data.total_pages);
          setTotalCount(response.data.total_present);
          setCountNumber(pageNo-1);
          setNumberOfElements(response.data.Attendance.length);
          setEmployeeId(response.data.Attendance.emp_id);
          setsearch(false);
          setBackdropOpen(false);
          let attData = response.data.Attendance;
          let attObj=[];
          for (let i = 0; i < attData.length; i++) {
            attObj.push({
              applied_rule_id: attData[i].applied_rule_id,
              company_id: attData[i].company_id,
              createad_at: attData[i].createad_at,
              emp_id: attData[i].emp_id,
              emp_name: attData[i].emp_name,
              end_time: attData[i].end_time,
              id: attData[i].id,
              ip_address: attData[i].ip_address,
              breaks:attData[i].breaks,
              late_start_time: attData[i].late_start_time,
              over_time_duration: attData[i].over_time_duration,
              session_date: attData[i].session_date,
              start_time: attData[i].start_time,
              type_of_shift: attData[i].type_of_shift,
              updated_at: attData[i].updated_at,
              working_duration: attData[i].working_duration,
              overtime_duration:attData[i].overtime_duration,
              total_work: getTotalTime(attData[i].start_time),
              break_duration: attData[i].breaks_array.reduce((a, b) => a + b, 0),
            })
          }
          setAttendance(attObj)
        },
        (error) => {
          setBackdropOpen(false);
          setAttendance();
          console.log(JSON.stringify(error.response.data));
        }
      );
  };



  return (
    <>
    <div>
      <ToastContainer />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="commoncomponent_cardcss">
        <div>
          <Row>
            <Col md={6}>
              <CardText
                style={{ marginTop: "-10px" }}
              >
                <h3>Attendance Report <RefreshIcon style={{cursor:'pointer'}} 
                onClick={()=> {
                setdatevalue("Click to open");
                setPastDateValue();
                setdatefilter(false);
                setDateFilterStatus(false);
                getAllAttendanceList(1,datenow,datenow);  
                }} /></h3>
              </CardText>
            </Col>
            <Col md={6} className="d-flex justify-content-end">
              {permissionObj?.includes(31)?
                <Button
                  color="primary"
                  onClick={createModalAttendance}
                >
                  + Add
                </Button>
              :null}
            </Col>
            <Modal
            ariaHideApp={false}
            isOpen={modalCreate1}
            onRequestClose={closeModal1}
            style={customStyles2}
            contentLabel="Example Modal"
            >
              <h2>
                <strong>Adding Attendance</strong>
              </h2>
              <form id="myForm" onSubmit={handleSubmit(createAttendance)}>
                <div>
                  <div className="form-group creatadmin">
                  <Row>
                      <Col md={12}>
                      <label>Employee Name:<span style={{ color: "red" }}>*</span></label>
                      <Select
                        {...register('emp_name',{ required: true,})}
                        //className={classes.selectdrop}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={onSelect}
                        value2={selected}
                        options={availableRoleById}
                      >
                      </Select>
                      {errors.emp_name && (
                          <p className="errormessage">Employee Name  is Required</p>
                        )}
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col md={12}>
                        <label>
                        StartTime<span style={{ color: "red" }}>*</span>:
                        </label>
                        <input
                          type="datetime-local"
                          className="form-control underline-input"
                          placeholder="Start Time"
                          id="start_time"
                          {...register("start_time",{ required: true })}
                        />
                        {errors.start_time && (
                          <p className="errormessage">StartTime  is Required</p>
                        )}
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col md={12}>
                        <Row className="justify-content-end mt-2">
                          <Button
                            color="secondary"
                            onClick={() => setCreate1(false)}
                          >
                            Cancel
                          </Button>
                          <Button
                            type="submit"
                            color="primary"
                            className="btn listing_addbutton ml-1"
                          >
                            Submit
                          </Button>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </div>
              </form>
            </Modal>
          </Row>
          <br/>
          <div className="newlisting_headertabdesign">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <Row>
                <div style={{ marginRight: "7px" }}>
                  {/* <form className="master">
                    <div style={{ marginTop: "-10px", width: "fit-content" }}>
                      <label className="area_rowslable">Limit</label>
                      <br />
                      <FormControl className={classes.formControl}>
                        <Select
                         className={classes.selectlimit}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          onChange={handleRows}
                          value={rows}
                        >
                          <MenuItem value={20}>20</MenuItem>
                          <MenuItem value={50}>50</MenuItem>
                          <MenuItem value={100}>100</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </form> */}
                </div>
                <div style={{ marginTop: "-10px", marginRight: "14px" }}>
                {/* <Button  
                     value="1"
                     className={classes.prevButton}
                     onClick={(e) => handleChangePrev(e)}
                     color="info">Prev</Button> */}
                     <div onClick={(e) => handleChangePrev(e)}  className={classes.prevButton}>
                     <ArrowBackIosIcon  style={{marginRight:"-10px"}}/> <strong  style={{ color: "red",  }}>Prev</strong></div>
                     </div>
                <div style={{ marginTop: "-10px", marginRight: "14px" }}>
                  <label className="area_rowslable">Date</label>
                  <DateRangePicker
                        onApply={(event, picker)=>handleApply(event, picker,1)}
                      >
                    <p className="datepickerrangevaluetext" style={{borderRadius:"10px"}}>{datevalue}</p>
                  </DateRangePicker>
                  {datefilter ? (
                    <div>
                      <a
                        className={classes.clrsrch}
                        onClick={() => {
                          setdisplay("inline");
                          setdatevalue("Click to open");
                          setPastDateValue();
                          setBackdropOpen(true);
                          setdatefilter(false);
                          setPageNo(1);
                          getAllAttendanceList(1,datenow,datenow);
                        }}
                      >
                        Clear Filter
                      </a>
                    </div>
                  ) : null}
                </div>
                <div style={{ marginTop: "-10px", marginRight: "14px" }}>
                {/* <Button
                     value="1"
                     className={classes.nextButton}
                     onClick={(e) => {
                                e.preventDefault();
                                handleChangeNext(e);
                                }}
                    color="info">Next</Button> */}
                 <div className={classes.nextButton}  onClick={(e) => { e.preventDefault(); handleChangeNext(e);}}>
                  <strong style={{ color: "red",}} >Next</strong> 
                  <ArrowForwardIosIcon/></div>
                  </div>
                    <div style={{ marginTop: "-10px", marginRight: "14px" }}>
                      <label>
                        <strong>Past Date:</strong>
                      </label>
                      <br />
                      <select
                        className={classes.select}
                        onChange={handleChangeDate}
                        value={pastDateValue}
                      >
                        <option value="">-Select Range-</option>
                        <option value="7"> 7 Days</option>
                        <option value="30"> 30 Days</option>
                        <option value="90"> 90 Days </option>
                        <option value="181">Half Yearly</option>
                        <option value="365">Yearly</option>
                      </select>
                      {dateFilterStatus ? (
                    <div>
                      <a
                        className={classes.clrsrch}
                        onClick={() => {
                          setPastDateValue('');
                          setdisplay("inline");
                          setdatevalue("Click to open");
                          setBackdropOpen(true);
                          setDateFilterStatus(false);
                          setPageNo(1);
                          getAllAttendanceList(1,datenow,datenow);
                        }}
                      >
                        Clear Filter
                      </a>
                    </div>
                  ) : null}
                    </div>
              </Row>
              <div>
              
              <div className="form-group">
                  <div>
                    <div className="clearsearchclass">
                      <form
                        id="myForm"
                        className="d-flex"
                        onSubmit={(e) => {
                        e.preventDefault();
                        handlesearch(e);
                    }}
                      >
                        <input
                          style={{
                            padding: "5px",
                            border: "2px solid",
                            marginTop: "-3px",
                            marginRight:"50px",
                          }}
                          className="form-control"
                          placeholder="Search.."
                          name="search"
                          required
                        />
                        <button
                          className={classes.srchbtn1}
                          type="submit"
                        >
                          <i className="fa fa-search"></i>
                        </button>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <div className="arealimit" style={{ marginTop:"-20px",marginRight:"-65px"}}>
                        <label className="area_rowslable">Show:</label>
                        </div>
                      <FormControl variant="standard" sx={{ m: 1, minWidth: 80 ,marginTop: 0, maxHeight: 80}}>
                        <select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={rows}
                          onChange={handleRows}
                          label="Show"
                        >
                          <option value={20}>20</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                      </FormControl>
                      <div>
                      </div>
                      </form>
                      {searchdata ? (
                        <a 
                        className={classes.clrsrch} 
                        onClick={() => {
                            setdisplay("inline");
                            setBackdropOpen(true);
                            setPastDateValue();
                            setPageNo(1);
                            setdirection("Desc");
                            //setsearch(false);
                            getAllAttendanceList(1,date1,date2);
                          }}                            
                        >
                          Clear Search
                        </a>
                      ) : null}
                    </div>                      
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br/>
          <Row>
               <Col md={4}>
               <label><strong>Employee Name:</strong></label>
                      <Select
                        className="basic-multi-select"
                        classNamePrefix="select"
                        value={selectEmployeeData}
                        onChange={onSelectEmployee}
                        options={availableRoleByAllId}
                      >
                      </Select>
                      {selectemployee ? (
                        <div>
                          <a
                            className={classes.clrsrch}
                            onClick={() => {
                              setBackdropOpen(true);
                              getAllAttendanceList(1,datenow,datenow)
                              setSelectedEmployeeData({value: null, label: null});
                              setSelectedEmployee(false);
                            }}
                          >
                            Clear Filter
                          </a>
                        </div>
                      ) : null}
               </Col>
               <Col md={8}>

               </Col>
          </Row> 
               <br />
          <div className="newlisting_headertabdesign">
            <Table responsive striped>
              <thead>
                <tr>
                  <th style={{ width: "50px" }}>SN</th>
                  <th style={{ width: "175px" }}>
                    Name{" "}
                    <UnfoldMoreIcon onClick={sortUser} id="emp_name"/>
                  </th>
                  <th style={{ width: "200px" }}>
                    Starttime{" "}
                    <UnfoldMoreIcon onClick={sortUser} id="start_time"/>
                  </th>
                  <th style={{ width: "200px" }}>
                    Endtime{" "}
                    <UnfoldMoreIcon onClick={sortUser} id="end_time"/>
                  </th>
                  <th style={{ width: "200px" }}>
                      working Hours{" "}
                      <UnfoldMoreIcon onClick={sortUser} id="working_duration"/>
                  </th>
                  <th style={{ width: "200px" }}>
                       OverTime{" "}
                  </th>
                  <th style={{ width: "175px" }}>
                    Break Duration{" "}
                  </th>
                  <th style={{width: "100px" }}>
                     Action{" "}  
                  </th>
                </tr>
              </thead>
              <tbody>
                {attendanceData != null ? (
                 attendanceData &&
                 attendanceData.map((attendance , index) => (
                    <tr>
                      <th>{countNumber * rows + 1 + index}</th>
                      <td>{attendance.emp_name}</td>
                      <td>
                        {attendance.start_time ?
                          moment(attendance.start_time.split("T")[0]).format("DD MMM YYYY")
                        : null}
                        <br />
                        {attendance.start_time?.split('T')[1].split('Z')[0]}
                      </td>
                      <td>
                        {attendance.end_time ?
                          moment(attendance.end_time.split("T")[0]).format("DD MMM YYYY")
                        : null}
                        <br />
                        {attendance.end_time?.split('T')[1].split('Z')[0]}
                      </td> 
                      <td>
                        {(attendance.working_duration == null) || (attendance.end_time == null) ?
                          attendance.total_work+' hr'
                        :
                          moment.utc(attendance.working_duration*1000).format('HH:mm')+' hr'
                        }
                      </td>
                      <td>
                        {attendance.overtime_duration != null?
                          moment.utc(attendance.overtime_duration*1000).format('HH:mm')+' hr':null
                        }
                      </td>
                      <td>
                        {attendance.break_duration?
                          moment.utc(attendance.break_duration*1000).format('HH:mm')+' hr':null
                        }
                      </td>               
                      <td>
                      
                        <Button
                          id={attendance.emp_id}
                          style={{
                          marginTop: "20px",
                          marginLeft: "5px",
                        }}
                        onClick={(e) => {
                                e.preventDefault();
                                 ViewBreak(e, attendance);
                                // DeleteIndUSer();
                                createModalConfig();
                              }}
                         color="info">View</Button>
                        <Modal
                         ariaHideApp={false}
                         isOpen={modalCreate}
                         onRequestClose={closeModal}
                         style={customStyles2}
                         contentLabel="Example Modal"
                         >
                        <h2>
                          <strong>View BreakDurations</strong>
                        </h2>
                        <br/>
                        <div className="newlisting_headertabdesign">
                         <Table responsive striped>
                         <thead>
                         <tr>
                         <th style={{ width: "180px" }}>
                         Break StartTime{" "}
                         <UnfoldMoreIcon id="emp_name"/>
                         </th>
                         <th style={{ width: "175px" }}>
                         Break EndTime{" "}
                         <UnfoldMoreIcon id="session_date"/>
                         </th>
                         <th style={{ width: "180px" }}>
                          Break Durations{" "}
                         <UnfoldMoreIcon id="session_date"/>
                         </th>
                         </tr>
                         </thead>
                         <tbody>
                         {indAttenance.breaks != null ? (
                          indAttenance.breaks &&
                          indAttenance.breaks.map((indAttd , index) => (
                         <tr>
                         <td>
                        {indAttd.break_start_time?.split('T')[1].split('Z')[0]}
                         </td>
                         <td>
                         {indAttd.break_end_time?.split('T')[1].split('Z')[0]}  
                        </td>
                        <td>
                        {indAttd.break_duration?
                          moment.utc(indAttd.break_duration*1000).format('HH:mm:ss')+' hr':null
                        }
                        </td>
                        </tr> 
                        ))) : (
                        <p style={{width:'500%'}}>No Records Found</p>
                         )}   
                         </tbody>
                         </Table>
                         </div>
                         <br />
                       <Row>
                      <Col md={12}>
                        <Row className="justify-content-end mt-2">
                          <Button
                            color="secondary"
                            onClick={() => setCreate(false)}
                          >
                            Close
                          </Button>
                        </Row>
                      </Col>
                    </Row>
                      </Modal>
                      </td>         
                    </tr>
                  ))) : (
                  <p style={{width:'500%'}}>{null}</p>
                )}
                
            </tbody>
            </Table>
            <Row>
            <Col md={6}>
              {attendanceData?.length ? (
                <p style={{ marginTop: "15px" }}>
                  {countNumber * rows + 1} to{" "}
                  {countNumber * rows + numberOfElements} of {totalcount} records
                </p>
              ) : (
                <p className="formfooterrecordstyle">No Record Found</p>
              )}
            </Col>
            <Col md={6}>
              <div className={classes.root}>
                {/* {searchdata || dateFilterStatus ? (
                  <Pagination
                    page={paginationPageNo}
                    count={getPageNo}
                   onChange={handlesearch}
                  />
                ) : ( */}
                  <Pagination
                    page={paginationPageNo}
                    count={getPageNo}
                    onChange={handlePage}
                  />
                {/* )} */}
              </div>
            </Col>
          </Row>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default AttendanceList ;