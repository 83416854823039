import React , { useState, useEffect }from 'react';
import { ToastContainer, toast } from "react-toastify";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Link, useNavigate } from "react-router-dom";
import { CardText, Col, Row, Button, Table } from "reactstrap";
import AddIcon from "@mui/icons-material/Add";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Pagination from "@mui/material/Pagination";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import { customStyles, useStyles, customStyles2 } from "./../../Utilities/CSSUtilities";
import axios from "axios";
import base_url from '../../api/bootapi';
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { logout, selectEmployee } from "../../components/features/empSlice";
import { useToasts } from 'react-toast-notifications';
import DateRangePicker from "react-bootstrap-daterangepicker";
import FormControl from '@mui/material/FormControl';

const PendingLeaveApplication = () => {
  const [backdropOpen, setBackdropOpen] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const jwtToken = "JWT " + localStorage.getItem("JWTToken");
  const [leaveData,setLeaveData] =useState([]);
  const employeData = useSelector(selectEmployee);
  const [pageNo, setPageNo] = useState(1);
  const [paginationPageNo, setPaginationPageNo] = useState(1);
  const [getPageNo, setGetPageNo] = useState(0);
  const [rows, setRows] = useState(3);
  const [countNumber, setCountNumber] = useState();
  const [numberOfElements, setNumberOfElements] = useState();
  const [totalCount, setTotalCount] = useState();
  const [searchData, setSearchData] = useState();
  const [searchStatus, setSearchStatus] = useState();
  const [dateFilter, setDateFilter] = useState();
  const [dateStatus, setDateStatus] = useState(false);
  const [declineNewData, setDeclineNewData] = useState();
  const navigate = useNavigate();
  const classes = useStyles();
  const { addToast } = useToasts();

  const closeModal = () => {
    setApproveLeave(false);
    setDeclineLeave(false);
  }

  useEffect( () => {
    getPermissions();
  },[rows]);

  const handleRows = (event) => {
    setRows(event.target.value);
  };

  const getPermissions = () => {
    const resource_obj = [];

    for (let i = 0; i < employeData.role_permissions.length; i++) {
      resource_obj.push(employeData.role_permissions[i].resource_id);
    }
    var available_resource = resource_obj.includes(42);

    if(available_resource) {
      if (localStorage.getItem("LeavePageNo")) {
        let currentPage = localStorage.getItem("LeavePageNo");
        setPageNo(currentPage);
        setPaginationPageNo(Number(currentPage));
        getAllLeaveList(currentPage);
      } else {
        setPageNo(1);
        setPaginationPageNo(1);
        getAllLeaveList(1);
      }
    } else {
      navigate('/errorPage');
    }
  };

  const [dateValue, setDateValue] = useState("Click to open");
  const handleApply = (event, picker, value1) => {
    setBackdropOpen(true);
    setDateValue(
      `${picker.startDate.format("DD-MM-YYYY")} to ${picker.endDate.format(
        "DD-MM-YYYY"
      )}`
    );
    let data = {
      from: picker.startDate.format("YYYY-MM-DD"),
      to: picker.endDate.format("YYYY-MM-DD"),
    };
    setDateFilter(data);
    setDateStatus(true);

    if((employeData.emp_role_id == 1) || (employeData.emp_role_id == 2) || (employeData.emp_role_id == 3)) {
      axios
        .get(`${base_url.api1}/myappapi/leaveapp/date?company_code=`+employeData.company_code+`&from=`+data.from+`&to=`+data.to+`&page=`+value1+`&size=`+rows+`&status=2`, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then(
          (response) => {
            setGetPageNo(response.data.total_pages);
            setLeaveData(response.data.Leaves);
            setCountNumber(value1-1);
            setTotalCount(response.data.total_count);
            setNumberOfElements(response.data.Leaves.length);
            setSearchStatus(false);
            setBackdropOpen(false);
          },
          (error) => {
            setGetPageNo(0);
            setLeaveData();
            setCountNumber(0);
            setNumberOfElements(0);
            setSearchStatus(false);
            setBackdropOpen(false);
          }
        );
    } else {
      axios
      .get(`${base_url.api1}/myappapi/leaveapp/date?company_code=`+employeData.company_code+`&from=`+data.from+`&to=`+data.to+`&page=`+value1+`&size=`+rows+`&emp_id=`+employeData.emp_id+`&status=2`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setGetPageNo(response.data.total_pages);
          setLeaveData(response.data.Leaves);
          setCountNumber(value1-1);
          setTotalCount(response.data.total_count);
          setNumberOfElements(response.data.Leaves.length);
          setSearchStatus(false);
          setBackdropOpen(false);
        },
        (error) => {
          setGetPageNo(0);
          setLeaveData();
          setCountNumber(0);
          setNumberOfElements(0);
          setSearchStatus(false);
          setBackdropOpen(false);
        }
      );
    }
  };

  const handlePageApply = (value1) => {
    setBackdropOpen(true);
    if((employeData.emp_role_id == 1) || (employeData.emp_role_id == 2) || (employeData.emp_role_id == 3)) {
      axios
        .get(`${base_url.api1}/myappapi/leaveapp/date?company_code=`+employeData.company_code+`&from=`+dateFilter.from+`&to=`+dateFilter.to+`&page=`+value1+`&size=`+rows+`&status=2`, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then(
          (response) => {
            setGetPageNo(response.data.total_pages);
            setLeaveData(response.data.Leaves);
            setCountNumber(value1-1);
            setTotalCount(response.data.total_count);
            setNumberOfElements(response.data.Leaves.length);
            setSearchStatus(false);
            setBackdropOpen(false);
          },
          (error) => {
            setGetPageNo(0);
            setLeaveData();
            setCountNumber(0);
            setNumberOfElements(0);
            setSearchStatus(false);
            setBackdropOpen(false);
          }
        );
    } else {
      axios
      .get(`${base_url.api1}/myappapi/leaveapp/date?company_code=`+employeData.company_code+`&from=`+dateFilter.from+`&to=`+dateFilter.to+`&page=`+value1+`&size=`+rows+`&emp_id=`+employeData.emp_id+`&status=2`, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setGetPageNo(response.data.total_pages);
          setLeaveData(response.data.Leaves);
          setCountNumber(value1-1);
          setTotalCount(response.data.total_count);
          setNumberOfElements(response.data.Leaves.length);
          setSearchStatus(false);
          setBackdropOpen(false);
        },
        (error) => {
          setGetPageNo(0);
          setLeaveData();
          setCountNumber(0);
          setNumberOfElements(0);
          setSearchStatus(false);
          setBackdropOpen(false);
        }
      );
    }
  };

  const handlePage = (event, value) => {
    setPaginationPageNo(value);
    setPageNo(value);
    if(searchStatus) {
      console.log('Coming Search');
      submitPageSearch(value);
    } else if(dateStatus) {
      console.log('Coming Date');
      handlePageApply(value);
    } else {
      console.log('Coming Normal');
      getAllLeaveList(value);
    }
  };

  const submitSearch = (e) => {
    setSearchStatus(true);
    let srchdata = e.target[0].value.trim();
    setSearchData(srchdata);
    setBackdropOpen(true);
    if((employeData.emp_role_id == 1) || (employeData.emp_role_id == 2) || (employeData.emp_role_id == 3)) {
      axios
        .get(`${base_url.api1}/myappapi/leaveapp/list?company_code=`+employeData.company_code+`&page=1&size=`+rows+`&query=`+srchdata+`&status=2`, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then(
          (response) => {
            setGetPageNo(response.data.total_pages);
            setLeaveData(response.data.leaveapp);
            setCountNumber(0);
            setTotalCount(response.data.total_count);
            setNumberOfElements(response.data.leaveapp.length);
            setBackdropOpen(false);
          },
          (error) => {
            setGetPageNo(0);
            setLeaveData();
            setCountNumber(0);
            setNumberOfElements(0);
            setBackdropOpen(false);
            console.log(JSON.stringify(error.response.data));
          }
        );
    } else {
      axios
        .get(`${base_url.api1}/myappapi/leaveapp/list?company_code=`+employeData.company_code+`&emp_id=`+employeData.emp_id+`&page=1&size=`+rows+`&query=`+srchdata+`&status=2`, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then(
          (response) => {
            setGetPageNo(response.data.total_pages);
            setLeaveData(response.data.leaveapp);
            setCountNumber(0);
            setTotalCount(response.data.total_count);
            setNumberOfElements(response.data.leaveapp.length);
            setBackdropOpen(false);
          },
          (error) => {
            setGetPageNo(0);
            setLeaveData();
            setCountNumber(0);
            setNumberOfElements(0);
            setBackdropOpen(false);
            console.log(JSON.stringify(error.response.data));
          }
        );
    }
  };

  const submitPageSearch = (value1) => {
    setSearchStatus(true);
    setBackdropOpen(true);
    if((employeData.emp_role_id == 1) || (employeData.emp_role_id == 2) || (employeData.emp_role_id == 3)) {
      axios
        .get(`${base_url.api1}/myappapi/leaveapp/list?company_code=`+employeData.company_code+`&page=`+value1+`&size=`+rows+`&query=`+searchData+`&status=2`, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then(
          (response) => {
            setGetPageNo(response.data.total_pages);
            setLeaveData(response.data.leaveapp);
            setCountNumber(0);
            setTotalCount(response.data.total_count);
            setNumberOfElements(response.data.leaveapp.length);
            setBackdropOpen(false);
          },
          (error) => {
            setGetPageNo(0);
            setLeaveData();
            setCountNumber(0);
            setNumberOfElements(0);
            setBackdropOpen(false);
            console.log(JSON.stringify(error.response.data));
          }
        );
    } else {
      axios
        .get(`${base_url.api1}/myappapi/leaveapp/list?company_code=`+employeData.company_code+`&emp_id=`+employeData.emp_id+`&page=1&size=`+rows+`&query=`+searchData+`&status=2`, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then(
          (response) => {
            setGetPageNo(response.data.total_pages);
            setLeaveData(response.data.leaveapp);
            setCountNumber(0);
            setTotalCount(response.data.total_count);
            setNumberOfElements(response.data.leaveapp.length);
            setBackdropOpen(false);
          },
          (error) => {
            setGetPageNo(0);
            setLeaveData();
            setCountNumber(0);
            setNumberOfElements(0);
            setBackdropOpen(false);
            console.log(JSON.stringify(error.response.data));
          }
        );
    }
  };

  const getAllLeaveList = (value1) => {
    setBackdropOpen(true);
    if((employeData.emp_role_id == 1) || (employeData.emp_role_id == 2) || (employeData.emp_role_id == 3)) {
      axios
        .get(`${base_url.api1}/myappapi/leaveapp/list?company_code=`+employeData.company_code+`&page=`+value1+`&size=`+rows+`&status=2`, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then(
          (response) => {
            setGetPageNo(response.data.total_pages);
            setLeaveData(response.data.leaveapp);
            setCountNumber(value1-1);
            setTotalCount(response.data.total_count);
            setNumberOfElements(response.data.leaveapp.length);
            setSearchStatus(false);
            setBackdropOpen(false);
          },
          (error) => {
            setGetPageNo(0);
            setLeaveData();
            setCountNumber(0);
            setNumberOfElements(0);
            setSearchStatus(false);
            setBackdropOpen(false);
            console.log(JSON.stringify(error.response.data));
          }
        );
    } else {
      axios
        .get(`${base_url.api1}/myappapi/leaveapp/list?company_code=`+employeData.company_code+`&emp_id=`+employeData.emp_id+`&page=`+value1+`&size=`+rows+`&status=2`, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then(
          (response) => {
            setGetPageNo(response.data.total_pages);
            setLeaveData(response.data.leaveapp);
            setCountNumber(value1-1);
            setTotalCount(response.data.total_count);
            setNumberOfElements(response.data.leaveapp.length);
            setSearchStatus(false);
            setBackdropOpen(false);
          },
          (error) => {
            setGetPageNo(0);
            setLeaveData();
            setCountNumber(0);
            setNumberOfElements(0);
            setSearchStatus(false);
            setBackdropOpen(false);
            console.log(JSON.stringify(error.response.data));
          }
        );
    }
  };

  const [leaveApproveOpen, setApproveLeave] = React.useState(false);
  const [leaveId, setLeaveId] = useState();
  const updateApproveLeave =(e, leave) => {
    setApproveLeave(true);
    setLeaveId(leave.leave_id);
  }

  const [leaveDeclineOpen, setDeclineLeave] = React.useState(false);
  const updateDeclineLeave =(e, leave) => {
    setDeclineLeave(true);
    setLeaveId(leave.leave_id);
  }

  const approveLeave =()=>{
    let data = {
      approved_by: employeData.emp_name,
      manager_id: employeData.emp_id,
      status: 1,
    }
    setBackdropOpen(true);
    axios
      .put(`${base_url.api1}/myappapi/leaveapp/update?company_code=`+employeData.company_code+`&leave_id=`+leaveId, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setBackdropOpen(false);
          setApproveLeave(false);
          getAllLeaveList(pageNo);
          addToast('Leave Approved', {
            appearance: "success",
            autoDismiss: true,
          });
        },
        (error) => {
          setBackdropOpen(false);
          setApproveLeave(false);
          addToast('Leave Not Approved Successfully!', {
            appearance: "error",
            autoDismiss: true,
          });
        }
      );
  }

  const declineLeave =()=>{
    let finalDesc = declineNewData+"\n>>Declined By, "+employeData.emp_name+'('+employeData.emp_role_name+')';
    let data = {
      approved_by: employeData.emp_name,
      description: finalDesc,
      manager_id: employeData.emp_id,
      status: 0,
    }
    setBackdropOpen(true);
    axios
      .put(`${base_url.api1}/myappapi/leaveapp/update?company_code=`+employeData.company_code+`&leave_id=`+leaveId, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setBackdropOpen(false);
          setDeclineLeave(false);
          getAllLeaveList(pageNo);
          addToast('Leave Declined', {
            appearance: "success",
            autoDismiss: true,
          });
        },
        (error) => {
          setBackdropOpen(false);
          setDeclineLeave(false);
          addToast('Leave Not Declined Successfully!', {
            appearance: "error",
            autoDismiss: true,
          });
        }
      );
  }

  return (
    <>
    <div>
      <ToastContainer />
      <Backdrop open={backdropOpen}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="commoncomponent_cardcss">
        <div>
          <Row>
            <Col md={6}>
              <CardText
                style={{ marginTop: "-10px" }}
              >
                <h3>Pending Leave Application</h3>
              </CardText>
            </Col>
          </Row>
          <div className="newlisting_headertabdesign">
            <Row>
              <Col md={6}>
              <Row>
              <form className="master">
                  <div style={{ marginTop: "-5px" }}>
                    <label className="area_rowslable">Limit</label>
                    <br />
                    {/* <FormControl className={classes.formControl}> */}
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 80 ,marginTop: 0, maxHeight: 80}}>
                      <select
                        className={classes.selectlimit}
                        labelId="simple-select-label"
                        id="simple-select"
                        value={rows}
                        onChange={handleRows}
                      >
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </FormControl>
                  </div>
                </form>
                <form className="form-group">
                  <div style={{
                    display: "flex",
                    justify : "space-between",
                    flexWrap: "wrap",
                  }}>
                    <div style={{ marginTop: "-20px" }}>
                      <label className="area_rowslable">Date</label>
                      <DateRangePicker
                        onApply={(event, picker)=>handleApply(event, picker,1)}
                      >
                        <p className="datepickerrangevaluetext">{dateValue}</p>
                      </DateRangePicker>
                      {dateStatus ? (
                        <div style={{ float: "left" }} >
                          <a onClick={()=>{
                            getAllLeaveList(1);
                            setDateValue("Click to open");
                            setDateStatus(false);
                          }}
                          >Clear Filter</a>
                        </div>
                      ) : null} 
                    </div>
                  </div>
                </form>
                </Row>
                <br />
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <div>
                    <div className="clearsearchclass">
                      <form
                        id="myForm"
                        className="d-flex"
                        onSubmit={(e) => {
                          e.preventDefault();
                          submitSearch(e);
                        }}
                      >
                        <input
                          style={{
                            padding: "5px",
                            border: "2px solid",
                            marginTop: "-1px",
                          }}
                          className="form-control"
                          placeholder="Search.."
                          name="search"
                          required
                        />
                        <button
                          className={classes.srchbtn}
                          type="submit"
                        >
                          <i className="fa fa-search"></i>
                        </button>
                      </form>
                      {searchStatus ? (
                        <a className={classes.clrsrch}
                          onClick={()=>{
                            getAllLeaveList(1);
                            setSearchStatus(false);
                          }}
                        >Clear Search</a>
                      ) : null}
                    </div>                      
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <br/>
          <div className="newlisting_headertabdesign">
            <Table responsive striped>
              <thead>
                <tr>
                  <th style={{ width: "50px" }}>SN</th>
                  <th style={{ width: "175px" }}>
                    Employee Name{" "}
                    <UnfoldMoreIcon id="emp_name"/>
                  </th>
                  <th style={{ width: "175px" }}>
                    From Date{" "}
                    <UnfoldMoreIcon id="from_date"/>
                  </th>
                  <th style={{ width: "175px" }}>
                    To Date{" "}
                    <UnfoldMoreIcon id="to_date"/>
                  </th>
                  <th style={{ width: "175px" }}>
                    Type Of Leave{" "}
                    <UnfoldMoreIcon id="type"/>
                  </th>
                  <th style={{ width: "20px" }}>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {leaveData != null ? (
                  leaveData &&
                  leaveData.map((leave , index) => (
                    <tr>
                      <td>{countNumber * rows + 1 + index}</td>
                      <td>{leave.emp_name}</td>
                      <td>
                        {leave.from_date?
                          moment(leave.from_date.split(".")[0]).format("DD MMM YYYY")
                        : null}
                      </td>
                      <td>
                        {leave.to_date?
                          moment(leave.to_date.split(".")[0]).format("DD MMM YYYY")
                        : null}
                      </td> 
                      <td>{leave.type}</td>
                      <td>
                        <div style={{ position: "relative", textAlign: "center" }}>
                          <div className="workflowdashboard_fa-ellipsis" data-toggle="dropdown">
                            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                          </div>
                          <div className="dropdown-menu workflowdashboarddropdown"style={{ width: "200px" }}>
                            <p>
                              <a color="info"
                                onClick={(e) => {
                                  e.preventDefault();
                                  updateApproveLeave(e,leave);
                                }}
                              >
                                Approve
                              </a>
                            </p>
                            <p>
                              <a color="info"
                                onClick={(e) => {
                                  e.preventDefault();
                                  updateDeclineLeave(e,leave);
                                }}
                              >
                                Decline
                              </a>
                            </p>
                            <Modal
                              isOpen={leaveApproveOpen}
                              onRequestClose={closeModal}
                              style={customStyles2}
                              contentLabel="Example Modal"
                              ariaHideApp={false}
                            >
                              <h5>
                                Approve Leave Application ?
                              </h5>
                              <div style={{ float: "right" }}>
                                <Button
                                  color="success"
                                  type="submit"
                                  onClick={approveLeave}
                                >
                                  Approve
                                </Button>
                                &nbsp;&nbsp;&nbsp;
                                <Button
                                  color="secondary"
                                  onClick={() => setApproveLeave(false)}
                                >
                                  Close
                                </Button>
                              </div>
                            </Modal>
                            <Modal
                              isOpen={leaveDeclineOpen}
                              onRequestClose={closeModal}
                              style={customStyles2}
                              contentLabel="Example Modal"
                              ariaHideApp={false}
                            >
                              <h5>
                                Decline Leave Application
                              </h5>
                              <textarea
                                style={{ minWidth: "600px" }}
                                className="form-control"
                                onChange={(e)=>{
                                  setDeclineNewData(e.target.value);
                                }}
                                rows={5}
                              />
                              <div style={{ float: "right" }}>
                                <Button
                                  color="secondary"
                                  onClick={() => setDeclineLeave(false)}
                                >
                                  Close
                                </Button>
                                &nbsp;&nbsp;&nbsp;
                                <Button
                                  color="danger"
                                  type="submit"
                                  onClick={declineLeave}
                                >
                                  Decline
                                </Button>
                              </div>
                            </Modal>
                          </div>
                        </div>
                      </td>
                    </tr>
                ))) : (
                  <p className={classes.headertekst}>{null}</p>
                )}
            </tbody>
            </Table>
            <Row>
            <Col md={6}>
              {leaveData?.length ? (
                <p style={{ marginTop: "15px" }}>
                  {countNumber * rows + 1} to{" "}
                  {countNumber * rows + numberOfElements} of {totalCount} records
                </p>
              ) : (
                <p className="formfooterrecordstyle">No Record Found</p>
              )}
            </Col>
            <Col md={6}>
              <div className={classes.root}>
                {searchData || dateFilter ? (
                  <Pagination
                    page={paginationPageNo}
                    count={getPageNo}
                    // onChange={handlesearch}
                  />
                ) : (
                  <Pagination
                    page={paginationPageNo}
                    count={getPageNo}
                    onChange={handlePage}
                  />
                )}
              </div>
            </Col>
          </Row>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default PendingLeaveApplication ;