import React , { useState, useEffect }from 'react';
import { ToastContainer, toast } from "react-toastify";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Link, useNavigate } from "react-router-dom";
import { CardText, Col, Row, Button, Table } from "reactstrap";
import AddIcon from "@mui/icons-material/Add";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Pagination from "@mui/material/Pagination";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import { customStyles, useStyles } from "../../../Utilities/CSSUtilities";
import axios from "axios";
import base_url from '../../../api/bootapi';
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { logout, selectEmployee } from "../../../components/features/empSlice";

const Audit = () => {
  const classes = useStyles();
  const [backdropOpen, setBackdropOpen] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const [modalCreate, setCreate] = React.useState(false);
  const [audits,setAudit] =useState([]);
  const employeData = useSelector(selectEmployee);
  const jwtToken = "JWT " + localStorage.getItem("JWTToken");
  const IpAddress = localStorage.getItem("EmpIpAddress");
  const [pageNo, setPageNo] = useState(1);
  const [paginationPageNo, setPaginationPageNo] = useState(1);
  const [getPageNo, setGetPageNo] = useState();
  const [rows, setRows] = React.useState(50);
  const [numberOfElements, setNumberOfElements] = useState();
  const [countNumber, setCountNumber] = useState();
  const [totalcount, setTotalCount] = useState();
  const [display1, setdisplay] = useState("inline");
  const [datevalue, setdatevalue] = useState("Click to open");
  const [searchdata, setsearch] = useState();
  const [selectedDelete, setSelectedDelete] = useState();
  const [direction, setdirection] = useState("Desc");
  const [sortState, setsortState] = useState("emp_name");
  const dispatch = useDispatch(); 
  const navigate =useNavigate();
  useEffect( () => {
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
    getPermissions();
  },[rows]);

  const handleRows = (event) => {
    setRows(event.target.value);
  };

  const handlepage = (event, value) => {
     if(searchdata==true)
    console.log(value);
    setPaginationPageNo(value);
    setPageNo(value);
    getAllAuditList(value);
  };

  const getPermissions = () => {
    const resource_obj = [];

    for (let i = 0; i < employeData.role_permissions.length; i++) {
      resource_obj.push(employeData.role_permissions[i].resource_id);
    }
    var available_resource = resource_obj.includes(79);

    if(available_resource) {
      if (localStorage.getItem("AuditPageNo")) {
        let currentPage = localStorage.getItem("AuditPageNo");
        setPageNo(currentPage);
        setPaginationPageNo(Number(currentPage));
        getAllAuditList(currentPage);
      } else {
        setPageNo(1);
        setPaginationPageNo(1);
        getAllAuditList(1);
      }
    } else {
      navigate('/errorPage');
    }
  };

  const getAllAuditList = (value1) => {
    setBackdropOpen(true);
    axios
      .get(`${base_url.api1}/myappapi/audit/list?company_code=`+ employeData.company_code+`&page=`+value1+`&size=`+ rows, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setBackdropOpen(false);
          console.log(response.data.Audit);
          setAudit(response.data.Audit);
          setGetPageNo(response.data.total_pages);
          setTotalCount(response.data.total_count);
          setNumberOfElements(response.data.Audit.length);
          setCountNumber(value1-1);
          document.getElementById("myForm").reset();
        },
        (error) => {
          setBackdropOpen(false);
            console.log(JSON.stringify(error.response.data));
        }
      );
  };

  const [searchinput, setsearchinput] = useState();
  const submitSearch = (e, value) => {
     setdisplay("none");
    setsearch(true);
    let srchdata = e.target[0].value.trim();
    setsearchinput(srchdata);
    console.log(srchdata);
    setBackdropOpen(true);
    axios
      .get(`${base_url.api1}/myappapi/audit/list?company_code=`+ employeData.company_code+`&search=`+srchdata, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setBackdropOpen(false);
          setAudit(response.data.Audit);
          setGetPageNo(response.data.total_pages);
          setNumberOfElements(response.data.Audit.length);
          setTotalCount(response.data.total_count);
        },
        (error) => {
          setBackdropOpen(false);
          console.log(JSON.stringify(error.response.data));
          setAudit();
        },
        (error) => {
          setBackdropOpen(false);
          console.log(JSON.stringify(error.response.data));
        }
      );
  };

  const sortUser = (e) => {
    setsortState(e.target.id);
    console.log();
    if (direction == "Desc") {
      setdirection("ASC");
    } else if (direction == "ASC") {
      setdirection("Desc");
    }
    let sortby = e.target.id

    axios
      .get(`${base_url.api1}/myappapi/audit/list?company_code=` + employeData.company_code + `&sortby=`+sortby+`&Order=`+direction +`&page=`+pageNo+`&size=`+rows, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setAudit(response.data.Audit);
          document.getElementById("myForm").reset();
          setGetPageNo(response.data.total_pages);
          //console.log(response.data.total_pages);
          setTotalCount(response.data.total_count);
          setCountNumber(pageNo-1);
          setNumberOfElements(response.data.Users.length);
          //console.log(response.data.Users.length);
          setSelectedDelete([]);
          setsearch(false);
          setBackdropOpen(false);
        },
        (error) => {
          console.log(JSON.stringify(error.response.data));
          setAudit();
        }
      );
  };


  return (
    <>
    <div>
    <Backdrop open={backdropOpen}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
     >
        <CircularProgress color="inherit" />
    </Backdrop>
      <div className="commoncomponent_cardcss">
        <div>
        <Row>
            <Col md={6}>
              <CardText
                style={{ marginTop: "-10px" }}
              >
                <h1>Audit Logs</h1>
              </CardText>
            </Col>
          </Row>
          <div className="newlisting_headertabdesign">
          <Row>
            <form className="master">
                  <div style={{ marginTop: "15px" }}>
                    <label className="area_rowslable">Limit</label>
                    <br />
                    <FormControl className={classes.formControl}>
                      <Select
                        className={classes.selectlimit}
                        labelId="simple-select-label"
                        id="simple-select"
                        value={rows}
                        onChange={handleRows}
                      >
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </form>
                <br/>
                {/* <form className="form-group">
                  
                      {searchdata ? (
                      <div>
                        <a
                          className={classes.clrsrch}
                          onClick={() => {
                            setdisplay("inline");
                            setdatevalue("Click to open");
                          }}
                        >
                          Clear Filter
                        </a>
                      </div>
                      ) : null} 
                </form>
              <br/> */}
              <Col md={0}>
                <div className="form-group">
                  <div>
                    <div className="clearsearchclass">
                      <form
                        id="myForm"
                        className="d-flex"
                        onSubmit={(e) => {
                          e.preventDefault();
                          submitSearch(e);
                      }}
                      >
                        <input
                          style={{
                            padding: "5px",
                            border: "2px solid",
                            marginTop: "-1px",
                          }}
                          className="form-control"
                          placeholder="Search.."
                          name="search"
                          required
                        />
                        <button
                          color="blue"
                          className={classes.srchbtn}
                          type="submit"
                        >
                          <i className="fa fa-search"></i>
                        </button>
                      </form>
                      {searchdata ? (
                        <a    
                        onClick={() => {
                            setdisplay("inline");
                            setBackdropOpen(true);
                            setPageNo(1);
                            getAllAuditList(1);
                            setsearchinput();
                            setsearch(false);
                          }}                      
                        >
                          Clear Search
                        </a>
                      ) : null}
                    </div>                      
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <br/>
          <br/>
          <div className="newlisting_headertabdesign">
            <Table responsive striped>
              <thead>
                <tr>
                  <th style={{ textAlign: "center", width: "50px" }}>SN</th>
                  <th>
                    User{" "}
                    <UnfoldMoreIcon onClick={sortUser} id="emp_name"/>
                  </th>
                  <th>
                    Ip Address{" "}
                  </th>
                  <th>
                    Created{" "}
                    <UnfoldMoreIcon onClick={sortUser} id="created_date"/>
                  </th>
                  <th>
                    Log{""}
                    <UnfoldMoreIcon onClick={sortUser} id="log"/>
                  </th>
                </tr>
              </thead>
              <tbody>
                {audits !=null ?(
                 audits &&
                 audits.map((audit , index) => (
                    <tr>
                      <th>{index + 1 }</th>
                      <td >{audit.emp_name}</td>
                      <td>{audit.ip}</td>
                      <td>{audit.created_date
                          ? moment(audit.created_date.split("T")[0]).format("DD MMM YYYY")
                          : null}
                          {/* <br/>
                          {audit.created_date?.split('T')[1].split('Z')[0]} */}
                      </td>
                      <td>{audit.log}</td>
                    </tr>
                  ))):(
                    <p style={{width:'200%'}}>{null}</p>
                  )}
              </tbody>
            </Table>
            <div>
            <Row>
            <Col md={6}>
              {audits?.length ? (
                <p style={{ marginTop: "15px" }}>
                  {countNumber * rows + 1} to{" "}
                  {countNumber * rows + numberOfElements} of {totalcount} records
                </p>
              ) : (
                <p className="formfooterrecordstyle">No Records Found</p>
              )}
            </Col>
            <Col md={6}>
              <div className={classes.root}>
                {searchdata ? (
                  <Pagination
                    page={paginationPageNo}
                    count={getPageNo}
                    onChange={submitSearch}
                  />
                ) : (
                  <Pagination
                    page={paginationPageNo}
                    count={getPageNo}
                    onChange={handlepage}
                  />
                )}
              </div>
            </Col>
          </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Audit ;