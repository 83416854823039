import React , { useState, useEffect }from 'react';
import { ToastContainer, toast } from "react-toastify";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Link, useNavigate } from "react-router-dom";
import { CardText, Col, Row, Button, Table } from "reactstrap";
import AddIcon from "@mui/icons-material/Add";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Pagination from "@mui/material/Pagination";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import { customStyles, useStyles, customStyles2 } from "../../../Utilities/CSSUtilities";
import axios from "axios";
import base_url from '../../../api/bootapi';
import { useDispatch, useSelector } from "react-redux";
import { logout, selectEmployee } from "../../../components/features/empSlice";
import DateRangePicker from "react-bootstrap-daterangepicker";

const AppConfig = () => {
  const [backdropOpen, setBackdropOpen] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const [modalCreate, setCreate] = React.useState(false);
  const [configs,setConfig] =useState([]);
  const [basevalue, setBaseValue] = useState();
  const employeData = useSelector(selectEmployee);
  const jwtToken = "JWT " + localStorage.getItem("JWTToken");
  const [pageNo, setPageNo] = useState(1);
  const [paginationPageNo, setPaginationPageNo] = useState(1);
  const [getPageNo, setGetPageNo] = useState();
  const [rows, setRows] = React.useState(50);
  const [searchdata, setsearch] = useState(false);
  const [datefilter, setdatefilter] = useState();
  const classes = useStyles();
  const [numberOfElements, setNumberOfElements] = useState();
  const [totalcount, setTotalCount] = useState();
  const [selectedDelete, setSelectedDelete] = useState([]);
  const [countNumber, setCountNumber] = useState();
  const [datevalue, setdatevalue] = useState("Click to open");
  const [display1, setdisplay] = useState("inline");
  const [direction, setdirection] = useState("Desc");
  const [sortState, setsortState] = useState("key_name");


  const closeModal = () => {
    setCreate(false);
  }

  const createModalConfig = () => {
    setCreate(true);
  };

  useEffect( () => {
    getPermissions();
  },[rows]);

  const handleRows = (event) => {
    setRows(event.target.value);
  };
    const handleEvent = (event, picker) => {
        console.log(picker.startDate);
      };
        
      const handleCallback = (start, end, label) => {
        console.log(start, end, label);
      };

  const getPermissions = () => {
    // getAllConfigList();
    if (localStorage.getItem("ConfigPageNo")) {
      let currentPage = localStorage.getItem("ConfigPageNo");
      setPageNo(currentPage);
      setPaginationPageNo(Number(currentPage));
      getAllConfigList(currentPage);
    } else {
      setPageNo(1);
      setPaginationPageNo(1);
      getAllConfigList(1);
    }
  };

  const getAllConfigList = (value1) => {
    console.log(value1);
    setBackdropOpen(true);

    axios
      .get(`${base_url.api1}/myappapi/appconfig/list?company_code=`+ employeData.company_code +`&page=`+ value1 +`&size=`+ rows, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setBackdropOpen(false);
          console.log(response.data.Appconfig);
          setConfig(response.data.Appconfig);
          setGetPageNo(response.data.total_pages);
          setTotalCount(response.data.total_count);
          setCountNumber(value1-1);
          setNumberOfElements(response.data.Appconfig.length);
          
        },
        (error) => {
          setBackdropOpen(false);
            console.log(JSON.stringify(error.response.data));
        }
      );
  };

  const createConfig = (data2) => {
    let key_name = data2.key_name;
    let key_value = data2.key_value;
    let is_default = false;
  

    let data3 = {
      "data":[{
      key_name,
      key_value,
      is_default,
      }]
    };

    setBackdropOpen(true);
    axios
      .post(`${base_url.api1}/myappapi/appconfig/create?company_code=`+ employeData.company_code, data3, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        setBackdropOpen(false);
        setCreate(false);
        getAllConfigList();
      })
      .catch((error) => {
        setBackdropOpen(false);
        console.log(JSON.stringify(error));
      });
  };

  const [editOpen, setEdit] = React.useState(false);
  const [editConfigId, seteditConfigId] = useState();
  const [editKeyname, seteditKeyname] = useState();
  const [editKeyValue, seteditKeyValue] = useState();
  const [editDescription, seteditDescription] = useState();
  const [editBaseValue , seteditBaseValue] =useState();

  const editRoleData = (elem,config) => {
    seteditConfigId(config.appconf_id);
    seteditKeyname(config.key_name);
    seteditKeyValue(config.key_value);
    seteditDescription(config.is_default);
    setEdit(true);
  };

  const editOldCategory= (data2) =>{
  
    let data = {
      //id:Number(editConfigId),
      key_name: editKeyname.trim(),
      key_value: editKeyValue,
      is_default:false,
    };

    setBackdropOpen(true);
    axios
      .put(`${base_url.api1}/myappapi/appconfig/update?company_code=`+ employeData.company_code +`&appconf_id=` +editConfigId , data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        setBackdropOpen(false);
        setEdit(false);
        getAllConfigList(pageNo);
      })
      .catch((error) => {
        setBackdropOpen(false);
      });
  }

  const handlepage = (event, value) => {
    console.log(value);
    setPaginationPageNo(value);
    setPageNo(value);
    getAllConfigList(value);
  };

  const [deleteOpen, setDelete] = React.useState(false);
  const deleteRole = (elem,config) => {
    seteditConfigId(config.appconf_id);
    setDelete(true);
  };
  const deleteCurrentCategory= () =>{
    
    setBackdropOpen(true);
    axios
      .delete(`${base_url.api1}/myappapi/appconfig/delete?company_code=`+ employeData.company_code + `&appconf_id=`+ editConfigId ,{
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        setBackdropOpen(false);
        setDelete(false);
        getAllConfigList();
      })
      .catch((error) => {
        setBackdropOpen(false);
      });
  }

  const [searchinput, setsearchinput] = useState();
  const submitSearch = (e, value) => {
     setdisplay("none");
    setsearch(true);
    let srchdata = e.target[0].value.trim();
    setsearchinput(srchdata);
    setBackdropOpen(true);
    console.log(srchdata);
    axios
      .get(`${base_url.api1}/myappapi/appconfig/list?company_code=`+ employeData.company_code+ `&page=` + 1 + `&size=` + rows +`&search=`+srchdata, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setConfig(response.data.Appconfig);
          setGetPageNo(response.data.total_pages);
          setNumberOfElements(response.data.Appconfig.length);
          setTotalCount(response.data.total_count);
          setBackdropOpen(false);
        },
        (error) => {
          setBackdropOpen(false);
          console.log(JSON.stringify(error.response.data));
          setConfig();
        },
        (error) => {
          setBackdropOpen(false);
          console.log(JSON.stringify(error.response.data));
        }
      );
  };

  const sortUser = (e) => {
    setsortState(e.target.id);
    console.log();
    if (direction == "Desc") {
      setdirection("ASC");
    } else if (direction == "ASC") {
      setdirection("Desc");
    }
    let sortby = e.target.id

    axios
      .get(`${base_url.api1}/myappapi/appconfig/list?company_code=` + employeData.company_code + `&sortby=`+sortby+`&Order=`+direction +`&page=`+pageNo, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setConfig(response.data.Appconfig);
          document.getElementById("myForm").reset();
          setGetPageNo(response.data.total_pages);
          //console.log(response.data.total_pages);
          setTotalCount(response.data.total_count);
          setCountNumber(pageNo-1);
          setNumberOfElements(response.data.Users.length);
          //console.log(response.data.Users.length);
          setSelectedDelete([]);
          setdatefilter(false);
          setsearch(false);
          setBackdropOpen(false);
        },
        (error) => {
          console.log(JSON.stringify(error.response.data));
          setConfig();
        }
      );
  };

  return (
    <>
    <div>
      <ToastContainer />
      <Backdrop open={backdropOpen}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
     >
        <CircularProgress color="inherit" />
    </Backdrop>
      <div className="commoncomponent_cardcss">
        <div>
          <Row>
            <Col md={6}>
              <CardText
                style={{ marginTop: "-10px" }}
              >
                <h1>App Config</h1>
              </CardText>
            </Col>
            <Col md={6} className="d-flex justify-content-end">
                <>
                <Button
                  color="primary"
                  onClick={createModalConfig}
                >
                  + Add
                </Button>
                </>
            </Col>
            <Modal
            ariaHideApp={false}
            isOpen={modalCreate}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
            >
              <h2>
                <strong>Add Config</strong>
              </h2>
              <form onSubmit={handleSubmit(createConfig)}>
                <div>
                  <div className="form-group creatadmin">
                    <Row>
                      <Col md={12}>
                        <label>
                         Key Name<span style={{ color: "red" }}>*</span>:
                        </label>
                        <input
                          type="text"
                          className="form-control underline-input"
                          placeholder="Key Name"
                          id="key_name"
                          {...register("key_name",{ required: true })}
                        />
                        {errors.key_name && (
                          <p className="errormessage">Key Name is Required</p>
                        )}
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col md={12}>
                        <label>
                        Key Value <span style={{ color: "red" }}>*</span>:
                        </label>
                        <input
                          type="text"
                          className="form-control underline-input"
                          placeholder="Key Value"
                          {...register("key_value",{ required: true })}
                        />
                        {errors.key_value && (
                          <p className="errormessage">Key Value is Required</p>
                        )}
                      </Col>
                    </Row>
                    <br/>
                    <Row>
                      <Col md={12}>
                        <Row className="justify-content-end mt-2">
                          <Button
                            color="secondary"
                            onClick={() => setCreate(false)}
                          >
                            Cancel
                          </Button>
                          <Button
                            type="submit"
                            color="primary"
                            className="btn listing_addbutton ml-1"
                          >
                            Submit
                          </Button>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </div>
              </form>
            </Modal>
          </Row>
          <br/>
          <Row>
            <form className="master">
                <div style={{ marginTop: "-7px"}}>
                  <label className="area_rowslable">Limit</label>
                  <br />
                  <FormControl className={classes.formControl}>
                    <Select
                      className={classes.selectlimit}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={rows}
                      onChange={handleRows}
                    >
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </form>
              <form className="form-group"> 
                     {datefilter ?(
                      <div>
                        <a
                          className={classes.clrsrch}
                          onClick={() => {
                            setdisplay("inline");
                            setdatevalue("Click to open");
                          }}
                        >
                            Clear Filter
                          </a>
                        </div>
                      ): null} 
                </form>
                <br />
              <Col md={0}>
                <div className="form-group">
                  <div>
                    <div className="clearsearchclass">
                      <form
                        id="myForm"
                        className="d-flex"
                        onSubmit={(e) => {
                          e.preventDefault();
                          submitSearch(e,1);
                        }}
                      >
                        <input
                          style={{
                            padding: "5px",
                            border: "2px solid",
                            marginTop: "-1px",
                          }}
                          className="form-control"
                          placeholder="Search.."
                          name="search"
                          required
                        />
                        <button
                          color='blue'
                          className={classes.srchbtn}
                          type="submit"
                        >
                          <i className="fa fa-search"></i>
                        </button>
                      </form>
                      {searchdata ? (
                         <a                         
                         onEvent={handleEvent}
                         onCallback={handleCallback} 
                         onClick={() => {
                             setdisplay("inline");
                             setBackdropOpen(true);
                             setPageNo(1);
                             getAllConfigList(1);
                            setsearchinput();
                            setsearch(false);   
                          }}                    
                         >
                          Clear Search
                        </a>
                      ) : null}
                    </div>                      
                  </div>
                </div>
              </Col>
        </Row>
        <br/>
          <div className="newlisting_headertabdesign">
            <Table responsive striped>
              <thead>
                <tr>
                  <th>S No.</th>
                  <th>
                    Config Name{" "}
                    <UnfoldMoreIcon onClick={sortUser} id="key_name"/>
                  </th>
                  <th>
                    Config Value{" "}
                    <UnfoldMoreIcon onClick={sortUser} id="key_value"/>
                  </th>
                  <th>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {configs.map((config , index) => (
                    <tr>
                      <th>{index + 1 }</th>
                      <td >{config.key_name}</td>
                      <td>{config.key_value}</td>
                      <td>
                        <Button style={{
                          marginTop: "20px",
                          marginLeft: "5px",
                        }} 
                        id={config.id}
                          onClick={(e)=>{
                            e.preventDefault();
                            editRoleData(e, config);
                          }}
                         color="info">Edit</Button>
                      </td>
                      <Modal
                        ariaHideApp={false}
                        isOpen={editOpen}
                        onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel="Example Modal"
                      >
                        <h2>
                          <strong>Edit Config</strong>
                        </h2>
                        <form id="editForm" onSubmit={(e) => {
                            e.preventDefault();
                            editOldCategory(e);
                          }}>
                          <div>
                            <div className="form-group creatadmin">
                              <Row>
                                <Col md={12}>
                                  <label>Key Name<span style={{ color: "red" }}>*</span>:</label>
                                  <input
                                    type="text"
                                    className="form-control underline-input"
                                    value={editKeyname}
                                    onChange={(e) => {
                                      seteditKeyname(e.target.value);
                                    }}
                                    required
                                  />
                                </Col>
                              </Row>
                              <br />
                              <Row>
                              <Col md={12}>
                                  <label>Key Value<span style={{ color: "red" }}>*</span>:</label>
                                  <input
                                    type="text"
                                    className="form-control underline-input"
                                    value={editKeyValue}
                                    onChange={(e) => {
                                      seteditKeyValue(e.target.value);
                                    }}
                                    required
                                  />
                                </Col>
                              </Row>
                              <br />
                              <Row>
                            </Row>
                            </div>
                            <Row style={{ float: "right" }}>
                              <Button
                                color="secondary"
                                style={{
                                  marginTop: "20px",
                                  marginRight: "10px",
                                }}
                                onClick={() => setEdit(false)}
                              >
                                Cancel
                              </Button>
                              <Button
                                color="primary"
                                type="submit"
                                style={{ marginTop: "20px" }}
                              >
                                Submit
                              </Button>
                            </Row>
                          </div>
                        </form>
                      </Modal>
                      <Modal
                        ariaHideApp={false}
                        isOpen={deleteOpen}
                        onRequestClose={closeModal}
                        style={customStyles2}
                        contentLabel="Example Modal"
                      >
                        <h5>
                          Are you sure you want to delete current record ?
                        </h5>
                        <div style={{ float: "right" }}>
                          <Button
                            color="danger"
                            type="submit"
                            onClick={deleteCurrentCategory}
                          >
                            Delete
                          </Button>
                          &nbsp;&nbsp;&nbsp;
                          <Button
                            color="secondary"
                            onClick={() => setDelete(false)}
                          >
                            Cancel
                          </Button>
                        </div>
                      </Modal>                    
                    </tr>
                  ))}  
              </tbody>
            </Table>
            <div>
            <Row>
            <Col md={6}>
              {configs?.length ? (
                <p style={{ marginTop: "15px" }}>
                  {countNumber * rows + 1} to{" "}
                  {countNumber * rows + numberOfElements} of {totalcount} records
                </p>
              ) : (
                <p className="formfooterrecordstyle">No Record Found</p>
              )}
            </Col>
            <Col md={6}>
              <div className={classes.root}>
                {/* {searchdata || datefilter ? (
                  <Pagination
                    page={paginationPageNo}
                    count={getPageNo}
                    onChange={getAllConfigList}
                  />
                ) : ( */}
                  <Pagination
                    page={paginationPageNo}
                    count={getPageNo}
                    onChange={handlepage}
                  />
              </div>
              </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default AppConfig ;