import React, { useState, useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Link, useNavigate } from 'react-router-dom';
import { CardText, Col, Row, Button, Table } from 'reactstrap';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Pagination from '@mui/material/Pagination';
import Modal from 'react-modal';
import { useForm } from 'react-hook-form';
import {
	customStyles,
	useStyles,
	customStyles2,
} from '../../../Utilities/CSSUtilities';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import base_url from '../../../api/bootapi';
import {
	setStarted,
	selectStarted,
	setStopped,
	selectStopped,
	logout,
	selectEmployee,
	setSessionIdData,
	selectSessionId,
} from './../../features/empSlice';
import holidayicon from '../../../components/images/event.png';
import close from '../../../components/images/closeicon.png';
import editicon from '../../../components/images/edit.png';

const ManageRole = () => {
	const [backdropOpen, setBackdropOpen] = useState(false);
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();
	const [setConfig, setConfigValue] = useState();
	const [modalIsOpen2, setIsOpen2] = React.useState(false);
	const [modalCreate, setCreate] = React.useState(false);
	const [roles, setRole] = useState([]);
	const [datevalue, setdatevalue] = useState('Click to open');
	const [searchdata, setsearch] = useState(false);
	const [datefilter, setdatefilter] = useState();
	const classes = useStyles();
	const [rows, setRows] = React.useState(50);
	const [pageNo, setPageNo] = useState(1);
	const [paginationPageNo, setPaginationPageNo] = useState(1);
	const [getPageNo, setGetPageNo] = useState();
	const jwtToken = 'JWT ' + localStorage.getItem('JWTToken');
	const [totalcount, setTotalCount] = useState();
	const [countNumber, setCountNumber] = useState();
	const [numberOfElements, setNumberOfElements] = useState();
	const formData = new FormData();
	const editFormData = new FormData();
	const [display1, setdisplay] = useState('inline');
	const [selectedDelete, setSelectedDelete] = useState();
	const [direction, setdirection] = useState('Desc');
	const [sortState, setsortState] = useState('role_name');
	let navigate = useNavigate();
	const dispatch = useDispatch();
	const employeeData = useSelector(selectEmployee);
	const startedData = useSelector(selectStarted);
	const stoppedData = useSelector(selectStopped);
	const sessionIdData = useSelector(selectSessionId);
	const [permissionObj, setPermissionObj] = useState();

	useEffect(() => {
		getPermissions();
	}, [rows]);

	const handleRows = (event) => {
		setRows(event.target.value);
	};

	const getPermissions = () => {
		const resource_obj = [];

		for (let i = 0; i < employeeData.role_permissions.length; i++) {
			resource_obj.push(employeeData.role_permissions[i].resource_id);
		}
		setPermissionObj(resource_obj);
		var available_resource64 = resource_obj.includes(64);
		var available_resource65 = resource_obj.includes(65);
		var available_resource66 = resource_obj.includes(66);
		var available_resource67 = resource_obj.includes(67);
		var available_resource68 = resource_obj.includes(68);

		if (
			available_resource64 &&
			(available_resource65 ||
				available_resource66 ||
				available_resource67 ||
				available_resource68)
		) {
			if (localStorage.getItem('RolePageNo')) {
				let currentPage = localStorage.getItem('RolePageNo');
				setPageNo(currentPage);
				setPaginationPageNo(Number(currentPage));
				getAllRoleList(currentPage);
			} else {
				setPageNo(1);
				setPaginationPageNo(1);
				getAllRoleList(1);
			}
		} else {
			navigate('/errorPage');
		}
	};

	const getAllRoleList = (value1) => {
		setBackdropOpen(true);
		axios
			.get(
				`${base_url.api1}/myappapi/role/list?company_code=` +
					employeeData.company_code +
					`&page=` +
					value1 +
					`&size=` +
					rows +
					`&super_admin=True` +
					`&status=True`,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then(
				(response) => {
					setBackdropOpen(false);
					console.log(response.data.Role);
					setRole(response.data.Role);
					setGetPageNo(response.data.total_pages);
					setTotalCount(response.data.total_count);
					setCountNumber(value1 - 1);
					setNumberOfElements(response.data.Role.length);

					//document.getElementById("myForm").reset();
				},
				(error) => {
					setBackdropOpen(false);
					console.log(JSON.stringify(error.response.data));
				}
			);
	};

	const createRole = (data2) => {
		setBackdropOpen(true);
		let role_name = data2.role_name;
		let status = JSON.parse(data2.status);
		let is_default = false;

		let data = {
			role_name,
			status,
			is_default,
		};

		console.log(data);
		setBackdropOpen(true);
		axios
			.post(
				`${base_url.api1}/myappapi/role/create?company_code=` +
					employeeData.company_code,
				data,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then((response) => {
				setBackdropOpen(false);
				setCreate(false);
				getAllRoleList(getPageNo);
			})
			.catch((error) => {
				setBackdropOpen(false);
				console.log(JSON.stringify(error));
			});
	};

	const [editOpen, setEdit] = React.useState(false);
	const [editRoleId, seteditRoleId] = useState();
	const [editRoleName, seteditRoleName] = useState();
	const [editRoleStatus, seteditRoleStatus] = useState();
	const [editDescription, seteditDescription] = useState();
	const editRoleData = (elem, role) => {
		console.log(role);
		seteditRoleId(role.role_id);
		seteditRoleName(role.role_name);
		seteditRoleStatus(role.status);
		seteditDescription(role.is_default);
		setEdit(true);
	};

	const editOldCategory = (data2) => {
		let data = {
			role_name: editRoleName.trim(),
			status: Number(editRoleStatus),
			is_default: false,
		};
		setBackdropOpen(true);
		axios
			.put(
				`${base_url.api1}/myappapi/role/update?company_code=` +
					employeeData.company_code +
					`&role_id=` +
					editRoleId,
				data,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then((response) => {
				setBackdropOpen(false);
				setEdit(false);
				getAllRoleList(getPageNo);
			})
			.catch((error) => {
				setBackdropOpen(false);
			});
	};
	const handlepage = (event, value) => {
		if (searchdata == true) console.log(value);
		setPaginationPageNo(value);
		setPageNo(value);
		getAllRoleList(value);
	};

	const closeModal = () => {
		setCreate(false);
	};

	const createModalConfig = () => {
		setCreate(true);
	};
	const handleEvent = (event, picker) => {
		console.log(picker.startDate);
	};
	const handleCallback = (start, end, label) => {
		console.log(start, end, label);
	};

	const [deleteOpen, setDelete] = React.useState(false);
	const deleteRole = (elem, role) => {
		console.log(role);
		seteditRoleId(role.role_id);
		setDelete(true);
	};
	const deleteCurrentCategory = () => {
		setBackdropOpen(true);
		axios
			.delete(
				`${base_url.api1}/myappapi/role/delete?company_code=` +
					employeeData.company_code +
					`&role_id=` +
					editRoleId,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then((response) => {
				setBackdropOpen(false);
				setDelete(false);
				getAllRoleList(getPageNo);
			})
			.catch((error) => {
				setBackdropOpen(false);
			});
	};
	const [searchinput, setsearchinput] = useState();
	const submitSearch = (e, value) => {
		setdisplay('none');
		setsearch(true);
		let srchdata = e.target[0].value.trim();
		setsearchinput(srchdata);
		console.log(srchdata);
		setBackdropOpen(true);
		axios
			.get(
				`${base_url.api1}/myappapi/role/list?company_code=` +
					employeeData.company_code +
					`&search=` +
					srchdata,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then(
				(response) => {
					setBackdropOpen(false);
					setRole(response.data.Role);
					console.log(response.data.Role);
					setGetPageNo(response.data.total_pages);
					setNumberOfElements(response.data.Role.length);
					setTotalCount(response.data.total_count);
				},
				(error) => {
					console.log(JSON.stringify(error.response.data));
					setRole();
					setBackdropOpen(false);
				},
				(error) => {
					setBackdropOpen(false);
					console.log(JSON.stringify(error.response.data));
				}
			);
	};

	const sortUser = (e) => {
		setsortState(e.target.id);
		console.log();
		if (direction == 'Desc') {
			setdirection('ASC');
		} else if (direction == 'ASC') {
			setdirection('Desc');
		}
		let sortby = e.target.id;
		axios
			.get(
				`${base_url.api1}/myappapi/role/list?company_code=` +
					employeeData.company_code +
					`&sortby=` +
					sortby +
					`&Order=` +
					direction +
					`&page=` +
					pageNo,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then(
				(response) => {
					setRole(response.data.Role);
					document.getElementById('myForm').reset();
					setGetPageNo(response.data.total_pages);
					//console.log(response.data.total_pages);
					setTotalCount(response.data.total_count);
					setCountNumber(pageNo - 1);
					setNumberOfElements(response.data.Users.length);
					//console.log(response.data.Users.length);
					setSelectedDelete([]);
					setsearch(false);
					setBackdropOpen(false);
				},
				(error) => {
					console.log(JSON.stringify(error.response.data));
					setRole();
				}
			);
	};

	return (
		<>
			<div>
				<Backdrop
					open={backdropOpen}
					sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				>
					<CircularProgress color="inherit" />
				</Backdrop>
				<div className="commoncomponent_cardcss">
					<div style={{ margin: '5px', marginTop: '-30px' }}>
						<Row>
							{/* <Col md={6}>
              <CardText
                style={{ marginTop: "-10px" }}
              >
                <h1>Roles</h1>
              </CardText>
            </Col>
            <Col md={0} className="d-flex justify-content-end">
              {permissionObj?.includes(65)?
                <Button
                  color="primary"
                  onClick={createModalConfig}
                >
                  + Add
                </Button>
              :null}
            </Col> */}
							{permissionObj?.includes(65) ? (
								<div className="newhead">
									<h4>Roles</h4>
									<div onClick={createModalConfig}>
										<h6>New</h6> <p>+</p>
									</div>
								</div>
							) : null}
							<Modal
								ariaHideApp={false}
								isOpen={modalCreate}
								onRequestClose={closeModal}
								style={customStyles}
								contentLabel="Example Modal"
							>
								<div style={{ textAlign: 'right', padding: '10px 20px' }}>
									<img
										className="closei"
										src={close}
										onClick={() => setCreate(false)}
									/>
								</div>
								<div className="modalContainer">
									<div style={{ textAlign: 'center' }}>
										<img className="modelIcon" src={holidayicon} />
									</div>
									<h1> Add Role</h1>
									<form onSubmit={handleSubmit(createRole)}>
										<div>
											<div className="form-group creatadmin">
												<Row>
													<Col md={12}>
														<label>Role Name:</label>
														<input
															type="text"
															className="form-control underline-input"
															placeholder="Role Name"
															id="roleName"
															{...register('role_name', { required: true })}
														/>
														{/* {errors.role_name && (
                          <p className="errormessage">RoleName is Required</p>
                        )} */}
													</Col>
												</Row>
												<br />
												<Row>
													<Col md={12}>
														<label>Status:</label>
														<select
															className="form-control"
															{...register('status', { required: true })}
														>
															<option value={true}>Active</option>
															<option value={false}>InActive</option>
														</select>
														{/* {errors.status && (
                              <p className="errormessage">Status is Required</p>
                            )} */}
													</Col>
												</Row>
												<br />
												<Row>
													<Col md={12}>
														<Row className="justify-content-end mt-2">
															<Button
																color="secondary"
																onClick={() => setCreate(false)}
															>
																Cancel
															</Button>
															<Button
																type="submit"
																color="primary"
																className="btn listing_addbutton ml-1"
															>
																Submit
															</Button>
														</Row>
													</Col>
												</Row>
											</div>
										</div>
									</form>
								</div>
							</Modal>
						</Row>
						<Row>
							<form className="master">
								<div style={{ marginTop: '15px' }}>
									<label className="area_rowslable">Limit</label>
									<br />
									<FormControl className={classes.formControl}>
										<Select
											className={classes.selectlimit}
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											value={rows}
											onChange={handleRows}
										>
											<MenuItem value={20}>20</MenuItem>
											<MenuItem value={50}>50</MenuItem>
											<MenuItem value={100}>100</MenuItem>
										</Select>
									</FormControl>
								</div>
							</form>
							<form className="form-group">
								{datefilter ? (
									<div>
										<a
											onEvent={handleEvent}
											onCallback={handleCallback}
											className={classes.clrsrch}
											onClick={() => {
												setdisplay('inline');
												setdatevalue('Click to open');
											}}
										>
											Clear Filter
										</a>
									</div>
								) : null}
							</form>
							<br />
							<Col md={0}>
								<div className="form-group">
									<div>
										<div className="clearsearchclass">
											<form
												id="myForm"
												className="d-flex"
												onSubmit={(e) => {
													e.preventDefault();
													submitSearch(e, 1);
												}}
											>
												<input
													style={{
														padding: '5px',
														border: '2px solid',
														marginTop: '-1px',
													}}
													className="form-control"
													placeholder="Search.."
													name="search"
													required
												/>
												<button
													color="blue"
													className={classes.srchbtn}
													type="submit"
												>
													<i className="fa fa-search"></i>
												</button>
											</form>
											{searchdata ? (
												<a
													onClick={() => {
														setdisplay('inline');
														setBackdropOpen(true);
														setPageNo(1);
														getAllRoleList(1);
														setsearchinput();
														setsearch(false);
													}}
												>
													Clear Search
												</a>
											) : null}
										</div>
									</div>
								</div>
							</Col>
						</Row>
						<br />
						<div className="newlisting_headertabdesign">
							<Table responsive striped>
								<thead>
									<tr>
										<th>SN</th>
										<th>
											RoleName{' '}
											<UnfoldMoreIcon onClick={sortUser} id="role_name" />
										</th>
										<th>
											Status <UnfoldMoreIcon onClick={sortUser} id="status" />
										</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									{roles.map((role, index) => (
										<tr>
											<th>{index + 1}</th>
											<td>{role.role_name}</td>
											<td>{role.status == true ? 'Active' : 'InActive'}</td>
											<td>
												{permissionObj?.includes(68) ? (
													<Button
														id={role.id}
														onClick={(e) => {
															navigate(
																'/role-permissions/' +
																	role.role_id +
																	'/' +
																	role.role_name
															);
														}}
														color="primary"
													>
														Permission
													</Button>
												) : null}
												{permissionObj?.includes(66) ? (
													<>
														{role.is_default != '1' ? (
															<Button
																id={role.id}
																onClick={(e) => {
																	e.preventDefault();
																	editRoleData(e, role);
																}}
																style={{ display: 'none' }}
																color="info"
															>
																Edit
															</Button>
														) : null}
													</>
												) : null}
												{permissionObj?.includes(67) ? (
													<>
														{role.is_default != '1' ? (
															<Button
																id={role.id}
																onClick={(e) => {
																	e.preventDefault();
																	deleteRole(e, role);
																}}
																color="danger"
																style={{ display: 'none' }}
															>
																Delete
															</Button>
														) : null}
													</>
												) : null}
											</td>
											<Modal
												ariaHideApp={false}
												isOpen={editOpen}
												onRequestClose={closeModal}
												style={customStyles}
												contentLabel="Example Modal"
											>
												<h2>
													<strong>Edit Role</strong>
												</h2>
												<form
													id="editForm"
													onSubmit={(e) => {
														e.preventDefault();
														editOldCategory(e);
													}}
												>
													<div>
														<div className="form-group creatadmin">
															<Row>
																<Col md={12}>
																	<label>
																		Title<span style={{ color: 'red' }}>*</span>
																		:
																	</label>
																	<input
																		type="text"
																		className="form-control underline-input"
																		value={editRoleName}
																		onChange={(e) => {
																			seteditRoleName(e.target.value);
																		}}
																		required
																	/>
																</Col>
															</Row>
															<br />
															<Row>
																<Col md={12}>
																	<label>
																		Status
																		<span style={{ color: 'red' }}>*</span>:
																	</label>
																	<select
																		name="status"
																		value={editRoleStatus}
																		onChange={(e) => {
																			seteditRoleStatus(e.target.value);
																		}}
																		required
																	>
																		<option value="1">Active</option>
																		<option value="0">InActive</option>
																	</select>
																</Col>
															</Row>
															<br />
														</div>
														<Row style={{ float: 'right' }}>
															<Button
																color="secondary"
																style={{
																	marginTop: '20px',
																	marginRight: '10px',
																}}
																onClick={() => setEdit(false)}
															>
																Cancel
															</Button>
															<Button
																color="primary"
																type="submit"
																style={{ marginTop: '20px' }}
															>
																Submit
															</Button>
														</Row>
													</div>
												</form>
											</Modal>
											<Modal
												ariaHideApp={false}
												isOpen={deleteOpen}
												onRequestClose={closeModal}
												style={customStyles2}
												contentLabel="Example Modal"
											>
												<h5>
													Are you sure you want to delete current record ?
												</h5>
												<div style={{ float: 'right' }}>
													<Button
														color="danger"
														type="submit"
														onClick={deleteCurrentCategory}
													>
														Delete
													</Button>
													&nbsp;&nbsp;&nbsp;
													<Button
														color="secondary"
														onClick={() => setDelete(false)}
													>
														Cancel
													</Button>
												</div>
											</Modal>
										</tr>
									))}
								</tbody>
							</Table>
							<div>
								<Row>
									<Col md={6}>
										{roles?.length ? (
											<p style={{ marginTop: '15px' }}>
												{countNumber * rows + 1} to{' '}
												{countNumber * rows + numberOfElements} of {totalcount}{' '}
												records
											</p>
										) : (
											<p className="formfooterrecordstyle">No Record Found</p>
										)}
									</Col>
									<Col md={6}>
										<div className={classes.root}>
											{searchdata || datefilter ? (
												<Pagination
													page={paginationPageNo}
													count={getPageNo}
													onChange={submitSearch}
												/>
											) : (
												<Pagination
													page={paginationPageNo}
													count={getPageNo}
													onChange={handlepage}
												/>
											)}
										</div>
									</Col>
								</Row>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ManageRole;
