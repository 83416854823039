import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Link, useNavigate } from 'react-router-dom';
import { CardText, Col, Row, Button, Table } from 'reactstrap';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Pagination from '@mui/material/Pagination';
import Modal from 'react-modal';
import { useForm } from 'react-hook-form';
import { customStyles2, useStyles } from './../../Utilities/CSSUtilities';
import axios from 'axios';
import base_url from '../../api/bootapi';
import moment from 'moment';
import { logout, selectEmployee } from '../../components/features/empSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

const ResignationList = () => {
	const [backdropOpen, setBackdropOpen] = useState(false);
	const navigate = useNavigate();
	const { addToast } = useToasts();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();
	const [modalIsOpen2, setIsOpen2] = React.useState(false);
	const [modalCreate, setCreate] = React.useState(false);
	const [users, setUser] = useState([]);
	const [resignation, setResignation] = useState([]);
	const [pageNo, setPageNo] = useState(1);
	const [paginationPageNo, setPaginationPageNo] = useState(1);
	const [getPageNo, setGetPageNo] = useState();
	const [rows, setRows] = React.useState(50);
	const [sortState, setsortState] = useState('holiday_name');
	const [direction, setdirection] = useState('Desc');
	const [totalcount, setTotalCount] = useState();
	const [countNumber, setCountNumber] = useState();
	const [numberOfElements, setNumberOfElements] = useState();
	const [selectedDelete, setSelectedDelete] = useState();
	const [datesrange, setdatesrange] = useState();
	const [display1, setdisplay] = useState('inline');
	const [permissionObj, setPermissionObj] = useState();
	const employeeData = useSelector(selectEmployee);
	const jwtToken = 'JWT ' + localStorage.getItem('JWTToken');
	const [descContent, setDescContent] = useState();
	const [descModelOpen, setDescModelOpen] = useState(false);
	const [declinedEmployeeResignation, setDeclinedEmployeeResignation] =
		useState({});
	const [approvedEmployeeResignation, setApprovedEmployeeResignation] =
		useState({});
	const [selectedDate, setSelectedDate] = useState('');

	useEffect(() => {
		getPermissions();
	}, []);

	const getPermissions = () => {
		const resource_obj = [];
		for (let i = 0; i < employeeData.role_permissions.length; i++) {
			resource_obj.push(employeeData.role_permissions[i].resource_id);
		}
		console.log(resource_obj);
		setPermissionObj(resource_obj);
		var available_resource19 = resource_obj.includes(19);
		var available_resource20 = resource_obj.includes(20);
		var available_resource21 = resource_obj.includes(21);

		if (
			available_resource19 &&
			(available_resource20 || available_resource21)
		) {
			getAllResignationList(1);
		} else {
			navigate('/errorPage');
		}
	};

	const getAllResignationList = (value1) => {
		axios
			.get(
				`${base_url.api1}/myappapi/resignation/list?company_code=` +
					employeeData.company_code +
					`&page=` +
					value1 +
					`&size=` +
					rows,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then(
				(response) => {
					console.log('Resignation List:', response.data);
					setResignation(response.data.Resignation);
					setGetPageNo(response.data.total_pages);
					setTotalCount(response.data.total_count);
					setCountNumber(value1 - 1);
					setNumberOfElements(response.data.Resignation.length);
					setSelectedDelete([]);
					setBackdropOpen(false);
					document.getElementById('myForm').reset();
				},
				(error) => {
					console.log(JSON.stringify(error.response.data));
					setBackdropOpen(false);
					setResignation();
				}
			);
	};

	const createResignation = (data2) => {
		setBackdropOpen(true);
		// let emp_id = employeeData.emp_id;
		// let emp_name = employeeData.emp_name;
		// let emp_email = employeeData.emp_email;
		// let reason = data2.reason;
		// let status = false;

		let data = {
			emp_id: employeeData.emp_id,
			emp_name: employeeData.emp_name,
			emp_email: employeeData.emp_email,
			reason: data2.reason,
			status: 'false',
			date_of_leaving: selectedDate,
		};
		//let jsonData = JSON.stringify(data);
		console.log(data);

		axios
			.post(
				`${base_url.api1}/myappapi/resignation/create?company_code=` +
					employeeData.company_code,
				data,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then((response) => {
				addToast('Resignation Sent Successfully', {
					appearance: 'success',
					autoDismiss: true,
				});
				setBackdropOpen(false);
				setCreate(false);
				getAllResignationList(1);
			})
			.catch((error) => {
				addToast('Resignation not sent', {
					appearance: 'error',
					autoDismiss: true,
				});
				setBackdropOpen(false);
				console.log(JSON.stringify(error));
			});
	};

	const closeModal = () => {
		setCreate(false);
	};

	const createModalConfig = () => {
		setCreate(true);
	};

	const [leaveApproveOpen, setApproveLeave] = React.useState(false);
	const [leaveId, setLeaveId] = useState();
	const updateApproveLeave = (e, regn) => {
		setApproveLeave(true);
		setLeaveId(regn.resignation_id);
	};

	const [leaveDeclineOpen, setDeclineLeave] = React.useState(false);
	const [declineData, setDeclineData] = useState();
	const [declineNewData, setDeclineNewData] = useState();

	const updateDeclineLeave = (e, regn) => {
		setDeclineLeave(true);
		setLeaveId(regn.resignation_id);
	};

	const approveLeave = () => {
		let data = {
			approved_by: employeeData.emp_name,
			manager_id: employeeData.emp_id,
			status: 1,
		};
		setBackdropOpen(true);
		axios
			.put(
				`${base_url.api1}/myappapi/leaveapp/update?company_code=` +
					employeeData.company_code +
					`&leave_id=` +
					leaveId,
				data,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then(
				(response) => {
					setBackdropOpen(false);
					setApproveLeave(false);
					//getAllLeaveList(pageNo);
					addToast('Resignation Approved', {
						appearance: 'success',
						autoDismiss: true,
					});
				},
				(error) => {
					setBackdropOpen(false);
					setApproveLeave(false);
					addToast('Resignation Not Approved Successfully!', {
						appearance: 'error',
						autoDismiss: true,
					});
				}
			);
	};

	const declineLeave = () => {
		let finalDesc;
		if (declineData != null) {
			finalDesc =
				declineData +
				'\n' +
				declineNewData +
				'\n>>Declined By, ' +
				employeeData.emp_name +
				'(' +
				employeeData.emp_role_name +
				')';
		} else {
			finalDesc =
				declineNewData +
				'\n>>Declined By, ' +
				employeeData.emp_name +
				'(' +
				employeeData.emp_role_name +
				')';
		}
		let data = {
			approved_by: employeeData.emp_name,
			description: finalDesc,
			manager_id: employeeData.emp_id,
			status: 0,
		};
		console.log(data);
		setBackdropOpen(true);
		axios
			.put(
				`${base_url.api1}/myappapi/leaveapp/update?company_code=` +
					employeeData.company_code +
					`&leave_id=` +
					leaveId,
				data,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then(
				(response) => {
					setBackdropOpen(false);
					setDeclineLeave(false);
					//getAllLeaveList(pageNo);
					addToast('Resignation Declined', {
						appearance: 'success',
						autoDismiss: true,
					});
				},
				(error) => {
					setBackdropOpen(false);
					setDeclineLeave(false);
					addToast('Resignation Not Declined Successfully!', {
						appearance: 'error',
						autoDismiss: true,
					});
				}
			);
	};
	const declineResignation = (value) => {
		//console.log(value);
		let data = {
			emp_id: value.emp_id,
			emp_email: value.emp_email,
			emp_name: value.emp_name,
			reason: value.reason,
			status: 'decline',
		};
		console.log(data);
		axios
			.put(
				`${base_url.api1}/myappapi/resignation/update?company_code=` +
					employeeData.company_code +
					`&resignation_id=` +
					value.resignation_id,
				data,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then(
				(response) => {
					setBackdropOpen(false);
					setDeclineLeave(false);
					//getAllLeaveList(pageNo);
					addToast('Resignation Declined', {
						appearance: 'success',
						autoDismiss: true,
					});
				},
				(error) => {
					setBackdropOpen(false);
					setDeclineLeave(false);
					addToast('Resignation Not Declined Successfully!', {
						appearance: 'error',
						autoDismiss: true,
					});
				}
			);
	};
	const approveResignation = (value) => {
		console.log(value);
		let data = {
			emp_id: value.emp_id,
			emp_email: value.emp_email,
			emp_name: value.emp_name,
			reason: value.reason,
			status: true,
		};
		console.log(data);
		axios
			.put(
				`${base_url.api1}/myappapi/resignation/update?company_code=` +
					employeeData.company_code +
					`&resignation_id=` +
					value.resignation_id,
				data,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then(
				(response) => {
					setBackdropOpen(false);
					setApproveLeave(false);
					//getAllLeaveList(pageNo);
					addToast('Resignation Approved', {
						appearance: 'success',
						autoDismiss: true,
					});
				},
				(error) => {
					setBackdropOpen(false);
					setApproveLeave(false);
					addToast('Resignation Not Approved Successfully!', {
						appearance: 'error',
						autoDismiss: true,
					});
				}
			);
	};

	const handleDateChange = (event) => {
		const date = event.target.value;
		setSelectedDate(formatDate(date));
	};

	const formatDate = (date) => {
		const selectedDate = new Date(date);
		const year = selectedDate.getFullYear();
		const month = String(selectedDate.getMonth() + 1).padStart(2, '0');
		const day = String(selectedDate.getDate()).padStart(2, '0');
		return `${year}-${month}-${day}`;
	};

	return (
		<>
			<div>
				<ToastContainer />
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={backdropOpen}
				>
					<CircularProgress color="inherit" />
				</Backdrop>
				<div className="commoncomponent_cardcss">
					<div>
						<Row>
							<Col md={6}>
								<CardText style={{ marginTop: '-10px' }}>
									<h3>Resignation List</h3>
								</CardText>
							</Col>
							<Col md={6} className="d-flex justify-content-end">
								<>
									<Button color="primary" onClick={createModalConfig}>
										+ Add
									</Button>
								</>
							</Col>
							<Modal
								ariaHideApp={false}
								isOpen={modalCreate}
								onRequestClose={closeModal}
								style={customStyles2}
								contentLabel="Example Modal"
							>
								<h2>
									<strong>Apply For Resignation</strong>
								</h2>
								<form onSubmit={handleSubmit(createResignation)}>
									<div>
										<div className="form-group creatadmin">
											<Row>
												<Col md={12}>
													<label>
														Emp Name<span style={{ color: 'red' }}>*</span>:
													</label>
													<input
														type="none"
														className="form-control underline-input"
														placeholder="Empolyee Name"
														id="emp_name"
														readOnly
														value={employeeData.emp_name}
														{...register('emp_name', { required: true })}
													/>
													{errors.emp_name && (
														<p className="errormessage">End Date is Required</p>
													)}
												</Col>
											</Row>
											<br />
											<Row>
												<Col md={12}>
													<label>
														Employee Email
														<span style={{ color: 'red' }}>*</span>:
													</label>
													<input
														type="none"
														className="form-control underline-input"
														placeholder="Empolyee Email"
														id="emp_email"
														readOnly
														value={employeeData.emp_email}
														{...register('emp_email', { required: true })}
													/>
													{errors.emp_email && (
														<p className="errormessage">End Date is Required</p>
													)}
												</Col>
											</Row>
											<br />
											<Row>
												<Col md={12}>
													<label>
														Date of Relieving
														<span style={{ color: 'red' }}>*</span>:
													</label>
													<input
														name="date_of_leaving"
														className="profilefield"
														type="date"
														value={selectedDate}
														{...register('date_of_leaving', { required: true })}
														onChange={(e) => {
															//setFirstName(e.target.value);
															// newHandleChange(e);

															handleDateChange(e);
														}}
													/>
													{errors.date_of_leaving && (
														<p className="errormessage">
															Date of leaving is required
														</p>
													)}
												</Col>
											</Row>
											<br />
											<Row>
												<Col md={12}>
													<label>
														Reason<span style={{ color: 'red' }}>*</span>:
													</label>
													<textarea
														type="text-area"
														className="form-control underline-input"
														placeholder="Reason"
														id="reason"
														style={{ minHeight: '100px' }}
														{...register('reason', { required: true })}
													/>
													{errors.reason && (
														<p className="errormessage">reason is Required</p>
													)}
												</Col>
											</Row>
											<br />
											<Row>
												<Col md={12}>
													<Row className="justify-content-end mt-2">
														<Button
															color="secondary"
															onClick={() => setCreate(false)}
														>
															Cancel
														</Button>
														<Button
															type="submit"
															color="primary"
															className="btn listing_addbutton ml-1"
														>
															Submit
														</Button>
													</Row>
												</Col>
											</Row>
										</div>
									</div>
								</form>
							</Modal>
						</Row>
						<br />
						<div className="newlisting_headertabdesign">
							<Table responsive striped>
								<thead>
									<tr>
										<th style={{ textAlign: 'center', width: '50px' }}>SN</th>
										<th>
											Resignation Id <UnfoldMoreIcon id="resignation_id" />
										</th>
										<th style={{ textAlign: 'center' }}>
											User Id <UnfoldMoreIcon id="user_id" />
										</th>
										<th>
											Apply Date <UnfoldMoreIcon id="apply_date" />
										</th>
										<th style={{ textAlign: 'center' }}>
											Reason <UnfoldMoreIcon id="reason" />
										</th>
										<th>
											Status <UnfoldMoreIcon id="status" />
										</th>
										<th style={{ textAlign: 'center', width: '175px' }}>
											Action
										</th>
									</tr>
								</thead>
								<tbody>
									{resignation && resignation.length > 0 ? (
										resignation.map((regn, index) => (
											<tr>
												<th>{index + 1}</th>
												<td>{regn.emp_name}</td>
												<td>{regn.emp_email}</td>
												<td>
													{regn.created_date
														? moment(regn.created_date.split('.')[0]).format(
																'DD MMM YYYY'
														  )
														: null}
												</td>

												<td
													className="leaveEllipsis"
													style={{ textAlign: 'center' }}
												>
													{regn.reason}
													{regn.reason != null ? (
														<span
															className="leaveEllipsisspan"
															onClick={() => {
																setDescModelOpen(true);
																setDescContent(regn.reason);
															}}
															style={{ marginLeft: '50px' }}
														>
															View More
														</span>
													) : null}
													<Modal
														ariaHideApp={false}
														isOpen={descModelOpen}
														onRequestClose={() => setDescModelOpen(false)}
														style={customStyles2}
														contentLabel="Example Modal"
													>
														<h4>Reason</h4>
														{descContent ? (
															<textarea
																style={{ minWidth: '600px' }}
																className="form-control"
																value={descContent}
																rows={10}
																readonly
															/>
														) : (
															<p> No Record Available</p>
														)}
														<div
															style={{
																display: 'flex',
																margin: '10px 0',
																justifyContent: 'flex-end',
															}}
														>
															<Button
																color="secondary"
																onClick={() => setDescModelOpen(false)}
															>
																Close
															</Button>
														</div>
													</Modal>
												</td>
												<td>
													{regn.status === 'true'
														? 'Approved'
														: regn.status === 'false'
														? 'Pending'
														: 'Declined'}
												</td>
												<td>
													<div
														style={{
															position: 'relative',
															textAlign: 'center',
														}}
													>
														<div
															className="workflowdashboard_fa-ellipsis"
															data-toggle="dropdown"
														>
															<i
																className="fa fa-ellipsis-v"
																aria-hidden="true"
															></i>
														</div>
														<div
															className="dropdown-menu workflowdashboarddropdown"
															style={{ width: '200px' }}
														>
															{permissionObj?.includes(20) ? (
																<p>
																	<div
																		color="info"
																		onClick={(e) => {
																			//e.preventDefault();
																			console.log('Resigntaion Approve', regn);
																			setApproveLeave(true);
																			//updateApproveLeave(e, regn);

																			setApprovedEmployeeResignation(regn);
																		}}
																	>
																		Approve
																	</div>
																</p>
															) : null}
															{permissionObj?.includes(21) ? (
																<p>
																	<div
																		color="info"
																		onClick={() => {
																			console.log('Resignation Declined');
																			setDeclineLeave(true);
																			setDeclinedEmployeeResignation(regn);
																		}}
																	>
																		Decline
																	</div>
																</p>
															) : null}
															<Modal
																ariaHideApp={false}
																isOpen={leaveApproveOpen}
																onRequestClose={closeModal}
																style={customStyles2}
																contentLabel="Example Modal"
															>
																<h5>Approve Resignation Application ?</h5>
																<div style={{ float: 'right' }}>
																	<Button
																		color="success"
																		type="submit"
																		onClick={() => {
																			approveResignation(
																				approvedEmployeeResignation
																			);
																		}}
																	>
																		Approve
																	</Button>
																	&nbsp;&nbsp;&nbsp;
																	<Button
																		color="secondary"
																		onClick={() => {
																			setApproveLeave(false);
																			setApprovedEmployeeResignation('');
																		}}
																	>
																		Close
																	</Button>
																</div>
															</Modal>
															<Modal
																ariaHideApp={false}
																isOpen={leaveDeclineOpen}
																onRequestClose={closeModal}
																style={customStyles2}
																contentLabel="Example Modal"
															>
																<h5>Decline Resignation Application</h5>
																<textarea
																	style={{ minWidth: '600px' }}
																	className="form-control"
																	onChange={(e) => {
																		setDeclineNewData(e.target.value);
																	}}
																	rows={5}
																/>
																<br />
																<div style={{ float: 'right' }}>
																	<Button
																		color="secondary"
																		onClick={() => {
																			setDeclineLeave(false);
																			setDeclinedEmployeeResignation(false);
																		}}
																	>
																		Close
																	</Button>
																	&nbsp;&nbsp;&nbsp;
																	<Button
																		color="danger"
																		type="submit"
																		onClick={() => {
																			declineResignation(
																				declinedEmployeeResignation
																			);
																		}}
																	>
																		Decline
																	</Button>
																</div>
															</Modal>
														</div>
													</div>
												</td>
											</tr>
										))
									) : (
										// Display a message when no resignation records are available
										<tr>
											<td colSpan={6}>No records available</td>
										</tr>
									)}
								</tbody>
							</Table>
							<div>
								{resignation && resignation.length > 0 ? <Pagination /> : null}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ResignationList;
