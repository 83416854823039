import React, { useState,Component } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useStyles } from "../../Utilities/CSSUtilities";
import axios from "axios";
import base_url from '../../api/bootapi';
import { Link, useNavigate } from "react-router-dom";




    const SignUp = (props) => {
        const jwtToken = "JWT " + localStorage.getItem("JWTToken");
        const { register, handleSubmit, formState: { errors } } = useForm();
        const [backdropOpen, setBackdropOpen] = useState(false);
        const [chkPassState, setchkPassState] = useState();
        const [chkPass, setchkPass] = useState();
        const [inputtype, setinputtype] = useState("password");
        const navigate = useNavigate();
        const classes = useStyles();
        const formData = new FormData();

        
        const onSubmit = (data2) => {
            let first_name = data2.first_name;
            let last_name = data2.last_name;
            let email = data2.email;
            let password = data2.password;
            let phone = data2.phone;
            let username = data2.email;
            let designation = 'CEO';
            let role_id = '1';
            let role_name = 'SuperAdmin';
            let is_verified = false;
            let is_owner = true;
            let status = true;
            let company_name = data2.company_name;
            let company_size = data2.company_size;

            let data ={
                first_name,
                last_name,
                email,
                password,
                phone,
                username,
                designation,
                role_id,
                role_name,
                is_verified,
                is_owner,
                status,
                company_name,
                company_size,

            };
            console.log(data);
            formData.append("data", JSON.stringify(data));
            postUser(formData);

        };
        const postUser = (data) => {
            setBackdropOpen(true);
            axios 
              .post(`${base_url.api1}/myappapi/users`, data, {
              })
              .then(
                (response) => {
                console.log(response);
                  toast.success("Success");
                  setBackdropOpen(false);      
                  navigate(`/`);
                },
                (error) => {
                  console.log(error);
                  setBackdropOpen(false);
                  toast.error("Company Not Created!");
                }
              );
          };

        return (
            <div class="signupform">
            <div class="sign-inner">
                <div class="title_container">
                    <h2 className="headclass">
                    <img src="./logo1.png" width={240} />
                    </h2>
                <h2 style={{ textAlign: "center" }}> Registration </h2>
                <form id="myForm" action="" method="post" onSubmit={handleSubmit(onSubmit)} >
                        <div class="input_field"> <span><i aria-hidden="true" class="fa fa-user"></i></span>
                            <input type="text"
                             {...register("first_name", { required: true })} 
                             id="first_name" 
                             placeholder="First Name" />
                        </div>
                        <div class="input_field"> <span><i aria-hidden="true" class="fa fa-user"></i></span>
                            <input type="text" 
                             {...register("last_name", { required: false })} 
                             id="last_name"
                             placeholder="Last Name" required />
                        </div>

                   <div class="input_field"> <span><i aria-hidden="true" class="fa fa-envelope"></i></span>
                        <input type="email" 
                        {...register("email", { required: true })} 
                        id="email"
                         placeholder="Email" required />
                    </div>
                    <div class="input_field"> <span><i aria-hidden="true" class="fa fa-phone"></i></span>
                        <input type="number" 
                        {...register("phone", { required: true })} 
                        id="phone"
                        placeholder="Phone Number" required />
                    </div>
                    <div class="input_field"> <span><i aria-hidden="true" class="fa fa-lock"></i></span>
                        <input type="password" id="password"
                        {...register("password", {required: true })}
                        value={chkPassState}
                        autoComplete="off"
                         placeholder="Password" required />
                    </div>
                    <div class="input_field"> <span><i aria-hidden="true" class="fa fa-lock"></i></span>
                        <input type="password" name="password" placeholder="Re-type Password" required />
                    </div>
                    <div class="input_field"> <span><i aria-hidden="true" class="fa fa-envelope"></i></span>
                        <input type="text" 
                        {...register("company_name", { required: true })} 
                             id="company_name" placeholder="Company Name" required />
                    </div>
                    <div class="input_field"> <span><i aria-hidden="true" class="fa fa-envelope"></i></span>
                        <input type="text" 
                        {...register("company_size", { required: true })} 
                        id="company_size"
                        placeholder="Company Size" required />
                    </div>
                    <input class="button" type="submit" value="Register" />
                    </form>
                </div>
                </div>
                </div>               

 );
} 
export default SignUp