import React , { useState, useEffect }from 'react';
import moment from "moment";
import { Link, useNavigate ,useParams} from "react-router-dom";
import { CardText, Col, Row, Button, Table } from "reactstrap";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Pagination from "@mui/material/Pagination";
import DateRangePicker from "react-bootstrap-daterangepicker";
import 'bootstrap-daterangepicker/daterangepicker.css';
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import axios from "axios";
import base_url from '../../../api/bootapi';
import { customStyles, useStyles,customStyles2 } from "../../../Utilities/CSSUtilities";
import { useDispatch, useSelector } from "react-redux";
import { logout, selectEmployee } from "../../features/empSlice";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useToasts } from 'react-toast-notifications';
import close from "../../../components/images/closeicon.png";
import holidayicon from "../../../components/images/event.png";
import editicon from "../../../components/images/edit.png";

const TypeofLeave = () => {
  const classes = useStyles();
  const params = useParams();
  //console.log(params); 
  const jwtToken = "JWT " + localStorage.getItem("JWTToken");
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [names, setName] = useState([]);
  const [modalType, setCreate] = React.useState(false);
  const navigate =useNavigate();
  const [searchdata, setsearch] = useState();
  const [datevalue, setdatevalue] = useState("Click to open");
  const [datefilter, setdatefilter] = useState();
  const [backdropOpen, setBackdropOpen] = useState(false);
  const employeeData = useSelector(selectEmployee);
  const [pageNo, setPageNo] = useState(1);
  const [paginationPageNo, setPaginationPageNo] = useState(1);
  const [getPageNo, setGetPageNo] = useState();
  const [rows, setRows] = React.useState(50);
  const [totalcount, setTotalCount] = useState();
  const [countNumber, setCountNumber] = useState();
  const [numberOfElements, setNumberOfElements] = useState();
  const [selectedDelete, setSelectedDelete] = useState([]);
  const [datesrange, setdatesrange] = useState();
  const [display1, setdisplay] = useState("inline");
  const [direction, setdirection] = useState("Desc");
  const [sortState, setsortState] = useState("name");
  const [permissionObj, setPermissionObj] = useState();

  useEffect( () => {
    getPermissions();
  },[rows]);

  const handleRows = (event) => {
    setRows(event.target.value);
  };
  
    
  const getPermissions = () => {
    const resource_obj = [];

    for (let i = 0; i < employeeData.role_permissions.length; i++) {
      resource_obj.push(employeeData.role_permissions[i].resource_id);
    }
    setPermissionObj(resource_obj);
    var available_resource52 = resource_obj.includes(52);
    var available_resource53 = resource_obj.includes(53);
    var available_resource54 = resource_obj.includes(54);
    var available_resource55 = resource_obj.includes(55);

    if(available_resource52 && (available_resource53 || available_resource54 || available_resource55)) {
      if (localStorage.getItem("TypeofLeavePageNo")) {
        let currentPage = localStorage.getItem("TypeofLeavePageNo");
        setPageNo(currentPage);
        setPaginationPageNo(Number(currentPage));
        getAllTypeofLeave(currentPage);
      } else {
        setPageNo(1);
        setPaginationPageNo(1);
        getAllTypeofLeave(1);
      }
    } else {
      navigate('/errorPage');
    }
  };

    
    const createTypeofLeave = (data2) =>{
        setBackdropOpen(true);
    let name = data2.name;
    let status = data2.status;
    let company_code = employeeData.company_code;

    let data ={
        name,
        status,
        company_code
    };
    axios
        .post(`${base_url.api1}/myappapi/typeofleave/create?company_code=`+employeeData.company_code,data,{

            headers:{
                Authorization: jwtToken,
            },
        })
        .then((response)=>{
                setBackdropOpen(false);
                getAllTypeofLeave(pageNo);
                setCreate(false);
            })
            .catch((error)=>{
                setBackdropOpen(false);
                setCreate(false);
            });
    }
    const [editOpen, setEdit] = React.useState(false);
  const [editTypeofLeaveId, seteditTypeofLeaveId] = useState();
  const [editTypeofLeave,seteditTypeofLeave] = useState();
  const [editTypeofLeaveStatus, seteditTypeofLeaveStatus] = useState();
  const editTypeofLeaveData = (elem,leave) => {
    console.log(leave);
    seteditTypeofLeaveId(leave.id);
    seteditTypeofLeave(leave.name);
    seteditTypeofLeaveStatus(leave.status);
    setEdit(true);
  };
  const editOldCategory= (data2) =>{
        let data = {
          name: editTypeofLeave.trim(),
          status: editTypeofLeaveStatus.trim(),
        };
        setBackdropOpen(false);
    
        axios
          .put(`${base_url.api1}/myappapi/typeofleave/update?company_code=`+ employeeData.company_code + `&id=` + editTypeofLeaveId,data, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((response) => {
            // setBackdropOpen(false);
            setEdit(false);
            getAllTypeofLeave(pageNo);
          })
          .catch((error) => {
            // setBackdropOpen(false);
          });
      }

    
  const handleCallback = (start, end, label) => {
    console.log(start, end, label);
  };

  const getAllTypeofLeave = (value1) => {
    console.log(value1);
    setBackdropOpen(true);
    axios
      .get(`${base_url.api1}/myappapi/typeofleave/list?company_code=` + employeeData.company_code+`&page=`+value1+`&size=`+ rows, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setName(response.data.Typeofleave);
          console.log(response.data.Typeofleave);
          setGetPageNo(response.data.total_pages);
          setTotalCount(response.data.total_count);
          setCountNumber(value1-1);
          setNumberOfElements(response.data.Typeofleave.length);
          setSelectedDelete([]);
          setsearch(false);
          setBackdropOpen(false);
          document.getElementById("myForm").reset();
        },
        (error) => {
          console.log(JSON.stringify(error.response.data));
          setBackdropOpen(false);
          setName();
        }
      );
  };

  const closedModal = () => {
    setDelete(false);
  }
  const closeModal = () => {
    setCreate(false);
  }

  const createModalConfig = () => {
    setCreate(true);
  };

  const DeleteType = () => {
    setDelete(true);
  };

  const [DeleteId, setDeleteId] = useState();
  const [deleteOpen, setDelete] = React.useState(false);
  const deleteTypeofLeave = (elem,leave) => {
    console.log(leave);
    setDeleteId(leave.id);
  };
  const deleteCurrentCategory = () => {
    axios
      .delete(`${base_url.api1}/myappapi/typeofleave/delete?company_code=` + employeeData.company_code +`&id=` + DeleteId  , {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setBackdropOpen(false);
          setDelete(false);
          getAllTypeofLeave(pageNo);
        },
        (error) => {
          setBackdropOpen(false);
          console.log(JSON.stringify(error.response.data));
        }
      );
  };

  const handleEvent = (event, picker) => {
    console.log(picker.startDate);
  };

  const handlepage = (event, value) => {
    if(searchdata==true)
    console.log(value);
    setPaginationPageNo(value);
    setPageNo(value);
    getAllTypeofLeave(value);
  };

  const [searchinput, setsearchinput] = useState();
  const submitSearch = (e, value) => {
     setdisplay("none");
    setsearch(true);
    let srchdata = e.target[0].value.trim();
    setsearchinput(srchdata);
    console.log(srchdata);
    setBackdropOpen(true);
    axios
      .get(`${base_url.api1}/myappapi/typeofleave/list?company_code=` + employeeData.company_code +`&page=`+1+`&size=`+rows+`&search=` + srchdata, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setName(response.data.Typeofleave);
          setGetPageNo(response.data.total_pages);
          setNumberOfElements(response.data.Typeofleave.length);
          setTotalCount(response.data.total_count);
          console.log(response.data);
          setBackdropOpen(false);
        },
        (error) => {
          console.log(JSON.stringify(error.response.data));
          setBackdropOpen(false);
          setName();
        },
        (error) => {
          console.log(JSON.stringify(error.response.data));
        }
      );
  };

  const sortUser = (e) => {
    setsortState(e.target.id);
    console.log();
    if (direction == "Desc") {
      setdirection("ASC");
    } else if (direction == "ASC") {
      setdirection("Desc");
    }
    let sortby = e.target.id

    axios
      .get(`${base_url.api1}/myappapi/typeofleave/list?company_code=` + employeeData.company_code + `&sortby=`+sortby+`&Order=`+direction +`&page=`+pageNo, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setName(response.data.Typeofleave);
          document.getElementById("myForm").reset();
          setGetPageNo(response.data.total_pages);
          //console.log(response.data.total_pages);
          setTotalCount(response.data.total_count);
          setCountNumber(pageNo-1);
          setNumberOfElements(response.data.Users.length);
          //console.log(response.data.Users.length);
          setSelectedDelete([]);
          setsearch(false);
          setBackdropOpen(false);
        },
        (error) => {
          console.log(JSON.stringify(error.response.data));
          setName();
        }
      );
  };

  
  return (
    <div>
      <Backdrop open={backdropOpen}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="commoncomponent_cardcss">
        <div style={{ margin: "5px",marginTop: "-30px" }}>
          <Row>
            {/* <Col md={6}>
              <CardText
                className={classes.headtext}
                style={{ marginTop: "-10px" }}
              >
                <h2>Type of Leave</h2>
              </CardText>
            </Col>
            <Col md={6}  className="d-flex justify-content-end">
              {permissionObj?.includes(53)?
                <Button
                  color="primary"
                  onClick={createModalConfig}
                >
                  + Add
                </Button>
              :null}
            </Col> */}
        {permissionObj?.includes(53)?
          <div className="newhead">
            <h4>Type of Leave</h4>
            <div onClick={createModalConfig}>
              <h6>New</h6> <p>+</p>
            </div>
          </div>:null}
            <Modal
              ariaHideApp={false}
              isOpen={modalType}
              onRequestClose={closedModal}
              style={customStyles}
            >
              {/* <h2>
                <strong>Add Type of Leave</strong>
              </h2> */}
          <div style={{ textAlign: "right", padding: "10px 20px" }}>
            <img className="closei" src={close} onClick={() =>setCreate(false)} />
          </div>
          <div className='modalContainer'>
            <div style={{ textAlign: "center" }}>
              <img className='modelIcon' src={holidayicon} />
            </div>
              <form onSubmit={handleSubmit(createTypeofLeave)}>
              <h1>Add Type of Leave</h1>
                <div>
                  <div className="form-group creatadmin">
                    <Row>
                        <label>
                          Type of Leave<span style={{ color: "red" }}>*</span>:
                        </label>
                        <input
                          type="text"
                          className="form-control underline-input"
                          placeholder="Leave Type"
                          id="leaveType"
                          {...register("name",{ required: true })}
                        />
                        {errors.name && (
                          <p className="errormessage">Leave Type is Required</p>
                        )}
                    </Row>
                    <Row>
                        <label>
                          Status:
                        </label>
                        <select
                          className="form-control underline-input"
                          {...register("status")}
                        >
                        <option value={true}>Active</option>
                        <option value={false}>InActive</option>
                        </select>
                            {errors.status && (
                              <p className="errormessage">Status is Required</p>
                            )}
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Row className="justify-content-end mt-2">
                          <Button
                            color="secondary"
                            onClick={() => setCreate(false)}
                          >
                            Cancel
                          </Button>
                          <Button
                            color='primary'
                            type="submit"
                            className="btn listing_addbutton ml-1"
                          >
                            Submit
                          </Button>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </div>
              </form>
              </div>
            </Modal>
          </Row>
          <div className="newlisting_headertabdesign">
            <Row>
              <form className="master">
                  <div style={{ marginTop: "7px",marginRight:"5px" }}>
                    <label className="area_rowslable">Limit</label>
                    <br />
                    <FormControl className={classes.formControl}>
                      <Select
                        className={classes.selectlimit}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={rows}
                        onChange={handleRows}
                      >
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </form>
                <form className="form-group"> 
                      {datefilter ? (
                      <div>
                        <a
                           onEvent={handleEvent}
                           onCallback={handleCallback}
                          className={classes.clrsrch}
                          onClick={() => {
                            setdisplay("inline");
                            setdatevalue("Click to open");
                          }}
                        >
                            Clear Filter
                          </a>
                        </div>
                      ) : null} 
                </form>
                <br />
              <Col md={0}>
                <div className="form-group">
                  <div>
                    <div className="clearsearchclass">
                      <form
                        id="myForm"
                        className="d-flex"
                        onSubmit={(e) => {
                          e.preventDefault();
                          submitSearch(e,1);
                        }}
                      >
                        <input
                          style={{
                            padding: "5px",
                            border: "2px solid",
                            marginTop: "-1px",
                          }}
                          className="form-control"
                          placeholder="Search.."
                          name="search"
                          required
                        />
                        <button
                          color='blue'
                          className={classes.srchbtn}
                          type="submit"
                        >
                          <i className="fa fa-search"></i>
                        </button>
                      </form>
                      {searchdata ? (
                         <a    
                         onClick={() => {
                             setdisplay("inline");
                             setBackdropOpen(true);
                             setPageNo(1);
                             getAllTypeofLeave(1);
                           }}                      
                         >
                          Clear Search
                        </a>
                      ) : null}
                    </div>                      
                  </div>
                </div>
              </Col>
            </Row>
            <br/>
          </div>
          <div className="newlisting_headertabdesign">
            <Table responsive striped>
            <thead>
                <tr>
                  <th >SNo.</th>
                  <th>  Type of Leave {" "}
                    <UnfoldMoreIcon onClick={sortUser} id="name"/>
                  </th>
                  <th style={{ textAlign: "center", width: "375px"}}>
                    Status{" "}
                    <UnfoldMoreIcon onClick={sortUser} id="status"/>
                  </th>
                  <th style={{ textAlign: "center", width: "25px" }}>Action</th>
                </tr>
              </thead>              
              <tbody>
                {names != null ? (
                  names &&
                  names.map((leave , index) => (
                  <tr>
                    <th>{countNumber * rows + 1 + index}</th>
                    <td>{leave.name}</td> 
                    <td style={{ textAlign: "center", width: "175px" }}>{leave.status == true ? "Active":"InActive"}</td>
                    {/* <td style={{ textAlign: "center", width: "175px" }}>{holiday.type_of_holiday}</td> */}
                    <td>
                      <div style={{
                          position: "relative",
                          textAlign: "center",
                        }}
                      >
                        <div
                          className="workflowdashboard_fa-ellipsis"
                          data-toggle="dropdown"
                        >
                          <i
                            className="fa fa-ellipsis-v "
                            aria-hidden="true"
                          ></i>
                        </div>
                        <div
                          className="dropdown-menu workflowdashboarddropdown"
                          style={{ width: "200px" }}
                        >
                          {permissionObj?.includes(54)?
                            <p id={leave.id}
                              onClick={(e)=>{
                                e.preventDefault();
                                editTypeofLeaveData(e, leave)}}>
                              Edit
                            </p>
                          :null}
                          <Modal
                            isOpen={editOpen}
                            onRequestClose={closeModal}
                            style={customStyles}
                            ariaHideApp={false}
                            contentLabel="Example Modal"
                          >
                             <div style={{ textAlign: "right", padding: "10px 20px" }}>
                              <img className="closei" src={close} onClick={() => setEdit(false)} />
                            </div>
                            <div className='modalContainer'>
                              <div style={{ textAlign: "center" }}>
                                <img className='modelIcon' src={editicon} />
                              </div>
                            <h2>
                              <strong>Edit Type of Leave</strong>
                            </h2>
                            <form id="editForm" onSubmit={(e) => {
                                e.preventDefault();
                                editOldCategory(e);
                              }}>
                              <div>
                                <div className="form-group creatadmin">
                                  <Row>
                                    <Col md={12}>
                                      <label>Type of Leave <span style={{ color: "red" }}>*</span>:</label>
                                      <input
                                        type="text"
                                        className="form-control underline-input"
                                        value={editTypeofLeave}
                                        onChange={(e) => {
                                          seteditTypeofLeave(e.target.value);
                                        }}
                                        required
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={12}>
                                      <label>Status<span style={{ color: "red" }}>*</span>:</label>
                                      <select
                                        name="status"
                                        className="form-control underline-input"
                                        value={editTypeofLeaveStatus}
                                        onChange={(e) => {
                                          seteditTypeofLeaveStatus(e.target.value);
                                        }}
                                      >
                                        <option value={true}>Active</option>
                                        <option value={false}>InActive</option>
                                      </select>
                                    </Col>
                                  </Row>
                                  <br/>
                                  
                                </div>
                                {/* <Row style={{ float: "right" }}>
                                  <Button
                                    color="secondary"
                                    style={{
                                      marginTop: "20px",
                                      marginRight: "10px",
                                    }}
                                    onClick={() => setEdit(false)}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    color="primary"
                                    type="submit"
                                    style={{ marginTop: "20px" }}
                                  >
                                    Submit
                                  </Button>
                                </Row> */}
                                  <div style={{ display: "flex", justifyContent: "space-between", margin: "30px 0px" }}>
                                    <button className='modalbtn backbtn' onClick={() => setEdit(false)}>Back</button>
                                    <button className='modalbtn submitbtn' >Submit</button>
                                  </div>
                                </div>
                              </form>
                            </div>    
                          </Modal>
                          {permissionObj?.includes(55)?
                            <p
                              id={leave.id}
                              onClick={(e) => {
                                deleteTypeofLeave(e, leave);
                                DeleteType();
                              }} color="danger">Delete
                            </p>
                          :null}
                         
                          <Modal
                            ariaHideApp={false}
                            isOpen={deleteOpen}
                            onRequestClose={closedModal}
                            style={customStyles2}
                            contentLabel="Example Modal"
                          >
                            <h5>
                              Are you sure you want to delete current record ?
                            </h5>
                            <div style={{ float: "right" }}>
                              <Button
                                color="danger"
                                type="submit"
                                onClick={deleteCurrentCategory}
                              >
                                Delete
                              </Button>
                              &nbsp;&nbsp;&nbsp;
                              <Button
                                color="secondary"
                                onClick={() => setDelete(false)}
                              >
                                Cancel
                              </Button>
                            </div>
                          </Modal> 
                        </div>
                      </div>
                    </td>
                  </tr>
                ))) : (
                  <p className={classes.headertekst}>{null}</p>
                )}
              </tbody>
            </Table>
            <div>
            <Row>
            <Col md={6}>
              {names?.length ? (
                <p style={{ marginTop: "15px" }}>
                  {countNumber * rows + 1} to{" "}
                  {countNumber * rows + numberOfElements} of {totalcount} records
                </p>
              ) : (
                <p className="formfooterrecordstyle">No Record Found</p>
              )}
            </Col>
            <Col md={6}>
              <div className={classes.root}>
                {searchdata || datefilter ? (
                  <Pagination
                    page={paginationPageNo}
                    count={getPageNo}
                    onChange={submitSearch}
                  />
                ) : (
                  <Pagination
                    page={paginationPageNo}
                    count={getPageNo}
                    onChange={handlepage}
                  />
                )}
              </div>
            </Col>
          </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 
export default TypeofLeave