import React , { useState, useEffect }from 'react';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Link, useNavigate,useParams } from "react-router-dom";
import { CardText, Col, Row, Button, Table } from "reactstrap";
import AddIcon from "@mui/icons-material/Add";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Pagination from "@mui/material/Pagination";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import { customStyles2, useStyles } from "./../../Utilities/CSSUtilities";
import axios from "axios";
import moment from "moment";
import base_url from '../../api/bootapi';
import { logout, selectEmployee } from "../../components/features/empSlice";
import { useDispatch, useSelector } from "react-redux";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Select from 'react-select';
import attachmenturl from '../../api/attachmenturl';
import CallAuditapi from "../services/auditservice";



const Task = () => {
  const [backdropOpen, setBackdropOpen] = useState(false);
  const params = useParams(); 
  const [modalCreate, setCreate] = React.useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [taskData,setTaskData] =useState([]);
  const [projectData,setProjectData] =useState([]);
  const navigate =useNavigate();
  const jwtToken = "JWT " + localStorage.getItem("JWTToken");
  const classes = useStyles();
  const employeeData = useSelector(selectEmployee);
  const [searchdata, setsearch] = useState(false);
  const [display1, setdisplay] = useState("inline");
  const [numberOfElements, setNumberOfElements] = useState();
  const [descContent, setDescContent] = useState();
  const [descModelOpen, setDescModelOpen] = useState(false);
  const [empData, setEmpData] = useState();
  const [rows, setRows] = React.useState(50);
  const [datefilter, setdatefilter] = useState(false);
  const [datevalue, setdatevalue] = useState("Task Date");
  const [direction, setdirection] = useState("Desc");
  const [pageNo, setPageNo] = useState(1);
  const [paginationPageNo, setPaginationPageNo] = useState(1);
  const [getPageNo, setGetPageNo] = useState();
  const [totalcount, setTotalCount] = useState();
  const [selectedDelete, setSelectedDelete] = useState([]);
  const [datesrange, setdatesrange] = useState();
  const [countNumber, setCountNumber] = useState();
  const [sortState, setsortState] = useState("task_title");
  const [modalShowDoc, setViewTaskDoc] = React.useState(false);
  const formData = new FormData();
  const dispatch = useDispatch(); 
  var urlData;
  const [permissionObj, setPermissionObj] = useState();

  // const createTask = createTasklist => {
  //   Navigate(createTasklist);
  // };

  useEffect( () => {
    getPermissions();
    window.addEventListener("storage", function (e) {
      if (e.key === null) {
        dispatch(logout());
        navigate("/");
      }
    });
  },[empData,rows]);

  const getPermissions = () => {
    const resource_obj = [];
    for (let i = 0; i < employeeData.role_permissions.length; i++) {
      resource_obj.push(employeeData.role_permissions[i].resource_id);
    }
    setPermissionObj(resource_obj);
    var available_resource = resource_obj.includes(78);
  
    if(available_resource) {
      if(localStorage.getItem("TaskPageNo")) {
        let currentPage = localStorage.getItem("TaskPageNo");
        setPageNo(currentPage);
        setPaginationPageNo(Number(currentPage));
        urlData = `&emp_id=`+ employeeData.emp_id+`&page=`+currentPage+`&size=`+ rows;
        getAllData(urlData);
      } else {
        setPageNo(1);
        setPaginationPageNo(1);
        urlData = `&emp_id=`+ employeeData.emp_id+`&page=1&size=`+ rows;
        getAllData(urlData);
      }
      statusByID();
      projectByID();
      userByRoleidAll();
      getAllTaskStatusList();
    } else {
      navigate('/errorPage');
    }
  };

  const closeModal = () => {
    setViewTaskDoc(false);
    setCreate(false);
    setDeleteModal(false);
  }


  const [forwardOpen, setforwardEdit] = React.useState(false);
  const [frwdReason, setFrwdReason] = useState();
  const [editTaskId, seteditTaskId] = useState();
  const forwardTask = (elem,Tasks) => {
    console.log(Tasks);
    seteditTaskId(Tasks.id);
    setforwardEdit(true);
  };
  const editforwardCategory= (data2) =>{
      setBackdropOpen(true);
      let assignee_id = employeeData.emp_id;
      let assignee_name = employeeData.emp_name;
      let emp_id = Number(selecteduser);
      let emp_name = defaultuser;
      let  reason = frwdReason+"\n>>"+employeeData.emp_name+ " forwarded this task to " + emp_name ;
        let data = {
          assignee_id,
          assignee_name,
          emp_id,
          emp_name,
          task_log:reason,  
        };
        formData.append("data", JSON.stringify(data));
        axios
          .put(`${base_url.api1}/office_app/assign_task?company_code=`+ employeeData.company_code + `&id=` + editTaskId  , formData, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((response) => {
            setBackdropOpen(false);
            setforwardEdit(false);
            // getAlluserTaskList(1);
            urlData =`&page=1&size=`+ rows+`&emp_id=`+employeeData.emp_id;
            getAllData(urlData);
          })
          .catch((error) => {
            setBackdropOpen(false);
          });
      }

      const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
          fileReader.onload = () => {
            resolve(fileReader.result);
          };
          fileReader.onerror = (error) => {
            reject(error);
          };
        });
      };
      const uploadLogo = async (data) => {
        console.log(data.target.files);
        const file = data.target.files[0];
        const base64 = await convertBase64(file);
      };


      const [statusOpen, setstatusEdit] = React.useState(false);
      const [TaskId, setTaskId] = useState();
      const [editStatus, seteditStatus] = useState();
      const [editDescription,seteditDescription] = useState();
      const editTaskStatus = (elem,Tasks) => {
        console.log(Tasks);
        setTaskId(Tasks.id);
        seteditStatus(Tasks.status);
        setstatusEdit(true);
      };
      const editOldTaskStatus= (data2) =>{
            // let doc = data2.doc;
            // if (doc.length) {
            //   for (let i = 0; i < doc.length; i++) {
            //     formData.append(
            //       "doc",
            //       doc[i],
            //       doc[i].name,
            //     );
            //   }
            // }
            let status =Number(selectedStatus);
            let task_status_name =defaultStatus;
            let textbox =editDescription +"\n>>"+employeeData.emp_name+ " updated the status to " + task_status_name;
            let data = {
              // doc,
              status,
              task_log: textbox,
            };
            formData.append("data", JSON.stringify(data));
            setBackdropOpen(true);
            axios
              .put(`${base_url.api1}/office_app/assign_task?company_code=`+ employeeData.company_code + `&id=` + TaskId  , formData, {
                headers: {
                  Authorization: jwtToken,
                },
              })
              .then((response) => {
                setBackdropOpen(false);
                setstatusEdit(false);
                // getAlluserTaskList(1);
                urlData =`&page=1&size=`+ rows+`&emp_id=`+employeeData.emp_id;
                getAllData(urlData);
              })
              .catch((error) => {
                setBackdropOpen(false);
              });
          }

  const handleEvent = (event, picker) => {
    console.log(picker.startDate);
  };
    
  const handleCallback = (start, end, label) => {
    console.log(start, end, label);
  };

  const handleRows = (event) => {
    setRows(event.target.value);
  };

  const [searchinput, setsearchinput] = useState();
  const submitSearch = (e, value) => {
     setdisplay("none");
    setsearch(true);
    let srchdata = e.target[0].value.trim();
    setsearchinput(srchdata);
    console.log(srchdata);
    setBackdropOpen(true);
    axios
      .get(`${base_url.api1}/office_app/assign_task/list?company_code=`+ employeeData.company_code +`&emp_id=`+ employeeData.emp_id+`&search=`+srchdata, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setBackdropOpen(false);
          setTaskData(response.data.Tasks);
          // console.log(response.data.Tasks);
          setGetPageNo(response.data.total_pages);
          setNumberOfElements(response.data.Tasks.length);
          setTotalCount(response.data.total_count);
          
        },
        (error) => {
          setBackdropOpen(false);
          console.log(JSON.stringify(error.response.data));
          setTaskData();
        },
        (error) => {
          setBackdropOpen(false);
          console.log(JSON.stringify(error.response.data));
          
        }
      );
  };


 const [availableroles, setAvailableroles] = useState([]);

 const lookup = availableroles;

const [selected, setSelected] = useState(-1); 
const value = selected !== -1 && lookup[selected];

const [selecteduser, setSelecteduser] = useState();
const [defaultuser, setdefaultUser] = useState();

const handleChangeUser = (event) => {
  console.log(event);
  setdefaultUser(event.target.value);
  console.log(event.target.value);
  const index = event.target.selectedIndex;
  console.log(index);
  const optionElement = event.target.childNodes[index];
  const optionElementId = optionElement.getAttribute("id");
  console.log(optionElementId);
  const optionElementValue = optionElement.getAttribute("value");
  console.log(optionElementValue); 

  setSelecteduser(optionElementId);
  setdefaultUser(optionElementValue);
};




const [availableRoleById, setAvailableRoleById] = useState([]);
const userByRoleidAll = () => {
  setBackdropOpen(true);
  axios
    .get(`${base_url.api1}/myappapi/all_users_by_role?company_code=` + employeeData.company_code +`&status=True` , {
      headers: {
        Authorization: jwtToken,
      },
    })
    .then((response) => {
      console.log(response.data.Users);
      setAvailableRoleById(response.data.Users);
      setBackdropOpen(false);
    })
    .catch((error) => {
      console.log(JSON.stringify(error));
      setBackdropOpen(false);
    });
};

const look = availableRoleById;

const [getselected, setgetSelected] = useState(-1); 
const value2 = getselected !== -1 && look[getselected];

const [selectedroleById, setSelectedroleById] = useState();
const [defaultRoleById, setdefaultRoleById] = useState();
const handleChangeRoleById = (event) => {
  console.log(event);
  setdefaultRoleById(event.target.value);
  console.log(event.target.value);
  const index = event.target.selectedIndex;
  console.log(index);
  const optionElement = event.target.childNodes[index];
  const optionElementId = optionElement.getAttribute("id");
  console.log(optionElementId);
  const optionElementValue = optionElement.getAttribute("value1");
  console.log(optionElementValue); 

  setSelectedroleById(optionElementId);
  setdefaultRoleById(optionElementValue);
};

const [taskstatus, setTaskStatus] = useState([]);
  const getAllTaskStatusList = (value1) => {
    console.log(value1);
    setBackdropOpen(true);
    axios
      .get(`${base_url.api1}/office_app/Task_status/all?company_code=` + employeeData.company_code, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setTaskStatus(response.data.Status);
          setBackdropOpen(false);
        },
        (error) => {
          console.log(JSON.stringify(error.response.data));
          setBackdropOpen(false);
          setTaskStatus();
        }
      );
  };
  const looksup = taskstatus;
    // console.log(looksup);
  const [selectedstatus, setSelectedstatus] = useState();
  const values = selectedstatus !== 0 && looksup[selectedstatus];
  const [selectedStatus, setSelectedStatus] = useState();
  const [defaultStatus, setdefaultStatus] = useState();
  const handleChangeStatus = (event) => {
    console.log(event);
    setdefaultStatus(event.target.value);
    console.log(event.target.value);
    const index = event.target.selectedIndex;
    console.log(index);
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    console.log(optionElementId);
    const optionElementValue = optionElement.getAttribute("value");
    console.log(optionElementValue); 

    setSelectedStatus(optionElementId);
    setdefaultStatus(optionElementValue);
  };
  const handlepage = (event, value) => {
    console.log(value);
    setPaginationPageNo(value);
    setPageNo(value);
    // getAlluserTaskList(value);
    urlData = `&page=1&size=`+ rows+`&emp_id=`+employeeData.emp_id;
    getAllData(urlData);
  };
  
   const handleApply = (event, picker) => {
    setBackdropOpen(true);
    setdatevalue(
      `${picker.startDate.format("DD-MM-YYYY")} to ${picker.endDate.format(
        "DD-MM-YYYY"
      )}`
    );
    let data = {
      from: picker.startDate.format("YYYY-MM-DD"),
      to: picker.endDate.format("YYYY-MM-DD"),
      //sortby: sortState,
     // Order: direction,
    };
    setdatesrange(data);
    axios
      .get(`${base_url.api1}/office_app/assign_task/range?company_code=` + employeeData.company_code +`&emp_id=`+ employeeData.emp_id  +`&from=`+data.from+`&to=`+data.to, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setTaskData(response.data.Tasks);
          setGetPageNo(response.data.total_pages);
           setTotalCount(response.data.total_count);
          setNumberOfElements(response.data.Tasks.length);
          setCountNumber(pageNo-1);
          setsearch(false);
          setBackdropOpen(false);
          setdatefilter(true);
          document.getElementById("myForm").reset();
        },
        (error) => {
          setTaskData();
          console.log(JSON.stringify(error.response.data));
          setdatefilter(true);
          setBackdropOpen(false);
        }
      );
  };

  const sortTask = (e) => {
    setBackdropOpen(true);
    setsortState(e.target.id);
    console.log();
    if (direction == "Desc") {
      setdirection("ASC");
    } else if (direction == "ASC") {
      setdirection("Desc");
    }
    let sortby = e.target.id

    axios
      .get(`${base_url.api1}/office_app/assign_task/list?company_code=` + employeeData.company_code +`&emp_id=`+ employeeData.emp_id+ `&sortby=`+sortby+`&Order=`+direction +`&page=`+pageNo, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setTaskData(response.data.Tasks);
          document.getElementById("myForm").reset();
          setGetPageNo(response.data.total_pages);
          setTotalCount(response.data.total_count);
          setCountNumber(pageNo-1);
          setNumberOfElements(response.data.Tasks.length);
          setsearch(false);
          setBackdropOpen(false);
        },
        (error) => {
          console.log(JSON.stringify(error.response.data));
          setTaskData();
          setBackdropOpen(false);
        }
      );
  };

  const [selectprojectId , setSelectProjectId] = useState();
  const [selectproject, setSelectedProject] = useState(false);
  const [selectProjectData, setSelectedProjectData] = useState();
  const onSelectProject = (selectedList, selectedItem) => {
    console.log("Entering",selectedList);
    setBackdropOpen(true);
    setSelectedProject(true);
    setSelectedProjectData(selectedList);
    setSelectProjectId(selectedList.value);

    if(selectedList.value==null){
      urlData =`&page=1&size=`+ rows+`&emp_id=`+employeeData.emp_id;
      getAllData(urlData);
    } else {
      urlData =  `&page=1&size=`+ rows+`&emp_id=`+employeeData.emp_id +`&project_id=`+selectedList.value;
      getAllData(urlData);
    }
  };


const [availableProject, setAvailableProject] = useState([]);
const [ProjectId, setprojectId] = useState([]);
  const projectByID = () => {
    axios
      .get(`${base_url.api1}/office_app/project/list?company_code=` + employeeData.company_code, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        console.log(response.data.Projects);
        setprojectId(response.data.id);
        let project_data = response.data.Projects;
        let project_obj = [];
        for (let i = 0; i < project_data.length; i++) {
          let data = {
            value: project_data[i].id,
            label: project_data[i].project_name,
          };
          project_obj.push(data);
        }
        setAvailableProject(project_obj);
        setGetPageNo(response.data.total_pages);
        setTotalCount(response.data.total_count);
        setCountNumber(0);
        setNumberOfElements(response.data.Projects.length);
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
      });
  };
  // const lookedup = availableProject;
  // console.log(lookedup);

  // const [newselect, setnewSelect] = useState(-1); 

  // const [getnewselect, setnewgetSelect] = useState(-1); 
  // const value4 = getnewselect !== -1 && lookedup[getnewselect];

  const [selectedprojectById, setSelectedprojectById] = useState();
  const [defaultProjectById, setdefaultProjectById] = useState();
  const handleChangeProjectById = (event) => {
    console.log(event);
    const index = event.target.selectedIndex;
    console.log(index);
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    console.log(optionElementId);
    const optionElementValue = optionElement.getAttribute("value");
    console.log(optionElementValue); 

    setSelectedprojectById(optionElementId);
    setdefaultProjectById(optionElementValue);
  };

  const [statusId , setStatusId] = useState();
  const [selectstatus, setselectedStatus] = useState(false);
  const [selectStatusData, setSelectedStatusData] = useState();
  const onSelectStatus = (selectedList, selectedItem) => {
    console.log("Entering",selectedList);
    setBackdropOpen(true);
    setselectedStatus(true);
    setSelectedStatusData(selectedList);
   
    if(selectedList.value==null){
      if(selectproject){
        urlData =`&page=1&size=`+ rows+`&emp_id=`+employeeData.emp_id +`&project_id=`+selectprojectId;
        getAllData(urlData);
      } else {
        urlData =  `&page=1&size=`+ rows+`&emp_id=`+employeeData.emp_id;
        getAllData(urlData);
    }
  }else{
    if(selectproject){
      urlData =`&page=1&size=`+ rows+`&emp_id=`+employeeData.emp_id+`&status=`+selectedList.value+`&project_id=`+selectprojectId;
      getAllData(urlData);
    } else {
      urlData =  `&page=1&size=`+ rows+`&emp_id=`+employeeData.emp_id+`&status=`+selectedList.value;
      getAllData(urlData);
    }
  }
  };
  
  const [availableStatus, setAvailableStatus] = useState([]);
  const [StatusId, setstatusId] = useState([]);
    const statusByID = () => {
      axios
        .get(`${base_url.api1}/office_app/Task_status/list?company_code=` + employeeData.company_code, {
          headers: {
            Authorization: jwtToken,
          },
        })
        .then((response) => {
          console.log(response.data.Status);
          setstatusId(response.data.id);
          let status_data = response.data.Status;
          let status_obj = [];
          for (let i = 0; i < status_data.length; i++) {
            let data = {
              value: status_data[i].id,
              label: status_data[i].name,
            };
            status_obj.push(data);
          }
          setAvailableStatus(status_obj);
          setGetPageNo(response.data.total_pages);
          setTotalCount(response.data.total_count);
          setCountNumber(0);
          setNumberOfElements(response.data.Status.length);
        })
        .catch((error) => {
          console.log(JSON.stringify(error));
        });
    };  

    const [Document,setDocument] = useState([]);

    const downloadAll = (filedata) => {
      setBackdropOpen(true);
      let data={
        file_name:filedata.document_name,
        file_path:attachmenturl+filedata.document_path,
      }
      let file_type = data.file_name.split(".");
      var url = `${base_url.api1}/office_app/download`
  
      fetch(url,{
        method: 'post',
        body: JSON.stringify(data),
        headers: {
          Authorization: jwtToken,
          'Content-Type': 'application/json',
        }
      })
      .then((response) => readAllChunks(response))
      .then(async function (response) {
        const downloadFileType = localStorage.getItem(
          "task_document_type"
        );
        var blob = new Blob(response, { type: downloadFileType });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = data.file_name;
        link.click();
        setBackdropOpen(false);
      })
      .catch((error) => {
        setBackdropOpen(false);
      });
    };
  
    const readAllChunks = (readableStream) => {
      localStorage.setItem(
        "task_document_type",
        readableStream.headers.get("content-type")
      );
      const reader = readableStream.body.getReader();
      const chunks = [];
  
      function pump() {
        return reader.read().then(({ value, done }) => {
          if (done) {
            return chunks;
          }
          chunks.push(value);
          return pump();
        });
      }
      return pump();
    };
  
    const getAllData = (urlData) => {
      setBackdropOpen(true);
      console.log(pageNo-1);
      axios
      .get(`${base_url.api1}/office_app/assign_task/list?company_code=`+ employeeData.company_code+urlData, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setBackdropOpen(false);
          setTaskData(response.data.Tasks);
          setGetPageNo(response.data.total_pages);
          setTotalCount(response.data.total_count);
          setCountNumber(pageNo-1);
          setNumberOfElements(response.data.Tasks.length);
          setsearch(false);
          document.getElementById("myForm").reset();
        },
        (error) => {
          setBackdropOpen(false);
          console.log(JSON.stringify(error.response.data));
          setTaskData();
          setGetPageNo(0);
          setTotalCount(0);
          setCountNumber(0);
          setNumberOfElements(0);
        }
      );
    }

  const [deleteTaskId, setDeleteTaskId] = useState();
  const [deleteOpen, setDeleteModal] = React.useState(false);

  const deleteCurrentTask = () => {
    setBackdropOpen(true);
    axios
      .delete(`${base_url.api1}/office_app/assign_task?company_code=` + employeeData.company_code +`&id=` + deleteTaskId  , {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setBackdropOpen(false);
          setDeleteModal(false);
          urlData = `&emp_id=`+ employeeData.emp_id+`&page=`+pageNo+`&size=`+ rows;
          getAllData(urlData);
        },
        (error) => {
          setBackdropOpen(false);
          setDeleteModal(false);
          console.log(JSON.stringify(error.response.data));
        }
      );
  };


  const [deleteTaskDocId, setDeleteTaskDocId] = useState();
  const [deleteDocOpen, setDeleteDocModal] = React.useState(false);
  const deleteCurrentTaskDoc = () => {
    setBackdropOpen(true);
    axios
      .delete(`${base_url.api1}/office_app/tasks_document?company_code=` + employeeData.company_code +`&id=` + deleteTaskDocId  , {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          let log = employeeData.emp_name+" deleted in task document.";
          CallAuditapi(log);
          setBackdropOpen(false);
          setDeleteDocModal(false);
          urlData = `&emp_id=`+ employeeData.emp_id+`&page=`+pageNo+`&size=`+ rows;
          getAllData(urlData);
        },
        (error) => {
          setBackdropOpen(false);
          setDeleteDocModal(false);
          console.log(JSON.stringify(error.response.data));
        }
      );
  };

  return (
    <div>
      <Backdrop open={backdropOpen}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="commoncomponent_cardcss">
        <div>
          <Row>
            <Col md={6}>
              <CardText
                style={{ marginTop: "-10px" }}
              >
                <h1>My Tasks </h1>
              </CardText>
            </Col>
            <Col md={6} className="d-flex justify-content-end">
              <>
              <Button
                color="primary"
                // onClick={createModalConfig}
                onClick={ () =>navigate('/add-mytask/'+employeeData.emp_id)}
              >
                + Add
              </Button>
              </>
            </Col>
            </Row>
            <Row>
              <Col md={3}>
                <div style={{ marginTop: "10px", marginRight: "14px" }}>
                      <label className="area_rowslable">Date</label>
                    <DateRangePicker
                    onEvent={handleEvent}
                    onCallback={handleCallback}
                    onApply={handleApply}
                >
                <p className="datepickerrangevaluetext">{datevalue}</p>
                  </DateRangePicker>
                  {datefilter ? (
                    <div>
                      <a
                        className={classes.clrsrch}
                        onClick={() => {
                          setdisplay("inline");
                          setdatevalue("Task Date");
                          setBackdropOpen(true);
                          setdirection("Desc");
                          setPageNo(1);
                          urlData = `&emp_id=`+ employeeData.emp_id+`&page=1&size=`+ rows;
                          getAllData(urlData);
                          setdatefilter(false);
                        }}
                      >
                        Clear Filter
                      </a>
                    </div>
                  ) : null}
                </div>
                </Col>
                <Col md={3}>
               <label><strong>Project Name:</strong></label>
                      <Select
                        // isMulti
                        className="basic-multi-select"
                        classNamePrefix="select"
                        value={selectProjectData}
                        onChange={onSelectProject}
                        options={availableProject}
                      />
                       {selectproject ? (
                        <div>
                          <a
                            className={classes.clrsrch}
                            onClick={() => {
                              setBackdropOpen(true);
                              urlData = `&emp_id=`+ employeeData.emp_id +`&page=1&size=`+ rows;
                              getAllData(urlData);
                              setSelectedProjectData({value: null, label: null});
                              setSelectedProject(false);
                            }}
                          >
                            Clear Filter
                          </a>
                        </div>
                      ) : null}
               </Col>
                <Col md={3}>
               <label><strong>Task Status:</strong></label>
                      <Select
                        className="basic-multi-select"
                        classNamePrefix="select"
                        value={selectStatusData}
                        onChange={onSelectStatus}
                        options={availableStatus}
                      />
                      {selectstatus ? (
                        <div>
                          <a
                            className={classes.clrsrch}
                            onClick={() => {
                              setBackdropOpen(true);
                              urlData = `&emp_id=`+ employeeData.emp_id +`&page=1&size=`+ rows;
                              getAllData(urlData);
                              setSelectedStatusData({value: null, label: null});
                              setselectedStatus(false);
                            }}
                          >
                            Clear Filter
                          </a>
                        </div>
                      ) : null}
                      </Col>
                <Col md={3}>
                    <div className="form-group" style={{ marginTop: "30px" }}>
                      <div>
                        <div className="clearsearchclass">
                          <form
                            id="myForm"
                            className="d-flex"
                            onSubmit={(e) => {
                              e.preventDefault();
                              submitSearch(e);
                          }}
                          >
                            <input
                              style={{
                                padding: "5px",
                                border: "2px solid",
                                marginTop: "-1px",
                              }}
                              className="form-control"
                              placeholder="Search.."
                              name="search"
                              required
                            />
                            <button
                              color="blue"
                              className={classes.srchbtn}
                              type="submit"
                            >
                              <i className="fa fa-search"></i>
                            </button>
                          </form>
                          {searchdata ? (
                            <a    
                            onClick={() => {
                                setdisplay("inline");
                                setBackdropOpen(true);
                                setPageNo(1);
                                urlData = `&emp_id=`+ employeeData.emp_id+`&page=1&size=`+ rows;
                                getAllData(urlData);
                                setsearchinput();
                                setsearch(false);
                              }}                      
                            >
                              Clear Search
                            </a>
                          ) : null}
                        </div>                      
                      </div>
                    </div>
                  </Col>
          </Row>
          <br/>
          <Row>
          {/* <Col md={3}>
               <label><strong>Project Name:</strong></label>
                      <Select
                        // isMulti
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={onSelectProject}
                        options={availableProject}
                      />
               </Col> */}
          </Row>
          <br/>
          <div className="newlisting_headertabdesign">
            <Table responsive striped>
              <thead>
                <tr>
                  <th style={{ width: "50px" }}>SN</th>
                  <th style={{ width: "375px" }}>
                    Task Title{" "}
                    <UnfoldMoreIcon onClick={sortTask} id="task_title"/>
                  </th>
                  <th style={{ width: "175px" }}>
                     Project Name{" "}
                    {/* <UnfoldMoreIcon id="project_name"/> */}
                  </th>
                  <th style={{ width: "175px" }}>
                     End Date{" "}
                    <UnfoldMoreIcon onClick={sortTask} id="task_end_time"/>
                  </th>
                  <th style={{ width: "175px" }}>
                    Task Status{" "}
                    <UnfoldMoreIcon onClick={sortTask} id="status"/>
                  </th>
                  <th style={{ width: "175px" }}>
                    Priority{" "}
                    <UnfoldMoreIcon onClick={sortTask} id="priority"/>
                  </th>
                  <th>
                    Task Description{" "}
                    {/* <UnfoldMoreIcon onClick={sortTask} id="task_description"/> */}
                  </th>
                  <th style={{ width: "175px" }}>
                    Documents{" "}
                    </th>
                  <th style={{ textAlign: "center", width: "175px" }}>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {taskData != null ? (
                  taskData &&
                  taskData.map((Tasks , index) => (
                    <tr>
                      <th>{countNumber * rows + 1 + index}</th>
                      <td>{Tasks.task_title}</td>  
                      {/* <td>
                        {Tasks.task_start_time
                          ? moment(Tasks.task_start_time.split(".")[0]).format(
                              "DD MMM YYYY"
                            )
                        : null}
                      </td> */}
                      <td>{Tasks.project_info.project_name}</td>  
                      <td>
                        {Tasks.task_end_time
                          ? moment(Tasks.task_end_time.split(".")[0]).format(
                              "DD MMM YYYY"
                            )
                          : null}</td>    
                      <td>{Tasks.task_status_name}</td>  
                      <td>{Tasks.priority}</td>  
                      {/* <td>
                        <Button
                          id={Tasks.document_path}
                          style={{
                          marginTop: "20px",

                        }}
                        // onClick={(e) => {
                        //         e.preventDefault();
                        //         viewDocuments(e,document);
                        //         createModalDoc();
                        //       }}
                         color="info"> View </Button></td> */}
                      <td className="taskDesc">
                        {Tasks.task_description}
                        {Tasks.task_description != null ? (
                          <span
                            className="taskDescspan"
                            onClick={() => {
                              setDescModelOpen(true);
                              setDescContent(Tasks.task_description);
                            }}
                          >
                            View More
                          </span>
                        ) : null}
                        <Modal
                          ariaHideApp={false}
                          isOpen={descModelOpen}
                          onRequestClose={() => setDescModelOpen(false)}
                          style={customStyles2}
                          contentLabel="Example Modal"
                        >
                          <h4>Task Description</h4>
                          {descContent ? (
                            <textarea
                              style={{ minWidth: "600px" }}
                              className="form-control"
                              value={descContent}
                              rows={10}
                              readonly
                            />
                          ) : (
                            <p> No Record Available</p>
                          )}
                          <div
                            style={{
                              display: "flex",
                              margin: "10px 0",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              color="secondary"
                              onClick={() => setDescModelOpen(false)}
                            >
                              Close
                            </Button>
                          </div>
                        </Modal>
                      </td>
                      <td>
                      {Tasks.document_path.length > 0?
                            <Button
                              id={Tasks.document_path}
                              style={{ marginTop: "20px" }}
                              onClick={(e) => {
                                e.preventDefault();
                                setBackdropOpen(true);
                                setViewTaskDoc(true);
                                setDocument(Tasks.document_path);
                                setBackdropOpen(false);
                              }}
                              color="info">
                                View Files
                            </Button>
                          :null}
                          <Modal
                            ariaHideApp={false}
                            isOpen={modalShowDoc}
                            onRequestClose={closeModal}
                            style={customStyles2}
                            contentLabel="Example Modal"
                          >
                            <h2><strong>View Documents</strong></h2>
                            <br/>
                            <div className="newlisting_headertabdesign">
                              <Table responsive striped>
                                <thead>
                                  <tr>
                                    <th style={{ width: "180px" }}>Document Name{" "}</th>
                                    <th style={{ width: "180px" }}>Action{" "}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Document != null ? (
                                    Document &&
                                    Document.map((Dts , index) => (
                                      <tr>
                                        <td>{Dts.document_name}</td>  
                                        <td>
                                          {permissionObj?.includes(17)?
                                            <p type="button" style={{color:'blue'}} onClick={()=>downloadAll(Dts)}>Download</p>
                                          :null}
                                          {permissionObj?.includes(18)?
                                          //   <p type="button" style={{color:'red'}}>Delete</p>
                                          // :null}
                                            <p id={Dts.id}
                                              onClick={() => {
                                                setDeleteTaskDocId(Dts.id);
                                                setDeleteDocModal(true);
                                              }} type="button" style={{color:'red'}}>Delete
                                            </p>                          
                                          :null}
                                          <Modal
                                            ariaHideApp={false}
                                            isOpen={deleteDocOpen}
                                            onRequestClose={closeModal}
                                            style={customStyles2}
                                            contentLabel="Example Modal"
                                          >
                                            <h5>Are you sure you want to delete current document ?</h5>
                                            <div style={{ float: "right" }}>
                                              <Button
                                                color="danger"
                                                type="submit"
                                                onClick={deleteCurrentTaskDoc}
                                              >
                                                Delete
                                              </Button>
                                              &nbsp;&nbsp;&nbsp;
                                              <Button
                                                color="secondary"
                                                onClick={() => setDeleteDocModal(false)}
                                              >
                                                Cancel
                                              </Button>
                                            </div>
                                          </Modal> 
                                        </td>
                                      </tr>
                                  ))) : (
                                    <p style={{width:'200%'}}>No Documents Found</p>
                                  )}
                                </tbody>
                              </Table>
                            </div>
                            <br />
                            <Row>
                              <Col md={12}>
                                <Row className="justify-content-end mt-2">
                                  <Button
                                    color="secondary"
                                    onClick={() => setViewTaskDoc(false)}
                                  >
                                    Close
                                  </Button>
                                </Row>
                              </Col>
                            </Row>
                          </Modal>
                        </td>
                      <td>
                      <div style={{
                          position: "relative",
                          textAlign: "center",
                        }}
                      >
                        <div
                          className="workflowdashboard_fa-ellipsis"
                          data-toggle="dropdown"
                        >
                          <i
                            className="fa fa-ellipsis-v "
                            aria-hidden="true"
                          ></i>
                        </div>
                        <div
                          className="dropdown-menu workflowdashboarddropdown"
                          style={{ width: "200px" }}
                        >
                          <p id={Tasks.id}
                            onClick={(e)=>{
                              e.preventDefault();
                              forwardTask(e, Tasks);
                              setFrwdReason(Tasks.reason)}}>
                              Forward Task
                          </p>
                            <Modal
                              isOpen={forwardOpen}
                              onRequestClose={closeModal}
                              style={customStyles2}
                              ariaHideApp={false}
                              contentLabel="Example Modal"
                            >
                              <h2>
                                <strong>Forward Task</strong>
                              </h2>
                              <form id="editForm" onSubmit={(e) => {
                                  e.preventDefault();
                                  editforwardCategory(e);
                                }}>
                                <div>
                                  <div className="form-group creatadmin">
                                    <Row>
                                      <Col md={12}>
                                        <label>Employee Name<span style={{ color: "red" }}>*</span>:</label>
                                        <select
                                              {...register('emp_name')}
                                              id="emp_name"
                                              className={classes.selectdrop}
                                              value={taskData.emp_name}
                                              required
                                              onChange={(e) => {
                                                taskData.emp_name =e.target.value;
                                                console.log(e.target.id);
                                                setSelected(e.target.value)
                                                console.log(e.target.value);
                                                handleChangeUser(e)
                                              }}
                                          >
                                              <option value="">-Select Employee-</option>
                                              {look.map((m, ix) => {
                                              return <option key={m.user_id} id={m.user_id} value={m.first_name + " " +m.last_name}>{m.first_name + " " +m.last_name}</option>
                                              })};
                                          </select>
                                      </Col>
                                    </Row>
                                  </div>
                                  <Row>
                                      <Col md={12}>
                                        <label>Reason :</label>
                                      <textarea
                                        style={{ minWidth: "600px" }}
                                        className="form-control"
                                        onChange={(e)=>{
                                          setFrwdReason(e.target.value);
                                        }}
                                        rows={5}
                                      />
                                      <br />
                                      </Col>
                                    </Row>
                                  <Row style={{ float: "right" }}>
                                    <Button
                                      color="secondary"
                                      style={{
                                        marginTop: "20px",
                                        marginRight: "10px",
                                      }}
                                      onClick={() => setforwardEdit(false)}
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      color="primary"
                                      type="submit"
                                      style={{ marginTop: "20px" }}
                                    >
                                      Submit
                                    </Button>
                                  </Row>
                                </div>
                              </form>
                            </Modal>
                      
                          <p id={Tasks.id}
                            onClick={(e)=>{
                            e.preventDefault();
                            editTaskStatus(e, Tasks)}}>
                             Update Status
                            </p>
                            <Modal
                              isOpen={statusOpen}
                              onRequestClose={closeModal}
                              style={customStyles2}
                              ariaHideApp={false}
                              contentLabel="Example Modal"
                            >
                              <h2>
                                <strong>Update Status</strong>
                              </h2>
                              <form id="editForm" onSubmit={(e) => {
                                  e.preventDefault();
                                  editOldTaskStatus(e);
                                }}>
                                <div>
                                  <div className="form-group creatadmin">
                                    <Row>
                                      <Col md={12}>
                                        <label>Task Status<span style={{ color: "red" }}>*</span>:</label>
                                        <select
                                          {...register("status",{required: true})}
                                          id="status"
                                          className={classes.selectdrop}
                                          value={taskData.status}
                                          onChange={(e) => {
                                            taskData.task_status_name =e.target.value;
                                            setSelectedstatus(e.target.value);
                                            console.log(e.target.value);
                                            handleChangeStatus(e);
                                          }}
                                          required
                                        >
                                          <option value="">-Select Status-</option>
                                            {looksup.map((m, ix) => {
                                              return <option key={m.id} id={m.id} value={m.name}>{m.name}</option>
                                            })};
                                        </select>
                                      </Col>
                                    </Row>
                                    <br />
                                  </div>
                                  {/* <Row>
                                  <Col md={4}>
                                      <label>Documents:</label>
                                      <input
                                        type="file"
                                        multiple
                                        {...register("doc", { required: false })} 
                                        className="form-control underline-input"
                                        name="doc"
                                        accept=".jpg, .jpeg, .pdf, .doc, .docx, .xls, .png"
                                      />
                                  </Col>
                                  </Row> */}
                                  <Row>
                                      <Col md={12}>
                                        <label>Comment:</label>
                                        {/* <textarea
                                            type="text"
                                            // {...register("task_description",{required: true})}
                                            id="task_description"
                                            className="form-control underline-input"
                                            // value={editDescription}
                                            rows={3}
                                            onChange={(e) => {
                                              seteditDescription(e.target.value);
                                            }}    
                                        /> */}
                                        <textarea
                                        type="text"
                                        className="form-control"
                                        onChange={(e)=>{
                                          seteditDescription(e.target.value);
                                        }}
                                        rows={3}
                                      />
                                      </Col>
                                    </Row>
                                  <Row style={{ float: "right" }}>
                                    <Button
                                      color="secondary"
                                      style={{
                                        marginTop: "20px",
                                        marginRight: "10px",
                                      }}
                                      onClick={() => setstatusEdit(false)}
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      color="primary"
                                      type="submit"
                                      style={{ marginTop: "20px" }}
                                    >
                                      Submit
                                    </Button>
                                  </Row>
                                </div>
                              </form>
                            </Modal>
                              {((employeeData.emp_role_id == 1) || (employeeData.emp_role_id == 2) || (employeeData.emp_role_id == 3))?
                                <p id={Tasks.id}
                                  onClick={() => {
                                    setDeleteTaskId(Tasks.id);
                                    setDeleteModal(true);
                                  }} color="danger">Delete
                                </p>:null}                         
                              <Modal
                                ariaHideApp={false}
                                isOpen={deleteOpen}
                                onRequestClose={closeModal}
                                style={customStyles2}
                                contentLabel="Example Modal"
                              >
                                <h5>Are you sure you want to delete current record ?</h5>
                                <div style={{ float: "right" }}>
                                  <Button
                                    color="danger"
                                    type="submit"
                                    onClick={deleteCurrentTask}
                                  >
                                    Delete
                                  </Button>
                                  &nbsp;&nbsp;&nbsp;
                                  <Button
                                    color="secondary"
                                    onClick={() => setDeleteModal(false)}
                                  >
                                    Cancel
                                  </Button>
                                </div>
                              </Modal> 
                        </div>
                      </div>
                    </td>
                  </tr>
                ))) : (
                  <p className={classes.headertekst}>No Tasks Found</p>
                )}
                
                </tbody>
                </Table>
                <div>
               <Row>
                <Col md={6}>
              {taskData?.length ? (
                <p style={{ marginTop: "15px" }}>
                  {countNumber * rows + 1} to{" "}
                  {countNumber * rows + numberOfElements} of {totalcount} records
                </p>
              ) : (
                <p className="formfooterrecordstyle">{null}</p>
              )}
            </Col>
            <Col md={6}>
              <div className={classes.root}>
              {searchdata || datefilter ? (
                  <Pagination
                    page={paginationPageNo}
                    count={getPageNo}
                    onChange={submitSearch}
                  />
                ) : (
                  <Pagination
                    page={paginationPageNo}
                    count={getPageNo}
                    onChange={handlepage}
                  />
                )}
              </div>
            </Col>
            </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Task;