import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout, selectEmployee } from '../../components/features/empSlice';
import axios from 'axios';
import base_url from '../../api/bootapi';
import Home from './../../Images/home.png';
import Employee from './../../Images/employee.png';
import Hat from './../../Images/hat.png';
import Traveler from './../../Images/traveler.png';
import ToDoList from './../../Images/to-do-list.png';
import Configuration from './../../Images/configuration.png';
import Resign from './../../Images/resign.png';
import Search from './../../Images/search.png';
import Performance from './../../Images/performance.png';
import Schedule from './../../Images/schedule.png';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import total from '../images/team.png';
import present from '../images/present.png';
import absent from '../images/absent.png';
import pending from '../images/pending.png';
import approve from '../images/verified.png';
import pendtask from '../images/pendingtask.png';
import overdue from '../images/overdue.png';
import MainDash from './MainDash';
import moment from 'moment';
import Modal from 'react-modal';
import {
	customStyles,
	useStyles,
	customStyles2,
} from './../../Utilities/CSSUtilities';

import CloseIcon from '@mui/icons-material/Close';
import { Table } from 'reactstrap';

const MainDashboardBodytop = (props) => {
	//const jwtToken = "JWT " + localStorage.getItem("JWTToken");
	const [backdropOpen, setBackdropOpen] = useState(false);
	const [totalemployee, settotalEmployee] = useState(0);
	const [totalpresent, settotalPresent] = useState(0);
	const [totalabsent, settotalAbsent] = useState();
	const [pendingleave, setpendingLeave] = useState(0);
	const [pendingResignations, setpendingResignations] = useState(0);
	const [teampendingleave, setteampendingLeave] = useState();
	const [totaltask, settotalTask] = useState(0);
	const [teamtotaltask, setteamtotalTask] = useState();
	const [pendingtask, setpendingTask] = useState(0);
	const [teampendingtask, setteampendingTask] = useState();
	const [overduetask, setoverdueTask] = useState(0);
	const [teamoverduetask, setteamoverdueTask] = useState();
	const [employeePresentList, setEmployeePresentList] = useState([]);
	const [employeeAbsentList, setEmployeeAbsentList] = useState([]);
	const [presentListModel, setPresentListModel] = useState(false);
	const [absentListModel, setAbsentListModel] = useState(false);

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const employeData = useSelector(selectEmployee);
	const jwtToken = 'JWT ' + employeData.token;
	useEffect(() => {
		window.addEventListener('storage', function (e) {
			if (e.key === null) {
				dispatch(logout());
				navigate('/');
			}
		});
		getCountDetails();
		getAllPresentList();
		getAllAbsentList();
		getTotalPendingResignations();
	}, []);

	const today = new Date();
	let toDate = moment(today).format('YYYY-MM-DD');
	let fromDate = toDate.split('-')[0] + '-' + toDate.split('-')[1] + '-01';

	const getCountDetails = () => {
		console.log('entering1');
		const isoDateString = new Date().toISOString();
		const datenow = isoDateString.split('T')[0];
		let count_data = localStorage.getItem('status_count');
		if (employeData.emp_role_id == '1') {
			console.log('entering2');
			setBackdropOpen(true);
			axios
				.get(
					`${base_url.api1}/myappapi/total_count?company_code=` +
						employeData.company_code +
						`&from=` +
						fromDate +
						`&to=` +
						toDate,
					{
						headers: {
							Authorization: jwtToken,
						},
					}
				)
				.then(
					(response) => {
						console.log(response.data);
						setBackdropOpen(false);
						count_data = {
							totalemployee: response.data.total_employee,
							totalpresent: response.data.total_present_employee,
							totalabsent: response.data.total_absent_employee,
							pendingleave: response.data.total_pending_leave,
							totaltask: response.data.task.total_task,
							pendingtask: response.data.task.Pending,
							overduetask: response.data.task.Overdue,
						};
						localStorage.setItem('status_count', JSON.stringify(count_data));
						settotalEmployee(response.data.total_employee);
						settotalPresent(response.data.total_present_employee);
						settotalAbsent(response.data.total_absent_employee);
						setpendingLeave(response.data.total_pending_leave);
						settotalTask(response.data.task.total_task);
						setpendingTask(response.data.task.Pending);
						setoverdueTask(response.data.task.Overdue);
					},
					(error) => {
						console.log(false);
						setBackdropOpen(false);
					}
				);
		} else if (employeData.emp_role_id == '3') {
			axios
				.get(
					`${base_url.api1}/myappapi/total_count?company_code=` +
						employeData.company_code +
						`&from=` +
						fromDate +
						`&to=` +
						toDate +
						`&manager=True` +
						`&emp_id=` +
						employeData.emp_id,
					{
						headers: {
							Authorization: jwtToken,
						},
					}
				)
				.then(
					(response) => {
						console.log(response);
						setBackdropOpen(false);
						count_data = {
							pendingleave: response.data.personal_pending_leave,
							teampendingleave: response.data.team_pending_leave,
							totaltask: response.data.personal_task.total_todays_task,
							teamtotaltask: response.data.team_task.total_todays_task,
							pendingtask: response.data.personal_task.Pending,
							teampendingtask: response.data.team_task.Pending,
							overduetask: response.data.personal_task.Overdue,
							teamoverduetask: response.data.team_task.Overdue,
						};
						localStorage.setItem('status_count', JSON.stringify(count_data));
						setpendingLeave(response.data.personal_pending_leave);
						setteampendingLeave(response.data.team_pending_leave);
						settotalTask(response.data.personal_task.total_todays_task);
						setteamtotalTask(response.data.team_task.total_todays_task);
						setpendingTask(response.data.personal_task.Pending);
						setteampendingTask(response.data.team_task.Pending);
						setoverdueTask(response.data.personal_task.Overdue);
						setteamoverdueTask(response.data.team_task.Overdue);
					},
					(error) => {
						console.log(false);
						setBackdropOpen(false);
					}
				);
		} else if (
			employeData.emp_role_id == '2' ||
			employeData.emp_role_id == '4' ||
			employeData.emp_role_id == '5'
		) {
			axios
				.get(
					`${base_url.api1}/myappapi/total_count?company_code=` +
						employeData.company_code +
						`&from=` +
						fromDate +
						`&to=` +
						toDate +
						`&emp_id=` +
						employeData.emp_id,
					{
						headers: {
							Authorization: jwtToken,
						},
					}
				)
				.then(
					(response) => {
						console.log(response);
						setBackdropOpen(false);
						count_data = {
							pendingleave: response.data.personal_pending_leave,
							totaltask: response.data.task.total_todays_task,
							pendingtask: response.data.task.Pending,
							overduetask: response.data.task.Overdue,
						};
						localStorage.setItem('status_count', JSON.stringify(count_data));
						setpendingLeave(response.data.personal_pending_leave);
						settotalTask(response.data.task.total_todays_task);
						setpendingTask(response.data.task.Pending);
						setoverdueTask(response.data.task.Overdue);
					},
					(error) => {
						console.log(false);
						setBackdropOpen(false);
					}
				);
		} else {
			console.log('else part');
		}
	};

	function close(divid) {
		let ftd = document.getElementById(divid);
		ftd.style.visibility = 'hidden';
	}
	const getAllPresentList = () => {
		axios
			.get(
				`${base_url.api1}/office_app/attendance/date_range?company_code=` +
					employeData.company_code +
					`&from=` +
					toDate +
					`&to=` +
					toDate,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then(
				(response) => {
					console.log(response.data.Attendance);
					setEmployeePresentList(response.data.Attendance);
				},
				(error) => {
					console.log(JSON.stringify(error.response.data));
				}
			);
	};
	const getAllAbsentList = () => {
		const today = new Date();
		const yesterday = new Date(today);
		yesterday.setDate(today.getDate() - 1);

		const year = yesterday.getFullYear();
		const month = String(yesterday.getMonth() + 1).padStart(2, '0');
		const date = String(yesterday.getDate()).padStart(2, '0');
		let yesterdayDate = `${year}-${month}-${date}`;
		axios
			.get(
				`${base_url.api1}/office_app/current_absent?company_code=` +
					employeData.company_code +
					`&session_date=` +
					toDate,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then(
				(response) => {
					console.log(response.data.Absent);
					setEmployeeAbsentList(response.data.Absent);
				},
				(error) => {
					console.log(JSON.stringify(error.response.data));
				}
			);
	};
	const getTotalPendingResignations = () => {
		axios
			.get(
				`${base_url.api1}/myappapi/resignation/list?company_code=` +
					employeData.company_code /* +
					`&page=1` +
					`&size=2` */ +
					`&status=false`,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then(
				(response) => {
					console.log(response.data);
					let length = response.data.Resignation.length;
					if (length) {
						setpendingResignations(response.data.Resignation.length);
					}
				},
				(error) => {
					console.log(JSON.stringify(error.response.data));
				}
			);
	};
	return (
		// <div>
		//   <Backdrop
		//     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
		//     open={backdropOpen}
		//   >
		//     <CircularProgress color="inherit" />
		//   </Backdrop>
		//   <div className="bodytop">
		//     <div className="row">
		//       <div className="col-md-3">
		//         <div className="bodytop_innercontainer ml-0" style={{ background: "lightblue" }}>
		//           <h1>{totalemployee}</h1>
		//           <h5>Active Employee <img src={Employee} width="40" height="40" /></h5>
		//         </div>
		//       </div>
		//       <div className="col-md-3">
		//         <div className="bodytop_innercontainer mr-0" style={{ background: "lightblue" }}>
		//           <h1>{totalpresent}</h1>
		//           <h5>Total Presents<img src={Search} width="40" height="40" /></h5>
		//         </div>
		//       </div>
		//       <div className="col-md-3">
		//         <div className="bodytop_innercontainer" style={{ background: "lightblue" }}>
		//           <h1>{totalemployee - totalpresent}</h1>
		//           <h5>Total Absent <img src={Hat} width="40" height="40" /></h5>
		//         </div>
		//       </div>
		//       <div className="col-md-3">
		//         <div className="bodytop_innercontainer" style={{ background: "lightblue" }}>
		//           <h1>{pendingleave}</h1>
		//           <h5>Pending Leave <img src={Traveler} width="40" height="40" /></h5>
		//         </div>
		//       </div>
		//     </div>
		//     <div className="row mt-3">
		//       <div className="col-md-3">
		//         <div className="bodytop_innercontainer" style={{ background: "lightblue" }}>
		//           <h1>{expiredleave}</h1>
		//           <h5>Total Task<img src={ToDoList} width="40" height="40" /></h5>
		//         </div>
		//       </div>
		//       <div className="col-md-3">
		//         <div className="bodytop_innercontainer" style={{ background: "lightblue" }}>
		//           <h1>{approvedleave}</h1>
		//           <h5>Pending Tasks <img src={Schedule} width="40" height="40" /></h5>
		//         </div>
		//       </div>
		//       <div className="col-md-3">
		//         <div className="bodytop_innercontainer" style={{ background: "lightblue" }}>
		//           <h1>{declinedleave}</h1>
		//           <h5>OverDue Task<img src={Resign} width="40" height="40" /></h5>
		//         </div>
		//       </div>
		//     </div>
		//   </div>
		// </div>
		<div className="dashboardcomponent_cardcss">
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={backdropOpen}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<div className="maindash_body">
				<div className="category">
					<div style={{ width: '100%' }}>
						{employeData.emp_role_id == '1' ? (
							<div className="grid-four">
								<MainDash
									bgcolor="#4E98FE"
									numbers={totalemployee}
									title="Active Employees"
									image={total}
								/>
								<div
									style={{ cursor: 'pointer' }}
									onClick={() => {
										console.log('Clicked');
										setPresentListModel(true);
									}}
								>
									<MainDash
										bgcolor="#6D62D6"
										numbers={totalpresent}
										title="Total Present"
										image={present}
									/>
								</div>
								<div
									style={{ cursor: 'pointer' }}
									onClick={() => {
										console.log('Clicked');
										setAbsentListModel(true);
									}}
								>
									<MainDash
										bgcolor="#86D2E8"
										numbers={totalemployee - totalpresent}
										title="Total Absent"
										image={absent}
									/>
								</div>

								<MainDash
									bgcolor="#00887D"
									numbers={pendingleave}
									title="Pending Leave"
									image={pending}
								/>

								<MainDash
									bgcolor="#23B4A8"
									numbers={totaltask}
									title="Total Task"
									image={approve}
								/>

								<MainDash
									bgcolor="#FFA600"
									numbers={pendingtask}
									title="Pending Task"
									image={pendtask}
								/>
								<div
									style={{ cursor: 'pointer' }}
									onClick={() => {
										navigate('/resignation');
										console.log('Resignation lists is clicked');
									}}
								>
									<MainDash
										bgcolor="#ba8bf7"
										numbers={pendingResignations}
										title=" Pending Resignations"
										image={pendtask}
									/>
								</div>

								<MainDash
									bgcolor="#AD0252"
									numbers={overduetask}
									title="OverDue Task"
									image={overdue}
								/>
							</div>
						) : null}

						{employeData.emp_role_id == '2' ||
						employeData.emp_role_id == '4' ||
						employeData.emp_role_id == '5' ? (
							<div className="grid-four">
								<MainDash
									bgcolor="#00887D"
									numbers={pendingleave}
									title="Pending Leave"
									image={pending}
								/>

								<MainDash
									bgcolor="#23B4A8"
									numbers={totaltask}
									title="Today's Task"
									image={approve}
								/>

								<MainDash
									bgcolor="#FFA600"
									numbers={pendingtask}
									title="Pending Task"
									image={pendtask}
								/>

								<MainDash
									bgcolor="#AD0252"
									numbers={overduetask}
									title="OverDue Task"
									image={overdue}
								/>
							</div>
						) : null}
						{employeData.emp_role_id == '3' ? (
							<div className="grid-four">
								<MainDash
									bgcolor="#00887D"
									numbers={pendingleave}
									title="Pending Leave"
									image={pending}
								/>

								<MainDash
									bgcolor="#23B4A8"
									numbers={totaltask}
									title="Today's Task"
									image={approve}
								/>

								<MainDash
									bgcolor="#FFA600"
									numbers={pendingtask}
									title="Pending Task"
									image={pendtask}
								/>

								<MainDash
									bgcolor="#AD0252"
									numbers={overduetask}
									title="OverDue Task"
									image={overdue}
								/>

								<MainDash
									bgcolor="#00887D"
									numbers={teampendingleave}
									title="Team's Pending Leave"
									image={pending}
								/>

								<MainDash
									bgcolor="#23B4A8"
									numbers={teamtotaltask}
									title=" Team's Today's Task"
									image={approve}
								/>

								<MainDash
									bgcolor="#FFA600"
									numbers={teampendingtask}
									title=" Team's Pending Task"
									image={pendtask}
								/>

								<MainDash
									bgcolor="#AD0252"
									numbers={teamoverduetask}
									title="Team's OverDue Task"
									image={overdue}
								/>
							</div>
						) : null}
					</div>
				</div>
				<Modal
					ariaHideApp={false}
					isOpen={presentListModel}
					onRequestClose={() => setPresentListModel(false)}
					style={customStyles2}
					contentLabel="Example Modal"
				>
					<div id="firsttbldiv">
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								maxHeight: '300px',
							}}
						>
							<h4>Total Present</h4>
							<CloseIcon onClick={() => setPresentListModel(false)} />
						</div>

						<div
							style={{
								overflow: 'auto',
								height: '300px',
							}}
						>
							<Table striped className="tbl">
								<thead
									style={{
										position: 'sticky',
										top: '0',
										background: 'white',
									}}
								>
									<tr>
										<th>Name</th>
										<th>Email</th>
									</tr>
								</thead>
								<tbody>
									{employeePresentList.length > 0 ? (
										employeePresentList.map((employee, index) => (
											<tr key={index}>
												<td>{employee.emp_name}</td>
												<td>{employee.emp_email}</td>
											</tr>
										))
									) : (
										<tr>
											<td colSpan="1" style={{ textAlign: 'center' }}>
												No records.
											</td>
										</tr>
									)}
								</tbody>
							</Table>
						</div>
					</div>
				</Modal>
				<Modal
					ariaHideApp={false}
					isOpen={absentListModel}
					onRequestClose={() => setAbsentListModel(false)}
					style={customStyles2}
					contentLabel="Example Modal"
				>
					<div id="secondtbldiv">
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								maxHeight: '300px',
							}}
						>
							<h4>Total Absent</h4>
							<CloseIcon onClick={() => setAbsentListModel(false)} />
						</div>

						<div
							style={{
								overflow: 'auto',
								height: '300px',
							}}
						>
							<Table striped className="tbl">
								<thead
									style={{
										position: 'sticky',
										top: '0',
										background: 'white',
									}}
								>
									<tr>
										<th>Name</th>
										<th>Email</th>
									</tr>
								</thead>
								<tbody>
									{employeeAbsentList.length > 0 ? (
										employeeAbsentList.map((employee, index) => (
											<tr key={index}>
												<td>{employee.emp_name}</td>
												<td>{employee.emp_email}</td>
											</tr>
										))
									) : (
										<tr>
											<td colSpan="1" style={{ textAlign: 'center' }}>
												No records.
											</td>
										</tr>
									)}
								</tbody>
							</Table>
						</div>
					</div>
				</Modal>

				<div className="tbldiv"></div>

				{/* <MainDashboardBodytop /> */}
			</div>
		</div>
	);
};

export default MainDashboardBodytop;
