import React, { useState, useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { CardText, Col, Row, Button, Table } from 'reactstrap';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Pagination from '@mui/material/Pagination';
import FormControl from '@mui/material/FormControl';
import Modal from 'react-modal';
import { useForm } from 'react-hook-form';
import {
	customStyles,
	useStyles,
	customStyles2,
} from './../../Utilities/CSSUtilities';
import axios from 'axios';
import moment from 'moment';
import base_url from '../../api/bootapi';
import { logout, selectEmployee } from '../../components/features/empSlice';
import { useDispatch, useSelector } from 'react-redux';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import Select from 'react-select';
import attachmenturl from '../../api/attachmenturl';
import holidayicon from '../../components/images/event.png';
import close from '../../components/images/closeicon.png';

const StatusMail = () => {
	const [backdropOpen, setBackdropOpen] = useState(false);
	const params = useParams();
	const [modalCreate, setCreate] = React.useState(false);
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();
	const [taskData, setTaskData] = useState([]);
	const [projectData, setProjectData] = useState([]);
	const navigate = useNavigate();
	const jwtToken = 'JWT ' + localStorage.getItem('JWTToken');
	const classes = useStyles();
	const employeeData = useSelector(selectEmployee);
	const [searchdata, setsearch] = useState(false);
	const [display1, setdisplay] = useState('inline');
	const [numberOfElements, setNumberOfElements] = useState();
	const [descContent, setDescContent] = useState();
	const [descModelOpen, setDescModelOpen] = useState(false);
	const [rows, setRows] = React.useState(50);
	const [dateFilter, setDateFilter] = useState(false);
	const [datevalue, setDatevalue] = useState('Click to open');
	const [direction, setdirection] = useState('Desc');
	const [pageNo, setPageNo] = useState(1);
	const [paginationPageNo, setPaginationPageNo] = useState(1);
	const [getPageNo, setGetPageNo] = useState();
	const [totalcount, setTotalCount] = useState();
	const [selectedDelete, setSelectedDelete] = useState([]);
	const [datesRange, setDatesRange] = useState();
	const [countNumber, setCountNumber] = useState();
	const [mails, setMails] = useState();
	const { addToast } = useToasts();
	const dispatch = useDispatch();

	useEffect(() => {
		getPermissions();
		window.addEventListener('storage', function (e) {
			if (e.key === null) {
				dispatch(logout());
				navigate('/');
			}
		});
	}, [rows]);

	const getPermissions = () => {
		const resource_obj = [];

		for (let i = 0; i < employeeData.role_permissions.length; i++) {
			resource_obj.push(employeeData.role_permissions[i].resource_id);
		}
		var available_resource = resource_obj.includes(73);

		if (available_resource) {
			if (localStorage.getItem('StatusPageNo')) {
				let currentPage = localStorage.getItem('StatusPageNo');
				setPageNo(currentPage);
				setPaginationPageNo(Number(currentPage));
				getAllStatusMailList(currentPage);
			} else {
				setPageNo(1);
				setPaginationPageNo(1);
				getAllStatusMailList(1);
			}
			UserByRoleID();
		} else {
			navigate('/errorPage');
		}
	};

	const onSubmit = (data2) => {
		let from_emp_id = employeeData.emp_id;
		let emp_name = employeeData.emp_name;
		let from_emp_email = employeeData.emp_email;
		let subject = data2.subject;
		let description = data2.description;
		let to_emp_id = employeeData.reporting_manager_id;
		let date = new Date().toISOString();

		let data = {
			from_emp_id,
			emp_name,
			from_emp_email,
			subject,
			description,
			to_emp_id,
			date,
		};
		console.log(data);
		postMail(data);
	};

	const postMail = (data) => {
		setBackdropOpen(true);

		axios
			.post(
				`${base_url.api1}/myappapi/status-mail/create?company_code=` +
					employeeData.company_code,
				data,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then(
				(response) => {
					addToast('Status Mail sent Successfully.', {
						appearance: 'success',
						autoDismiss: true,
					});
					console.log(data);
					getAllStatusMailList(pageNo);
					setCreate(false);
					setBackdropOpen(false);
				},
				(error) => {
					addToast('Status mail not sent!', {
						appearance: 'error',
						autoDismiss: true,
					});
					console.log(error);
					setBackdropOpen(false);
					setCreate(false);
				}
			);
	};
	const handleRows = (event) => {
		setRows(event.target.value);
	};
	const handleEvent = (event, picker) => {
		console.log(picker.startDate);
	};

	const handleCallback = (start, end, label) => {
		console.log(start, end, label);
	};

	const getAllStatusMailList = (value1) => {
		console.log(value1);
		setBackdropOpen(true);
		if (
			employeeData.emp_role_id == 1 ||
			employeeData.emp_role_id == 2 ||
			employeeData.emp_role_id == 3
		) {
			axios
				.get(
					`${base_url.api1}/myappapi/status-mail/list?company_code=` +
						employeeData.company_code +
						`&page=` +
						value1 +
						`&size=` +
						rows,
					{
						headers: {
							Authorization: jwtToken,
						},
					}
				)
				.then(
					(response) => {
						setMails(response.data.Status);
						console.log(response.data.Status);
						setGetPageNo(response.data.total_pages);
						setTotalCount(response.data.total_count);
						setCountNumber(value1 - 1);
						setNumberOfElements(response.data.Status.length);
						setsearch(false);
						setBackdropOpen(false);
						document.getElementById('myForm').reset();
					},
					(error) => {
						console.log(JSON.stringify(error.response.data));
						setBackdropOpen(false);
						setMails();
					}
				);
		} else {
			axios
				.get(
					`${base_url.api1}/myappapi/status-mail/list?company_code=` +
						employeeData.company_code +
						`&from_emp_id=` +
						employeeData.emp_id +
						`&page=` +
						value1 +
						`&size=` +
						rows,
					{
						headers: {
							Authorization: jwtToken,
						},
					}
				)
				.then(
					(response) => {
						setMails(response.data.Status);
						setGetPageNo(response.data.total_pages);
						setTotalCount(response.data.total_count);
						setCountNumber(value1 - 1);
						setNumberOfElements(response.data.Status.length);
						setsearch(false);
						setBackdropOpen(false);
						document.getElementById('myForm').reset();
					},
					(error) => {
						console.log(JSON.stringify(error.response.data));
						setBackdropOpen(false);
						setMails();
					}
				);
		}
	};

	const handlepage = (event, value) => {
		console.log(value);
		setPaginationPageNo(value);
		setPageNo(value);
		getAllStatusMailList(value);
	};

	const closedModal = () => {
		setCreate(false);
		setDescModelOpen(false);
	};

	const createModalStatusMail = () => {
		setCreate(true);
	};

	const handleApply = (event, picker) => {
		setBackdropOpen(true);
		setDatevalue(
			`${picker.startDate.format('DD-MM-YYYY')} to ${picker.endDate.format(
				'DD-MM-YYYY'
			)}`
		);
		let data = {
			from: picker.startDate.format('YYYY-MM-DD'),
			to: picker.endDate.format('YYYY-MM-DD'),
			// sortby: sortState,
			// Order: direction,
		};
		setDatesRange(data);
		if (
			employeeData.emp_role_id == 1 ||
			employeeData.emp_role_id == 2 ||
			employeeData.emp_role_id == 3
		) {
			axios
				.get(
					`${base_url.api1}/myappapi/status-mail/list?company_code=` +
						employeeData.company_code +
						`&from=` +
						data.from +
						`&to=` +
						data.to,
					{
						headers: {
							Authorization: jwtToken,
						},
					}
				)
				.then(
					(response) => {
						setBackdropOpen(false);
						setMails(response.data.Status);
						setGetPageNo(response.data.total_pages);
						setTotalCount(response.data.total_count);
						setCountNumber(pageNo - 1);
						setNumberOfElements(response.data.Status.length);
						setDateFilter(true);
					},
					(error) => {
						setBackdropOpen(false);
						setMails();
						console.log(JSON.stringify(error.response.data));
						setDateFilter(true);
					}
				);
		} else {
			axios
				.get(
					`${base_url.api1}/myappapi/status-mail/list?company_code=` +
						employeeData.company_code +
						`&from_emp_id=` +
						employeeData.emp_id +
						`&from=` +
						data.from +
						`&to=` +
						data.to,
					{
						headers: {
							Authorization: jwtToken,
						},
					}
				)
				.then(
					(response) => {
						setBackdropOpen(false);
						setMails(response.data.Status);
						setGetPageNo(response.data.total_pages);
						setTotalCount(response.data.total_count);
						setCountNumber(pageNo - 1);
						setNumberOfElements(response.data.Status.length);
						setDateFilter(true);
					},
					(error) => {
						setBackdropOpen(false);
						setMails();
						console.log(JSON.stringify(error.response.data));
						setDateFilter(true);
					}
				);
		}
	};

	const [searchinput, setsearchinput] = useState();
	const submitSearch = (e, value) => {
		setBackdropOpen(true);
		setdisplay('none');
		setsearch(true);
		let srchdata = e.target[0].value.trim();
		setsearchinput(srchdata);
		console.log(srchdata);
		axios
			.get(
				`${base_url.api1}/myappapi/status-mail/list?company_code=` +
					employeeData.company_code +
					`&from_emp_id=` +
					employeeData.emp_id +
					`&search=` +
					srchdata,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then(
				(response) => {
					setBackdropOpen(false);
					setMails(response.data.Status);
					// console.log(response.data.Status);
					setGetPageNo(response.data.total_pages);
					setNumberOfElements(response.data.Status.length);
					setTotalCount(response.data.total_count);
				},
				(error) => {
					setBackdropOpen(false);
					console.log(JSON.stringify(error.response.data));
					setMails();
				},
				(error) => {
					setBackdropOpen(false);
					console.log(JSON.stringify(error.response.data));
				}
			);
	};
	const [employeeId, setEmployeeId] = useState();
	const [selectEmployeeState, setSelectedEmployee] = useState(false);
	const [selectEmployeeData, setSelectedEmployeeData] = useState();
	const onSelectEmployee = (selectedList, selectedItem) => {
		console.log('Entering', selectedList);
		setBackdropOpen(true);
		setSelectedEmployee(true);
		setSelectedEmployeeData(selectedList);
		if (selectedList.value == null) {
			axios
				.get(
					`${base_url.api1}/myappapi/status-mail/list?company_code=` +
						employeeData.company_code +
						`&page=1&size=` +
						rows,
					{
						headers: {
							Authorization: jwtToken,
						},
					}
				)
				.then(
					(response) => {
						setGetPageNo(response.data.total_pages);
						setTotalCount(response.data.total_count);
						setCountNumber(0);
						setNumberOfElements(response.data.Status.length);
						setEmployeeId(response.data.Status.from_emp_id);
						setsearch(false);
						setBackdropOpen(false);
						let attData = response.data.Status;
						let attObj = [];
						for (let i = 0; i < attData.length; i++) {
							attObj.push({
								date: attData[i].date,
								description: attData[i].description,
								emp_name: attData[i].emp_name,
								company_id: attData[i].company_id,
								from_emp_email: attData[i].from_emp_email,
								from_emp_id: attData[i].from_emp_id,
								subject: attData[i].subject,
								to_emp_email: attData[i].to_emp_email,
								to_emp_id: attData[i].to_emp_id,
								id: attData[i].id,
							});
						}
						setMails(attObj);
					},
					(error) => {
						setBackdropOpen(false);
						setMails();
						console.log(JSON.stringify(error.response.data));
					}
				);
		} else {
			axios
				.get(
					`${base_url.api1}/myappapi/status-mail/list?company_code=` +
						employeeData.company_code +
						`&page=1&size=` +
						rows +
						`&from_emp_id=` +
						selectedList.value,
					{
						headers: {
							Authorization: jwtToken,
						},
					}
				)
				.then(
					(response) => {
						setGetPageNo(response.data.total_pages);
						setTotalCount(response.data.total_count);
						setCountNumber(0);
						setNumberOfElements(response.data.Status.length);
						setEmployeeId(response.data.Status.from_emp_id);
						setsearch(false);
						setBackdropOpen(false);
						let attData = response.data.Status;
						let attObj = [];
						for (let i = 0; i < attData.length; i++) {
							attObj.push({
								date: attData[i].date,
								description: attData[i].description,
								emp_name: attData[i].emp_name,
								company_id: attData[i].company_id,
								from_emp_email: attData[i].from_emp_email,
								from_emp_id: attData[i].from_emp_id,
								subject: attData[i].subject,
								to_emp_email: attData[i].to_emp_email,
								to_emp_id: attData[i].to_emp_id,
								id: attData[i].id,
							});
						}
						setMails(attObj);
					},
					(error) => {
						setDateFilter(true);
						setBackdropOpen(false);
						setMails();
						console.log(JSON.stringify(error.response.data));
					}
				);
		}
	};

	const [availableEmployee, setAvailableEmployee] = useState({
		value: null,
		label: null,
	});
	const UserByRoleID = () => {
		axios
			.get(
				`${base_url.api1}/myappapi/all_users_by_role?company_code=` +
					employeeData.company_code +
					`&status=True`,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then((response) => {
				let emp_data = response.data.Users;
				let emp_obj = [];
				for (let i = 0; i < emp_data.length; i++) {
					let data = {
						value: emp_data[i].user_id,
						label: emp_data[i].first_name + ' ' + emp_data[i].last_name,
					};
					emp_obj.push(data);
				}
				setAvailableEmployee(emp_obj);
			})
			.catch((error) => {
				console.log(JSON.stringify(error));
			});
	};

	return (
		<div>
			<Backdrop
				open={backdropOpen}
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<div className="commoncomponent_cardcss">
				<div style={{ margin: '5px' }}>
					<Row>
						<Col md={6}>
							<CardText
								className={classes.headtext}
								style={{ marginTop: '-10px' }}
							>
								<h2>Status Mail</h2>
							</CardText>
						</Col>
						<Col md={6} className="d-flex justify-content-end">
							<>
								<Button color="primary" onClick={createModalStatusMail}>
									Send Status Mail
								</Button>
							</>
						</Col>
						<Modal
							ariaHideApp={false}
							isOpen={modalCreate}
							onRequestClose={closedModal}
							style={customStyles}
							contentLabel="Example Modal"
						>
							<div style={{ textAlign: 'right', padding: '10px 20px' }}>
								<img
									className="closei"
									src={close}
									onClick={() => setCreate(false)}
								/>
							</div>
							<div className="modalContainer">
								<div style={{ textAlign: 'center' }}>
									<img className="modelIcon" src={holidayicon} />
								</div>
								<h1>Send Status Mail</h1>
								<form onSubmit={handleSubmit(onSubmit)} id="addform">
									<div>
										<div className="form-group creatadmin">
											<Row>
												<label>
													Task Title<span style={{ color: 'red' }}>*</span>:
												</label>
												<input
													type="text"
													className="form-control underline-input"
													placeholder="Task Title"
													id="subject"
													{...register('subject', { required: true })}
												/>
												{errors.subject && (
													<p className="errormessage">Subject is Required</p>
												)}
											</Row>
											<br />
											<Row>
												<label>
													Task Description
													<span style={{ color: 'red' }}>*</span>:
												</label>
												<textarea
													style={{ minWidth: '600px' }}
													className="form-control"
													rows={5}
													{...register('description')}
													required
												/>
												<br />
											</Row>
											<Row>
												<div style={{ margin: '0px' }}>
													<label>
														Task Status
														<span style={{ color: 'red' }}>*</span>:
													</label>
													<br />
													{/* <FormControl className={classes.formControl}> */}

													<select
														className={classes.selectlimit}
														labelId="simple-select-label"
														id="simple-select"
														//value={}
														onChange={(e) => {
															//handleChangeStatus(e.target.value);
														}}
													>
														<option value={'Started'}>Started</option>
														<option value={'Pending'}>Pending</option>
														<option value={'Completed'}>Completed</option>
														<option value={'Not Started'}>Not Started</option>
													</select>
												</div>
											</Row>
											<Row>
												<Col md={12}>
													<Row className="justify-content-end mt-2">
														<Button
															color="secondary"
															onClick={() => setCreate(false)}
														>
															Cancel
														</Button>
														<Button
															color="primary"
															type="submit"
															className="btn listing_addbutton ml-1"
														>
															Send
														</Button>
													</Row>
												</Col>
											</Row>
										</div>
									</div>
								</form>
								<div>
									<br />
								</div>
							</div>
						</Modal>
					</Row>
					<div className="newlisting_headertabdesign">
						<Row>
							<form className="master">
								<div style={{ marginTop: '15px' }}>
									<label className="area_rowslable">Limit:</label>
									<br />
									<FormControl className={classes.formControl}>
										<select
											className={classes.selectlimit}
											labelId="simple-select-label"
											id="simple-select"
											value={rows}
											onChange={handleRows}
										>
											<option value={20}>20</option>
											<option value={50}>50</option>
											<option value={100}>100</option>
										</select>
									</FormControl>
								</div>
							</form>
							<br />
							<form className="form-group">
								<div
									style={{
										display: 'flex',
										justify: 'space-between',
										flexWrap: 'wrap',
									}}
								>
									<div style={{ marginTop: '1px', marginLeft: '5px' }}>
										<label className="area_rowslable">Date:</label>
										<DateRangePicker
											onEvent={handleEvent}
											onCallback={handleCallback}
											onApply={handleApply}
										>
											<p className="datepickerrangevaluetext">{datevalue}</p>
										</DateRangePicker>
										{dateFilter ? (
											<div>
												<a
													className={classes.clrsrch}
													onClick={() => {
														setdisplay('inline');
														setDatevalue('Click to open');
														setBackdropOpen(true);
														setdirection('Desc');
														setPageNo(1);
														getAllStatusMailList(1);
														setDateFilter(false);
													}}
												>
													Clear Filter
												</a>
											</div>
										) : null}
									</div>
								</div>
							</form>
							<br />
							{employeeData.emp_role_id == 1 ||
							employeeData.emp_role_id == 2 ||
							employeeData.emp_role_id == 3 ? (
								<Col md={3}>
									<div style={{ marginTop: '8px', marginLeft: '5px' }}>
										<label>
											<strong>Employee Name:</strong>
										</label>
										<Select
											className="basic-multi-select"
											classNamePrefix="select"
											value={selectEmployeeData}
											onChange={onSelectEmployee}
											options={availableEmployee}
										/>
										{selectEmployeeState ? (
											<div>
												<a
													className={classes.clrsrch}
													onClick={() => {
														setBackdropOpen(true);
														getAllStatusMailList(1);
														setSelectedEmployeeData({
															value: null,
															label: null,
														});
														setSelectedEmployee(false);
													}}
												>
													Clear Filter
												</a>
											</div>
										) : null}
									</div>
								</Col>
							) : null}
							<br />
							<Col md={0}>
								<div className="form-group">
									<div>
										<div className="clearsearchclass">
											<form
												id="myForm"
												className="d-flex"
												onSubmit={(e) => {
													e.preventDefault();
													submitSearch(e);
												}}
											>
												<input
													style={{
														padding: '5px',
														border: '2px solid',
														marginTop: '-1px',
													}}
													className="form-control"
													placeholder="Search.."
													name="search"
													required
												/>
												<button
													color="blue"
													className={classes.srchbtn}
													type="submit"
												>
													<i className="fa fa-search"></i>
												</button>
											</form>
											{searchdata ? (
												<a
													onClick={() => {
														setdisplay('inline');
														setBackdropOpen(true);
														setPageNo(1);
														getAllStatusMailList(1);
														setsearchinput();
														setDateFilter(false);
													}}
												>
													Clear Search
												</a>
											) : null}
										</div>
									</div>
								</div>
							</Col>
						</Row>
					</div>
					<div className="newlisting_headertabdesign">
						<Table responsive striped>
							<thead>
								<tr>
									<th style={{ textAlign: 'center', width: '50px' }}>SNo.</th>
									<th>Subject </th>
									{employeeData.emp_role_id == 1 ||
									employeeData.emp_role_id == 2 ||
									employeeData.emp_role_id == 3 ? (
										<th>From </th>
									) : null}
									{employeeData.emp_role_id == 1 ||
									employeeData.emp_role_id == 2 ||
									employeeData.emp_role_id == 3 ? (
										<th>MailId </th>
									) : null}
									<th>Send Date </th>
									<th>Send Time </th>
									<th style={{ width: '375px' }}>Description </th>
									{/* <th style={{ textAlign: "center", width: "175px" }}>Action</th> */}
								</tr>
							</thead>
							<tbody>
								{mails != null ? (
									mails &&
									mails.map((Status, index) => (
										<tr>
											<th>{countNumber * rows + 1 + index}</th>
											<td>{Status.subject}</td>
											{employeeData.emp_role_id == 1 ||
											employeeData.emp_role_id == 2 ||
											employeeData.emp_role_id == 3 ? (
												<td>{Status.emp_name}</td>
											) : null}
											{employeeData.emp_role_id == 1 ||
											employeeData.emp_role_id == 2 ||
											employeeData.emp_role_id == 3 ? (
												<td>{Status.from_emp_email}</td>
											) : null}
											<td>
												{Status.date
													? moment(Status.date.split('.')[0]).format(
															'DD MMM YYYY'
													  )
													: null}
											</td>
											<td>
												{Status.date
													? moment(Status.date).format('HH:mm:ss')
													: null}
											</td>
											<td className="leaveEllipsis">
												{Status.description}
												{Status.description != null ? (
													<span
														className="taskDescspan"
														onClick={() => {
															setDescModelOpen(true);
															setDescContent(Status.description);
														}}
													>
														View More
													</span>
												) : null}
												<Modal
													ariaHideApp={false}
													isOpen={descModelOpen}
													onRequestClose={() => setDescModelOpen(false)}
													style={customStyles2}
													contentLabel="Example Modal"
												>
													<h4>Description</h4>
													{descContent ? (
														<textarea
															style={{ minWidth: '600px' }}
															className="form-control"
															value={descContent}
															rows={10}
															readonly
														/>
													) : (
														<p> No Record Available</p>
													)}
													<div
														style={{
															display: 'flex',
															margin: '10px 0',
															justifyContent: 'flex-end',
														}}
													>
														<Button
															color="secondary"
															onClick={() => setDescModelOpen(false)}
														>
															Close
														</Button>
													</div>
												</Modal>
											</td>
										</tr>
									))
								) : (
									<p className={classes.headertekst}>{null}</p>
								)}
							</tbody>
						</Table>
						<div>
							<Row>
								<Col md={6}>
									{mails?.length ? (
										<p style={{ marginTop: '15px' }}>
											{countNumber * rows + 1} to{' '}
											{countNumber * rows + numberOfElements} of {totalcount}{' '}
											records
										</p>
									) : (
										<p className="formfooterrecordstyle">No Record Found</p>
									)}
								</Col>
								<Col md={6}>
									<div className={classes.root}>
										{searchdata || dateFilter ? (
											<Pagination
												page={paginationPageNo}
												count={getPageNo}
												onChange={submitSearch}
											/>
										) : (
											<Pagination
												page={paginationPageNo}
												count={getPageNo}
												onChange={handlepage}
											/>
										)}
									</div>
								</Col>
							</Row>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default StatusMail;
