import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Link, useNavigate } from 'react-router-dom';
import { CardText, Col, Row, Button, Table } from 'reactstrap';
import AddIcon from '@mui/icons-material/Add';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Pagination from '@mui/material/Pagination';
import Modal from 'react-modal';
import { useForm } from 'react-hook-form';
import {
	customStyles,
	useStyles,
	customStyles2,
} from './../../Utilities/CSSUtilities';
import axios from 'axios';
import base_url from '../../api/bootapi';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { logout, selectEmployee } from '../../components/features/empSlice';
import { useToasts } from 'react-toast-notifications';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Select from 'react-select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import CallAuditapi from '../services/auditservice';

const LeaveApplication = () => {
	const [backdropOpen, setBackdropOpen] = useState(false);
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();
	const [modalCreate, setCreate] = React.useState(false);
	const jwtToken = 'JWT ' + localStorage.getItem('JWTToken');
	const [leaveData, setLeaveData] = useState([]);
	const employeData = useSelector(selectEmployee);
	const [pageNo, setPageNo] = useState(1);
	const [paginationPageNo, setPaginationPageNo] = useState(1);
	const [getPageNo, setGetPageNo] = useState(0);
	const [rows, setRows] = useState(50);
	const [countNumber, setCountNumber] = useState();
	const [numberOfElements, setNumberOfElements] = useState();
	const [totalCount, setTotalCount] = useState();
	const [searchData, setSearchData] = useState();
	const [searchStatus, setSearchStatus] = useState();
	const [dateFilter, setDateFilter] = useState();
	const [dateStatus, setDateStatus] = useState(false);
	const [descContent, setDescContent] = useState();
	const [descModelOpen, setDescModelOpen] = useState(false);
	const [permissionObj, setPermissionObj] = useState();
	const navigate = useNavigate();
	const classes = useStyles();
	const { addToast } = useToasts();
	const closeModal = () => {
		setCreate(false);
		setApproveLeave(false);
		setDeclineLeave(false);
		setDescModelOpen(false);
	};
	const createModalConfig = () => {
		setCreate(true);
	};

	useEffect(() => {
		getPermissions();
	}, [rows]);

	const handleRows = (event) => {
		setRows(event.target.value);
	};

	const getPermissions = () => {
		const resource_obj = [];

		for (let i = 0; i < employeData.role_permissions.length; i++) {
			resource_obj.push(employeData.role_permissions[i].resource_id);
		}
		setPermissionObj(resource_obj);
		var available_resource37 = resource_obj.includes(37);
		var available_resource38 = resource_obj.includes(38);
		var available_resource39 = resource_obj.includes(39);

		if (
			available_resource37 &&
			(available_resource38 || available_resource39)
		) {
			if (localStorage.getItem('LeavePageNo')) {
				let currentPage = localStorage.getItem('LeavePageNo');
				setPageNo(currentPage);
				setPaginationPageNo(Number(currentPage));
				getAllLeaveList(currentPage);
			} else {
				setPageNo(1);
				setPaginationPageNo(1);
				getAllLeaveList(1);
			}
		} else {
			navigate('/errorPage');
		}
	};

	const [dateValue, setDateValue] = useState('Click to open');
	const handleApply = (event, picker, value1) => {
		setBackdropOpen(true);
		setDateValue(
			`${picker.startDate.format('DD-MM-YYYY')} to ${picker.endDate.format(
				'DD-MM-YYYY'
			)}`
		);
		let data = {
			from: picker.startDate.format('YYYY-MM-DD'),
			to: picker.endDate.format('YYYY-MM-DD'),
		};
		setDateFilter(data);
		setDateStatus(true);

		if (
			employeData.emp_role_id == 1 ||
			employeData.emp_role_id == 2 ||
			employeData.emp_role_id == 3
		) {
			axios
				.get(
					`${base_url.api1}/myappapi/leaveapp/date?company_code=` +
						employeData.company_code +
						`&from=` +
						data.from +
						`&to=` +
						data.to +
						`&page=` +
						value1 +
						`&size=` +
						rows,
					{
						headers: {
							Authorization: jwtToken,
						},
					}
				)
				.then(
					(response) => {
						setGetPageNo(response.data.total_pages);
						setLeaveData(response.data.Leaves);
						setCountNumber(value1 - 1);
						setTotalCount(response.data.total_count);
						setNumberOfElements(response.data.Leaves.length);
						setSearchStatus(false);
						setBackdropOpen(false);
					},
					(error) => {
						setGetPageNo(0);
						setLeaveData();
						setCountNumber(0);
						setNumberOfElements(0);
						setSearchStatus(false);
						setBackdropOpen(false);
					}
				);
		} else {
			axios
				.get(
					`${base_url.api1}/myappapi/leaveapp/date?company_code=` +
						employeData.company_code +
						`&from=` +
						data.from +
						`&to=` +
						data.to +
						`&page=` +
						value1 +
						`&size=` +
						rows +
						`&emp_id=` +
						employeData.emp_id,
					{
						headers: {
							Authorization: jwtToken,
						},
					}
				)
				.then(
					(response) => {
						setGetPageNo(response.data.total_pages);
						setLeaveData(response.data.Leaves);
						setCountNumber(value1 - 1);
						setTotalCount(response.data.total_count);
						setNumberOfElements(response.data.Leaves.length);
						setSearchStatus(false);
						setBackdropOpen(false);
					},
					(error) => {
						setGetPageNo(0);
						setLeaveData();
						setCountNumber(0);
						setNumberOfElements(0);
						setSearchStatus(false);
						setBackdropOpen(false);
					}
				);
		}
	};

	const handlePageApply = (value1) => {
		setBackdropOpen(true);

		if (
			employeData.emp_role_id == 1 ||
			employeData.emp_role_id == 2 ||
			employeData.emp_role_id == 3
		) {
			axios
				.get(
					`${base_url.api1}/myappapi/leaveapp/date?company_code=` +
						employeData.company_code +
						`&from=` +
						dateFilter.from +
						`&to=` +
						dateFilter.to +
						`&page=` +
						value1 +
						`&size=` +
						rows,
					{
						headers: {
							Authorization: jwtToken,
						},
					}
				)
				.then(
					(response) => {
						setGetPageNo(response.data.total_pages);
						setLeaveData(response.data.Leaves);
						setCountNumber(value1 - 1);
						setTotalCount(response.data.total_count);
						setNumberOfElements(response.data.Leaves.length);
						setSearchStatus(false);
						setBackdropOpen(false);
					},
					(error) => {
						setGetPageNo(0);
						setLeaveData();
						setCountNumber(0);
						setNumberOfElements(0);
						setSearchStatus(false);
						setBackdropOpen(false);
					}
				);
		} else {
			axios
				.get(
					`${base_url.api1}/myappapi/leaveapp/date?company_code=` +
						employeData.company_code +
						`&from=` +
						dateFilter.from +
						`&to=` +
						dateFilter.to +
						`&page=` +
						value1 +
						`&size=` +
						rows +
						`&emp_id=` +
						employeData.emp_id,
					{
						headers: {
							Authorization: jwtToken,
						},
					}
				)
				.then(
					(response) => {
						setGetPageNo(response.data.total_pages);
						setLeaveData(response.data.Leaves);
						setCountNumber(value1 - 1);
						setTotalCount(response.data.total_count);
						setNumberOfElements(response.data.Leaves.length);
						setSearchStatus(false);
						setBackdropOpen(false);
					},
					(error) => {
						setGetPageNo(0);
						setLeaveData();
						setCountNumber(0);
						setNumberOfElements(0);
						setSearchStatus(false);
						setBackdropOpen(false);
					}
				);
		}
	};

	const handlePage = (event, value) => {
		setPaginationPageNo(value);
		setPageNo(value);
		if (searchStatus) {
			console.log('Coming Search');
			submitPageSearch(value);
		} else if (dateStatus) {
			console.log('Coming Date');
			handlePageApply(value);
		} else {
			console.log('Coming Normal');
			getAllLeaveList(value);
		}
	};

	const getAllLeaveList = (value1) => {
		setBackdropOpen(true);
		console.log('Employee Data:', employeData);
		if (
			employeData.emp_role_id == 1 ||
			employeData.emp_role_id == 2 ||
			employeData.emp_role_id == 3
		) {
			axios
				.get(
					`${base_url.api1}/myappapi/leaveapp/list?company_code=` +
						employeData.company_code +
						`&page=` +
						value1 +
						`&size=` +
						rows,
					{
						headers: {
							Authorization: jwtToken,
						},
					}
				)
				.then(
					(response) => {
						setGetPageNo(response.data.total_pages);
						setLeaveData(response.data.leaveapp);
						setCountNumber(value1 - 1);
						setTotalCount(response.data.total_count);
						setNumberOfElements(response.data.leaveapp.length);
						setSearchStatus(false);
						setBackdropOpen(false);
						document.getElementById('myForm').reset();
					},
					(error) => {
						setGetPageNo(0);
						setLeaveData();
						setCountNumber(0);
						setNumberOfElements(0);
						setSearchStatus(false);
						setBackdropOpen(false);
						console.log(JSON.stringify(error.response.data));
						document.getElementById('myForm').reset();
					}
				);
		} else {
			axios
				.get(
					`${base_url.api1}/myappapi/leaveapp/list?company_code=` +
						employeData.company_code +
						`&emp_id=` +
						employeData.emp_id +
						`&page=` +
						value1 +
						`&size=` +
						rows,
					{
						headers: {
							Authorization: jwtToken,
						},
					}
				)
				.then(
					(response) => {
						setGetPageNo(response.data.total_pages);
						setLeaveData(response.data.leaveapp);
						setCountNumber(value1 - 1);
						setTotalCount(response.data.total_count);
						setNumberOfElements(response.data.leaveapp.length);
						setSearchStatus(false);
						setBackdropOpen(false);
					},
					(error) => {
						setGetPageNo(0);
						setLeaveData();
						setCountNumber(0);
						setNumberOfElements(0);
						setSearchStatus(false);
						setBackdropOpen(false);
						console.log(JSON.stringify(error.response.data));
					}
				);
		}
	};

	const submitSearch = (e) => {
		setSearchStatus(true);
		let srchdata = e.target[0].value.trim();
		setSearchData(srchdata);
		setBackdropOpen(true);
		if (
			employeData.emp_role_id == 1 ||
			employeData.emp_role_id == 2 ||
			employeData.emp_role_id == 3
		) {
			axios
				.get(
					`${base_url.api1}/myappapi/leaveapp/list?company_code=` +
						employeData.company_code +
						`&page=1&size=` +
						rows +
						`&query=` +
						srchdata,
					{
						headers: {
							Authorization: jwtToken,
						},
					}
				)
				.then(
					(response) => {
						setGetPageNo(response.data.total_pages);
						setLeaveData(response.data.leaveapp);
						setCountNumber(0);
						setTotalCount(response.data.total_count);
						setNumberOfElements(response.data.leaveapp.length);
						setBackdropOpen(false);
					},
					(error) => {
						setGetPageNo(0);
						setLeaveData();
						setCountNumber(0);
						setNumberOfElements(0);
						setBackdropOpen(false);
						console.log(JSON.stringify(error.response.data));
					}
				);
		} else {
			axios
				.get(
					`${base_url.api1}/myappapi/leaveapp/list?company_code=` +
						employeData.company_code +
						`&emp_id=` +
						employeData.emp_id +
						`&page=1&size=` +
						rows +
						`&query=` +
						srchdata,
					{
						headers: {
							Authorization: jwtToken,
						},
					}
				)
				.then(
					(response) => {
						setGetPageNo(response.data.total_pages);
						setLeaveData(response.data.leaveapp);
						setCountNumber(0);
						setTotalCount(response.data.total_count);
						setNumberOfElements(response.data.leaveapp.length);
						setBackdropOpen(false);
					},
					(error) => {
						setGetPageNo(0);
						setLeaveData();
						setCountNumber(0);
						setNumberOfElements(0);
						setBackdropOpen(false);
						console.log(JSON.stringify(error.response.data));
					}
				);
		}
	};

	const submitPageSearch = (value1) => {
		setSearchStatus(true);
		setBackdropOpen(true);
		if (
			employeData.emp_role_id == 1 ||
			employeData.emp_role_id == 2 ||
			employeData.emp_role_id == 3
		) {
			axios
				.get(
					`${base_url.api1}/myappapi/leaveapp/list?company_code=` +
						employeData.company_code +
						`&page=` +
						value1 +
						`&size=` +
						rows +
						`&query=` +
						searchData,
					{
						headers: {
							Authorization: jwtToken,
						},
					}
				)
				.then(
					(response) => {
						setGetPageNo(response.data.total_pages);
						setLeaveData(response.data.leaveapp);
						setCountNumber(0);
						setTotalCount(response.data.total_count);
						setNumberOfElements(response.data.leaveapp.length);
						setBackdropOpen(false);
					},
					(error) => {
						setGetPageNo(0);
						setLeaveData();
						setCountNumber(0);
						setNumberOfElements(0);
						setBackdropOpen(false);
						console.log(JSON.stringify(error.response.data));
					}
				);
		} else {
			axios
				.get(
					`${base_url.api1}/myappapi/leaveapp/list?company_code=` +
						employeData.company_code +
						`&emp_id=` +
						employeData.emp_id +
						`&page=1&size=` +
						rows +
						`&query=` +
						searchData,
					{
						headers: {
							Authorization: jwtToken,
						},
					}
				)
				.then(
					(response) => {
						setGetPageNo(response.data.total_pages);
						setLeaveData(response.data.leaveapp);
						setCountNumber(0);
						setTotalCount(response.data.total_count);
						setNumberOfElements(response.data.leaveapp.length);
						setBackdropOpen(false);
					},
					(error) => {
						setGetPageNo(0);
						setLeaveData();
						setCountNumber(0);
						setNumberOfElements(0);
						setBackdropOpen(false);
						console.log(JSON.stringify(error.response.data));
					}
				);
		}
	};

	const onSubmit = (data2) => {
		let from_date = data2.from_date + 'T00:00:00Z';
		let to_date = data2.to_date + 'T00:00:00Z';
		let description = data2.description;
		let type = 'Casual';
		let emp_name = employeData.emp_name;
		let status = 2;
		let emp_id = employeData.emp_id;

		let data = {
			from_date,
			to_date,
			description,
			type,
			emp_name,
			status,
			emp_id,
		};

		setBackdropOpen(true);
		axios
			.post(
				`${base_url.api1}/myappapi/leaveapp/post?company_code=` +
					employeData.company_code,
				data,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then((response) => {
				setBackdropOpen(false);
				setCreate(false);
				getAllLeaveList(pageNo);
				addToast('Applied For Leave Successful', {
					appearance: 'success',
					autoDismiss: true,
				});
				let log = employeData.emp_name + ' Applied for Leave application';
				CallAuditapi(log);
			})
			.catch((error) => {
				setBackdropOpen(false);
				setCreate(false);
				addToast('Applied For Leave UnSuccessful', {
					appearance: 'error',
					autoDismiss: true,
				});
				getAllLeaveList(pageNo);
				console.log(JSON.stringify(error));
			});
	};

	const [leaveApproveOpen, setApproveLeave] = React.useState(false);
	const [leaveId, setLeaveId] = useState();
	const updateApproveLeave = (e, leave) => {
		setApproveLeave(true);
		setLeaveId(leave.leave_id);
	};

	const [leaveDeclineOpen, setDeclineLeave] = React.useState(false);
	const [declineData, setDeclineData] = useState();
	const [declineNewData, setDeclineNewData] = useState();

	const updateDeclineLeave = (e, leave) => {
		setDeclineLeave(true);
		setLeaveId(leave.leave_id);
	};

	const approveLeave = () => {
		let data = {
			approved_by: employeData.emp_name,
			manager_id: employeData.emp_id,
			status: 1,
		};
		setBackdropOpen(true);
		axios
			.put(
				`${base_url.api1}/myappapi/leaveapp/update?company_code=` +
					employeData.company_code +
					`&leave_id=` +
					leaveId,
				data,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then(
				(response) => {
					setBackdropOpen(false);
					setApproveLeave(false);
					getAllLeaveList(pageNo);
					addToast('Leave Approved', {
						appearance: 'success',
						autoDismiss: true,
					});
				},
				(error) => {
					setBackdropOpen(false);
					setApproveLeave(false);
					addToast('Leave Not Approved Successfully!', {
						appearance: 'error',
						autoDismiss: true,
					});
				}
			);
	};

	const declineLeave = () => {
		let finalDesc;
		if (declineData != null) {
			finalDesc =
				declineData +
				'\n' +
				declineNewData +
				'\n>>Declined By, ' +
				employeData.emp_name +
				'(' +
				employeData.emp_role_name +
				')';
		} else {
			finalDesc =
				declineNewData +
				'\n>>Declined By, ' +
				employeData.emp_name +
				'(' +
				employeData.emp_role_name +
				')';
		}
		let data = {
			approved_by: employeData.emp_name,
			description: finalDesc,
			manager_id: employeData.emp_id,
			status: 0,
		};
		console.log(data);
		setBackdropOpen(true);
		axios
			.put(
				`${base_url.api1}/myappapi/leaveapp/update?company_code=` +
					employeData.company_code +
					`&leave_id=` +
					leaveId,
				data,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then(
				(response) => {
					setBackdropOpen(false);
					setDeclineLeave(false);
					getAllLeaveList(pageNo);
					addToast('Leave Declined', {
						appearance: 'success',
						autoDismiss: true,
					});
				},
				(error) => {
					setBackdropOpen(false);
					setDeclineLeave(false);
					addToast('Leave Not Declined Successfully!', {
						appearance: 'error',
						autoDismiss: true,
					});
				}
			);
	};

	return (
		<>
			<div>
				<ToastContainer />
				<Backdrop
					open={backdropOpen}
					sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				>
					<CircularProgress color="inherit" />
				</Backdrop>
				<div className="commoncomponent_cardcss">
					<div>
						<Row>
							<Col md={6}>
								<CardText style={{ marginTop: '-10px' }}>
									<h3>Leave Application</h3>
								</CardText>
							</Col>
							<Col md={6} className="d-flex justify-content-end">
								<Button color="primary" onClick={createModalConfig}>
									Apply For Leave
								</Button>
							</Col>
							<Modal
								isOpen={modalCreate}
								onRequestClose={closeModal}
								style={customStyles2}
								ariaHideApp={false}
								contentLabel="Example Modal"
							>
								<h3>
									<strong>Apply For Leave</strong>
								</h3>
								<div style={{ display: 'flex', flexDirection: 'row' }}>
									<div style={{ flex: 1 }}>
										<Row>
											<label>Total Leaves Allowed:</label>
										</Row>
										<Row>
											<label>Consumed Leaves:</label>
										</Row>
										<Row>
											<label>Leaves Left:</label>
											<strong></strong>
										</Row>
									</div>
									<div style={{ flex: 1.8 }}>
										<Row>
											<label>
												<strong>{employeData.total_leaves}</strong>
											</label>
										</Row>
										<Row>
											<label>
												<strong>{employeData.used_leaves}</strong>
											</label>
										</Row>
										<Row>
											<label>
												<strong>
													{employeData.total_leaves - employeData.used_leaves}
												</strong>
											</label>
										</Row>
									</div>
								</div>
								<form
									onSubmit={handleSubmit(onSubmit)}
									style={{ marginTop: '10px' }}
								>
									<Row>
										<label>
											From Date:<span style={{ color: 'red' }}>*</span>
										</label>
										<input
											type="date"
											className="form-control underline-input"
											{...register('from_date', { required: true })}
										/>
										{errors.from_date && (
											<span className="errormessage">
												From Date is required
											</span>
										)}
									</Row>
									<Row>
										<label>
											To Date:<span style={{ color: 'red' }}>*</span>
										</label>
										<input
											type="date"
											className="form-control underline-input"
											{...register('to_date', { required: true })}
										/>
										{errors.to_date && (
											<span className="errormessage">To Date is required</span>
										)}
									</Row>
									<Row>
										<label>Description:</label>
										<textarea
											type="text"
											className="form-control underline-input"
											rows={3}
											{...register('description', { required: false })}
										/>
										{/* {errors.description && <span className="errormessage">Description is required</span>} */}
									</Row>
									<Row>
										<Col md={12}>
											<Row className="justify-content-end mt-2">
												<Button
													color="secondary"
													onClick={() => setCreate(false)}
												>
													Cancel
												</Button>
												&nbsp;&nbsp;
												<Button type="submit" color="primary">
													Submit
												</Button>
											</Row>
										</Col>
									</Row>
								</form>
							</Modal>
						</Row>
						<div className="newlisting_headertabdesign">
							<Row>
								<Col md={6}>
									<Row>
										<form className="master">
											<div style={{ marginTop: '-5px' }}>
												<label className="area_rowslable">Limit</label>
												<br />
												{/* <FormControl className={classes.formControl}> */}
												<FormControl
													variant="standard"
													sx={{
														m: 1,
														minWidth: 80,
														marginTop: 0,
														maxHeight: 80,
													}}
												>
													<select
														className={classes.selectlimit}
														labelId="simple-select-label"
														id="simple-select"
														value={rows}
														onChange={handleRows}
													>
														<option value={20}>20</option>
														<option value={50}>50</option>
														<option value={100}>100</option>
													</select>
												</FormControl>
											</div>
										</form>
										<form className="form-group">
											<div
												style={{
													display: 'flex',
													justify: 'space-between',
													flexWrap: 'wrap',
												}}
											>
												<div style={{ marginTop: '-20px' }}>
													<label className="area_rowslable">Date</label>
													<DateRangePicker
														onApply={(event, picker) =>
															handleApply(event, picker, 1)
														}
													>
														<p className="datepickerrangevaluetext">
															{dateValue}
														</p>
													</DateRangePicker>
													{dateStatus ? (
														<div style={{ float: 'left' }}>
															<a
																onClick={() => {
																	getAllLeaveList(1);
																	setDateValue('Click to open');
																	setDateStatus(false);
																}}
															>
																Clear Filter
															</a>
														</div>
													) : null}
												</div>
											</div>
										</form>
									</Row>
									<br />
								</Col>
								<Col md={6}>
									<div className="form-group">
										<div>
											<div className="clearsearchclass">
												<form
													id="myForm"
													className="d-flex"
													onSubmit={(e) => {
														e.preventDefault();
														submitSearch(e);
													}}
												>
													<input
														style={{
															padding: '5px',
															border: '2px solid',
															marginTop: '-1px',
														}}
														className="form-control"
														placeholder="Search.."
														name="search"
														required
													/>
													<button className={classes.srchbtn} type="submit">
														<i className="fa fa-search"></i>
													</button>
												</form>
												{searchStatus ? (
													<a
														className={classes.clrsrch}
														onClick={() => {
															getAllLeaveList(1);
															setSearchStatus(false);
														}}
													>
														Clear Search
													</a>
												) : null}
											</div>
										</div>
									</div>
								</Col>
							</Row>
						</div>
						<br />
						<div className="newlisting_headertabdesign">
							<Table responsive striped>
								<thead>
									<tr>
										<th style={{ width: '50px' }}>SN</th>
										<th style={{ width: '175px' }}>
											Employee Name <UnfoldMoreIcon id="emp_name" />
										</th>
										<th style={{ width: '150px' }}>
											From Date <UnfoldMoreIcon id="from_date" />
										</th>
										<th style={{ width: '150px' }}>
											To Date <UnfoldMoreIcon id="to_date" />
										</th>
										<th style={{ width: '175px' }}>
											Type Of Leave <UnfoldMoreIcon id="type" />
										</th>
										<th style={{ width: '175px' }}>
											Approved By <UnfoldMoreIcon id="approved_by" />
										</th>
										<th style={{ width: '175px' }}>
											Applied On <UnfoldMoreIcon id="created_date" />
										</th>
										<th style={{ width: '150px' }}>
											Status <UnfoldMoreIcon id="status" />
										</th>
										<th style={{ width: '175px' }}>
											Reason <UnfoldMoreIcon id="status" />
										</th>
										{employeData.emp_role_id == 1 ||
										employeData.emp_role_id == 2 ||
										employeData.emp_role_id == 3 ? (
											<th style={{ width: '20px' }}>Action</th>
										) : null}
									</tr>
								</thead>
								<tbody>
									{leaveData != null ? (
										leaveData &&
										leaveData.map((leave, index) => (
											<tr>
												<td>{countNumber * rows + 1 + index}</td>
												<td>{leave.emp_name}</td>
												<td>
													{leave.from_date
														? moment(leave.from_date.split('.')[0]).format(
																'DD MMM YYYY'
														  )
														: null}
												</td>
												<td>
													{leave.to_date
														? moment(leave.to_date.split('.')[0]).format(
																'DD MMM YYYY'
														  )
														: null}
												</td>
												<td>{leave.type}</td>
												<td>{leave.approved_by}</td>
												<td>
													{leave.created_date
														? moment(leave.created_date.split('T')[0]).format(
																'DD MMM YYYY'
														  )
														: null}
													<br />
													{leave.created_date?.split('T')[1].split('Z')[0]}
												</td>
												<td>
													{leave.status == 0 ? 'Declined' : null}
													{leave.status == 1 ? 'Approved' : null}
													{leave.status == 2 ? 'Pending' : null}
												</td>
												<td className="leaveEllipsis">
													{leave.description}
													{leave.description != null ? (
														<span
															className="leaveEllipsisspan"
															onClick={() => {
																setDescModelOpen(true);
																setDescContent(leave.description);
															}}
														>
															View More
														</span>
													) : null}
													<Modal
														ariaHideApp={false}
														isOpen={descModelOpen}
														onRequestClose={() => setDescModelOpen(false)}
														style={customStyles2}
														contentLabel="Example Modal"
													>
														<h4>Reason</h4>
														{descContent ? (
															<textarea
																style={{ minWidth: '600px' }}
																className="form-control"
																value={descContent}
																rows={10}
																readonly
															/>
														) : (
															<p> No Record Available</p>
														)}
														<div
															style={{
																display: 'flex',
																margin: '10px 0',
																justifyContent: 'flex-end',
															}}
														>
															<Button
																color="secondary"
																onClick={() => setDescModelOpen(false)}
															>
																Close
															</Button>
														</div>
													</Modal>
												</td>
												{employeData.emp_role_id == 1 ||
												employeData.emp_role_id == 2 ||
												employeData.emp_role_id == 3 ? (
													<td>
														<div
															style={{
																position: 'relative',
																textAlign: 'center',
															}}
														>
															<div
																className="workflowdashboard_fa-ellipsis"
																data-toggle="dropdown"
															>
																<i
																	className="fa fa-ellipsis-v"
																	aria-hidden="true"
																></i>
															</div>
															<div
																className="dropdown-menu workflowdashboarddropdown"
																style={{ width: '200px' }}
															>
																{permissionObj?.includes(38) ? (
																	<p>
																		<a
																			color="info"
																			onClick={(e) => {
																				e.preventDefault();
																				updateApproveLeave(e, leave);
																			}}
																		>
																			Approve
																		</a>
																	</p>
																) : null}
																{permissionObj?.includes(39) ? (
																	<p>
																		<a
																			color="info"
																			onClick={(e) => {
																				e.preventDefault();
																				updateDeclineLeave(e, leave);
																				setDeclineData(leave.description);
																			}}
																		>
																			Decline
																		</a>
																	</p>
																) : null}
																<Modal
																	ariaHideApp={false}
																	isOpen={leaveApproveOpen}
																	onRequestClose={closeModal}
																	style={customStyles2}
																	contentLabel="Example Modal"
																>
																	<h5>Approve Leave Application ?</h5>
																	<div style={{ float: 'right' }}>
																		<Button
																			color="success"
																			type="submit"
																			onClick={approveLeave}
																		>
																			Approve
																		</Button>
																		&nbsp;&nbsp;&nbsp;
																		<Button
																			color="secondary"
																			onClick={() => setApproveLeave(false)}
																		>
																			Close
																		</Button>
																	</div>
																</Modal>
																<Modal
																	ariaHideApp={false}
																	isOpen={leaveDeclineOpen}
																	onRequestClose={closeModal}
																	style={customStyles2}
																	contentLabel="Example Modal"
																>
																	<h5>Decline Leave Application</h5>
																	<textarea
																		style={{ minWidth: '600px' }}
																		className="form-control"
																		onChange={(e) => {
																			setDeclineNewData(e.target.value);
																		}}
																		rows={5}
																	/>
																	<br />
																	<div style={{ float: 'right' }}>
																		<Button
																			color="secondary"
																			onClick={() => setDeclineLeave(false)}
																		>
																			Close
																		</Button>
																		&nbsp;&nbsp;&nbsp;
																		<Button
																			color="danger"
																			type="submit"
																			onClick={declineLeave}
																		>
																			Decline
																		</Button>
																	</div>
																</Modal>
															</div>
														</div>
													</td>
												) : null}
											</tr>
										))
									) : (
										<p className={classes.headertekst}>{null}</p>
									)}
								</tbody>
							</Table>
						</div>
						<Row>
							<Col md={6}>
								{leaveData?.length ? (
									<p style={{ marginTop: '15px' }}>
										{countNumber * rows + 1} to{' '}
										{countNumber * rows + numberOfElements} of {totalCount}{' '}
										records
									</p>
								) : (
									<p className="formfooterrecordstyle">No Record Found</p>
								)}
							</Col>
							<Col md={6}>
								<div className={classes.root}>
									{/* {searchStatus || dateStatus ? (
                  <Pagination
                    page={paginationPageNo}
                    count={getPageNo}
                    onChange={handlesearch}
                  />
                ) : ( */}
									<Pagination
										page={paginationPageNo}
										count={getPageNo}
										onChange={handlePage}
									/>
									{/* )} */}
								</div>
							</Col>
						</Row>
					</div>
				</div>
			</div>
		</>
	);
};

export default LeaveApplication;
