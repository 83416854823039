import React , { useState, useEffect }from 'react';
import moment from "moment";
import { Link, useNavigate ,useParams} from "react-router-dom";
import { CardText, Col, Row, Button, Table } from "reactstrap";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Pagination from "@mui/material/Pagination";
import DateRangePicker from "react-bootstrap-daterangepicker";
import 'bootstrap-daterangepicker/daterangepicker.css';
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import axios from "axios";
import base_url from '../../../api/bootapi';
import { customStyles, useStyles, customStyles2 } from "./../../../Utilities/CSSUtilities";
import { useDispatch, useSelector } from "react-redux";
import { logout, selectEmployee } from "../../../components/features/empSlice";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useToasts } from 'react-toast-notifications';
import { toast } from "react-toastify";
import holidayicon from "../../../components/images/event.png";
import close from "../../../components/images/closeicon.png";
import editicon from "../../../components/images/edit.png";

const Department = () => {
  const classes = useStyles();
  const params = useParams();
  //console.log(params); 
  const jwtToken = "JWT " + localStorage.getItem("JWTToken");
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [department, setDepartment] = useState([]);
  const [modalCreate, setCreate] = React.useState(false);
  const navigate =useNavigate();
  const [searchdata, setsearch] = useState();
  const [datevalue, setdatevalue] = useState("Click to open");
  const [datefilter, setdatefilter] = useState();
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [inputtype, setinputtype] = useState("password");
  const employeeData = useSelector(selectEmployee);
  const [pageNo, setPageNo] = useState(1);
  const [paginationPageNo, setPaginationPageNo] = useState(1);
  const [getPageNo, setGetPageNo] = useState();
  const [rows, setRows] = React.useState(50);
  const [sortState, setsortState] = useState("name");
  const [direction, setdirection] = useState("Desc");
  const [totalcount, setTotalCount] = useState();
  const [countNumber, setCountNumber] = useState();
  const [numberOfElements, setNumberOfElements] = useState();
  const [selectedDelete, setSelectedDelete] = useState();
  const [datesrange, setdatesrange] = useState();
  const [display1, setdisplay] = useState("inline");
  const [permissionObj, setPermissionObj] = useState();

  const { addToast } = useToasts();

  const createDepartment = (data2) => {
    setBackdropOpen(true);
    let department_name  = data2.department_name;
    let status = false;
  
    let data = {
      department_name,
      status,
    };
    axios 
      .post(`${base_url.api1}/myappapi/department?company_code=` + employeeData.company_code, data, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          getAllDepartmentList(pageNo);
          setCreate(false);
          
        },
        (error) => {
          console.log(error);
          setBackdropOpen(false);
          setCreate(false);
        }
      );
  };

  useEffect( () => {
    getPermissions();
  },[rows]);
  
  const handleRows = (event) => {
    setRows(event.target.value);
  };
  
    
  const getPermissions = () => {
    const resource_obj = [];

    for (let i = 0; i < employeeData.role_permissions.length; i++) {
      resource_obj.push(employeeData.role_permissions[i].resource_id);
    }
    setPermissionObj(resource_obj);
    var available_resource69 = resource_obj.includes(69);
    var available_resource70 = resource_obj.includes(70);
    var available_resource71 = resource_obj.includes(71);
    var available_resource72 = resource_obj.includes(72);

    if(available_resource69 && (available_resource70 || available_resource71 || available_resource72)) {
      if (localStorage.getItem("DepartmentPageNo")) {
        let currentPage = localStorage.getItem("DepartmentPageNo");
        setPageNo(currentPage);
        setPaginationPageNo(Number(currentPage));
        getAllDepartmentList(currentPage);
      } else {
        setPageNo(1);
        setPaginationPageNo(1);
        getAllDepartmentList(1);
      }
    } else {
      navigate('/errorPage');
    }
  };

  const [editOpen, setEdit] = React.useState(false);
  const [statusId, setStatusId] = useState();
  const [departmentName, setDepartmentName] = useState();
  const editDepartment = (elem,depart) => {
    console.log(depart);
    setStatusId(depart.id);
    setDepartmentName(depart.department_name);
    setEdit(true);
  };
  const editOldCategory= (data2) =>{
        setBackdropOpen(true);
        let data = {
            department_name: departmentName.trim(),
        };
        axios
          .put(`${base_url.api1}/myappapi/department?company_code=`+ employeeData.company_code + `&id=` + statusId  , data, {
            headers: {
              Authorization: jwtToken,
            },
          })
          .then((response) => {
            setBackdropOpen(false);
            setEdit(false);
            getAllDepartmentList(pageNo);
          })
          .catch((error) => {
            setBackdropOpen(false);
          });
      }

  const handleEvent = (event, picker) => {
    console.log(picker.startDate);
  };
    
  const handleCallback = (start, end, label) => {
    console.log(start, end, label);
  };

  const getAllDepartmentList = (value1) => {
    console.log(value1);
    setBackdropOpen(true);
    axios
      .get(`${base_url.api1}/myappapi/department/list?company_code=` + employeeData.company_code +`&page=`+ value1 +`&size=`+ rows, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setDepartment(response.data.Department);
          setGetPageNo(response.data.total_pages);
          setTotalCount(response.data.total_count);
          setCountNumber(value1-1);
          setNumberOfElements(response.data.Department.length);
          setSelectedDelete([]);
          setsearch(false);
          setBackdropOpen(false);
        },
        (error) => {
          console.log(JSON.stringify(error.response.data));
          setBackdropOpen(false);
          setDepartment();
        }
      );
  };

  const closedModal = () => {
    setDelete(false);
  }
  const closeModal = () => {
    setCreate(false);
  }

  const createModalConfig = () => {
    setCreate(true);
  };

  const DeleteDepart = () => {
    setDelete(true);
  };

  const [DeleteId, setDeleteId] = useState();
  const [deleteOpen, setDelete] = React.useState(false);
  const deleteDepartment = (elem,depart) => {
    console.log(depart);
    setDeleteId(depart.id);
  };
  const deleteCurrentCategory = () => {
    axios
      .delete(`${base_url.api1}/myappapi/department?company_code=` + employeeData.company_code +`&id=` + DeleteId  , {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setBackdropOpen(false);
          setDelete(false);
          getAllDepartmentList(pageNo);
        },
        (error) => {
          setBackdropOpen(false);
          console.log(JSON.stringify(error.response.data));
        }
      );
  };

  const handlepage = (event, value) => {
    setPaginationPageNo(value);
    setPageNo(value);
    if(searchinput!='' || searchinput !=null || searchinput != undefined){
      submitSearchpage(value);
    }else{
      getAllDepartmentList(value);
    }
    console.log(value);
    
  };


  const [searchinput, setsearchinput] = useState();
  const submitSearch = (e, value) => {
     setdisplay("none");
    setsearch(true);
    let srchdata = e.target[0].value.trim();
    setsearchinput(srchdata);
    console.log(srchdata);
    axios
      .get(`${base_url.api1}/myappapi/department/all?company_code=`+ employeeData.company_code+ `&page=`+ value +`&size=` + rows +`&search=`+srchdata, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setDepartment(response.data.Department);
          console.log(response.data.Department);
          setGetPageNo(response.data.total_pages);
          setNumberOfElements(response.data.Department.length);
          setTotalCount(response.data.total_count);
        },
        (error) => {
          console.log(JSON.stringify(error.response.data));
          setDepartment();
        },
        (error) => {
          console.log(JSON.stringify(error.response.data));
        }
      );
  };

  const submitSearchpage = ( value) => {
    setdisplay("none");
   setsearch(true);
   setBackdropOpen(true);
   axios
     .get(`${base_url.api1}/myappapi/department/all?company_code=`+ employeeData.company_code+ `&page=`+ value +`&size=` + rows +`&search=`+searchinput, {
       headers: {
         Authorization: jwtToken,
       },
     })
     .then(
       (response) => {
         setDepartment(response.data.Department);
         console.log(response.data.Department);
         setGetPageNo(response.data.total_pages);
         setNumberOfElements(response.data.Department.length);
         setTotalCount(response.data.total_count);
         setBackdropOpen(false);
       },
       (error) => {
         console.log(JSON.stringify(error.response.data));
         setDepartment();
         setBackdropOpen(false);
       },
       (error) => {
         console.log(JSON.stringify(error.response.data));
       }
     );
 };

 const sortUser = (e) => {
  setsortState(e.target.id);
  console.log();
  if (direction == "Desc") {
    setdirection("ASC");
  } else if (direction == "ASC") {
    setdirection("Desc");
  }
  let sortby = e.target.id

  axios
    .get(`${base_url.api1}/myappapi/department/all?company_code=` + employeeData.company_code + `&sortby=`+sortby+`&Order=`+direction +`&page=`+pageNo, {
      headers: {
        Authorization: jwtToken,
      },
    })
    .then(
      (response) => {
        setDepartment(response.data.Department);
        document.getElementById("myForm").reset();
        setGetPageNo(response.data.total_pages);
        //console.log(response.data.total_pages);
        setTotalCount(response.data.total_count);
        setCountNumber(pageNo-1);
        setNumberOfElements(response.data.Users.length);
        //console.log(response.data.Users.length);
        setSelectedDelete([]);
        setsearch(false);
        setBackdropOpen(false);
      },
      (error) => {
        console.log(JSON.stringify(error.response.data));
        setDepartment();
      }
    );
};


  
  return (
    <div>
      <Backdrop open={backdropOpen}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="commoncomponent_cardcss">
        <div style={{ margin: "5px",marginTop: "-30px" }}>
          <Row>
            {/* <Col md={6}>
              <CardText
                className={classes.headtext}
                style={{ marginTop: "-10px" }}
              >
                <h2>Department List</h2>
              </CardText>
            </Col>
            <Col md={6}  className="d-flex justify-content-end">
              {permissionObj?.includes(70)?
                <Button
                  color="primary"
                  onClick={createModalConfig}
                >
                  + Add
                </Button>
              :null}
            </Col> */}
             {permissionObj?.includes(70)?
             <div className="newhead">
              <h4>Department List</h4>
              <div onClick={createModalConfig}>
                <h6>New</h6> <p>+</p>
              </div>
            </div> :null}

            <Modal
              ariaHideApp={false} 
              isOpen={modalCreate}
              onRequestClose={closedModal}
              style={customStyles}
              contentLabel="Example Modal"
            > <div style={{ textAlign: "right", padding: "10px 20px" }}>
            <img className="closei" src={close} onClick={() =>setCreate(false)} />
          </div>
          <div className='modalContainer'>
            <div style={{ textAlign: "center" }}>
              <img className='modelIcon' src={holidayicon} />
            </div>
            <form onSubmit={handleSubmit(createDepartment)}>
               <h1>Add Department</h1>
                <div>
                  <div className="form-group creatadmin">
                    <Row>
                        <label>
                          Department Name<span style={{ color: "red" }}>*</span>:
                        </label>
                        <input
                          type="text"
                          className="form-control underline-input"
                          placeholder="Department Name"
                          id="department"
                          {...register("department_name",{ required: true })}
                        />
                        {errors.department_name && (
                          <p className="errormessage">Department Name is Required</p>
                        )}
                    </Row>
                    <br/>
                    <Row>
                      <Col md={12}>
                        <Row className="justify-content-end mt-2">
                          <Button
                            color="secondary"
                            onClick={() => setCreate(false)}
                          >
                            Cancel
                          </Button>
                          <Button
                            color='primary'
                            type="submit"
                            className="btn listing_addbutton ml-1"
                          >
                            Submit
                          </Button>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </div>
              </form>
            </div>
            </Modal>
          </Row>
          <div className="newlisting_headertabdesign">
          <Row>
            <form className="master">
                  <div style={{ marginTop: "15px" }}>
                    <label className="area_rowslable">Limit</label>
                    <br />
                    <FormControl className={classes.formControl}>
                      <Select
                        className={classes.selectlimit}
                        labelId="simple-select-label"
                        id="simple-select"
                        value={rows}
                        onChange={handleRows}
                      >
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </form>
                <br/>
            </Row>
            <br/>
          </div>
          <div className="newlisting_headertabdesign">
            <Table responsive striped>
            <thead>
                <tr>
                  <th>SNo.</th>
                  <th style={{ textAlign: "center" }}>
                    Department Name{" "}
                    <UnfoldMoreIcon onClick={sortUser} id="department_name"/>
                  </th>
                  <th style={{ textAlign: "center", width: "175px" }}>Action</th>
                </tr>
              </thead>              
              <tbody>
                {department != null ? (
                  department &&
                  department.map((depart , index) => (
                  <tr>
                    <th>{countNumber * rows + 1 + index}</th>
                    <td style={{ textAlign: "center" }}>{depart.department_name}</td> 
                    <td>
                      <div style={{
                          position: "relative",
                          textAlign: "center",
                        }} 
                      >
                        <div
                          className="workflowdashboard_fa-ellipsis"
                          data-toggle="dropdown"
                        >
                          <i
                            className="fa fa-ellipsis-v "
                            aria-hidden="true"
                          ></i>
                        </div>
                        <div
                          className="dropdown-menu workflowdashboarddropdown"
                          style={{ width: "200px" }}
                        >
                          {permissionObj?.includes(71)?
                            <p id={depart.id}
                              onClick={(e)=>{
                                e.preventDefault();
                                editDepartment(e, depart)}}>
                              Edit
                            </p>
                          :null}
                          <Modal
                            isOpen={editOpen}
                            onRequestClose={closeModal}
                            style={customStyles}
                            ariaHideApp={false}
                            contentLabel="Example Modal"
                          >
                             <div style={{ textAlign: "right", padding: "10px 20px" }}>
                              <img className="closei" src={close} onClick={() => setEdit(false)} />
                            </div>
                            <div className='modalContainer'>
                              <div style={{ textAlign: "center" }}>
                                <img className='modelIcon' src={editicon} />
                              </div>
                            <h2>
                              <strong>Edit Department Name</strong>
                            </h2>
                            <form id="editForm" onSubmit={(e) => {
                                e.preventDefault();
                                editOldCategory(e);
                              }}>
                              <div>
                                <div className="form-group creatadmin">
                                  <Row>
                                    <Col md={12}>
                                      <label>Department Name<span style={{ color: "red" }}>*</span>:</label>
                                      <input
                                        type="text"
                                        className="form-control underline-input"
                                        value={departmentName}
                                        onChange={(e) => {
                                          setDepartmentName(e.target.value);
                                        }}
                                        required
                                      />
                                    </Col>
                                  </Row>
                                  <br/>
                                </div>
                                {/* <Row style={{ float: "right" }}>
                                  <Button
                                    color="secondary"
                                    style={{
                                      marginTop: "20px",
                                      marginRight: "10px",
                                    }}
                                    onClick={() => setEdit(false)}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    color="primary"
                                    type="submit"
                                    style={{ marginTop: "20px" }}
                                  >
                                    Submit
                                  </Button>
                                </Row> */}
                                 <div style={{ display: "flex", justifyContent: "space-between", margin: "30px 0px" }}>
                                <button className='modalbtn backbtn' onClick={() => setEdit(false)}>Back</button>
                                <button className='modalbtn submitbtn' >Submit</button>
                              </div>
                              </div>
                            </form>
                            </div>
                          </Modal>
                          {permissionObj?.includes(72)?<>
                            {depart.id != "1" && depart.id != "2" && depart.id != "3" && depart.id != "4" && depart.id != "42" ? (
                              <p
                                id={depart.id}
                                onClick={(e) => {
                                  deleteDepartment(e, depart);
                                  DeleteDepart();
                                }} color="danger">Delete
                              </p>
                            ):null}
                          </>:null}
                          <Modal
                            ariaHideApp={false}
                            isOpen={deleteOpen}
                            onRequestClose={closedModal}
                            style={customStyles2}
                            contentLabel="Example Modal"
                          >
                            <h5>
                              Are you sure you want to delete current record ?
                            </h5>
                            <div style={{ float: "right" }}>
                              <Button
                                color="danger"
                                type="submit"
                                onClick={deleteCurrentCategory}
                              >
                                Delete
                              </Button>
                              &nbsp;&nbsp;&nbsp;
                              <Button
                                color="secondary"
                                onClick={() => setDelete(false)}
                              >
                                Cancel
                              </Button>
                            </div>
                          </Modal> 
                        </div>
                      </div>
                    </td>
                  </tr>
                ))) : (
                  <p className={classes.headertekst}>{null}</p>
                )}
              </tbody>
            </Table>
            <div>
            <Row>
            <Col md={6}>
              {department?.length ? (
                <p style={{ marginTop: "15px" }}>
                  {countNumber * rows + 1} to{" "}
                  {countNumber * rows + numberOfElements} of {totalcount} records
                </p>
              ) : (
                <p className="formfooterrecordstyle">No Record Found</p>
              )}
            </Col>
            <Col md={6}>
              <div className={classes.root}>
                {/* {searchdata || datefilter ? (
                  <Pagination
                    page={paginationPageNo}
                    count={getPageNo}
                    onChange={submitSearch}
                  />
                ) : ( */}
                  <Pagination
                    page={paginationPageNo}
                    count={getPageNo}
                    onChange={handlepage}
                  />
                {/* )} */}
              </div>
            </Col>
          </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Department