import React, { useState } from "react";
import { withRouter, useParams, useNavigate} from "react-router-dom";
import axios from "axios";
import "./../css/Password.css";
import base_url from '../../api/bootapi';
import { ToastContainer, toast } from "react-toastify";
import Swal from 'sweetalert2';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

function ForgotPassword() {
  let params = useParams();
  console.log(params);
  const navigate = useNavigate();
  const [details, setDetails] = useState({ email: "", new_password: "" });
  const [inputType, setInputType] = useState("password");
  const [backdropOpen, setBackdropOpen] = useState(false);

  const forgotPassword = (e) => {
    console.log(e.target[0].value);
    console.log(e.target[1].value);
    let data={
      email: e.target[0].value.trim(),
      new_password: e.target[1].value.trim(),
    }
    console.log(data);
    setBackdropOpen(true);
    axios
      .post(`${base_url.api1}/myappapi/users/changepassword?company_code=OC_Z2CpoOMpmrRSeTfk`,data, {
        headers: {
            Authorization: "JWT "+params.token.trim(),
            },
      })
      .then((response) => {
        // toast.success("Password Reset Successful");
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Password Reset Successfully',
          showConfirmButton: false,
          timer: 1500
        })
        setBackdropOpen(false);
        navigate(`/`);
      })
      .catch((error) => {
        // toast.error("Token Error");
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Token Error',
          showConfirmButton: false,
          timer: 1500
        })
        setBackdropOpen(false);
      });
  };

  return (
    <div>
      <Backdrop open={backdropOpen}
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
  >
    <CircularProgress color="inherit" />
  </Backdrop>
    <div className="loginform">
      <ToastContainer />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          forgotPassword(e);
        }}
      >
        <div className="form-inner forgot_form_width">
          <h2 className="forgot_form_head">Forgot Password</h2>
          <div className="form-group">
            <label>Username</label>
            <input
              type="text"
              className="form-control underline-input forminput2"
              value={params.email}
              readOnly
            />
          </div>
          <div className="form-group mt-10">
            <label>New Password</label>
            <div style={{ position: "relative" }}>
              <input
                type={inputType}
                className="form-control underline-input forminput2"
                name="new_password"
                id="new_password"
                placeholder="Password"
                onChange={(e) =>
                  setDetails({ ...details, new_password: e.target.value })
                }
              />
              {inputType === "password" ? (
                <i
                  className="fa fa-eye-slash fonticonpositioning"
                  onClick={() => setInputType("text")}
                  aria-hidden="true"
                ></i>
              ) : (
                <i
                  className="fa fa-eye fonticonpositioning"
                  onClick={() => setInputType("password")}
                  aria-hidden="true"
                ></i>
              )}
            </div>
          </div>
          <div className="form-group text-center mt-40">
            <input
              type="submit"
              className="btn btn-full btn-dutch b-0 br-5 text-uppercase login_access"
              value="Submit"
            />
          </div>
        </div>
      </form>
      {/* </div> */}
    </div>
    </div>
  );
}

export default ForgotPassword;
