
import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  Col,
  Row,
  Button,
  FormGroup,
  Input
  } from "reactstrap";
  import Select from "react-select";
  import { useForm } from "react-hook-form";
  import base_url from '../../api/bootapi';
  import { toast } from "react-toastify";
  import Backdrop from '@mui/material/Backdrop';
  import CircularProgress from "@mui/material/CircularProgress";
  import { Link, useNavigate,useParams } from "react-router-dom";
  import axios from "axios";
  import { useStyles } from "./../../Utilities/CSSUtilities";
  import { useDispatch, useSelector } from "react-redux";
  import { 
    selectStarted,
    selectStopped,
    selectEmployee,
    selectSessionId,
    logout,
   } from "../../components/features/empSlice";
   import CallAuditapi from "../services/auditservice";

const AddMyTask = (props) => {
const params = useParams();
const dispatch = useDispatch(); 
const jwtToken = "JWT " + localStorage.getItem("JWTToken");
const { register, handleSubmit, formState: { errors } } = useForm();
const [backdropOpen, setBackdropOpen] = useState(false);
const navigate = useNavigate();
const classes = useStyles();
const employeeData = useSelector(selectEmployee);
const formData = new FormData();
const user_name = localStorage.getItem("user_name");


useEffect( () => {
  getPermissions();
  window.addEventListener("storage", function (e) {
    if (e.key === null) {
      dispatch(logout());
      navigate("/");
    }
  });
},[]);

const getPermissions = () => {
  const resource_obj = [];
  for (let i = 0; i < employeeData.role_permissions.length; i++) {
    resource_obj.push(employeeData.role_permissions[i].resource_id);
  }
  var available_resource = resource_obj.includes(13);

  if(available_resource) {
    projectByidAll();
    getAllTaskStatusList();
  } else {
    navigate('/errorPage');
  }
};

const goback = () => {
  navigate('/my-tasks');
};  

const onSubmit = (data2) => {
  let doc = data2.doc;
  if (doc.length) {
    for (let i = 0; i < doc.length; i++) {
      formData.append(
        "doc",
        doc[i],
        doc[i].name,
      );
    }
  }
  if(projectState==undefined){
    setProjectState(true);
    return;
  }
  // console.log(projectData);
  // let task_description = data2.task_description.trim();
  let task_title =data2.task_title;
  let task_start_time = data2.task_start_time ?(data2.task_start_time):null;
  let task_end_time = data2.task_end_time ?(data2.task_end_time):null;
  let status = Number(selectedStatus);
  let priority = data2.priority;
  let assignee_id =employeeData.reporting_manager_id;
  let assignee_name = employeeData.reporting_manager_name;
  let company_code = employeeData.company_code;
  let emp_id =employeeData.emp_id;
  let emp_name = employeeData.emp_name.trim();
  let added_by = employeeData.emp_name.trim();
  let project_id = Number(projectData.value);
  let project_name = projectData.label;
  let task_type =data2.task_type;
  let task_log = assignee_name + " assigned task " + task_title + " to " + emp_name + "/n>>Added By "+added_by;


  let data = {
    emp_id,
    task_title,
    status,
    emp_name,
    assignee_id,
    assignee_name,
    task_log,
    project_id,
    task_start_time,
    task_end_time,
    task_description: data2.task_description ?(data2.task_description.trim()):null,
    priority,
    project_name ,
    added_by,
    task_type,


    };
     console.log(data);
     formData.append("data", JSON.stringify(data));
     postTask(formData);
  };

  const postTask = (data) => {
    setBackdropOpen(true);
    axios 
      .post(`${base_url.api1}/office_app/assign_task?company_code=`+ employeeData.company_code, data, {
        headers: {
                    Authorization: jwtToken,
                  },
       })
      .then(
        (response) => {
          console.log(data);
          let log = employeeData.reporting_manager_name+" created the task "+response.data.task_title +" for "+employeeData.emp_name ;
          CallAuditapi(log);
          setBackdropOpen(false);      
          navigate(`/my-tasks`);
        },
        (error) => {
          console.log(error);
          setBackdropOpen(false);
          toast.error("Task Not Created!");
        }
      );
  };

//   const [projectId , setprojectId] = useState();
//   const projectByid = () => {
//     setBackdropOpen(true);
//     axios
//       .get(`${base_url.api1}/office_app/project?company_code=` + employeeData.company_code + `&project_id=`+ params.project_id, {
//         headers: {
//         Authorization: jwtToken,
//         },
//       })
//       .then(
//         (response) => {
//           setBackdropOpen(false);
//           console.log(response.data.task);
//           setprojectId(response.data.project_info);
//           console.log(response.data.task);
//           //document.getElementById("myForm").reset();
//         },
//         (error) => {
//             setBackdropOpen(false);
//             console.log(JSON.stringify(error.response.data));
//         }
//       );
//   };

  const [availableProject, setAvailableProject] = useState([]);
  const [projectId, setProjectId] = useState([]);
  const projectByidAll = () => {
    setBackdropOpen(true);
    axios
      .get(`${base_url.api1}/office_app/project/list?company_code=` + employeeData.company_code, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then((response) => {
        console.log(response.data.Projects);
        setProjectId(response.data.id);
        let ofc_data = response.data.Projects;
        const office_obj = [];
        for (let i = 0; i < ofc_data.length; i++) {
          let data = {
            value: ofc_data[i].id,
            label: ofc_data[i].project_name,
          };
          office_obj.push(data);
        }
        setAvailableProject(office_obj);
        // setAvailableProjectById(response.data.Projects);
        setBackdropOpen(false);
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
        setBackdropOpen(false);
      });
  };

  const [selectedProjectId, setSelectedProjectId] = useState();
  const [selectedProjectName, setSelectedProjectName] = useState();
  const handleChangeProject = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    console.log(optionElementId);
    const optionElementValue = optionElement.getAttribute("value");
    console.log(optionElementValue); 
  

    setSelectedProjectId(optionElementId);
    setSelectedProjectName(optionElementValue);
  };

  const [projectState, setProjectState] = useState();
  const [projectData, setSelectedProject] = useState();
  const onSelectProject = (selectedList, selectedItem) => {
    console.log("Entering",selectedList);
    setSelectedProject(selectedList);
    setProjectState(false);
  };

  const [taskstatus, setTaskStatus] = useState([]);
  const getAllTaskStatusList = (value1) => {
    // console.log(value1);
    setBackdropOpen(true);
    axios
      .get(`${base_url.api1}/office_app/Task_status/all?company_code=` + employeeData.company_code, {
        headers: {
          Authorization: jwtToken,
        },
      })
      .then(
        (response) => {
          setTaskStatus(response.data.Status);
          setBackdropOpen(false);
        },
        (error) => {
          console.log(JSON.stringify(error.response.data));
          setBackdropOpen(false);
          setTaskStatus();
        }
      );
  };
  const looksup = taskstatus;
    // console.log(looksup);
  const [selectedstatus, setSelectedstatus] = useState(-1);
  const values = selectedstatus !== 0 && looksup[selectedstatus];
  const [selectedStatus, setSelectedStatus] = useState();
  const [defaultStatus, setdefaultStatus] = useState();
  const handleChangeStatus = (event) => {
    console.log(event);
    setdefaultStatus(event.target.value);
    console.log(event.target.value);
    const index = event.target.selectedIndex;
    console.log(index);
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    console.log(optionElementId);
    const optionElementValue = optionElement.getAttribute("value");
    console.log(optionElementValue); 

    setSelectedStatus(optionElementId);
    setdefaultStatus(optionElementValue);
  };

  // const [availableUserById, setAvailableUserById] = useState([]);
  // const [userId, setUserId] = useState([]);
  // const userByRoleidAll = () => {
  //   setBackdropOpen(true);
  //   axios
  //     .get(`${base_url.api1}/myappapi/all_users_by_role?company_code=` + employeeData.company_code +`&status=True` , {
  //       headers: {
  //         Authorization: jwtToken,
  //       },
  //     })
  //     .then((response) => {
  //       console.log(response.data.Users);
  //       setUserId(response.data.user_id);
  //       let emp_data = response.data.Users;
  //       const emp_obj = [];
  //       //  const emp_obj = [{
  //         //   value: null,
  //         //   label: "-Select Employee-",
  //         // }];
  //       for (let i = 0; i < emp_data.length; i++) {
  //         let data = {
  //           value: emp_data[i].user_id,
  //           label: emp_data[i].first_name +" "+emp_data[i].last_name,
  //         };
  //         emp_obj.push(data);
  //       }
  //       setAvailableUserById(emp_obj);
  //       // setAvailableUserById(response.data.Users);
  //       setBackdropOpen(false);
  //     })
  //     .catch((error) => {
  //       console.log(JSON.stringify(error));
  //       setBackdropOpen(false);
  //     });
  // };

  // const [userState, setUserState] = useState();
  // const [userData, setSelectedUser] = useState();
  // const onSelectUser = (selectedList, selectedItem) => {
  //   console.log("Entering",selectedList);
  //   setSelectedUser(selectedList);
  //   setUserState(false);  
  // };


  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Card className="commoncomponent_cardcss">
        <CardBody>
          <CardSubtitle className="font-weight-bold"></CardSubtitle>
          <div style={{ margin: "5px" }}>
            <Row>
              <Col md={6}>
                <br/>
                <CardText 
                  className={classes.headtext}
                  style={{ marginTop: "-10px" }}>
                  <strong>Create Your Task</strong>
                </CardText>
              </Col>
              <Col md={6}>
                <Button
                  color="primary"
                  style={{ float: "right" }}
                  onClick={goback}
                >
                  &#x2630;My Tasks
                </Button>
              </Col>
            </Row>
            <form id="myForm" onSubmit={handleSubmit(onSubmit)}>
              <div style={{ padding: "0 20px " }}>
                <div className="form-group creatadmin">
                  <Row>
                    <Col md={4}>
                      <label>Task Title:<span style={{ color: "red" }}>*</span></label>
                      <input
                        type="text"
                        {...register("task_title", { required: true })} 
                        id="task_title"
                        className="form-control underline-input"
                        placeholder="Task Title"
                      />
                      {errors.task_title && <p className="errormessage">Task title is Required</p>}
                    </Col> 
                    {/* <Col md={4}>
                       <label>Select Project:<span style={{ color: "red" }}>*</span></label>
                      <select
                        {...register('project_name',{required: true})}
                        className={classes.selectdrop}
                        // className="basic-multi-select"
                        // classNamePrefix="select"
                        // options={availableProjectById}
                        onChange={(e) => {
                            setgetSelected(e.target.value)
                            handleChangeProject(e)
                        }}
                      >
                        <option value="">-Select Project-</option>
                        {look.map((m, ix) => {
                          return <option key={m.id} id={m.id} value={m.project_name}>{m.project_name}</option>
                        })};
                      </select>
                      {errors.project_name && (
                         <p className="errormessage">project name is Required</p>
                        )}
                    </Col>                   */}
                    <Col md={4}>
                      <label>Select Project:</label>
                      <Select
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={onSelectProject}
                        options={availableProject}
                      >
                      </Select>
                      {projectState == true ? <p className="errormessage">project name is Required</p> :null}
                      </Col>
                      {/* <Col md={4}>
                      <label>Select Employee:</label>
                      <Select
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={onSelectUser}
                        options={availableUserById}
                      >
                      </Select>
                      {userState == true ? <p className="errormessage">Employee Name is Required</p> :null}
                      </Col> */}
                    <Col md={4}>
                         <label >
                           Priority<span style={{ color: "red" }}>*</span>:
                         </label>
                         <select className="form-control underline-input"
                         {...register("priority",{required: true})}
                        >
                          <option value="">-Select Priority-</option>
                         <option value="high">High</option>
                        <option value="moderate">Moderate</option>
                         <option value="low">Low</option>
                     </select>
                      {errors.priority && (
                         <p className="errormessage">priority is Required</p>
                      )}
                       </Col>  
                  </Row>
                </div>
                <div className="form-group creatadmin">
                  <Row>
                  <Col md={4}>
                       <label>
                         Start Date:<span style={{ color: "red" }}>*</span>
                      </label>
                       <input
                         type="datetime-local"
                         {...register('task_start_time')}
                         id="task_start_time"
                        className="form-control underline-input"
                         placeholder='Start Date'
                         />
                       {errors.task_start_time && (
                         <p className="errormessage">StartTime is Required</p>
                       )}
                     </Col>
                     <Col md={4}>
                       <label>
                         End Date:*
                       </label>
                       <input
                       type="datetime-local"
                       {...register("task_end_time")} 
                       id="task_end_time"
                       className="form-control underline-input"
                       placeholder='End Date'
                       />
                       {errors.task_end_time && (
                         <p className="errormessage">End Date is Required</p>
                       )}
                     </Col>  
                     <Col md={4}>
                        <label>Documents:</label>
                        <input
                          type="file"
                           multiple
                          {...register("doc", { required: false })} 
                          className="form-control underline-input"
                          accept=".jpg, .jpeg, .pdf, .doc, .docx, .xls, .png"
                        />
                     </Col>
                     </Row> 
                     <Row>
                     <Col md={4}>
                        <label>
                          Task Type:<span style={{ color: "red" }}>*</span>:
                        </label>
                        <select
                          className="form-control underline-input"
                          {...register("task_type", { required: true })}
                        >
                          <option value="">-Select Task Type-</option>
                          <option value="Task">Task</option>
                          <option value="Bug">Bug</option>
                        </select>
                          {errors.task_type && (
                            <p className="errormessage">Task Type is Required</p>
                          )}

                       </Col>             
                     <Col md={4}>
                         <label >
                           Status<span style={{ color: "red" }}>*</span>:
                         </label>
                         <select className="form-control underline-input"
                         {...register("status",{ required: true })}
                         onChange={(e) => {
                          setSelectedstatus(e.target.value)
                          handleChangeStatus(e)
                        }}
                         >
                         <option value="">-Select Status-</option>
                        {looksup.map((m, ix) => {
                          return <option key={m.id} id={m.id} value={m.name}>{m.name}</option>
                        })};
                       </select>
                       {errors.status && (
                         <p className="errormessage">status is Required</p>
                        )}
                     </Col>  
                  </Row>
                </div>
                <div className="form-group creatadmin">
                  <Row>
                  {/* <Col md={4}>
                      <label>Assigned Task Modules :<span style={{ color: "red" }}>*</span></label>
                      <textarea
                        type="text"
                        {...register("assigned_task_modules", { required: true })} 
                        id="assigned_task_modules"
                        className="form-control underline-input"
                        rows={3}
                      />
                      {errors.assigned_task_modules && <p className="errormessage">Task title is Required</p>}
                    </Col>  */}
                  <Col md={12}>
                 <label>Description:</label>
                   <textarea
                     type="text"
                     id="task_description"
                     className="form-control underline-input"
                     rows={10}
                     {...register("task_description",{ required: false })}
                   />
                       {errors.task_description && <p className="errormessage">Task Description is Required</p>}
                   </Col>
                  </Row>
                <div
                  style={{
                    float: "right",
                    display: "flex",
                    padding: "5px",
                  }}
                >
                  {/* <Button
                    color="secondary"
                    style={{
                      marginTop: "20px",
                      marginLeft: "5px",
                      float: "right",
                    }}
                    onClick={() => {
                      document.getElementById("myForm").reset();
                }}
                  >
                    Reset
                  </Button> */}
                  <Button
                    color="primary"
                    type="submit"
                    style={{
                      marginTop: "20px",
                      marginLeft: "5px",
                      float: "right",
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </div>
              </div>
            </form>
          </div>
        </CardBody>
      </Card>
      </div>
  )
}

export default AddMyTask
