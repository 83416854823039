import axios from "axios";
import base_url from '../../api/bootapi';

const CallAuditapi = (log) =>{
    const jwtToken = "JWT " + localStorage.getItem("JWTToken");
    const IpAddress = localStorage.getItem("EmpIpAddress");
    const EmpName = localStorage.getItem("EmpName");
    const EmpId = localStorage.getItem("EmpId");
    const Company_Id = localStorage.getItem("Company_Id");
    const Company_Code = localStorage.getItem("Company_Code");

    let auditData ={
        ip:IpAddress,
        emp_id:EmpId,
        emp_name:EmpName,
        company_id:Company_Id,
        log,
    }
    console.log(auditData);
    axios.post(`${base_url.api1}/myappapi/audit/create?company_code=`+Company_Code,auditData,{
    headers: {
        Authorization : jwtToken,
        }
    });
}
export default CallAuditapi;