
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { Provider } from 'react-redux'
import { store, persistor } from './Store/store'
import { PersistGate } from "redux-persist/integration/react";


ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Routes>
            <Route path='*' element={<App />} />
          </Routes>
        </PersistGate>
      </Provider>
    </Router>,
  </React.StrictMode>,
  document.getElementById("root")
);
