import React, { useState, useEffect, useRef } from 'react';
import {
	Card,
	CardBody,
	CardSubtitle,
	CardText,
	Col,
	Row,
	Button,
	FormGroup,
	Input,
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import base_url from '../../api/bootapi';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Select from 'react-select';
import PasswordStrengthBar from 'react-password-strength-bar';
import { useStyles } from './../../Utilities/CSSUtilities';
import { useDispatch, useSelector } from 'react-redux';
import {
	setStarted,
	selectStarted,
	setStopped,
	selectStopped,
	logout,
	selectEmployee,
	setSessionIdData,
	selectSessionId,
} from '../../components/features/empSlice';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import CallAuditapi from '../services/auditservice';

const CreateEmployee = (props) => {
	const { addToast } = useToasts();
	const jwtToken = 'JWT ' + localStorage.getItem('JWTToken');
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();
	const [backdropOpen, setBackdropOpen] = useState(false);
	const [chkPassState, setchkPassState] = useState();
	const [chkPass, setchkPass] = useState();
	const [inputtype, setinputtype] = useState('password');
	const navigate = useNavigate();
	const classes = useStyles();
	const dispatch = useDispatch();
	const employeeData = useSelector(selectEmployee);
	const formData = new FormData();
	const newFormData = new FormData();
	const [permissionObj, setPermissionObj] = useState();
	const [userData, setUserData] = useState();

	useEffect(() => {
		getPermissions();
		window.addEventListener('storage', function (e) {
			if (e.key === null) {
				dispatch(logout());
				navigate('/');
			}
		});
		return () => {
			setAvailableroles();
			setManager();
			setDepartmentData();
		};
	}, []);

	const getPermissions = () => {
		const resource_obj = [];
		for (let i = 0; i < employeeData.role_permissions.length; i++) {
			resource_obj.push(employeeData.role_permissions[i].resource_id);
		}
		setPermissionObj(resource_obj);
		var available_resource = resource_obj.includes(2);

		if (available_resource) {
			getRoles();
			getManagerData();
			getDeparmentData();
			getDesignationData();
		} else {
			navigate('/errorPage');
		}
	};

	const goback = () => {
		navigate('/employee-list');
	};
	const onSubmit = (data2) => {
		let primary_image = data2.image.item(0);
		if (primary_image) {
			formData.append('image', primary_image, primary_image.name);
		}
		let first_name = data2.first_name;
		let last_name = data2.last_name;
		let password = data2.password;
		let email = data2.email;
		let phone = data2.phone;
		let date_of_birth = data2.date_of_birth ? data2.date_of_birth : null;
		let gender = data2.gender;
		let role_name = data2.role_name;
		let role_id = Number(selectedrole);
		let total_leaves = Number(data2.total_leaves);
		let used_leaves = Number(data2.used_leaves);
		let status = data2.status;
		let is_verified = false;
		let reporting_manager_name = data2.reporting_manager_name;
		let reporting_manager_id = Number(selectedReportingManagerId);
		let designation = selectedDesignationName;
		let department = Number(selectedDepartmentId);
		let is_owner = false;
		let company_id = employeeData.company_id;
		let company_code = employeeData.company_code;
		let manager = projecManagerData;

		let data = {
			first_name,
			last_name,
			password,
			email,
			phone,
			date_of_birth,
			gender,
			role_name,
			total_leaves,
			used_leaves,
			role_id,
			status,
			designation,
			department,
			is_verified,
			is_owner,
			company_id,
			company_code,
			reporting_manager_id,
			reporting_manager_name,
			manager,
		};
		setUserData(data);
		newFormData.append('first_name', data.first_name);
		newFormData.append('last_name', data.last_name);
		console.log(data);
		formData.append('data', JSON.stringify(data));
		postUser(formData);
	};

	const postUser = (data) => {
		console.log(newFormData.get('first_name'));
		setBackdropOpen(true);
		axios.post(`${base_url.api1}/myappapi/users`, data, {}).then(
			(response) => {
				addToast('Employee Created Successfully', {
					appearance: 'success',
					autoDismiss: true,
				});
				let log =
					employeeData.emp_name +
					' created the employee ' +
					newFormData.get('first_name') +
					' ' +
					newFormData.get('last_name');
				CallAuditapi(log);
				setBackdropOpen(false);
				navigate(`/employee-list`);
			},
			(error) => {
				console.log(error);
				setBackdropOpen(false);
				addToast(error, {
					appearance: 'error',
					autoDismiss: true,
				});
			}
		);
	};

	const [availableroles, setAvailableroles] = useState([]);

	const getRoles = () => {
		let urlData;
		if (employeeData.emp_role_id == 1) {
			urlData = employeeData.company_code + `&status=True&super_admin=True`;
		} else {
			urlData = employeeData.company_code + `&status=True`;
		}
		axios
			.get(
				`${base_url.api1}/myappapi/role/list?company_code=` +
					employeeData.company_code,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then((response) => {
				console.log(response.data.Role);
				setAvailableroles(response.data.Role);
			})
			.catch((error) => {
				//toast.error(JSON.stringify(error.response.data.message));
				console.log(JSON.stringify(error));
			});
	};
	const lookup = availableroles;

	const [selected, setSelected] = useState(-1);
	const value = selected !== -1 && lookup[selected];

	const [selectedrole, setSelectedrole] = useState();
	const [defaultRole, setdefaultRole] = useState();
	const handleChangeRole = (event) => {
		setdefaultRole(event.target.value);
		const index = event.target.selectedIndex;
		const optionElement = event.target.childNodes[index];
		const optionElementId = optionElement.getAttribute('id');
		console.log(optionElementId);
		const optionElementValue = optionElement.getAttribute('value');
		console.log(optionElementValue);

		setSelectedrole(optionElementId);
		setdefaultRole(optionElementValue);
	};

	const [selectedReportingManagerId, setSelectedReportingManagerId] =
		useState();
	const [selectedReportingManagerName, setSelectedReportingManagerName] =
		useState();
	const handleChangeReportingManager = (event) => {
		const index = event.target.selectedIndex;
		const optionElement = event.target.childNodes[index];
		const optionElementId = optionElement.getAttribute('id');
		console.log(optionElementId);
		const optionElementValue = optionElement.getAttribute('value');
		console.log(optionElementValue);
		setSelectedReportingManagerId(optionElementId);
		setSelectedReportingManagerName(optionElementValue);
	};

	const [selectedDepartmentId, setSelectedDepartmentId] = useState();
	const [selectedDepartmentName, setSelectedDepartmentName] = useState();
	const handleChangeDepartment = (event) => {
		const index = event.target.selectedIndex;
		const optionElement = event.target.childNodes[index];
		const optionElementId = optionElement.getAttribute('id');
		console.log(optionElementId);
		const optionElementValue = optionElement.getAttribute('value');
		console.log(optionElementValue);
		setSelectedDepartmentId(optionElementId);
		setSelectedDepartmentName(optionElementValue);
	};

	const [selectedDesignationId, setSelectedDesignationId] = useState();
	const [selectedDesignationName, setSelectedDesignationName] = useState();
	const handleChangeDesignation = (event) => {
		const index = event.target.selectedIndex;
		const optionElement = event.target.childNodes[index];
		const optionElementId = optionElement.getAttribute('id');
		console.log(optionElementId);
		const optionElementValue = optionElement.getAttribute('value');
		console.log(optionElementValue);
		setSelectedDesignationId(optionElementId);
		setSelectedDesignationName(optionElementValue);
	};

	const convertBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const fileReader = new FileReader();
			fileReader.readAsDataURL(file);
			fileReader.onload = () => {
				resolve(fileReader.result);
			};
			fileReader.onerror = (error) => {
				reject(error);
			};
		});
	};

	const [baseimage, setbaseimage] = useState('');
	const [logofilesize, setlogofilesize] = useState(false);
	const uploadLogo = async (data) => {
		const file = data.target.files[0];
		formData.append('image', file, file.name);
		const base64 = await convertBase64(file);
		setbaseimage(base64);
	};
	const chklogosize = (e) => {
		var chk = false;
		const numberfiles = e.target.files;
		for (let i = 0; i < numberfiles.length; i++) {
			if (numberfiles[i].size / 1048576 > 10) {
				chk = true;
			}
		}
		setlogofilesize(chk);
	};

	const [projecManagerState, setProjecManagerState] = useState(false);
	const [projecManagerData, setSelectedProjectManager] = useState();
	const onSelectProjectManager = (selectedList, selectedItem) => {
		console.log('Entering', selectedList);
		let mng_obj = [];
		for (let i = 0; i < selectedList.length; i++) {
			let data = {
				manager_id: selectedList[i].value,
				manager_name: selectedList[i].label,
			};
			mng_obj.push(data);
		}
		if (mng_obj.length == 0) {
			setProjecManagerState(true);
		} else {
			setProjecManagerState(false);
			setSelectedProjectManager(mng_obj);
		}
	};

	const [availableManager, setAvailableManager] = useState();
	const [managerData, setManager] = useState();
	const getManagerData = () => {
		axios
			.get(
				`${base_url.api1}/myappapi/all_users_by_role?role_id=1,2,3,4&company_code=` +
					employeeData.company_code,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then((response) => {
				// console.log(response.data.Users);
				setManager(response.data.Users);
				let manager_data = response.data.Users;
				const manager_object = [];
				for (let i = 0; i < manager_data.length; i++) {
					let data = {
						value: manager_data[i].user_id,
						label: manager_data[i].first_name + ' ' + manager_data[i].last_name,
					};
					manager_object.push(data);
				}
				setAvailableManager(manager_object);
			})
			.catch((error) => {
				console.log(JSON.stringify(error));
			});
	};

	const [availableDepatment, setAvailableDepartment] = useState();
	const [departmentData, setDepartmentData] = useState();
	const getDeparmentData = () => {
		axios
			.get(
				`${base_url.api1}/myappapi/department/list?company_code=` +
					employeeData.company_code,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then((response) => {
				console.log(response.data.Department);
				setDepartmentData(response.data.Department);
				let department_data = response.data.Department;
				const department_object = [];
				for (let i = 0; i < department_data.length; i++) {
					let data = {
						value: department_data[i].id,
						label: department_data[i].department_name,
					};
					department_object.push(data);
				}
				setAvailableDepartment(department_object);
			})
			.catch((error) => {
				console.log(JSON.stringify(error));
			});
	};

	const [availableDesignation, setAvailableDesignation] = useState();
	const [designationData, setDesignationData] = useState();
	const getDesignationData = () => {
		axios
			.get(
				`${base_url.api1}/myappapi/designation_list?company_code=` +
					employeeData.company_code,
				{
					headers: {
						Authorization: jwtToken,
					},
				}
			)
			.then((response) => {
				console.log(response.data.Designation);
				setDesignationData(response.data.Designation);
				let designation_data = response.data.Designation;
				const designation_object = [];
				for (let i = 0; i < designation_data.length; i++) {
					let data = {
						value: designation_data[i].id,
						label: designation_data[i].name,
					};
					designation_object.push(data);
				}
				setAvailableDesignation(designation_object);
			})
			.catch((error) => {
				console.log(JSON.stringify(error));
			});
	};

	return (
		<div>
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={backdropOpen}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Card className="commoncomponent_cardcss">
				<CardBody>
					<CardSubtitle className="font-weight-bold"></CardSubtitle>
					<div style={{ margin: '5px' }}>
						<Row>
							<Col md={6}>
								<br />
								<CardText
									className={classes.headtext}
									style={{ marginTop: '-10px' }}
								>
									<strong>Create Employee</strong>
								</CardText>
							</Col>
							<Col md={6}>
								<Button
									variant="contained"
									color="primary"
									style={{ float: 'right' }}
									onClick={goback}
								>
									&#x2630; Back
								</Button>
							</Col>
						</Row>
						<form
							id="myForm"
							onSubmit={handleSubmit(onSubmit)}
							autoComplete="off"
						>
							<div style={{ padding: '0 20px ' }}>
								<div className="form-group creatadmin">
									<Row>
										<Col md={3}>
											<label>
												First Name:<span style={{ color: 'red' }}>*</span>
											</label>
											<input
												type="text"
												{...register('first_name', { required: true })}
												id="first_name"
												className="form-control underline-input"
												placeholder="First Name"
											/>
											{errors.first_name && (
												<p className="errormessage">First Name is Required</p>
											)}
										</Col>
										<Col md={3}>
											<label>Last Name:</label>
											<input
												type="text"
												{...register('last_name', { required: false })}
												id="last_name"
												className="form-control underline-input"
												placeholder="Last Name"
											/>
											{errors.last_name && (
												<p className="errormessage">Last Name is Required</p>
											)}
										</Col>
										<Col md={3}>
											<label>
												Email:<span style={{ color: 'red' }}>*</span>
											</label>
											<input
												type="email"
												{...register('email', { required: true })}
												id="email"
												className="form-control underline-input"
												placeholder="Email"
											/>
											{errors.email && (
												<p className="errormessage">Email is Required</p>
											)}
										</Col>
										<Col md={3}>
											<label>
												Password:<span style={{ color: 'red' }}>*</span>
											</label>
											<input
												className="form-control underline-input forminput"
												type={inputtype}
												placeholder="Password"
												id="password"
												{...register('password', { required: true })}
												value={chkPassState}
												autoComplete="new-password"
												onChange={(e) => {
													setchkPassState(e.target.value);
													setchkPass(false);
												}}
											/>
											{inputtype === 'password' ? (
												<i
													className="fa fa-eye-slash fonticonpositioning"
													onClick={() => setinputtype('text')}
													aria-hidden="true"
												></i>
											) : (
												<i
													className="fa fa-eye fonticonpositioning"
													onClick={() => setinputtype('password')}
													aria-hidden="true"
												></i>
											)}
											<PasswordStrengthBar password={chkPassState} />
											{errors.password && (
												<p className="errormessage">Password is Required</p>
											)}
										</Col>
									</Row>
								</div>
								<div className="form-group creatadmin">
									<Row>
										<Col md={3}>
											<label>Date of Birth:</label>
											<input
												type="date"
												{...register('date_of_birth', { required: false })}
												id="date_of_birth"
												className="form-control underline-input"
												placeholder="Date Of Birth"
											/>
										</Col>
										<Col md={3}>
											<label>
												Designation:<span style={{ color: 'red' }}>*</span>
											</label>
											<select
												{...register('designation', {
													required: true,
												})}
												className={classes.selectdrop}
												onChange={(e) => {
													handleChangeDesignation(e);
												}}
											>
												<option value="">-Select Designation-</option>
												{designationData?.map((d, ix) => {
													return (
														<option key={d.id} id={d.id} value={d.name}>
															{d.name}
														</option>
													);
												})}
												;
											</select>
											{errors.designation && (
												<p className="errormessage">Designation is Required</p>
											)}
										</Col>
										<Col md={3}>
											<label>
												Phone:<span style={{ color: 'red' }}>*</span>
											</label>
											<input
												type="number"
												{...register('phone', { required: true })}
												id="phone"
												className="form-control underline-input"
												placeholder="Phone"
											/>
											{errors.phone && (
												<p className="errormessage">Phone is Required</p>
											)}
										</Col>
										<Col md={3}>
											<label>
												Gender:<span style={{ color: 'red' }}>*</span>
											</label>
											<select
												className={classes.selectdrop}
												{...register('gender', { required: true })}
											>
												<option value="">-Select Gender-</option>
												<option value="Male">Male</option>
												<option value="Female">Female</option>
												<option value="Other">Other</option>
											</select>
											{errors.gender && (
												<p className="errormessage">Gender is Required</p>
											)}
										</Col>
									</Row>
								</div>
								<div className="form-group creatadmin">
									<Row>
										<Col md={3}>
											<label>
												Role:<span style={{ color: 'red' }}>*</span>
											</label>
											<select
												{...register('role_name', { required: true })}
												className={classes.selectdrop}
												onChange={(e) => {
													setSelected(e.target.value);
													handleChangeRole(e);
												}}
												value={selected}
											>
												<option value="">-Select Role-</option>
												{lookup.map((m, ix) => {
													return (
														<option
															key={m.role_id}
															id={m.role_id}
															value={m.role_name}
														>
															{m.role_name}
														</option>
													);
												})}
												;
											</select>
											{errors.role_name && (
												<p className="errormessage">Rolename is Required</p>
											)}
										</Col>
										<Col md={3}>
											<label>Photo:</label>
											<input
												type="file"
												{...register('image', { required: false })}
												id="image"
												className="form-control underline-input"
												onChange={(data) => {
													uploadLogo(data);
													chklogosize(data);
												}}
												accept=".jpg, .jpeg, .bmp, .gif, .png"
											/>
											{logofilesize ? (
												<p className="errormessage">
													Please Upload Image Below 10mb.
												</p>
											) : null}
										</Col>
										<Col md={3}>
											<label>Total Leaves:</label>
											<input
												type="number"
												{...register('total_leaves', { required: false })}
												id="Total Leave"
												className="form-control underline-input"
												placeholder="Total Leaves"
											/>
											{errors.total_leaves && (
												<p className="errormessage">total_leaves is Required</p>
											)}
										</Col>
										<Col md={3}>
											<label>Used Leaves:</label>
											<input
												type="number"
												{...register('used_leaves', { required: false })}
												id="used_leaves"
												className="form-control underline-input"
												placeholder="Used Leaves"
											/>
											{errors.used_leaves && (
												<p className="errormessage">Used Leaves is Required</p>
											)}
										</Col>
									</Row>
								</div>
								<div className="form-group creatadmin">
									<Row>
										<Col md={3}>
											<label>
												Reporting Manager:
												<span style={{ color: 'red' }}>*</span>
											</label>
											<select
												{...register('reporting_manager_name', {
													required: true,
												})}
												className={classes.selectdrop}
												onChange={(e) => {
													handleChangeReportingManager(e);
												}}
											>
												<option value="">-Select Reporting Manager-</option>
												{managerData?.map((m, ix) => {
													return (
														<option
															key={m.user_id}
															id={m.user_id}
															value={m.first_name + ' ' + m.last_name}
														>
															{m.first_name + ' ' + m.last_name}
														</option>
													);
												})}
												;
											</select>
											{errors.reporting_manager_name && (
												<p className="errormessage">
													Reporting Manager is Required
												</p>
											)}
										</Col>
										<Col md={3}>
											<label>
												Project Manager:<span style={{ color: 'red' }}>*</span>
											</label>
											<Select
												isMulti
												className="basic-multi-select"
												classNamePrefix="select"
												onChange={onSelectProjectManager}
												options={availableManager}
											></Select>
										</Col>
										<Col md={3}>
											<label>
												Department:<span style={{ color: 'red' }}>*</span>
											</label>
											<select
												{...register('department', {
													required: true,
												})}
												className={classes.selectdrop}
												onChange={(e) => {
													handleChangeDepartment(e);
												}}
											>
												<option value="">-Select Department-</option>
												{departmentData?.map((m, ix) => {
													return (
														<option
															key={m.id}
															id={m.id}
															value={m.department_name}
														>
															{m.department_name}
														</option>
													);
												})}
												;
											</select>
											{errors.department && (
												<p className="errormessage">Department is Required</p>
											)}
										</Col>
										<Col md={3}>
											<label>Status:</label>
											<select
												className="form-control"
												{...register('status', { required: true })}
											>
												<option value={true}>Active</option>
												<option value={false}>InActive</option>
											</select>
										</Col>
									</Row>
								</div>
								<div
									style={{
										float: 'right',
										display: 'flex',
										padding: '5px',
									}}
								>
									<Button
										color="secondary"
										style={{
											marginTop: '20px',
											marginLeft: '5px',
											float: 'right',
										}}
										onClick={() => {
											document.getElementById('myForm').reset();
										}}
									>
										Reset
									</Button>
									<Button
										color="primary"
										type="submit"
										style={{
											marginTop: '20px',
											marginLeft: '5px',
											float: 'right',
										}}
									>
										Submit
									</Button>
								</div>
							</div>
						</form>
					</div>
				</CardBody>
			</Card>
		</div>
	);
};

export default CreateEmployee;
